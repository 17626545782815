@import '../../../../assets/scss/variables';

app-card-information {
  color: #595A5A;
  .card-outer {
    position: relative;

    display: flex !important;
    flex-direction: column;
    background-color: $white;
    border-radius: 20px;
    overflow: hidden;
    margin: 0 12px 48px;
    box-shadow: 0 4px 4px rgba(128, 137, 160, 0.25);
    .card__content {
      flex: 1 0 auto;
      display: flex;
      padding: 40px 15px;
      flex-direction: column;
    }
  }
  .description{
    font-size: 14px;
  }
  .item-highlighted {
    position: absolute;
    left: 0;
    width: 140px;
    padding: 7px 0;
    background: $yellow-color2;
    top: 0;
    border-radius: 5px 0;
    text-align: center;
    &.ends {
      background: $red-color1;
    }
    &.album {
      position: relative;
      left: -20px;
      width: auto;
      display: flex;
      padding-right: 15px;
      padding-left: 25px;
    }
    &.released {
      background: $red-color9;
    }
    &.legacy {
      @extend .gold-color;
    }
    &.news {
      background: $red-color1;
    }
    &.new {
      background: $green-color10;
    }
    &.newcomer {
      background: $blue-green;
    }
    &.headliner {
      background: $blue-color11;
    }
    &.talents {
      background: $gray-color1;
    }

    &.funded {
      background: $blue-color1;
    }

    &.failed {
      background: $gray-color3;
    }
    span {
      color: $white;
      font-weight: bold;
      font-size: $menu-font-size;
    }
  }
  .img-block {
    width: 100px;
    margin-right: 25px;
    margin-bottom: 12px;
    img{
      width: 100%;
    }
  }
}
