@import '../../../../assets/scss/variables';

app-carousel {
  .section-slider {
    width: 100%;
    position: relative;

    .slick-slider {
      width: 100%;
      margin: auto;
      height: 500px;
    }

    .slick-dots {
      bottom: 5px !important;

      li button:before {
        color: $white !important;
        opacity: 0.7;
        font-size: 12px;
      }

      li.slick-active button:before {
        color: $yellow-color2 !important;
        font-size: 15px;
        opacity: 1;
      }
    }

    .image-wrapper {
      height: 556px;
      width: 100%;
      background-size: cover;
      background-position: center center;
      position: relative;
    }

    .slider-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .slider-title {
      font-size: 64px;
      color: $white;
      font-weight: $font-weight-400;
      line-height: 75px;

      span {
        font-weight: $font-weight-900;
      }
    }

    p {
      font-style: normal;
      color: $white;
      line-height: 42px;
      font-weight: $font-weight-300;
      font-size: 36px;
    }
  }

  .slider-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .slider-title {
      order: 1
    }
    p {
      order: 2
    }
    .button {
      order: 3
    }
  }

  .slider-btn {
    width: 357px;
    background: $yellow-color2;
    font-size: 21px;
    height: 60px;
    line-height: 60px;
    margin-top: 32px;

    &:hover {
      color: #fff;
    }
  }

  .image-wrapper {
    &.mobile {
      display: none;
    }

    &.desktop {
      display: block;
    }
  }
}

@media screen and (max-width: 1024px) {
  app-carousel {
    .section-slider {
      .slider-title {
        font-size: 29px;
        line-height: 40px;
      }
      p {
        font-size: 18px;
      }
    }

    .slider-btn {
      width: 270px;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 767px) {
  app-carousel {
    .slider-wrapper {
      padding: 0 10px;
      .slider-title {
        order: 2
      }
      .button {
        order: 1
      }
      p {
        text-align: center;
        line-height:18px;
        order: 3
      }
    }
    .slider-btn {
      margin-top: 0;
      height: 50px;
      line-height: 50px;
      width: 250px;
    }
    .section-slider {
      .slider-title {
        font-size: 26px;
        line-height: 34px;
        margin-top: 18px;
        text-align: center;
      }
      .slider-text {
        align-items: flex-start;
        margin-top: 100px;
      }
      p {
        text-align: center;
      }
      .slick-slider {
        height: 400px;
      }

      .image-wrapper {
        height: 400px;

        &.mobile {
          display: block;
        }

        &.desktop {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  app-carousel {
    .section-slider {
      .slider-title {
        font-size: 24px;
        line-height: 30px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .header {
      padding: 0 5px !important;

      .login span {
        padding-right: 5px !important;
      }
    }

    .hamburger {
      left: 10px !important;
    }
  }
}
