@import "./variables";

@mixin maxW($maxw) {
  @media (max-width: $maxw) {
    @content;
  }
}

@mixin maxH($maxh) {
  @media (max-height: $maxh) {
    @content;
  }
}

@mixin minW($minw) {
  @media (min-width: $minw) {
    @content;
  }
}

@mixin minH($minh) {
  @media (min-height: $minh) {
    @content;
  }
}

@mixin screen1600 {
  @media screen and (max-width: 1600px) {
    @content;
  }
}
@mixin screen1490 {
  @media screen and (max-width: 1490px) {
    @content;
  }
}
@mixin screen1380 {
  @media screen and (max-width: 1380px) {
    @content;
  }
}
@mixin screen1370 {
  @media screen and (max-width: 1370px) {
    @content;
  }
}

@mixin screen1199 {
  @media screen and (max-width: 1199px) {
    @content;
  }
}

@mixin screen1023 {
  @media screen and (max-width: 1023px) {
    @content;
  }
}

@mixin screen767 {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin screen460 {
  @media screen and (max-width: 460px) {
    @content;
  }
}
@mixin screen414 {
  @media screen and (max-width: 414px) {
    @content;
  }
}
@mixin screen320 {
  @media screen and (max-width: 320px) {
    @content;
  }
}

@mixin buttonSubmitYellow {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: $font-weight-700;
  @content;
}
@mixin inputBorderNone {
  border: none;
  @content;
}
@mixin buttonSubmitSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  mat-spinner {
    position: absolute;
    left: 10px;
    top: 10px;
    circle{
      stroke: $white-color;
    }
  }
  @content;
}

@mixin placeholder($pl_size, $pl_weight, $pl_pos) {
  input::-webkit-input-placeholder { /* WebKit browsers */
    font-size: $pl_size;
    font-weight: $pl_weight;
    text-align: $pl_pos;
  }
  input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    font-size: $pl_size;
    text-align: $pl_pos;
  }
}
