@import '../../../../assets/scss/variables';

app-project-bids {
  color: $gray-color3;
  .bids__table {
    width: 100%;
  }
  .table-header {
    th {
      padding: 18px 0;
    }
    font-weight: 700;
    font-size: 14px;
  }
  .bid-status {
    white-space: nowrap;
    color: $red-color12;
    &.isWinning {
      color: $green-color10;
    }
    &.isLoosing {
      color: $red-color12;
    }
  }

  .bids__button {
    height: 40px;
    text-transform: uppercase;
    margin: 0 auto;
    position: relative;
    &.disable {
      opacity: 0.6;
      cursor: pointer;
    }

  }

  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: $white-color;

  }
  #button-spinner{
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .bids__row {
    &:nth-child(2n + 1) {
      background: #F2F7FB;
    }
    td {
      border-top: 1px dashed #C4C4C4;
      border-bottom: none;
      text-align: center;
      padding: 16px 0;
      color: $gray-color3;
      font-weight: 400;

      &.bid-song-name {
        text-transform: uppercase;
        font-weight: 500;

      }
    }

  }
  .d-date {
    display: block;
  }
  .m-date {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  app-project-bids {
    .bids__row {
      td {
        border: none;
        padding: 10px 0;
        &.bid-song-name, &.bid-amount {
          display: none;
        }
      }
    }
    .bids__button {
      height: 36px;
      min-width: 100px;
    }
    .table-header {
      display: none;
    }
    .m-date {
      display: block;
    }
    .d-date {
      display: none;
    }
  }
}
