@import '../../../assets/scss/variables';
app-sorting-home-mobile {
  .m-sort-tab {
    display: none;
  }
  @media screen and (max-width: 1024px) {
    .m-sort-tab {
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
      height: 0;
      overflow: hidden;
      box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.15);
      background: $white;
      z-index: 500;
      width: 100%;
      transition: height 0.25s ease-out;
      &.active {
        transition: height 0.25s ease-out;
        height: 365px;
        width: 100%;
      }
      .sort-tab__header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 17px 0;
        font-weight: 700;
        color: $black-color1;
        .picture-cross {
          position: absolute;
          right: 15px;
          top: 18px;
        }
      }
      .sort-tab__item-s {
        border-top: 1px dashed #A8C1D3;
      }
      .item-s {
        display: flex;
        align-items: center;
        &:last-child {
          justify-content: flex-end;
        }
      }
      .item-s__title {
        font-size: 14px;


        padding-left: 50px;
        font-weight: normal;
        color: $gray-color3;
        &.active {
          color: $black-color1;
        }
      }
      .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 30px;
      }
      .col-50 {
        &:first-child{
          width: 70%;
        }
        &:last-child{
          width: 30%;
        }

      }
    }
    .sort-icon{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        transform: translateY(-50%);
        top: 0;
        left: 2px;
        width: 19px;
        height: 21px;
      }
      &.sort-icon--end-soonest-active{
        &:before{
          background-image: url('/assets/images/home-sorting/date_asc.svg');
        }
      }
      &.sort-icon--end-soonest-disable{
        &:before{
          background-image: url('/assets/images/home-sorting/date_desc.svg');
        }
      }

      &.sort-icon--share-disable{
        &:before{
          width: 23px;
          background-image: url('/assets/images/home-sorting/share-disable.svg');
        }
      }
      &.sort-icon--share-enable{
        &:before{
          width: 23px;
          background-image: url('/assets/images/home-sorting/share-enable.svg');
        }
      }
      &.sort-icon--newest-enable{
        &:before{
          width: 23px;
          left:-1px;
          background-image: url('/assets/images/home-sorting/newest_first.svg');
        }
      }
      &.sort-icon--newest-disable{
        &:before{
          width: 23px;
          left:-1px;
          background-image: url('/assets/images/home-sorting/newest_last.svg');
        }
      }
    }
  }
}
