@import '../../../../assets/scss/variables';

:host {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .4;
  &.active {
    opacity: 1;
    .item, .prev, .next {
      cursor: pointer;
    }
  }
  .item, .prev, .next {
    cursor: default;
    color: $blue-color1;
  }
  .item {
    padding: 0 5px;
    display: inline-block;
    line-height: 26px;
    margin: 0 2px;
    &.active, &:hover {
      color: $gray-color1;
    }
    &.active {
      font-family: $font-weight-700;
      cursor: default;
    }
  }
  .prev {
    margin-right: 40px;
    text-transform: uppercase;
  }
  .next {
    margin-left: 40px;
    text-transform: uppercase;
  }
  .splitter {
    margin: 0 3px;
  }
  .disabled, .disabled:hover {
    color: #cacaca;
    background-color: transparent;
    cursor: default;
  }
  .range__text {
    font-size: 12px;
  }

  .mat-form-field {
    margin: 0 20px;
    width: 50px;
  }
}
