@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';

app-calculation {
  display: flex;
  @include placeholder(22px, 900, center);

  .lnr {
    cursor: inherit;
  }

  .inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-box, .bid__reminder {
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
  }

  .bid__reminder {
    margin-top: 15px;
    margin-bottom: 0;
  }

  .button {
    &.disable {
      opacity: 0.6;
      cursor: pointer;
    }
  }

  .select-share {
    margin-bottom: 10px;
  }

  #bid-closed__button-join {
    margin-top: 15px;
  }

  .bid-closed__remind-submitted {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      margin-top: 15px;
      font-weight: 900;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
    }

    #bid-closed__button-explore {
      margin-top: 15px;
    }
  }

  .button.button-explore {
    margin-top: 15px !important;
    margin-bottom: 0 !important;
  }

  .bid__text {
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    color: #8089A0;

    .link-blue {
      font-size: 12px;
    }
  }

  .bid-closed {
    width: 330px;
    min-height: 202px;
    position: absolute;
    padding: 15px;
    background: #DFEBF4;
    box-shadow: 0 2px 4px rgba(144, 170, 190, 0.5);
    border-radius: 7px;
    top: 50px;
  }

  .bid-closed__sold-out {
    width: 220px;
    height: 50px;
    background: #6182A8;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 700;
    color: $white-color;
    line-height: 50px;
    text-align: center;
    font-size: 15px;
    margin: 15px auto;
  }

  .bid-closed__text {
    font-weight: 900;
    font-size: 18px;
    text-align: center;
    padding: 0 20px;
  }

  #bid-closed__button {
    margin: 15px auto;
    width: 300px;
    max-width: 300px;
  }

  .bid-closed__button {
    line-height: 50px;
  }

  #bid-closed__sing-up {
    margin: 10px 0;
    line-height: 50px;
  }

  .bid-closed__sing-up-text {
    padding: 0;
  }

  .calculation__inner {
    &.blur {
      pointer-events: none;
      filter: blur(3px);
      -webkit-filter: blur(3px);
    }
  }

  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: $white-color;
  }

  mat-spinner {
    position: absolute !important;
    left: 10px;
  }

  .info-text {
    width: 45vw;
    background: url('/assets/images/calculate.jpg') center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    text-align: right;
    font-weight: 900;
    font-size: 69px;
    line-height: 1;
    text-transform: uppercase;
    color: #fff;

    .inner {
      background: linear-gradient(to left, #134D66 0%, rgba(19, 77, 102, 0.01) 89.43%);;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .sub-text {
      text-transform: none;
      font-size: 56px;
      line-height: 1.1;
    }
  }

  .button-s {
    background: #2ebd59;
    border-radius: 500px;
    color: #fff;
    cursor: pointer;
    font-size: 11px;
    line-height: 11px;
    min-width: 92px;
    padding-top: 2px;
    padding-bottom: 3px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    margin: 0 10px 0 0;
    float: left;
    width: fit-content;
    border: 0;
  }

  .bt-icon {
    background-image: url(https://open.scdn.co/cdn/images/follow/sprite@1.b994540b.png);
    background-position: 0 0;
    content: "";
    display: inline-block;
    height: 17px;
    position: absolute;
    top: 3px;
    border: 0;
    width: 17px;
    left: 3px;
  }

  .bt-text {
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    margin-left: 13px;
    border: 0;
    padding: 4px 4px 3px 8px;
  }

  .profile-title {
    padding: 15px 0;
    font-weight: 700;
    text-align: center;
    font-size: 15px;
  }

  .followers-icon {
    margin-top: 4px;
  }

  .followers-count {
    font-weight: 900;
    color: black;
    font-size: 14px;
    font-style: normal;
    padding-left: 30px;
    position: absolute;
    line-height: 16px;
  }

  .followers-count-twitter {
    font-weight: 900;
    color: black;
    font-size: 14px;
    font-style: normal;
    padding-left: 30px;
    position: absolute;
    line-height: 16px;
  }

  .followers-text {
    color: $gray-color1;
    font-style: normal;
    padding-left: 4px;
  }

  .followers-text-tap {
    color: $gray-color1;
    font-style: normal;
    padding-left: 4px;
  }

  .follow-caption {
    color: $gray-color1;
  }

  .follow-name {
    font-weight: 200;
  }

  .followers-container {
    width: 100px;
    height: 40px;
    word-wrap: normal;
  }

  .followers-social-row {
    height: fit-content;
  }

  .followers-flex-container {
    display: grid;
    grid-template-columns: 100px 100px 100px;
    grid-template-rows: 50px auto;
    justify-content: left;
    padding-top: 11px;
    pointer-events: none;
  }

  .row {
    width: 320px;
    display: flex;
    justify-content: flex;
    margin-top: 10px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
      max-width: 330px;
    }

    .button {
      text-transform: uppercase;
      width: 100%;
      height: 50px;
      max-width: 350px;
      font-weight: 500;
      font-size: 15px;
      margin: 0 auto 10px;
    }
  }

  .calculation__timer {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .calculation__description {
    font-weight: 400;
    font-size: 12px;
    color: #8089A0;
    text-align: center;
  }

  .get-title {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }

  .get-text {
    color: $gray-color3;
    list-style-type: disc;
    margin: 0px 40px;

    li {
      color: #5AA2E5;
    }

    .item {
      margin-bottom: 15px;
      padding-left: 20px;
      position: relative;
    }

    .lnr-chevron-right {
      position: absolute;
      top: 3px;
      left: 0;
      font-size: 9px;
      color: #5AA2E5;
      font-weight: 900;
    }
  }

  .select-wrapper {
    border: 2px solid $blue-color1;
    border-radius: 5px;
    height: 40px;
    width: 90px;
  }

  .collectible-scheduled {
    display: flex;
    flex-direction: column;
    @include placeholder(18px, 500, center);

    .timer {
      background: $white-color;
    }
  }

  mat-select {
    padding: 0;
    border: none;
  }


}

@media screen and (max-width: 1380px) {
  app-calculation {
    .input-box {
      background: #F2F7FB;
      border-radius: 5px;
    }

    .wrapper {
      padding: 0 54px;
    }

    .bid-closed {
      top: 40px;
    }
  }
}

@media screen and (max-width: 1100px) {
  app-calculation {
    .wrapper {
      padding: 0 54px 0 24px;
    }
  }
}

@media screen and (max-width: 1023px) {
  app-calculation {
    flex-direction: column;

    .info-text {
      width: 100%;
      height: 200px;
      font-size: 45px;
      text-align: center;

      br {
        display: none;
      }

      .inner {
        align-items: flex-end;
      }

      .sub-text {
        font-size: 30px;
        margin: 8px 0 25px;
      }
    }

    .followers-social-row {
      visibility: hidden;
      display: none;
    }

    .wrapper {
      height: auto;
      width: 100%;
      margin-top: 18px;
    }
  }
}

@media screen and (max-width: 767px) {
  app-calculation {
    .followers-social-row {
      visibility: hidden;
      display: none;
    }

    .input-box {
      background: $white-color;
    }

    .bid-closed {
      margin: auto;
    }
  }
}

@media screen and (max-width: 460px) {
  app-calculation {
    .info-text {
      display: none;
    }

    .followers-social-row {
      visibility: hidden;
      display: none;
    }

    .wrapper {
      margin-top: 100px;

      .inner {
        max-width: calc(100% - 20px);
      }
    }

    .select-share .item {
      width: 60px;
    }

    .select-wrapper {
      width: calc(100% - 201px);
    }
  }
}
