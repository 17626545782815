@import '../../../../assets/scss/variables';

app-input-date-picker {
  .input-width {min-width:100%;
    line-height: 50px;
    display:block;
    width: 100%;
    height: 42px;
    padding: 6px 12px;
    font-size: 14px;
    border: 2px solid #5AA2E5;
    color: #5AA2E5;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
  }
}


