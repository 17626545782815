@import "assets/scss/mixin";

:host {
  display: flex;
  gap: 16px;

  @include maxW(576px) {
    flex-direction: column;
    gap: 0;
  }
}

.refund {
  &__text {
    padding-top: 8px;
  }

  &__select-type  {
    @include maxW(576px) {
      width: 100%;
    }
  }
}