@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixin";

app-my-nft {
  .nft-list {
    padding: 30px 0;
    display: grid;
    grid-template-columns: repeat(4, auto);
    row-gap: 40px;
    column-gap: 20px;

    @include maxW(1480px) {
      margin: auto;
      grid-template-columns: repeat(3, auto);
    }

    @include maxW(1100px) {
      grid-template-columns: repeat(2, auto);
    }

    @include maxW(768px) {
      grid-template-columns: 1fr;
    }

  }

  app-tabs {
    margin-top: 10px;
    .tab-header {
      max-width: 1300px;
      @include screen767 {
        justify-content: flex-start;
      }
      @include screen460 {
        padding: 0 10px;
      }
    }
    .tab-item:first-child {
      @include screen767 {
        padding-left: 0;
      }
    }
  }
  app-kyc-status {
    @include screen767 {
      max-width: initial;
    }
  }

  .section-projects {
    display: flex;
    flex-wrap: wrap;
    max-width: 1450px;
    margin: 0 auto;
  }

  .main-wrapper {
    min-height: 100vh;
    background: $white;
  }

  main {
    flex: 1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }
  .page-section {
    border-top: 1px dashed $gray-color1;
  }

  .summary-info {
    padding-top: 15px;
    padding-left: 37px;

    &__row {
      width: 100%;
      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: center;

      .value {
        font-size: 27px;
        white-space: nowrap;
      }

      .text {
        text-align: left;
        color: $gray-color3;
      }

      .item {
        width: auto;
      }
    }

    &__list {
      display: flex;
      gap: 20px;

      width: 60%;
    }
  }

  .circle {
    background-color: #b7bccb;
    position: absolute;
    height: 40px;
    width: 40px;
    cursor: pointer;
    display: flex;
    right: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    .tooltip-card {
      width: 190px;
      top: 45px;
      &:after {
        bottom: unset;
        top: -7px;
        right: 15px;
      }
    }
    img {
      width: 20px;
      margin-top: 4px;
    }
  }

  .spinner {
    display: flex;
    justify-content: center;
    height: 200px;
    align-items: center;
    width: 100%;
  }

  .button-action {
    .button {
      height: 50px;
      width: 200px;
      font-size: 16px;
    }
  }

  .button.border-blue {
    background: transparent;
  }

  .nft-footer {
    display: flex;
    background: #fff;
    border-radius: 0 0 20px 20px;
    height: 80px;
    width: 310px;
    z-index: 0;
    position: relative;
    padding: 0 12px;
    margin: 0 12px 0;
    box-shadow: 0 4px 3px 0 rgb(128 137 160 / 40%);
    justify-content: center;
    align-items: center;
  }

  .card-nft-button {
    background-color: $gray-color1;
    color: $white-color;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    width: 160px;
    font-size: 18px;
    height: 40px;
  }
  .sell-button {
    background-color: $blue-color1;
  }

  .no-list-data {
    font-style: italic;
  }

  .load-more {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    .loader {
      height: auto;
      margin: auto 5px;
    }
  }

  .lnr.lnr-music-note {
    font-size: 14px;
  }

  .mobile-screen {
    display: none;
  }

  app-loader {
    height: 50px;
    display: flex;
  }

  .list__item:hover {
    z-index: 10;
  }

  .nft-image {
    width: 100%;
    min-height: 400px;
    object-fit: cover;
  }
}

@media screen and (max-width: 1370px) {
  app-my-nft {
    .section-projects {
      max-width: 95%;
    }
  }
}

@media screen and (max-width: 1123px) {
  app-my-nft {
    main {
      width: 100%;
    }
    .value {
      font-size: 36px;
    }
    .section-projects {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

@media screen and (max-width: 950px) {
  app-my-nft {
    .section-projects {
      max-width: -webkit-fill-available;
    }
    main {
      background: $white;
    }

    .summary-info__list {
      display: flex;
      align-items: center;

      width: 100%;
      justify-content: space-between;
      padding: 0;
    }
    .button-action {
      width: 100%;
      margin-top: 15px;
    }
    .load-more {
      margin: 0 0 15px 0;
    }

    .summary-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0 0;
    }

    .value {
      text-align: center;
      font-size: 24px;
    }
  }
  @media screen and (max-width: 767px) {
    app-my-nft {
      .desktop-screen {
        display: none;
      }
      .mobile-screen {
        display: block;
      }

      .summary-info__list {
        flex-direction: column;
        .summary-info__item {
          width: 100%;
          &.item__mobile-screen {
            display: flex;
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    app-my-nft {
      .nft-image {
        height: 300px;
      }
    }
  }
}
