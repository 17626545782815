@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';

app-main-slider {
  .section-slider {
    width: 100%;
    position: relative;
    @include screen460 {
      margin-top: $header-height-mobile;
    }
    .slick-slider {
      width: 100%;
      margin: auto;
      height: 555px;
      @include screen767 {
        height: 400px;
      }
    }

    .market-container {
      display: flex;
      order: 3;
    }

    .slick-dots {
      bottom: 5px !important;

      li button:before {
        color: $white !important;
        opacity: 0.7;
        font-size: 12px;

      }

      li.slick-active button:before {
        color: $yellow-color2 !important;
        font-size: 15px;
        opacity: 1;

      }
    }

    .image-wrapper {
      height: 556px;
      width: 100%;
      background-size: cover;
      background-position: center center;
      position: relative;
      @include screen767 {
        height: 400px;
        &.mobile {
          display: block;
        }
        &.desktop {
          display: none;
        }
        img {
          width: 100%;
          height: 400px;
          min-height: 400px;
        }
      }
    }

    .slider-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .slider-title {
      margin-bottom: 20px;
      font-size: 30px;
      color: $white;
      font-weight: 700;
      line-height: normal;
      text-align: center;

      @include maxW(767px) {
        font-size: 14px;
        margin-bottom: 10px;
      }

      span {
        font-weight: $font-weight-900;
      }
    }

    .slider-subtitle {
      margin-bottom: 20px;
      font-size: 45px;
      font-weight: 900;
      line-height: normal;
      color: $white;
      text-align: center;

      @include maxW(767px) {
        font-size: 25px;
      }
    }
  }

  .slider-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .slider-title {
      order: 1
    }
    p {
      order: 2
    }
    .button {
      order: 3
    }
  }

  .slider-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 357px;
    font-size: 21px;
    height: 60px;
    margin-top: 20px;
  }

  .image-wrapper {
    &.mobile {
      height: 0;
      overflow: hidden;
    }

    &.desktop {
      display: block;
    }
  }
  .image {
    height: 556px;
    width: 100%;
    min-height: 556px;
    object-fit: cover;
  }
}

@media screen and (max-width: 1024px) {
  app-main-slider {
    .section-slider {
      .slider-title {
        font-size: 29px;
        line-height: 40px;
      }
      p {
        font-size: 18px;
      }
    }

    .slider-btn {
      width: 270px;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 767px) {
  app-main-slider {
    .market-container {
      flex-direction: column;
      margin-top: 20px;
    }
    .slider-wrapper {
      padding: 0 10px;
      margin-bottom: 20px;
      .slider-title {
        order: 1
      }
      .button {
        order: 3
      }
      p {
        text-align: center;
        line-height: 18px;
        order: 2
      }
    }
    .slider-btn {
      margin-top: 10px;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
      width: 200px;
    }
    .section-slider {
      .slider-title {
        font-size: 14px;
        line-height: 34px;
        margin-top: 18px;
        text-align: center;
      }
      .slider-text {
        align-items: center;
      }
      p {
        text-align: center;
      }

    }
  }
}

@media screen and (max-width: 320px) {
  app-main-slider {
    .section-slider {
      .slider-title {
        font-size: 24px;
        line-height: 30px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .header {
      padding: 0 5px !important;

      .login span {
        padding-right: 5px !important;
      }
    }

    .hamburger {
      left: 10px !important;
    }
  }
}
