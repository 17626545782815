@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';

app-my-auction {
  .wrapper {
    min-height: 100vh;
    background: $white;
  }
  .info-amount {
    width: 100%;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item .text {
      margin-bottom: 0;
    }
    .text {
      white-space: nowrap;
    }
  }
  .table-header {
    border-bottom: 1px dashed $gray-color4;
  }
  .table th {
    border-bottom: unset;
  }
  .auction__list {
    padding: 25px 0;
  }

  .spinner {
    margin-top: 40px;
  }
  .bids__button {
    height: 40px;
    margin: 0 23px;
    position: relative;
    text-transform: uppercase;
    .button-spinner {
      position: absolute;
      left: 10px;
      top: 10px;
      circle {
        stroke: #fff;
      }
    }
  }
  .bid-status {
    color: $red-color12;
    &.isWinning {
      color: $green-color10;
    }
    &.isLoosing {
      color: $red-color12;
    }
    &.isQualified {
      color: $yellow-color2;
    }
  }
  .spinner {
    mat-spinner {
      left: 10px;
      top: 10px;
    }
  }
  tr {
    td {
      border-top: 1px dashed #C4C4C4;
    }
    &:first-child {
      td {
        border-top: none;
      }
    }
  }
  td {
    border-bottom: none;
    text-align: center;
    padding: 16px 0;
    color: $gray-color3;
    font-weight: 400;

    &.bid-amount {
      color: $black-color1;
    }
    .bid-song-name {
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
      color: $black-color1;

    }
  }
  th {
    text-align: center !important;
  }
  .auction-button {
    display: flex;
    justify-content: flex-end;
  }
  .timer__title {
    display: none;
  }
  .timer {
    .timer__timing-item {
      background: #8089A0 !important;
    }
  }


  .bids__row {
    &:nth-child(2n + 1) {
      td {
        background: #F2F7FB;
      }
    }

  }
  .auction__bids-amount {
    font-size: 27px;
  }
  .mobile-screen {
    display: none;
  }
  .auction__balance {
    .text {
      color: #8089A0;
      font-size: 14px;
    }
  }
  .auction__content {
    position: relative;
    border-top: 1px dashed #B7BCCB;
    width: 1300px;
    margin: 0 auto;
    padding-top: 18px;
    @include screen1370 {
      width: 90%;
    }
    @include screen767 {
      width: 100%;
      border-top: none;
      padding-left: 15px;
      padding-right: 15px;
    }
    @include screen320 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .mob-screen {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  app-my-auction {
    .mob-screen {
      display: block;
    }
    app-timer {
      margin-top: 15px;
    }
    .timer-col {
      display: flex;
      padding-right: 15px;
      flex-direction: column;
      align-items: flex-end;
      app-timer {
        margin-top: 15px;
        .timer__timing-item {
          font-size: 14px !important;
          line-height: 28px !important;
          height: 26px !important;
          width: 24px !important;
        }
        .timer__timing {
          justify-content: flex-end;
        }
      }

    }

    .bids__button {
      margin: 0;
      min-width: 120px;
    }
    .td-song {
      text-align: left;
      padding-left: 15px;
      p {
        line-height: 20px;
      }
    }
    .max-screen {
      display: none;
    }
    .bid-status {
      font-weight: 500;
    }

  }
}
