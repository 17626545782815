@import '../../../../assets/scss/variables';

app-nickname-popup {
  .inner {
    width: 560px;
  }
  .popup-content {
    position: relative;
  }

  .subscribe__input {
    border-bottom: 1px solid #B7BCCB;
    width: 100%;
    outline: 0;
    border-width: 0 0 1px 0;
    padding: 0 0 10px 40px;
    font-size: 14px;
  }
  .popup-input {
    margin: 30px 0;
    position: relative;
    &.error {
      .subscribe__input {
        color: $red-color1;
      }
      .popup-error {
        height: 10px;
        transition: all 1s ease-out;
      }
    }
  }
  .popup-error {
    transition: all 1s ease-out;
    color: $red-color1;
    font-size: 12px;
    text-align: left;
    line-height: 20px;
    height: 0;
  }
  .popup-sub-title {
    font-weight: 400;
    font-size: 15px;
    text-align: center;
  }
  .popup-image {
    position: absolute;
    left: 5px;
    top: 0;
  }
  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    padding: 0;
    font-size: 14px;
  }

  .popup-button {
    margin: 0 auto 15px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
  }
  .terms {
    font-weight: 400;
    font-size: 12px;
    color: $gray-color3;
    .link-blue {
      font-weight: 400;
      font-size: 12px;
    }
  }
}

