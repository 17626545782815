@import '../../../assets/scss/variables';

app-song-player {
  //  background: rgba(0, 0, 0, .8);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 320px;
  overflow: hidden;
  z-index: 10;
  .project-image-wrapper {
    height: 36px;
  }
  .project-image {
    width: 36px;
    height: 36px;
    min-width: 36px;
    margin-right: 20px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
    background-position: center;
    background-size: cover;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .share-button {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    width: 230px;
    line-height: 40px;
    height: 40px;
    margin: 0;
    &.disabled {
      background: $gray-color3;
      color: #fff;
      opacity: .5;
    }
    &.mobile {
      display: none;
    }

    &.desktop {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .fa-play, .fa-pause {
    font-size: 10px;
  }
  .name {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
    width: 95%;
    max-width: 300px;
    .song-name, .artist {
      display: block;
    }
    .song-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .wrapper-info {
    margin-bottom: 10px;
    width: 95%;
    display: flex;
  }
  .artist {
    color: $black-color1;
    font-weight: bold;
  }
  .song-name, .played, .total {
    color: $gray-color3;
  }
  .song-time {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .progress-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #fff;
  }

  .progress {
    height: 3px;
    width: 95%;
    border-radius: 5px;
    background: #DFEBF4;

    .inner {
      height: 3px;
      border-radius: 5px;
      background: $yellow-color2;
    }
  }
}

@media screen and (max-width: 1024px) {
  app-song-player {
    .progress-wrapper, .name {
      width: 380px;
    }

    .progress {
      width: 290px;
    }
  }
}

@media screen and (max-width: 767px) {
  app-song-player {

    .progress-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      display: block;

      .played, .total {
        display: none;
      }

      .progress {
        border-radius: 0;
        width: 100%;
      }
    }

    .name {
      width: calc(100vw - 140px);
      text-align: center;
      max-width: 100%;
    }

    .share-button {
      &.mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
      }

      &.desktop {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  app-song-preview {
    .name {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 auto 8px;
    }
  }
}
