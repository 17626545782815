@import '../../../../assets/scss/variables';

app-delete-popup {
  .inner {
    width: 460px;
    max-width: 90vw;
    position: relative;
    padding: 40px 20px;
    text-align: center;
  }
  .button {
    min-width: 160px;
  }
  .lnr-cross {
    position: absolute;
    right: 20px;
    font-size: 16px;
    font-weight: 600;
    top: 20px;
    cursor: pointer;
    color: $blue-color1;
  }

  .block-title {
    font-weight: 900;
    font-size: 21px;
    text-transform: uppercase;
    text-align: center;
    margin: 30px 0 10px;
  }

  .sub-text {
    font-size: 15px;
    text-align: center;
    margin-bottom: 10px;
    color: $black-color1;
    line-height: 24px;
  }

  .text {
    margin: 30px 0 10px;
    font-weight: bold;
    font-size: 21px;
    &.text-uppercase {
      text-transform: uppercase;
    }
  }

  .image {
    display: block;
    margin: 0 auto;
    height: 130px;
  }
  .button-wrapper {
    display: flex;
    width: 100%;
    font-size: 14px;
    margin: 30px auto 0;
    justify-content: space-around;
    align-items: center;
    .cancel {
      color: $link-color;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 767px) {
  app-delete-popup {
    .text br {
      display: none;
    }
    .inner {
      padding: 40px 10px;
    }
    .button {
      min-width: 150px;
    }
  }
}

