@import '../../../../assets/scss/variables';

app-share-popup {
  .inner {
    width: 580px;
    max-width: 90vw;
    position: relative;
    padding: 37px 20px 40px 20px;
    text-align: left;
    overflow: hidden;
  }
  .facebook-icon {
    width: 50px;
    height: 50px;
    background: url('../../../../assets/images/social/facebook_new.png') center;
    background-size: cover;
    font-size: 69px;
  }
  .icon-block {
    display: block;
    height: 50px;
  }
  .btn-share {
    width: 100%;
    height: 50px;
  }
  .social {
    width: 50px;
    cursor: pointer;
    .circle {
      &:before {
        width: 50px;
        height: 50px;
      }
    }
    &.facebook .fa-facebook-f {
      font-size: 22px;
      left: 18px;
      top: 14px;
    }
    &.twitter .fa-twitter {
      font-size: 22px;
      left: 15px;
      top: 15px;
    }
    &.linkedin .fa-linkedin {
      font-size: 22px;
      left: 16px;
      top: 15px;
    }
    &.pinterest .fa-pinterest-p {
      font-size: 26px;
      left: 15px;
      top: 12px;
    }
    &.whatsapp .fa-whatsapp {
      font-size: 28px;
      left: 12px;
      top: 11px;
    }
  }

  .mat-mini-fab.mat-accent {
    background-color: #0077b5;
  }
  .text-icon {
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
  }
  .mat-fab.mat-accent {
    background-color: #ffffff !important;
  }
  .mat-fab {
    box-shadow: none !important;
    .mat-button-wrapper {
      height: 100%;
      position: relative;
      padding: 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .mat-mini-fab .mat-button-wrapper {
    padding: 14px 0;
    display: inline-block;
    line-height: 24px;
  }

  .lnr-cross {
    position: absolute;
    right: 20px;
    font-size: 16px;
    font-weight: 600;
    top: 20px;
    cursor: pointer;
    color: $blue-color1;
  }
  .text-head {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
  }
  section {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .share-button-row {
    width: 100%;
    border-top: 1px dashed $gray-color4;
    padding-top: 34px;
  }

  .share-button-row button {
    display: table-cell;
  }
  .share-button-container.ws {
    display: none;
  }
  .share-flex-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .share-button-container {
    display: flex;
    justify-content: center;
    color: #000;
    width: 25%;
    flex-direction: column;
  }
  h1 {
    font-size: 20px;
    font-weight: bold;
  }
}

.agreement-text {
  font-weight: normal;
  text-align: left;
  padding-left: 15px;
  span {
    font-weight: 500;
  }
}

@media screen and (max-width: 767px) {
  app-share-popup {
    .text br {
      display: none;
    }
    .inner {
      width: 100%;
      max-height: 90%;
      overflow: hidden !important;
      padding: 20px 30px 30px 30px;
      height: 290px !important;
    }
    .share-flex-container {
      display: grid;
      grid-template-columns: 33% 33% 33%;
      grid-template-rows: 85px 85px;
      justify-content: space-evenly;
      align-items: flex-start;
    }
    .share-button-row {
      padding-top: 30px;
    }
    .share-button-container.ws {
      display: flex;
    }
    .share-button-row {
      display: grid;
    }
    .text-head {
      font-size: 18px;
      text-transform: initial;
    }
    .share-button-container {
      display: flex;
      justify-content: flex-end;
      color: #000;
      width: 100%;
      height: 100%;
      &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        justify-content: flex-start;
      }

      &:first-child, &:nth-child(4) {
        .mat-button-wrapper {
          justify-content: flex-start;
        }
        .text-icon {
          text-align: left;
        }
      }
      &:nth-child(3) {
        .mat-button-wrapper {
          justify-content: flex-end;
        }
        .text-icon {
          text-align: right;
        }
      }
    }
  }
}
