@import '../../../../assets/scss/variables';

app-card-news {
  color: #595A5A;
  .card-outer {
    position: relative;
    display: flex !important;
    flex-direction: column;
    background-color: $white;
    margin: 0 12px 48px;
    box-shadow: 0 4px 4px rgba(128, 137, 160, 0.25);
    .card__content {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
    }
    .card__footer {
      padding: 10px 20px;
      height: 68px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .card__description {
    font-weight: 400;
    z-index: 2;
    font-size: 13px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
  }
  .card__inner {
    padding: 0 20px;
    height: 240px;
    overflow: hidden;
  }
  .card__date-box {
    display: flex;
    align-items: center;
  }
  .followers-container {
    width: 30px;
    margin: 0 4px;
    height: 28px;
    justify-content: flex-end;
    word-wrap: normal;
  }
  .social-wrapper {
    width: 60%;
    display: flex;
    justify-content: flex-end;
  }
  .card__date {
    padding-left: 8px;
  }
  .card__content-inner {
    margin-top: 12px;
  }

  .social-view {
    font-weight: 500;
    font-size: 14px;
    width: 144px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $blue-color1;
    a:hover {
      text-decoration: none;
    }
    iframe {
      padding-top: 15px;
    }
  }

  .card__title {
    font-size: 18px;
    font-weight: bold;
    line-height: 36px;
  }
  .card__image {
    width: 100%;
    img {
      width: 100%;
      height: 160px;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 767px) {

  app-card-news {
    .card-outer {
      //margin: 20px auto;
      width: 310px;
    }
  }
}
