@import '../../../../assets/scss/variables';

app-bid-popup {
  .inner {
    width: 360px;
    max-width: 90vw;
    position: relative;
    padding: 0;
    text-align: center;
  }
  .button {
    height: 40px;
  }
  .content{
    padding: 0 20px;
    .item{
      width: 100%;
    }
  }
  .buttons-wrapper{
    justify-content: space-between;
  }
}

