@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

app-project-song-detail {
  .song-value {
    white-space: nowrap;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .legacy-block {
    position: relative;

    .fa-music {
      position: relative;
      font-size: 14px;
      top: 0;
      left: 0;
      margin-right: 8px;
      color: $blue-color1;
    }

    span {
      font-weight: 500;
    }

    .legacy__text {
      margin-top: 10px;
      font-size: 14px;
      color: #8089A0;

      .legacy__text-blue {
        color: $blue-color1;
      }
    }
  }


  .project__detail {
    position: relative;
    display: flex;

    .item-content-title {
      line-height: 23px;
      font-weight: $font-weight-900;
      font-size: 27px;

    }
  }


  .detail-song-info__static {
    display: flex;
    padding-top: 10px;
    // height: 40px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .grs-details {
    width: 100%;
  }

  .grs-details__body {
    display: flex;
    color: #8089A0;
    justify-content: space-between;

    .grs-details__col {
      width: 100%;

      li {
        display: flex;
        justify-content: space-between;
        height: fit-content;
        min-height: 24px;
      }

      .grs-details__line {
        color: $black-color1;
        font-weight: bold;
      }

      //.row-auto-height{
      //  height: fit-content;
      //}
    }
  }


}

@media screen and (max-width: 1490px) {
  app-project-song-detail {

    .detail-song-info__static-text {
      padding: 0 12px;
    }
  }
}

@media screen and (max-width: 1380px) {
  app-project-song-detail {

  }
}

@media screen and (max-width: 767px) {
  app-project-song-detail {

    .artist-name-wrapper {
      display: none;
    }

    .detail-song-info__static {
      flex-direction: column;
      padding-top: 10px;
      height: unset;
    }

    .detail-song-info__static-col {
      width: 100%;
      padding: 5px 0;
      margin-right: 0;
    }

    .detail-song-info__static-text {
      display: flex;

      p {
        padding-left: 5px;
      }
    }

    .grs-details__col li {
      height: 25px;
    }

    .teaser {
      margin: 15px 0 0;
      display: block;
      height: auto;
      overflow: unset;
    }


    .song-value {
      padding-left: 20px;
    }

    .grs-details__col {
      p {
        white-space: nowrap;
      }
    }

  }
}
