@import '../../../assets/scss/variables';

app-ubo {
  .ubo__form {
    display: flex;
    padding: 20px 0;
    //margin: 0 -25px;
  }
  .form__col {
    width: 33.33%;
    padding: 0 25px;
  }
  .form__item {
    width: 100%;
  }
  .col__wrapper {
    display: flex;
  }
  .form__birthdate {
    justify-content: space-between;
    .form__item {
      width: 32%;
    }
  }
  .form__address {
    justify-content: space-between;
    .form__item {
      width: 48%;
    }
  }
  .ubo__submit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 25px;
    .button {
      height: 40px;
    }
  }
}

@media screen and (max-width: 1199px) {
  .ubo__form{
    flex-direction: column;
    .form__col{
      width: 100%;
      padding: 0;
    }
  }
}
@media screen and (max-width: 767px) {
  .follow-wrapper {
    visibility: hidden;
    display: none;
  }
  .followers-social-row {
    visibility: hidden;
    display: none;
  }
}

@media screen and (max-width: 460px) {
  app-ubo {
    .info-text {
      display: none;
    }
    .follow-wrapper {
      visibility: hidden;
      display: none;
    }
    .followers-social-row {
      visibility: hidden;
      display: none;
    }
    .wrapper {
      margin-top: 100px;

      .inner {
        max-width: calc(100% - 20px);
      }
    }
    .follow-wrapper {
      border-bottom: 1px dashed #C4C4C4;
      min-height: 40px;
      justify-content: center;
      width: 100%;
      grid-auto-columns: 38%;
      padding-top: 6px;
    }
    .select-share .item {
      width: 60px;
    }

    .select-wrapper {
      width: calc(100% - 201px);
    }
  }
}
