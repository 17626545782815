@import "../variables";
@import "../mixin";

.input-box{
  border: none;
  width: 100%;
  font-size: 16px;
  padding: 16px 20px 13px 20px;
  border-radius: 5px;
}
