@import '../../../assets/scss/variables';

app-artist-song {
  display: flex;
  min-height: 100vh;
  background-color: white;

  .info-text {
    width: 45vw;
    background: url('../../../assets/images/wizard.jpg') center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    text-align: right;
    font-weight: 900;
    font-size: 69px;
    line-height: 1;
    text-transform: uppercase;
    color: #fff;

    .inner {
      background: linear-gradient(to left, #134D66 0%, rgba(19, 77, 102, 0.01) 89.43%);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .sub-text {
      text-transform: none;
      font-size: 56px;
      line-height: 1.1;
    }
  }

  .wrapper {
    width: 55vw;
    background: $white-color;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
      max-width: 70%;
      padding: 100px 0 50px;
      width: 416px;
    }

    .agreement {
      border-bottom: 1px dashed $gray-color4;
      padding: 20px 0 20px 20px;

      .agreement-text {
        width: 270px;
        padding-left: 10px;
      }
    }

    .button-wrapper {
      margin-top: 30px;
      padding: 0 25px;
      justify-content: space-between;
      align-items: center;

      .button.yellow {
        width: 210px;
        height: 50px;
        font-size: 16px;
        font-weight: $font-weight-700;
      }

      .button.transparent {
        min-width: 100px;
        width: 100px;
        font-size: 16px;
        font-weight: $font-weight-700;
      }

      .cancel {
        color: $link-color;
        font-size: 14px;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 500;
        text-align: center;
        width: 50%;
        .fas {
          margin-right: 10px;
        }
      }
    }
    .item-document {
      display: flex;
      mat-spinner {
        margin-right: -40px;
        margin-left: 10px;
        margin-top: 15px;
      }
    }
    .cancel-message.cancel {
      text-align: center;
      width: 100%;
    }
    .title {
      font-weight: 900;
      font-size: 24px;
      margin-bottom: 25px;
      text-align: center;
    }

    .item-document {
      padding: 0 25px;
      margin-top: 10px;
    }
    .upload-section {
      width: 100%;
      height: 50px;
      &:last-child {
        margin-left: 0;
      }
      label {
        flex-direction: row;
      }
      .fa-music {
        font-size: 20px;
        padding-right: 18px;
      }
    }

    .provide-song {
      border-bottom: 1px dashed #A8C1D3;
      margin: 15px 25px 15px;
      .user-raw {
        padding: 15px 25px 15px;
      }
    }
    .item {
      color: $gray-color3;
      padding: 0 25px;
      margin-top: 30px;
    }
    .item + .social-item {
      .mat-item {
        margin-top: 15px;
        padding: 0 25px 0 58px;
      }
    }
    .mat-item {
      width: 100%;
      padding: 0 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .far, .fas {
        color: $link-color;
      }
    }
    .input-text-item {
      &.mat-item {
        margin-top: 10px;
      }
    }
    .item-align-left {
      align-items: flex-start;
    }
    .span-label {
      color: $gray-color3;
    }
    .social-item {
      position: relative;
      .mat-item {
        margin-top: 2px;
        padding: 0 25px 0 58px;
      }
    }
    .social-links {
      margin-top: 20px;
    }
    .followers-container {
      position: absolute;
      left: 20px;
    }

    .user {
      color: $black-color1;
      font-size: 18px;
      line-height: 32px;
      font-weight: bold;
    }
    .button {
      width: 100%;
      text-transform: uppercase;
    }
  }
  .sum-message {
    .title-status {
      font-weight: 900;
      font-size: 24px;
      margin-bottom: 25px;
      text-align: center;
    }
    .text {
      font-weight: 500;
      text-align: center;
      font-size: 16px;
      color: $gray-color3;
    }
  }
  .icon-cancel {
    cursor: pointer;
  }

  .file-name {
    font-weight: 600;
    .icon-cancel {
      padding-right: 10px;
    }
    .fa-times-circle {
      color: $red-color1;
    }
  }
  .success, .error {
    width: 75px;
    height: 75px;
    margin: 0 auto 20px;
    border-radius: 50%;
    color: #fff;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .success {
    background: $green-color2;
  }

  .error {
    background: $red-color1;
  }
  button:disabled {
    cursor: initial;
  }
  .links {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    line-height: 28px;
  }
}

.m25-left {
  margin-left: 25px;
}

@media screen and (max-width: 1023px) {
  app-artist-song {
    flex-direction: column;
    overflow: scroll;
    .info-text {
      width: 100%;
      height: 200px;
      font-size: 45px;
      text-align: center;

      br {
        display: none;
      }

      .inner {
        align-items: flex-end;
      }

      .sub-text {
        font-size: 30px;
        margin: 8px 0 25px;
      }
    }

    .wrapper {
      width: 100%;
      height: 100vh;
      .inner {
        padding-top: 0;
        height: calc(100vh - 40px);

        display: flex;
        flex-direction: column;
      }
      #step-three {
        flex: 1;
      }
      .item {
        width: 368px;
      }
    }
    .progress-line {
      display: none;
    }
    .title {
      padding-top: 30px;
    }
  }
}

@media screen and (max-width: 460px) {
  app-artist-song {
    .info-text {
      display: none;
    }

    .sum-message {
      .title-status {
        font-weight: normal;
        font-size: 18px;
      }

      .text {
        font-weight: 400;
        font-size: 14px;
      }
    }

    .wrapper {
      margin: 20px 0;
      height: 100vh;

      .mat-item, .item, .item-document, .button-wrapper {
        padding: 0 14px;
      }

      .provide-song {
        border: none;

        .user-raw {
          padding: 15px 14px 0;
        }
      }

      .followers-container {
        left: 14px;
      }

      .inner {
        height: 100%;
        max-width: calc(100% - 20px);
        width: 100%;
        padding: 70px 0 20px 0;
      }

      .social-item {
        .mat-item {
          padding: 0 14px 0 58px;
        }
      }

      .stickFooter {
        height: calc(100vh - 84px);
      }

      .item {
        width: 100%;
      }
    }

    .select-share .item {
      width: 60px;
    }

    .select-wrapper {
      width: calc(100% - 201px);
    }

    .progress-line {
      display: none;
    }

    .title {
      display: none;
    }

    .mobile-steps {
      display: flex;
    }

    .payment-item {
      max-width: calc(50% - 20px);
    }

    .m25-left {
      margin-left: 10px;
    }

    .wrapper .provide-song {
      margin: 15px 10px 15px;
    }
  }
}

@media screen and (max-width: 320px) {
  app-artist-song {
    .wrapper {
      margin-top: 20px;
      .button-wrapper {
        font-size: 13px;
        .cancel {
          font-size: 13px;
        }
        .button.yellow {
          font-size: 13px;
          width: 180px;
        }
      }
    }
  }
}

