@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';

app-navigation {
  .hamburger {
    cursor: pointer;
    display: none;
    position: absolute;
    left: 0;
    top: 8px;
    padding: 15px;
    color: $white;
    z-index: 2;
    .hamburger-inner {
      background-color: $white;
      @include screen767 {
        width: 20px;
        height: 3px;
        top: 6px;
      }
      &::before {
        background-color: $white;
        @include screen767 {
          width: 20px;
          height: 3px;
          top: 10px;
        }
      }
      &::after {
        background-color: $white;
        @include screen767 {
          width: 20px;
          height: 3px;
         // top: 16px;
        }
      }
    }
    &.is-active {
      .hamburger-inner {
        background-color: $white;
        &::before {
          background-color: $white;
        }
        &::after {
          background-color: $white;
        }
      }
    }
    @include screen1199 {
      display: block;
    }
  }
  .navigation-list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navigation__item {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-size: $menu-font-size;
      color: $white;
      padding: 0 26px;
      font-weight: 500;
      line-height: 74px;
      cursor: pointer;
      position: relative;
      .navigation-list__link {
        opacity: 0.7;
      }
      &.active {
        .navigation-list__link {
          opacity: 1;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 14px;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 59px;
          height: 3px;
          background: #FFA800;
        }
      }
      @include screen1490 {
        padding: 0 18px;
      }
      @include screen1370 {
        padding: 0 10px;
      }
    }
    @include screen1490 {
      width: 100%;
    }
    @include screen1199 {
      display: none;
    }

  }
  .dropdown-menu {
    position: absolute;
    background-color: $white;
    left: 6px;
    transition: all 0.2s ease-in;
    height: 0;
    overflow: hidden;
    right: 6px;
    border-radius: 7px;
    top: 60px;
  }
  .sub-menu-mobile {
    border-top: 1px dashed $gray-color4;
    .sub-menu-item-mobile {
      padding: 20px 40px;
      &:last-child {
        border-top: 1px dashed $gray-color4;
      }
    }
  }

  .navigation-mobile-list {
    &.active {
      transition: all 0.2s ease-in;
      height: 285px;
      @include screen767 {
        height: 80px
      }
    }
  }

  .navigation-mobile-list__link {
    padding: 20px 28px;
    border-bottom: 1px dashed #c4c4c4;
    color: #8089a0;
    @include screen767 {
      padding: 7px 18px;
      font-size: 10px;
    }
  }

  .navigation-mobile-list__item {
    .navigation-item:hover .sub-menu {
      height: 88px;

      display: block;
    }
  }

  .fas {
    border: 10px solid transparent;
    cursor: pointer;
    transition: all .25s ease-out 0s;

    &.opened {
      transform: rotate(-180deg)
    }
  }

  .sub-menu {
    position: absolute;
    display: none;
    top: 65px;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 0 0 3px #8089A0;
    left: 0;
    overflow: hidden;
    width: 240px;
    .sub-menu-item {
      padding: 0 20px;
      color: $gray-color3;
      line-height: 44px;
      border-top: 1px dashed $gray-color4;
      &:first-child {
        border-top: none;
      }
      &:hover {
        color: #fff;
        background: #FFA800;
      }
    }
  }
}

