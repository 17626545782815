@import '../../../assets/scss/variables';

app-menu-search {
  .search {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .search__col {
    display: flex;
    align-items: center;
    &.search__col--direction {
      flex-direction: column;
    }
    &.item__col_j-end {
      justify-content: center;
      align-items: flex-end;
    }
  }
  .search__clear {
    cursor:pointer;
    .button {

      &.active {
        border: $blue-color1;
        color: $blue-color1;
      }
      border: $gray-color4;
      color: $gray-color4;
      height: 40px;
      min-width: 120px;
      background: transparent;
    }

    span {
      color: $blue-color1;
      font-size: 18px;
    }
  }
  .sort {
    .sort__value {
      color: $blue-color1;
      font-weight: normal;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .sort__title {
      font-size: 12px;
    }

    .sort__text {
      padding-right: 10px;
    }
    .fas {

      color: $gray-color4;
      &.active {
        color: $blue-color1;
      }
    }
  }
  .arrows-tablet {
    display: flex;
    align-items: center;
    .fa-calendar-minus {
      padding-right: 10px;
    }
    svg {
      path {
        fill: white;
      }
    }
  }

  .search__arrow {
    display: none;
  }
  .clear__desktop {
    display: block;
  }
  .clear__mobile {
    display: none;
  }
  .sort__dropdown {
    display: flex;
  }
}

@media screen and (max-width: 1400px) {
  app-menu-search {
    .search {
      max-width: 1240px;
    }
    .mobile__view {
      width: 160px;
    }
  }
}

@media screen and (max-width: 1280px) {
  app-menu-search {
    .search {
      max-width: 90%;
    }
    .clear__desktop {
      display: none;
    }
    .clear__mobile {
      display: block;
    }
    .mobile__view {
      width: 150px;
    }
  }

}

@media screen and (max-width: 1120px) {
  app-menu-search {
    .mobile__view {
      width: 140px;
    }
  }
}

@media screen and (max-width: 1024px) {
  app-filter-mobile {
    .search {
      width: 100%;
    }
    .search__arrow {
      display: flex;
    }
  }
}
