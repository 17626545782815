@import '../../../assets/scss/variables';
app-video-player {
  background: rgba(0, 0, 0, .8);
  height: 76px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 320px;
  overflow: hidden;
  z-index: 10;
  left: 0;
  position: fixed;
  bottom: 0;
  .project-image {
    width: 54px;
    height: 54px;
    border: 3px solid #fff;
    margin-right: 30px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .desktop-butt {
    margin-left: 30px;
  }

  progress::-webkit-progress-value {background-color: $yellow-color2 !important;}

  .share-button {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    width: 230px;
    line-height: 40px;
    height: 40px;
    margin: 0;
    &.disabled{
      background: $gray-color3;
      color: #fff;
      opacity: .5;
    }
    &.mobile {
      display: none;
    }

    &.desktop {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .name {
    width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 8px;
    color: #fff;
  }

  .artist {
    color: $gray-color3;
  }

  .progress-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //margin-right: 30px;
    width: -webkit-fill-available;
    color: #fff;
  }

  .progress {
    height: 5px;
    width: 390px;
    border-radius: 5px;
    background: rgba(255, 255, 255, .4);
    cursor: pointer;

    .inner {
      height: 5px;
      border-radius: 5px;
      background: $yellow-color2;
    }
  }
  .volume {
    margin-left: 30px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .muted{
    margin: 0.3rem;
  }
  .volume-level {
    height: 5px;
    width: 40px;
    border-radius: 5px;
    background: rgba(255, 255, 255, .4);

    .volume-inner {
      height: 5px;
      border-radius: 5px;
      background: $yellow-color2;
    }
  }
}

@media screen and (max-width: 1024px) {
    app-video-player {
    .progress-wrapper, .name {
      width: 400px;
    }

    .progress {
      width: 290px;
    }
  }
}

@media screen and (max-width: 767px) {
    app-video-player {
    padding-top: 5px;
    height: 93px;
    margin-left: -15px;
    .progress-wrapper, .name {
      width: unset;
      flex-wrap: wrap;
    }

    .mobile {
      display: flex;
      flex-direction: column;
    }

    .project-image {
      width: 60px;
      height: 60px;
      margin-right: 10px;
      margin-left: 10px;
    }
    .volume {
      margin-left: 20px;
    }

    .progress-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      .played, .total {
        display: none;
      }

      .progress {
        border-radius: 0;
        width: 100%;
      }
    }

    .name {
      //width: calc(100vw - 140px);
      width: 200px;
      text-align: center;
      display: flex;
      justify-content: center;
    }
    .desktop-butt {
      display: none;
    }

    .share-button {
      &.mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        align-self: center;
      }

      &.desktop {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 460px) {
    app-video-player {
    .name {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 auto 8px;
    }
  }
}
