@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixin";
app-nft-wizard {
  display: flex;
  min-height: 100vh;
  .button {
    font-size: 16px;
  }
  .flow-info-text {
    font-weight: 900;
    line-height: 1;
    font-size: 69px;
    background-image: url("../../../assets/images/wizard.jpg");

    .inner {
      font-size: 69px;
      background: linear-gradient(to left, #134D66 0%, rgba(19, 77, 102, 0.01) 89.43%);
    }
  }

  .kyc-validation {
    width: 460px;
    button {
      text-transform: uppercase;
      margin: 0 auto;
    }
    p {
      margin-bottom: 20px;
    }
  }

  .nft-wizard__view-token {
    max-width: 210px;
    margin: 10px auto 0;
  }
  app-ubo {
    .ubo__form {
      flex-direction: column;
      align-items: center;
      border-top: 1px dashed #a8c1d3;
      border-bottom: none;
      padding: 10px 0;

    }
    .form__col {
      width: 100%;
      padding: 0;
    }
  }

  .upload-wrapper {
    margin-bottom: 20px;
  }

  .step__ubo {
    max-width: 416px;
    margin: 38px 0;
  }

  .ubos__tilte {
    padding: 10px 0;
  }

  .ubo {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    max-width: 416px;
    gap: 20px;
    .mat-progress-spinner circle,
    .mat-spinner circle {
      stroke: $white !important;
    }
  }

  .ubo-wrapper {
    height: 0;
    overflow-y: scroll;
    padding: 0 20px;
    &.addUBO {
      height: 600px;
    }
  }

  .loader-picture__image {
    width: 100%;
  }

  app-checkbox {
    margin-right: 10px;
  }

  .shareholders__ubos {
    width: 100%;
  }

  .add-shareholder {
    display: flex;
    margin: 20px 0;
    justify-content: flex-end;
    a {
      padding-right: 20px;
      text-decoration: none;
    }
  }


  .wizard__button {
    width: 264px;
    position: relative;
    margin: 0 auto;
    font-size: 16px;
    mat-spinner {
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }

  .right-part {
    //overflow: auto;
    width: 55vw;
    background: #fff;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .steps-container {
    border-top: 1px dashed #a8c1d3;
    border-bottom: 1px dashed #a8c1d3;
    padding: 40px 0;
    max-width: 416px;
    //  margin-top: 38px;
  }

  .summary-info__checkbox-list {
    border-top: 1px dashed #a8c1d3;
    border-bottom: 1px dashed #a8c1d3;
    display: flex;
    flex-flow: column;
    justify-content: center;
    &.checkbox-list--size {
      width: 416px;
      // height: 126px;
    }
    &.checkbox-list--pos {
      margin: 20px 0;
      padding: 20px 60px;
    }
  }

  .summary-info {
    .checkbox-container1 {
      display: flex;
      align-items: center;
      margin: 10px 0;
      &.hide {
        display: none;
      }
      .checkbox-text {
        font-weight: 500;
        white-space: nowrap;
        color: #8089a0;
      }
    }
    .block-title {
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .button {
      font-size: 16px;
      line-height: 50px;
    }
  }

  .step {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    align-items: center;
    gap: 10px;
    padding-left: 20px;
  }

  .step-number {
    align-items: center;
    width: 100%;
    justify-content: center;
    display: flex;
    .circle {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #f2f7fb;
      box-shadow: inset 0 0 8px rgba(144, 170, 190, 0.2);
    }
    p {
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 16px;
      text-transform: capitalize;
      color: #8089a0;
    }
  }

  .nft-wizard__text {
    color: #8089a0;
    max-width: 370px;
    margin: 0 auto;
    text-align: center;
  }

  .create-wallet {
    .steps-container {
      margin-top: 20px;
      margin-bottom: 20px;

    }
    button {
      margin-top: 40px;
    }
  }

  .kyc-validation {
    .block-title {
      max-width: 460px;
    }
  }

  .new-wallet-block {
    display: flex;
    flex-flow: column;
    width: 416px;
    height: 256px;
    justify-content: space-evenly;
    gap: 15px;
  }

  .decision-buttons {
    display: flex;
    align-items: center;
    gap: 51px;
    width: 100%;
    padding-left: 40px;
    .left-button {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      text-transform: uppercase;
      /* blue2 */
      color: #5aa2e5;
    }
    .right-button {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 265px;
    }
  }

  .success-background {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: url("../../../assets/images/nftwizard/greendelipse.svg");
    width: 75px;
    height: 75px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .succes-finger {
    background: url("../../../assets/images/nftwizard/fingerup.svg");
    background-repeat: no-repeat;
    width: 49px;
    height: 64.46px;
    margin-left: 10px;
    margin-top: 15px;
  }

  .icon-lock {
    background: url("../../../assets/images/nftwizard/lock.svg");
    padding-right: 55px;
    background-repeat: no-repeat;
    width: 49px;
    height: 64.46px;
  }

  .step1-completed {
    background: url("../../../assets/images/nftwizard/done.svg");
    width: 30px;
    height: 30px;
  }

  .cancel {
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
  }

  .button-wrapper.nft-button-wrapper {
    align-self: center;
    display: flex;
    gap: 64px;
    align-items: center;
    justify-content: center;
    button {
      width: 213px;
    }
    a {
      text-decoration: none;
    }
  }

  .step-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }

  .cancel {
    align-self: flex-start;
    align-items: center;
    .lnr {
      padding-right: 10px;
    }
  }

  .spinner__button {
    position: relative;
    mat-spinner {
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }

  .item-document,
  .file-doc__input {
    width: 177px;
    height: 110px;
    background: #eefcff;
    position: relative;
    border: 2px dashed $blue-color1;
    overflow: hidden;
    &:last-child {
      margin-left: 15px;
    }
    label {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
    }
    span,
    .fa-camera {
      color: $blue-color1;
    }
    img {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    span {
      font-size: 14px;
    }
    .fa-camera {
      font-size: 20px;
      line-height: 23px;
    }
  }

  .file-doc__input {
    width: auto;
    &:last-child {
      margin-left: 0;
    }
  }

  .form {
    border-top: 1px dashed $gray-color4;
  }

  .lnr-cross {
    position: absolute;
    right: 20px;
    font-size: 16px;
    font-weight: 600;
    top: 20px;
    cursor: pointer;
    color: $blue-color1;
  }

  .block-title {
    font-weight: 900;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
  }
}

@media screen and (max-width: 1023px) {
  app-nft-wizard {
    flex-direction: column;

    .ubo__form {
      border-top: none;
    }
    .flow-info-text {
      .sub-text {
        display: none;
      }
      .sub-text2 {
        display: block !important;
        font-weight: 900;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
      }
    }
    .right-part {
      flex-grow: 1;
      width: 100%;
      align-items: flex-start;
    }
    .new-wallet-block {
      border-top: none;
      border-bottom: 1px dashed #a8c1d3;
    }

    .summary-info__checkbox-list {
      &.checkbox-list--size {
        width: 100%;
      }
    }
    .success-background {
      margin-top: 100px;
    }
    .flow-progress-line {
      display: none !important;
    }
    .steps-container {
      border-top: none;
    }
    .ubo {
      h3 {
        display: none;
      }
      .shareholders__ubos {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  app-nft-wizard {
    .kyc-button-wrapper {
      width: 100%;
      flex-direction: column;
      .button-readable {
        width: 100% !important;
        margin-top: 20px;
      }
    }
    .nft-wizard {
      .button-upload {
        margin-top: 20px;
        width: 100% !important;
      }
      .crop__upload {
        width: 100% !important;
      }
      .other-photo {
        margin-top: 20px;
      }
    }
    .steps-container {
      margin-top: 0;
    }
    app-upload-file-popup {
      .inner {
        padding: 30px 18px !important;
        max-width: 100vw !important;
        height: 100%;
      }
      .mobile-button {
        justify-content: space-between;
        .button {
          width: auto !important;
          min-width: auto !important;
          height: 40px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 !important;
          &.take-photo-btn {
            width: 115px !important;
          }
          &:first-child {
            padding: 0 10px;
          }
          &.isActive {
            background: $blue-color1;
            color: $white-color;
          }
          &.disabled {
            opacity: 0.3;
            border: 2px solid #949494;
            cursor: default;
          }
        }
        label {
          justify-content: center;
          padding: 0 10px;
          white-space: nowrap;
          margin-left: 14px;
        }
      }
      .item-document {
        width: 100%;
        margin-top: 16px;
        &:last-child {
          margin-left: 0;
        }
      }
      .popup-wrapper {
        width: 100% !important;
        max-height: 100%;
        height: 100%;
        top: 0;
      }
      .button-wrapper {
        border: none;
        padding-top: 0;
        button {
          max-width: 100%;
        }
      }
      .mobile-preview {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        top: 0;
        left: 0;
        padding: 0 16px;
        background: white;
        label {
          min-width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        img {
          width: 100%;
        }
        .img-block {
          max-width: 80%;
          margin: 0 auto;
          flex: 1;
          width: 100%;
          padding: 20px 0;
          text-align: center;
        }
        .button-wrapper {
          margin-bottom: 40px;
        }
      }
      .other-photo {
        line-height: 48px;
        margin-top: 16px;
      }
      .form {
        padding: 0;
      }
      .block-title {
        font-size: 18px;
      }
      .form-wrapper {
        padding: 20px 0 0 0;
      }
      .wrapper-upload {
        flex-direction: column;
        padding-bottom: 20px;
      }
      .mobile-upload {
        display: block;
      }
      .desktop-upload {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  app-nft-wizard {
    .nft-wizard {
      .kyc-validation__upload {
        width: 100%;
      }
    }

    .flow-progress-line {
      display: none !important;
    }

    .create-wallet, .kyc-validation, .summary-info {
      align-self: baseline;
      padding: 0 20px;
      .steps-container {
        margin-top: 0;
      }
      .nft-wizard__text {
        text-align: center;
        font-size: 14px;
      }
    }
    .summary-info {
      width: 100%;
      .block-title {
        font-size: 16px;
        margin-bottom: 20px;
      }

      .checkbox-container1 {
        margin-bottom: 10px;
      }
      .nft-wizard__text {
        font-size: 14px;
        margin-bottom: 20px;
      }
      .summary-info__checkbox-list {
        &.checkbox-list--pos {
          padding: 20px;
          margin: 0 0 20px 0;
        }
      }
    }
    .kyc-validation {
      width: 100%;
      .block-title {
        font-size: 16px;
      }
    }

    .button-wrapper.nft-button-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 250px;
      gap: 0;
      .button {
        padding: revert;
        width: auto;
      }
      a {
        display: flex;
        flex-grow: 1;
        text-decoration: none;
        justify-content: center;
      }
    }
    .add-shareholder {
      margin: 0;
    }
    .right-part {
      flex-grow: 1;
      align-items: center;
    }
    .ubo {
      max-width: -webkit-fill-available;
    }
  }
}
