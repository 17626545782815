@import '../../../../assets/scss/variables';

app-transfer-money-popup {
  .inner {
    width: 300px;
    max-width: 90vw;
    position: relative;
    padding: 40px 20px;
    text-align: center;
  }
  .payment-item {
    border: 2px solid $blue-color1;
    position: relative;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 45px;
    margin: 10px;
    cursor: pointer;
    .payment-item-text {
      position: relative;
      width: 50%;
      font-size: 11px;
      z-index: 50;
    }
    .payment-item-text-pay {
      position: relative;
      display: block;
      font-size: 12px;
      font-weight: 900;
    }

    img {
      max-width: 70%;
    }
    .maestro-logo {
      width: 21%;
    }
    .payment-klarna{
      padding: 0 50px;
    }
    .klarna-logo {
      &.klarnaMore10 {
        max-width: 100%;
        width: 100%;
        text-align: center;
        padding-left: 0;
      }
      .klarna-img {
        width: 55px;
        max-width: 55px;
      }

    }
    .klarna-text {
      padding-left: 10px;
    }
    &:hover:not(.disabled), &.active {
      background: #DFEBF4;
    }

    &.disabled {
      opacity: .5;
      filter: grayscale(100%);
      cursor: default;
    }
  }
  .block-title {
    font-weight: 900;
    font-size: 21px;
    text-transform: uppercase;
    text-align: center;
    margin: 30px 0 10px;
  }
  .transfer-money__amount {
    width: 240px;
  }
  .transfer-money__payment-type {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .direct-payment {
    margin: 10px;
    width: 100%;
    .google-pay {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    &.disablePayment {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  #deposit{
    font-size:16px;
  }
  .transfer-money__button {
    margin-top: 20px;
    width: 240px !important;
    position: relative;
    text-transform: uppercase;
    mat-spinner {
      position: absolute;
      left: 10px;
      top: 10px;
      circle {
        stroke: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  app-transfer-money-popup {
    #deposit.transfer-money__button {
      font-size: 16px;
    }
    #deposit-popup {
      .popup-wrapper {
        width: 100%;
        top: 0;
        height: 100%;
        max-height: 100%;
        max-width: 100vw;

      }
      .inner{
        max-width: 100vw;
      }
    }
  }
}

