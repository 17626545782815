@import '../../../assets/scss/variables';

app-about-grs {
  height: 100%;
  .main-wrapper {
    background: $white-color;
    padding-top: 74px;
    display: flex;
    flex-direction: column;
    // height: 100vh;

    .inner {
      max-width: 1180px;
      width: 1180px;
      margin: 0 auto;
      flex: 1;
    }
  }
  .main-about {
    display: flex;
    justify-content: space-between;
    .list {
      width: 68%;
    }
    .quote {
      width: 30%;
    }
  }

  blockquote {
    font-size: 16px;
    line-height: 24px;
    font-style: italic;
    color: #000000;
    padding-left: 35px;
    position: relative;
    &:before {
      content: "\201C";
      font-size: 48px;
      line-height: 25px;
      color: $blue-color1;
      position: absolute;
      left: 10px;
      top: -5px;
    }
  }
  .main-title {
    margin: 54px 0 20px 0;
    text-align: center;
  }

  section.content {
    padding-bottom: 35px;
    .item {
      border-bottom: $default-border-dash;
      padding: 40px;
      &:last-child {
        border-bottom: none;
      }
    }
    .sub-title {
      font-size: 20px;
      line-height: 18px;
      color: $black-color1;
      margin-bottom: 20px;
    }
    .text {
      text-align: center;
    }
  }
  .list {
    line-height: 18px;
  }
  .founder {
    display: flex;
    .founder-photo-img {
      width: 190px;
      min-width: 190px;
      position: relative;
      height: 190px;
      background: url('/assets/images/user/fallback_new.png') center center;
      background-size: cover;
      border-radius: 50%;
    }
    .founder-description {
      margin-left: 57px;
    }
    .founder-text {
      line-height: 18px;
    }
  }
  .team {
    .team-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .team-user {
        width: 30%;
        margin-top: 70px;
      }
    }
    .team-user-img {
      width: 173px;
      min-width: 173px;
      margin: 0 auto;
      border-radius: 50%;
      position: relative;
      height: 173px;
      background-size: cover !important;
      background: url('/assets/images/fallback.png') center center;
      &.user1 {
        background: url('/assets/images/fallback.png') center center;
      }
      &.user2 {
        background: url('/assets/images/team/charlotte.png') center center;
      }
      &.user3 {
        background: url('/assets/images/team/hofer.png') center center;
      }
      &.user4 {
        background: url('/assets/images/team/Violeta-Zlabinger.jpg') center center;
      }
      &.user5 {
        background: url('/assets/images/team/Patricia-Halva.jpg') center center;
      }
      &.user6 {
        background: url('/assets/images/team/Satyam-Subhash.jpg') center center;
      }
      &.user7 {
        background: url('/assets/images/team/jurgen.png') center center;
      }
      &.user8 {
        background: url('/assets/images/team/Karl-Holaubeck.jpg') center center;
      }
      &.user9 {
        background: url('/assets/images/team/philipp.png') center center;
      }

    }
    .name {
      margin-top: 25px;
      font-weight: bold;
      text-align: left;
      font-size: 18px;
      line-height: 18px;
      color: #000000;
    }
    .position {
      margin-top: 5px;
      text-align: left;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
    }
    .user-desc {
      margin-top: 10px;
      line-height: 18px;
    }
    .team-sub-title {
      text-align: center;
      font-size: 20px;
      line-height: 18px;
      color: $black-color1;
    }
  }
  .mob-screen {
    display: none;
  }
  .mid-screen {
    display: block;
  }
}

@media screen and (max-width: 1440px) {
  app-about-grs {
    .main-wrapper .inner {
      margin: 0 60px;
      width: auto;
    }
  }
}

@media screen and (max-width: 1023px) {
  app-about-grs {
    section.content {
      .item {
        padding: 40px 0;
        &:first-child {
          padding: 20px 0 40px 0;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  app-about-grs {
    .main-wrapper {
      height: 100%
    }
    .mob-screen {
      display: block;
    }
    .mid-screen {
      display: none;
    }
    section.content {
      width: 100%;
      .flex-align {
        flex-direction: column;
      }
      .item {
        width: 100%;
        padding: 30px 0;
        &:first-child {
          padding: 0 0 30px 0;
        }
        .wrapper {
          width: 100%
        }
      }
      .sub-title {
        text-align: center;
        font-size: 18px;
        line-height: 21px;
      }
    }

    .main-about {
      flex-direction: column;

      .list {
        width: 100%;
      }
      .quote {
        width: 100%;
      }
    }
    blockquote {
      margin-top: 35px;
      padding-left: 20px;
      font-size: 14px;
      line-height: 20px;
      &:before {
        left: -5px;
      }
    }
    .founder {
      flex-direction: column;
      display: block;
      .founder-photo-img {
        float: left;
        width: 125px;
        height: 125px;
        margin-right: 20px;
        margin-bottom: 15px;
        min-width: 125px;
      }
      .founder-description {
        margin-left: 0;
        padding: 0;
      }
      .founder-text {
        p {
          margin-top: 15px;
        }
      }
    }
    .team {
      .team-user-img {
        width: 120px;
        height: 120px;
        min-width: 120px;
      }
      .team-list {
        .team-user {
          width: 100%;
          margin-top: 40px;
          padding: 0 10px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .name {
            text-align: center;
            font-size: 16px;
            line-height: 18px;
          }
          .position {
            text-align: center;
            font-size: 14px;
            line-height: 18px;
          }
          .user-desc {
            text-align: center;
            line-height: 18px;
          }
        }
      }
    }
    .main-title {
      font-size: 20px;
      margin: 30px 0;
    }
    .main-wrapper .inner {
      margin: 0 40px;
      width: auto;
    }
  }
}

@media screen and (max-width: 460px) {
  app-about-grs {
    .main-wrapper .inner {
      margin: 0 20px;
      width: auto;
    }
  }
}
