@import '../../../../assets/scss/variables';

app-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: rgba(5, 27, 34, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  > .inner {
    border: $default-border;
    border-radius: 5px;
    background: $white-color;

    .lnr-cross {
      position: absolute;
      right: 20px;
      font-size: 12px;
      font-weight: 600;
      top: 18px;
      cursor: pointer;
      color: #1ac5d0;
    }

    > .title {
      border-bottom: $default-border;
      margin-bottom: 4px;
      padding: 18px 24px;
      font-size: 13px;
      text-transform: uppercase;
      line-height: 1;
      background: #F6F9FC;
      color: $blue-color4;
    }

    .row {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  .content {
    padding: 0 24px;
    margin-top: 22px;
  }

  .popup-wrapper {
    background: #fff;
    position: relative;
    border: 1px solid #c7cfd8;
    border-radius: 5px;
    max-height: 90%;
    overflow: hidden;
    .title {
      border-bottom: $default-border;
      margin-bottom: 4px;
      padding: 18px 24px;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 1;
      font-weight: 600;
    }
    .row {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
  .lnr.default-cross {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    font-size: 24px;
    color: #8089A0;
    opacity: .7;
    border: 10px solid transparent;
  }
}

@media screen and (max-width: 768px) {
  app-popup {
    .inner {
      width: 100%!important;
      max-height: 90% !important;
      overflow: auto !important;
    }
    .form > .row {
      display: block;

      > .item {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  app-popup {
    .popup-wrapper {
      width: 90%;
      max-height: 650px;
      overflow-y: scroll;
      top: 10px;
    }
    .inner {
      width: 100% !important;
    }
  }
}
