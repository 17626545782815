@import '../../../../assets/scss/variables';

app-investor-popup {
  .inner {
    width: 580px;
    max-width: 90vw;
    position: relative;
    padding: 40px;
    text-align: left;
  }

  .lnr-cross {
    position: absolute;
    right: 20px;
    font-size: 16px;
    font-weight: 600;
    top: 20px;
    cursor: pointer;
    color: $blue-color1;
  }

  .text-head {
    text-align: center;
    font-weight: bold;
  }

  .sub-text-bold {
    font-weight: bold;
    color: $black-color1;
  }
  .block-title {
    font-weight: 900;
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    margin: 30px 0 10px;
  }

  .text {
    text-align: left;
    margin-bottom: 10px;
    margin-top: 20px;
    h1 {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .sign-in {
    margin: 30px 20px;
    padding: 30px 20px;
    border-top: 1px dashed $gray-color4;
    border-bottom: 1px dashed $gray-color4;
  }

  .sub-text {
    margin-top: 20px;
    font-weight: normal;
    color: $black-color1;
  }
  .agreement-text {
    font-weight: normal;
    text-align: left;
    padding-left: 15px;
    span {
      font-weight: 500;
    }
  }
  .button-wrapper {
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    padding: 0 20px;

    .cancel {
      color: $gray-color1;
      font-size: 16px;
      text-transform: uppercase;
      cursor: pointer;
      font-weight: bold;
      border-color: $gray-color3;
    }
    .cancel:hover{
      color: $link-color;
    }
    .button {
      width: 213px;
      margin-left: 170px;
      margin-right: 700x;
      height: 50px;
      font-weight: bold;
      font-size: 16px;
    }

  }
  .image {
    display: block;
    margin: 0 auto;
    height: 130px;
  }
}

@media screen and (max-width: 767px) {
  app-investor-popup {
    .text br {
      display: none;
    }
    .text {
      text-align: center;
    }
   .button-wrapper .button {
      width: 213px;
      margin-left: 37px;
      margin-right: 700x;
      height: 50px;
      font-weight: bold;
      font-size: 16px;
  }
  }
}

