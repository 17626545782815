@import '../../../assets/scss/variables';

app-project {
  .main-wrapper {
    width: 100%;
    position: relative;
    background: $white-color;
  }

  .image-wrapper {
    height: 474px;
    width: 100%;
    position: relative;
  }
  app-card-project {
    .card:hover {
      position: relative;
      z-index: 2;
    }
    .card-outer {
      margin: 0;
    }
    .card-image-wrapper {
      margin: 0;
    }
    .back {
      margin: 0;
    }
  }

  .play-button {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid #fff;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  app-project-song-detail {
    display: flex;
    flex: 1;
  }
  .project__detail-song-information {
    width: 100%;
    position: relative;
    display: flex;
    padding: 0 55px;
    flex-direction: column;
  }
  .project__song-info {
    position: relative;
    width: 100%;
    margin-top: -118px;
    display: flex;
    z-index: 10;
    padding: 0 40px;
    justify-content: center;
  }

  .project__box-header {
    display: flex;
    position: relative;
    width: 100%;
    background: $gray-color2;
    padding: 26px 60px;
    box-shadow: 0 2px 4px rgba(144, 170, 190, 0.5);
    border-radius: 7px;

    .project__detail {
      flex-grow: 1;
    }
  }

  .grs-details__title {
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    margin: 15px 0 18px 0;
  }

  .profile-title {
    padding: 30px 0;
  }

  .project__get-information {
    width: 300px;
    min-width: 300px;
    .get-title {
      margin: 25px 0 20px 0;
      text-align: center;
    }
  }
  .project__title-sub {
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
  }

  .project__mobile-view-p {
    display: none;
  }
  .project__subscribe {
    padding: 25px 90px;
  }
  .project-blue-box {
    background: #DFEBF4;
    box-shadow: 0px 2px 4px rgba(144, 170, 190, 0.5);
    border-radius: 7px;
    margin-top: 20px;
  }
  .album-logo {
    display: block;
    position: relative;
    background-size: cover;
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 17px;
      top: 8px;
      width: 21px;
      height: 21px;
      background-size: 21px;
      background-image: url('../../../assets/images/music.png');
    }
  }

  .gold-color {
    background-image: -webkit-linear-gradient($gold-dark 0%, $gold 100%);
    background-image: -o-linear-gradient($gold-dark 0%, $gold 100%);
    background-image: linear-gradient($gold-dark 0%, $gold 100%);
  }
  .limited-edition {
    border: 2px solid $gold-dark;
    border-radius: 5px;
    padding: 3px 10px;
    background: linear-gradient(to right, #D2B652 0%, #ECCE63 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    font-size: 36px;
    text-align: center;
    font-weight: bold;
    max-width: 360px;
  }
  .profile-artist-info {
    display: flex;
  }
  .project__logo-image {
    width: 15px;
    position: relative;
    margin-top: -18px;
  }
  .project__logo-title {
    width: 140px;
    position: absolute;
    top: 3px;
    right: 20px;
  }
  .project-highlighted {
    position: relative;
    padding: 4px 15px;
    width: auto;
    height: 45px;
    display: flex;
    justify-content: space-between;
    color: $white-color;
    background: $yellow-color2;
    border-radius: 5px 5px 0 0;
    text-align: center;
    &.ends {
      background: $red-color1;
    }
    &.newcomer {
      background: linear-gradient(180deg, #6182A8 42.62%, #8CABCF 100%);
    }
    &.headliner {
      background: linear-gradient(180deg, #34476E 21.77%, #6783BC 100%);
    }
    &.album {
      display: flex;
      position: relative;
      padding: 0;
      width: auto;
      overflow: hidden;
      .text-uppercase {
        padding: 5px 15px;
      }
      // left: -30px;
    }
    &.released {
      background: $red-color9;
    }
    &.legacy {
      @extend .gold-color;
    }
    &.new {
      background: $green-color10;
    }

    &.funded {
      background: $blue-color1;
    }

    span {
      color: $white;
      font-weight: bold;
      line-height: 20px;
      display: inline-block;
      padding: 0 10px;
      font-size: $menu-font-size;
    }
  }
  .project__sign-highlight {
    position: absolute;
    top: -27px;
    left: 40px;
    display: flex;
  }
  .item-highlighted-album {
    position: relative;
    width: auto;
    z-index: 2;
  }
  .album-bcg {
    width: 100%;
    display: block;
    position: relative;
    transform: translateX(-10%) skewX(-20deg);
    background: $red-color11;
  }
  .album-text {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    padding: 5px 20px 5px 30px;
    text-transform: uppercase;
    transform: translateX(0%) skew(20deg);
  }

  .project__detail {
    position: relative;
    display: flex;

    .item-content-title {
      line-height: 23px;
      font-weight: $font-weight-900;
      font-size: 27px;

    }
  }

  .project-logo {
    margin-right: 35px;
    position: relative;

    .project-logo-img {
      width: 160px;
      position: relative;
      height: 160px;
      background-size: cover;
      background-position: center center;
      border-radius: 80px;
    }
  }

  .profile-info {
    position: relative;
    padding: 0 90px 40px 90px;
  }

  .project__title {
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .project-first-section {
    margin-top: -80px;
  }

  audio {
    max-width: 350px;
    width: 100%;
  }

  //.img-block {
  //  width: 100px;
  //  margin-right: 25px;
  //  margin-bottom: 12px;
  //}

  //.image-desc-float {
  //  float: left;
  //  width: 100px;
  //  height: 100px;
  //  border-radius: 50%;
  //  background-position: center;
  //  background-size: cover;
  //  background-image: url('../../../assets/images/fallback.png');
  //  overflow: hidden;
  //
  //  .inner {
  //    width: 100%;
  //    height: 100%;
  //    background-position: center;
  //    background-size: cover;
  //  }
  //}
  .profile-title {
    padding: 25px 0;
  }


  .img-copy {
    padding-left: 5px;
    cursor: pointer;
  }


  .slider-item {
    background-position: center;
    background-size: cover;
    overflow: hidden;
    background-image: url('../../../assets/images/fallback.png');

    .inner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
    }
  }

  .profile-info-left {
    padding-right: 50px;
    flex-grow: 1;
  }

  .call-to-action__body {
    visibility: hidden;
    background: #F2F7FB;
    display: flex;
    height: 0;
    .call-to-action__image {
      width: 197px;
      height: 197px;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));
      border-radius: 14px;
    }
    &.show {
      padding: 25px 90px;
      visibility: visible;
      width: 100%;
      height: 100%;
    }
  }
  .get-text {
    color: #8089a0;
    list-style-type: disc;
  }
  .get-text .item {
    margin-bottom: 15px;
    padding-left: 20px;
    position: relative;
  }
  .get-text li {
    color: #5aa2e5;
  }

  .section__calculation {
    display: none;
  }
  app-loader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .global-page-loader {
      margin: 0;
    }
  }
}

app-song-preview {
  position: fixed;
  bottom: 0;
}

@media screen and (max-width: 1490px) {
  app-project {
    .project__detail-song-information {
      padding: 0 30px;
    }
    .project__box-header {
      padding: 27px 54px;
    }
  }
}

@media screen and (max-width: 1380px) {
  app-project {
    .project__calculation {
      display: none;
    }
    .profile-artist-info .project__get-information {
      display: none;
    }
    .project__detail-song-information {
      padding: 0 0 0 27px;
    }
    .project__box-header {
      padding: 27px 27px 27px 54px;
    }

    .section__calculation {
      display: flex;
      margin: 20px auto 0;
      width: 95%;
      justify-content: space-between;
      app-calculation {
        width: 50%;
        .wrapper {
          justify-content: unset;
          padding: 0 54px;
        }
      }
      .project__get-information {
        width: 50%;
        padding-left: 40px;
        .get-title {
          margin: 15px 0 20px 0;
        }
      }
    }

    .profile-info {
      margin-top: 0;
      padding: 0 28px 20px 28px;
    }
  }
}

@media screen and (max-width: 1100px) {
  app-project {
    .project__box-header {
      padding: 27px 24px;
    }
    .project__song-info{
      padding: 0 20px;
    }
    app-calculation {
      .wrapper {
        padding: 0 24px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  app-project {
    .project__detail{
      margin-bottom: 20px;
    }
    .project__detail-song{
      display: none;
    }
    .call-to-action__body {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &.show {
        padding: 25px 18px;

      }
    }
    .project__detail-song-information{
      padding: 0;
    }
    .grs-details__title{
      display: none;
    }
    .project__box-header {
      padding: 0;
      top: 0;
      background: transparent;
      box-shadow: none;
      width: 100%;
    }
    .project__logo-image {
      margin-top: -16px;
    }
    .project__mobile-view-p {
      display: block;
      margin-bottom: 15px;
      padding: 0 18px;
    }
    .image-wrapper {
      display: none;
    }

    .profile-info {
      display: none;
    }

    .project__mobile-view-p {
      margin-top: 15px;
    }

    .project__title-sub {
      padding-left: 18px;
      line-height: 14px;
    }


    .get-text {
      margin-left: 18px;
      .item {
        padding-left: 0;
      }
    }

    .section__calculation {
      width: 100%;
      background: #F2F7FB;
      margin: 0;
      padding: 15px 18px 10px;
      border-radius: 0 7px 7px 7px;
      app-calculation {
        width: 100%;
        .wrapper {
          padding: 0;
          margin: 0;
          justify-content: center;
          .inner {
            max-width: 100%;
          }
        }
        .amount {
          max-width: 100%;
        }
        .item {
          min-width: 60px;
        }

        .item-dropdown, .button {
          width: 100%;
          max-width: 100%;
        }
      }
      .project__get-information {
        display: none;
      }
    }

    app-card-project {
      margin: 0 auto;
    }
    .project-highlighted {
      width: 202px;
      span {
        line-height: 25px;
      }
      &.superRare {
        width: 150px;
      }
    }
    .sign-highlight__wrapper {
      width: 310px;
      margin: 0 auto;
    }
    .project__sign-highlight {
      top: 90px;
      left: 0;
      width: 100%;
    }
    .project__song-info {
      margin-top: 0;
      padding: 100px 0 0 0;
    }
    .project__detail {
      flex-direction: column;
      padding: 0 18px;
    }
    .project__subscribe {
      padding: 15px 18px;
    }

    app-subscribe-newsletter {
      .subscribe__button {
        margin: 0;
      }
      .subscribe__button-wrapper {
        flex-direction: column;
      }
      .subscribe__input {
        width: 100%;
        height: 50px;
        margin-bottom: 15px;
      }
      .subscribe-title {
        font-size: 18px;
        margin-bottom: 20px;
        padding: 0 25px;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  app-project {
    .limited-edition {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: 320px) {
  app-project {

    .project__detail {
      padding: 0 10px;
    }
  }
}
