@charset "UTF-8";
/* RESET STYLES*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

blockquote, q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
table td, table th {
  padding: 0;
}

input[type=radio], input[type=checkbox] {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

p img.left {
  float: left;
  padding: 0;
}

p img.right {
  float: right;
}

blockquote {
  color: #666;
  font-style: italic;
}

strong {
  font-weight: 400;
}

em, dfn {
  font-style: italic;
}

sup, sub {
  line-height: 0;
}

abbr, acronym {
  border-bottom: 1px dotted #666;
}

address {
  font-style: italic;
}

del {
  color: #666;
}

a {
  text-decoration: none;
  color: #5AA2E5;
  transition: color linear 0.2s;
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
  color: #1e6fbb;
  outline: none;
}
a.active, a:active {
  color: #1e6fbb;
  outline: none;
}

ul, ol {
  list-style: none;
  margin: 0;
}

li ul, li ol {
  margin: 0;
}

dl {
  margin: 0;
}

dd {
  margin-left: 0;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* ==========================================================================
   Helper classes
   ========================================================================== */
/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */
.hidden {
  display: none !important;
  visibility: hidden;
}

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */
.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible !important;
}

*, *:after, *:before {
  box-sizing: border-box;
}

.no-tap-highlight, app-checkbox {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html {
  height: 100%;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  background: #F2F7FB;
  font-size: 14px;
  color: #000;
  line-height: 1.2;
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.1;
  font-weight: normal;
}

a {
  text-decoration: none;
}

audio {
  width: 100%;
}

.card-outer {
  overflow: hidden;
  border-radius: 20px;
  width: 310px;
  height: 473px;
}

.lineHeight10 {
  line-height: 1;
}

.lineHeight20 {
  line-height: 1.4;
}

.spinner {
  display: flex;
  justify-content: center;
}

.lineHeight40 {
  line-height: 1.8;
}

.lineHeight60 {
  line-height: 2.2;
}

.lineHeight80 {
  line-height: 2.6;
}

.lineHeight100 {
  line-height: 3;
}

.gray-text {
  color: #8089A0;
  font-size: 14px;
  line-height: 18px;
}

.global-spinner-wrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.default-link {
  text-decoration: none;
  color: inherit;
}
.default-link:hover {
  text-decoration: none;
  color: inherit;
}
.default-link.link-block {
  display: block;
}
.default-link.disabled {
  opacity: 0.6;
  pointer-events: none;
}

input, button, textarea {
  font-family: "Roboto", sans-serif;
  -webkit-appearance: none;
}

input, select {
  font-size: 100%;
}

div {
  display: block;
}

.wizard__button .mat-progress-spinner circle, .wizard__button .mat-spinner circle {
  stroke: white;
}

#style-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #DFEBF4;
}

#style-scrollbar::-webkit-scrollbar {
  width: 7px;
  background-color: #F5F5F5;
}

#style-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #8089A0;
  box-shadow: 0 0 0 2px white;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.item-share-document {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.item-share-document span {
  padding-left: 10px;
}

.color-white {
  color: #fff;
}

.color-light-grey {
  color: #8089A0;
}

.black-color {
  color: rgba(2, 8, 10, 0.7019607843);
}

.item-highlighted {
  position: relative;
  padding: 5px 18px;
  margin-right: 18px;
  background: #FFA800;
  border-radius: 5px 5px 0 0;
  text-align: center;
}
.item-highlighted.ends {
  background: #F95738;
}
.item-highlighted.released {
  background: #9a463d;
}
.item-highlighted.new {
  background: #27AE60;
}
.item-highlighted.funded {
  background: #5AA2E5;
}
.item-highlighted span {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}

.title14 {
  font-weight: normal;
  font-size: 14px;
}

.small-toggle .mat-slide-toggle-bar, .xs-toggle .mat-slide-toggle-bar {
  height: 26px !important;
  width: 48px !important;
  border-radius: 30px;
  background-color: #5AA2E5;
  border: 2px solid #5AA2E5;
}
.small-toggle.yellow .mat-slide-toggle-bar, .yellow.xs-toggle .mat-slide-toggle-bar {
  height: 26px !important;
  width: 48px !important;
  border-radius: 30px;
  background-color: #FFA800;
  border: 2px solid #FFA800;
}
.small-toggle.yellow .mat-slide-toggle.mat-checked .mat-slide-toggle-bar, .yellow.xs-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: transparent;
  border: 2px solid #8089A0;
}
.small-toggle.yellow .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb, .yellow.xs-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: transparent;
  border: 2px solid #8089A0;
}
.small-toggle.disable .mat-slide-toggle-bar, .disable.xs-toggle .mat-slide-toggle-bar {
  height: 26px !important;
  width: 48px !important;
  border-radius: 30px;
  background-color: #8089A0;
  border: 2px solid #8089A0;
}
.small-toggle .mat-slide-toggle-thumb-container, .xs-toggle .mat-slide-toggle-thumb-container {
  transform: translate3d(46px, 0, 0);
}
.small-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb, .xs-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: transparent;
  border: 2px solid #B7BCCB;
}
.small-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-bar, .xs-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: transparent;
  border: 2px solid #B7BCCB;
}
.small-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container, .xs-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(26px, 0, 0);
}
.small-toggle .mat-slide-toggle-thumb, .xs-toggle .mat-slide-toggle-thumb {
  width: 20px;
  height: 20px;
}
.small-toggle .mat-slide-toggle.mat-checked .mat-ripple-element, .xs-toggle .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: transparent;
}
.small-toggle .mat-slide-toggle .mat-slide-toggle-ripple, .xs-toggle .mat-slide-toggle .mat-slide-toggle-ripple {
  top: calc(50% - 17px);
  left: calc(50% - 17px);
}
.small-toggle .mat-slide-toggle-thumb-container, .xs-toggle .mat-slide-toggle-thumb-container {
  top: 1px;
  left: -24px;
}

.xs-toggle .mat-slide-toggle-bar {
  height: 18px !important;
  width: 44px !important;
}
.xs-toggle.disable .mat-slide-toggle-bar {
  height: 18px !important;
  width: 44px !important;
}
.xs-toggle .mat-slide-toggle-thumb-container {
  transform: translate3d(44px, 0, 0);
}
.xs-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(24px, 0, 0);
}
.xs-toggle .mat-slide-toggle-thumb {
  width: 13px;
  height: 13px;
}
.xs-toggle .mat-slide-toggle-thumb-container {
  top: 0;
  left: -22px;
}

.mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.5) !important;
}

.fa-question-circle {
  font-size: 14px;
  color: #5AA2E5;
}

.profile-page .mat-slide-toggle-bar {
  height: 34px !important;
  width: 68px !important;
  border-radius: 30px;
  background-color: #5AA2E5;
  border: 2px solid #5AA2E5;
}
.profile-page .mat-slide-toggle-thumb-container {
  transform: translate3d(46px, 0, 0);
}
.profile-page .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: transparent;
  border: 2px solid #B7BCCB;
}
.profile-page .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: transparent;
  border: 2px solid #B7BCCB;
}
.profile-page .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(14px, 0, 0);
}
.profile-page .mat-slide-toggle-thumb {
  width: 26px;
  height: 26px;
}
.profile-page .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: transparent;
}
.profile-page .mat-slide-toggle .mat-slide-toggle-ripple {
  top: calc(50% - 17px);
  left: calc(50% - 17px);
}
.profile-page .mat-slide-toggle-thumb-container {
  top: 2px;
  left: -10px;
}

.stickyMenu .mat-select-value, .stickyMenu .mat-form-field-appearance-legacy, .stickyMenu .mat-form-field-underline {
  color: white !important;
}
.stickyMenu .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: white !important;
}
.stickyMenu .mat-select .mat-select-arrow {
  color: white !important;
}

.title15 {
  font-weight: normal;
  font-size: 15px;
}

.title16 {
  font-weight: normal;
  font-size: 16px;
}

.title18 {
  font-size: 18px;
  font-weight: normal;
}

.title27 {
  font-size: 27px;
  font-weight: normal;
}

.title28 {
  font-size: 28px;
  font-weight: normal;
}

.title32 {
  font-size: 32px;
  font-weight: normal;
}

.title36 {
  font-weight: normal;
  font-size: 36px;
}

.title48 {
  font-weight: normal;
  font-size: 48px;
}

.font-bold {
  font-weight: 900;
}

.link-blue {
  font-weight: 500;
  font-size: 14px;
  color: #5AA2E5;
}

.link-red {
  font-weight: 500;
  font-size: 14px;
  color: #F95738;
}

.wrapper {
  width: 100%;
}

.upload-section {
  background: #EEFCFF;
  position: relative;
  border: 2px dashed #5AA2E5;
  overflow: hidden;
}
.upload-section:last-child {
  margin-left: 15px;
}
.upload-section.passport {
  width: 100%;
  margin-left: 0;
}
.upload-section label {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.upload-section span, .upload-section .fa-camera, .upload-section .fa-music {
  color: #5AA2E5;
}
.upload-section img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.upload-section input[type=file] {
  display: none;
}
.upload-section span {
  font-size: 14px;
}
.upload-section .fa-camera {
  font-size: 20px;
  line-height: 23px;
}

.nowrap {
  white-space: nowrap;
}

.lnr-chevron-right {
  display: inline-block;
  font-weight: 900;
  cursor: pointer;
}
.lnr-chevron-right.opened {
  transition: all 0.25s ease-out 0s;
  transform: rotate(90deg);
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-raw {
  display: flex;
  justify-content: space-between;
}

.flex-raw-center {
  display: flex;
  justify-content: center;
}

app-reinvest-payment app-checkbox .checkbox {
  min-width: 30px;
  margin-top: 35px;
  border: 2px solid #5AA2E5;
}

.payments .mat-radio-button .mat-radio-ripple {
  display: none;
}

.social-item .mat-form-field-appearance-legacy {
  padding: 0 10px;
}
.social-item .mat-form-field-infix {
  border-top: 0;
}

.faq-description .content-text {
  text-align: left;
  margin-bottom: 15px;
  line-height: 18px;
}
.faq-description ul li:before {
  content: "";
  position: absolute;
  top: 30%;
  left: -25px;
  width: 8px;
  height: 8px;
  border: 2px solid #07a3e4;
  border-radius: 50% 50% 50% 50%;
}
.faq-description ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}
.faq-description ul {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}
.faq-description li {
  display: list-item;
  position: relative;
  text-align: -webkit-match-parent;
}

.social .circle {
  position: relative;
  z-index: 0;
  display: block;
}
.social .circle:before {
  content: "";
  display: block;
  position: absolute;
  width: 28px;
  height: 28px;
  top: 0;
  left: 0;
  border-radius: 100%;
}
.social.twitter {
  position: relative;
}
.social.twitter .circle:before {
  background-color: #00d1ff;
}
.social.twitter .fa-twitter {
  position: absolute;
  font-size: 14px;
  left: 8px;
  top: 8px;
  color: #ffffff;
}
.social.linkedin {
  position: relative;
}
.social.linkedin .circle:before {
  background-color: #0274B3;
}
.social.linkedin .fa-linkedin {
  position: absolute;
  font-size: 14px;
  left: 8px;
  top: 8px;
  color: #ffffff;
}
.social.pinterest {
  position: relative;
}
.social.pinterest .circle:before {
  background-color: #E81B22;
}
.social.pinterest .fa-pinterest-p {
  position: absolute;
  font-size: 22px;
  left: 8px;
  top: 8px;
  color: #ffffff;
}
.social.whatsapp {
  position: relative;
}
.social.whatsapp .circle:before {
  background-color: #25d366;
}
.social.whatsapp .fa-whatsapp {
  position: absolute;
  font-size: 22px;
  left: 8px;
  top: 8px;
  color: #ffffff;
}
.social.instagram {
  position: relative;
}
.social.instagram .circle:before {
  background-color: #e0518d;
}
.social.instagram .fa-instagram {
  position: absolute;
  font-size: 19px;
  left: 6px;
  top: 4px;
  color: #ffffff;
}
.social.youtube {
  position: relative;
}
.social.youtube .circle:before {
  background-color: #F95738;
}
.social.youtube .fa-youtube {
  position: absolute;
  font-size: 15px;
  left: 6px;
  top: 7px;
  color: #ffffff;
}
.social.dropbox .circle:before {
  background-color: #5AA2E5;
}
.social.dropbox .fa-dropbox {
  position: absolute;
  font-size: 15px;
  left: 6px;
  top: 7px;
  color: #ffffff;
}
.social.soundcloud .circle:before {
  background-color: #FF7A00;
}
.social.soundcloud .fa-soundcloud {
  position: absolute;
  font-size: 15px;
  left: 4px;
  top: 6px;
  color: #ffffff;
}
.social.tiktok .circle:before {
  background-color: #000;
}
.social.tiktok .fa-tiktok {
  position: absolute;
  font-size: 15px;
  left: 8px;
  top: 6px;
  color: #ffffff;
}
.social.facebook {
  position: relative;
}
.social.facebook .circle:before {
  background-color: #2c53b7;
}
.social.facebook .fa-facebook-f {
  position: absolute;
  font-size: 16px;
  left: 9px;
  top: 6px;
  color: #ffffff;
}
.social.spotify {
  position: relative;
}
.social.spotify .fa-spotify {
  font-size: 28px;
  color: #1ed760;
}

.tooltip {
  position: absolute;
  display: none;
  font-size: 14px;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  z-index: 2;
  color: #fff;
  border: 1px solid #e1e1e1;
}
.tooltip:after {
  content: "";
  display: block;
  position: absolute;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.7);
}

.flex-align-justify {
  display: flex;
  justify-content: center;
}

.width70 {
  width: 70%;
}

.width30 {
  width: 30%;
}

.flex-align {
  display: flex;
}

.flex-align-column {
  display: flex;
  flex-direction: column;
}

.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #5AA2E5 !important;
}

.flex-item {
  width: 50%;
}

.grey-text {
  color: #8089A0;
}

.img-circle {
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 50px;
  background-position: center;
  background-size: cover;
}

.backgroundImage {
  background-size: cover;
  background-position: center center;
}

.lnr.disabled {
  opacity: 0.5;
}
.lnr.lnr-warning {
  font-size: 12px;
  color: #EF3E42;
  padding-right: 10px;
}
.lnr.vertical-align {
  display: flex;
  align-items: center;
}

.warning {
  font-size: 12px;
  color: #EF3E42;
}

.warning-text {
  color: #F95738;
}

.no-list-data {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}

div.global-page-loader {
  position: relative;
  width: 100%;
  height: 100px;
  margin: auto;
  color: #5AA2E5;
}
div.global-page-loader .lnr {
  font-size: 60px;
  left: calc(50% - 30px);
  position: absolute;
  top: 0;
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.wrapper-pagination-footer {
  margin: 20px 0;
}

.loader .lnr {
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.lnr {
  font-size: 15px;
  margin-left: 20px;
  cursor: pointer;
  font-weight: bold;
}
.lnr:hover {
  text-decoration: none;
}
.lnr:first-child {
  margin-left: 0;
}
.lnr.lnr-pencil {
  color: #5AA2E5;
}
.lnr.lnr-file-empty {
  color: #5AA2E5;
}
.lnr.lnr-chevron-right {
  color: #5AA2E5;
}
.lnr.lnr-users {
  color: #5AA2E5;
  font-size: 18px;
  font-weight: bold;
}
.lnr.lnr-calendar-full {
  color: #5AA2E5;
  font-size: 16px;
  font-weight: bold;
}
.lnr.lnr-map-marker {
  color: #5AA2E5;
  font-size: 18px;
  font-weight: bold;
}
.lnr.lnr-user {
  color: #5AA2E5;
  font-size: 14px;
  font-weight: bold;
}
.lnr.lnr-magnifier {
  color: #5fa83f;
}
.lnr.lnr-checkmark-circle {
  color: #5fa83f;
}
.lnr.lnr-cross-circle {
  color: #F95738;
}
.lnr.lnr-trash {
  color: #F95738;
}
.lnr.lnr-cross {
  color: #F95738;
}
.lnr.disabled {
  opacity: 0.5;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #5AA2E5;
  border: 1px solid #5AA2E5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.min-screen, .mid-screen {
  display: none;
}

.max-screen {
  display: table-cell;
}

.loader {
  position: relative;
  width: 30px;
  height: 30px;
}
.loader .image-load {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.loader.hide {
  display: none;
}

@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: "Linearicons-Free";
  src: url("../fonts/linearicons/Linearicons-Free.eot?w118d");
  src: url("../fonts/linearicons/Linearicons-Free.eot?#iefixw118d") format("embedded-opentype"), url("../fonts/linearicons/Linearicons-Free.woff2?w118d") format("woff2"), url("../fonts/linearicons/Linearicons-Free.woff?w118d") format("woff"), url("../fonts/linearicons/Linearicons-Free.ttf?w118d") format("truetype"), url("../fonts/linearicons/Linearicons-Free.svg?w118d#Linearicons-Free") format("svg");
  font-weight: normal;
  font-style: normal;
}
.lnr {
  font-family: "Linearicons-Free";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lnr-home:before {
  content: "\e800";
}

.lnr-apartment:before {
  content: "\e801";
}

.lnr-pencil:before {
  content: "\e802";
}

.lnr-magic-wand:before {
  content: "\e803";
}

.lnr-drop:before {
  content: "\e804";
}

.lnr-lighter:before {
  content: "\e805";
}

.lnr-poop:before {
  content: "\e806";
}

.lnr-sun:before {
  content: "\e807";
}

.lnr-moon:before {
  content: "\e808";
}

.lnr-cloud:before {
  content: "\e809";
}

.lnr-cloud-upload:before {
  content: "\e80a";
}

.lnr-cloud-download:before {
  content: "\e80b";
}

.lnr-cloud-sync:before {
  content: "\e80c";
}

.lnr-cloud-check:before {
  content: "\e80d";
}

.lnr-database:before {
  content: "\e80e";
}

.lnr-lock:before {
  content: "\e80f";
}

.lnr-cog:before {
  content: "\e810";
}

.lnr-trash:before {
  content: "\e811";
}

.lnr-dice:before {
  content: "\e812";
}

.lnr-heart:before {
  content: "\e813";
}

.lnr-star:before {
  content: "\e814";
}

.lnr-star-half:before {
  content: "\e815";
}

.lnr-star-empty:before {
  content: "\e816";
}

.lnr-flag:before {
  content: "\e817";
}

.lnr-envelope:before {
  content: "\e818";
}

.lnr-paperclip:before {
  content: "\e819";
}

.lnr-inbox:before {
  content: "\e81a";
}

.lnr-eye:before {
  content: "\e81b";
}

.lnr-printer:before {
  content: "\e81c";
}

.lnr-file-empty:before {
  content: "\e81d";
}

.lnr-file-add:before {
  content: "\e81e";
}

.lnr-enter:before {
  content: "\e81f";
}

.lnr-exit:before {
  content: "\e820";
}

.lnr-graduation-hat:before {
  content: "\e821";
}

.lnr-license:before {
  content: "\e822";
}

.lnr-music-note:before {
  content: "\e823";
}

.lnr-film-play:before {
  content: "\e824";
}

.lnr-camera-video:before {
  content: "\e825";
}

.lnr-camera:before {
  content: "\e826";
}

.lnr-picture:before {
  content: "\e827";
}

.lnr-book:before {
  content: "\e828";
}

.lnr-bookmark:before {
  content: "\e829";
}

.lnr-user:before {
  content: "\e82a";
}

.lnr-users:before {
  content: "\e82b";
}

.lnr-shirt:before {
  content: "\e82c";
}

.lnr-store:before {
  content: "\e82d";
}

.lnr-cart:before {
  content: "\e82e";
}

.lnr-tag:before {
  content: "\e82f";
}

.lnr-phone-handset:before {
  content: "\e830";
}

.lnr-phone:before {
  content: "\e831";
}

.lnr-pushpin:before {
  content: "\e832";
}

.lnr-map-marker:before {
  content: "\e833";
}

.lnr-map:before {
  content: "\e834";
}

.lnr-location:before {
  content: "\e835";
}

.lnr-calendar-full:before {
  content: "\e836";
}

.lnr-keyboard:before {
  content: "\e837";
}

.lnr-spell-check:before {
  content: "\e838";
}

.lnr-screen:before {
  content: "\e839";
}

.lnr-smartphone:before {
  content: "\e83a";
}

.lnr-tablet:before {
  content: "\e83b";
}

.lnr-laptop:before {
  content: "\e83c";
}

.lnr-laptop-phone:before {
  content: "\e83d";
}

.lnr-power-switch:before {
  content: "\e83e";
}

.lnr-bubble:before {
  content: "\e83f";
}

.lnr-heart-pulse:before {
  content: "\e840";
}

.lnr-construction:before {
  content: "\e841";
}

.lnr-pie-chart:before {
  content: "\e842";
}

.lnr-chart-bars:before {
  content: "\e843";
}

.lnr-gift:before {
  content: "\e844";
}

.lnr-diamond:before {
  content: "\e845";
}

.lnr-linearicons:before {
  content: "\e846";
}

.lnr-dinner:before {
  content: "\e847";
}

.lnr-coffee-cup:before {
  content: "\e848";
}

.lnr-leaf:before {
  content: "\e849";
}

.lnr-paw:before {
  content: "\e84a";
}

.lnr-rocket:before {
  content: "\e84b";
}

.lnr-briefcase:before {
  content: "\e84c";
}

.lnr-bus:before {
  content: "\e84d";
}

.lnr-car:before {
  content: "\e84e";
}

.lnr-train:before {
  content: "\e84f";
}

.lnr-bicycle:before {
  content: "\e850";
}

.lnr-wheelchair:before {
  content: "\e851";
}

.lnr-select:before {
  content: "\e852";
}

.lnr-earth:before {
  content: "\e853";
}

.lnr-smile:before {
  content: "\e854";
}

.lnr-sad:before {
  content: "\e855";
}

.lnr-neutral:before {
  content: "\e856";
}

.lnr-mustache:before {
  content: "\e857";
}

.lnr-alarm:before {
  content: "\e858";
}

.lnr-bullhorn:before {
  content: "\e859";
}

.lnr-volume-high:before {
  content: "\e85a";
}

.lnr-volume-medium:before {
  content: "\e85b";
}

.lnr-volume-low:before {
  content: "\e85c";
}

.lnr-volume:before {
  content: "\e85d";
}

.lnr-mic:before {
  content: "\e85e";
}

.lnr-hourglass:before {
  content: "\e85f";
}

.lnr-undo:before {
  content: "\e860";
}

.lnr-redo:before {
  content: "\e861";
}

.lnr-sync:before {
  content: "\e862";
}

.lnr-history:before {
  content: "\e863";
}

.lnr-clock:before {
  content: "\e864";
}

.lnr-download:before {
  content: "\e865";
}

.lnr-upload:before {
  content: "\e866";
}

.lnr-enter-down:before {
  content: "\e867";
}

.lnr-exit-up:before {
  content: "\e868";
}

.lnr-bug:before {
  content: "\e869";
}

.lnr-code:before {
  content: "\e86a";
}

.lnr-link:before {
  content: "\e86b";
}

.lnr-unlink:before {
  content: "\e86c";
}

.lnr-thumbs-up:before {
  content: "\e86d";
}

.lnr-thumbs-down:before {
  content: "\e86e";
}

.lnr-magnifier:before {
  content: "\e86f";
}

.lnr-cross:before {
  content: "\e870";
}

.lnr-menu:before {
  content: "\e871";
}

.lnr-list:before {
  content: "\e872";
}

.lnr-chevron-up:before {
  content: "\e873";
}

.lnr-chevron-down:before {
  content: "\e874";
}

.lnr-chevron-left:before {
  content: "\e875";
}

.lnr-chevron-right:before {
  content: "\e876";
}

.lnr-arrow-up:before {
  content: "\e877";
}

.lnr-arrow-down:before {
  content: "\e878";
}

.lnr-arrow-left:before {
  content: "\e879";
}

.lnr-arrow-right:before {
  content: "\e87a";
}

.lnr-move:before {
  content: "\e87b";
}

.lnr-warning:before {
  content: "\e87c";
}

.lnr-question-circle:before {
  content: "\e87d";
}

.lnr-menu-circle:before {
  content: "\e87e";
}

.lnr-checkmark-circle:before {
  content: "\e87f";
}

.lnr-cross-circle:before {
  content: "\e880";
}

.lnr-plus-circle:before {
  content: "\e881";
}

.lnr-circle-minus:before {
  content: "\e882";
}

.lnr-arrow-up-circle:before {
  content: "\e883";
}

.lnr-arrow-down-circle:before {
  content: "\e884";
}

.lnr-arrow-left-circle:before {
  content: "\e885";
}

.lnr-arrow-right-circle:before {
  content: "\e886";
}

.lnr-chevron-up-circle:before {
  content: "\e887";
}

.lnr-chevron-down-circle:before {
  content: "\e888";
}

.lnr-chevron-left-circle:before {
  content: "\e889";
}

.lnr-chevron-right-circle:before {
  content: "\e88a";
}

.lnr-crop:before {
  content: "\e88b";
}

.lnr-frame-expand:before {
  content: "\e88c";
}

.lnr-frame-contract:before {
  content: "\e88d";
}

.lnr-layers:before {
  content: "\e88e";
}

.lnr-funnel:before {
  content: "\e88f";
}

.lnr-text-format:before {
  content: "\e890";
}

.lnr-text-format-remove:before {
  content: "\e891";
}

.lnr-text-size:before {
  content: "\e892";
}

.lnr-bold:before {
  content: "\e893";
}

.lnr-italic:before {
  content: "\e894";
}

.lnr-underline:before {
  content: "\e895";
}

.lnr-strikethrough:before {
  content: "\e896";
}

.lnr-highlight:before {
  content: "\e897";
}

.lnr-text-align-left:before {
  content: "\e898";
}

.lnr-text-align-center:before {
  content: "\e899";
}

.lnr-text-align-right:before {
  content: "\e89a";
}

.lnr-text-align-justify:before {
  content: "\e89b";
}

.lnr-line-spacing:before {
  content: "\e89c";
}

.lnr-indent-increase:before {
  content: "\e89d";
}

.lnr-indent-decrease:before {
  content: "\e89e";
}

.lnr-pilcrow:before {
  content: "\e89f";
}

.lnr-direction-ltr:before {
  content: "\e8a0";
}

.lnr-direction-rtl:before {
  content: "\e8a1";
}

.lnr-page-break:before {
  content: "\e8a2";
}

.lnr-sort-alpha-asc:before {
  content: "\e8a3";
}

.lnr-sort-amount-asc:before {
  content: "\e8a4";
}

.lnr-hand:before {
  content: "\e8a5";
}

.lnr-pointer-up:before {
  content: "\e8a6";
}

.lnr-pointer-right:before {
  content: "\e8a7";
}

.lnr-pointer-down:before {
  content: "\e8a8";
}

.lnr-pointer-left:before {
  content: "\e8a9";
}

.button {
  vertical-align: top;
  outline: none;
  border-radius: 50px;
  cursor: pointer;
  border: 1px solid #949494;
  text-align: center;
  font-weight: 500;
  color: #fff;
  display: block;
  min-width: 150px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  height: 50px;
  border: none;
}
.button.large-size {
  width: 210px;
  font-size: 16px;
}
.button.yellow {
  border: none;
  padding: 0;
  background: #FFA800;
  color: #fff;
}
.button.yellow:hover:not(:disabled) {
  background: #e69700;
}
.button.green {
  border: none;
  padding: 0;
  background: #27AE60;
  color: #fff;
}
.button.small-button {
  width: 120px;
  height: 40px;
  min-width: 120px;
}
.button.button-standart {
  padding: 0 30px;
}
.button.width-auto-button {
  width: 100%;
  padding: 0 15px;
  height: 40px;
  min-width: 120px;
}
.button.height40 {
  height: 40px;
}
.button.transparent-white {
  background: transparent;
  color: #fff;
}
.button.transparent {
  background: transparent;
  color: #5AA2E5;
}
.button.button_height40 {
  height: 40px;
}
.button.button_m20-left {
  margin-left: 20px;
}
.button.blue {
  border: none;
  padding: 0;
  background: #2C53B7;
  color: #fff;
}
.button.blue:hover:not(:disabled) {
  background: #274aa2;
}
.button.border-blue {
  border: 2px solid #5AA2E5;
  color: #5AA2E5;
  box-sizing: border-box;
  border-radius: 27px;
  background: #fff;
}
.button.border-red {
  border: 2px solid #F95738;
  color: #F95738;
  box-sizing: border-box;
  border-radius: 27px;
  background: #fff;
}
.button.border-blue-square {
  border: 2px solid #5AA2E5;
  color: #5AA2E5;
  box-sizing: border-box;
  border-radius: 5px;
  background: #fff;
}
.button.red {
  border: none;
  padding: 0;
  background: #E81B22;
  color: #fff;
}
.button.red:hover:not(:disabled) {
  background: #d4151c;
}
.button.disabled {
  background: #ECF1F5;
  color: #000;
  cursor: inherit;
}
.button.inactive {
  opacity: 0.6;
}
.button:hover {
  text-decoration: none;
}
.button:hover .lnr, .button:hover .fas {
  background: inherit;
}
.button .lnr {
  margin-right: 8px;
}
.button.reset {
  min-width: 120px;
  margin-top: 23px;
}
.button.blue-btn {
  color: #5AA2E5;
  border: 2px solid #5AA2E5;
  background: #fff;
}
.button.blue-btn:hover {
  border: 2px solid #0F8F97;
  background: #fff;
  color: #0F8F97;
}
.button.red-btn {
  background: #EF3E42;
  border: 2px solid #EF3E42;
  color: #fff;
}
.button.red-btn:hover {
  border: 2px solid #C02424;
  background: #C02424;
  color: #fff;
}
.button:disabled {
  opacity: 0.6;
}

.align-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bold-style {
  font-weight: bold;
}

.buttons-wrapper {
  margin: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  text-transform: capitalize;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: #bbc4c9;
}

input::-moz-placeholder { /* Firefox 19+ */
  text-transform: capitalize;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: #bbc4c9;
}

input:-ms-input-placeholder { /* IE 10+ */
  text-transform: capitalize;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: #bbc4c9;
}

.form {
  padding: 0 24px;
}
.form .error .input-text, .form .error .item .input, .item .form .error .input,
.form .error .select,
.form .error .textarea,
.form .error .item-phone,
.form .error .c-btn,
.form .error .select2-selection,
.form .error .button-radio {
  border-color: #EF3E42;
}
.form .error .label, .form .error .button-radio-label {
  color: #EF3E42;
}
.form .error .input {
  border-color: #EF3E42;
}
.form .error .error-message {
  color: #EF3E42;
  font-size: 12px;
  margin-top: 5px !important;
  font-family: "Roboto", sans-serif;
}

.error-message {
  color: #EF3E42;
  font-size: 12px;
  margin: 15px 0 0 0;
  font-family: "Roboto", sans-serif;
}

.textarea {
  border: 1px solid #949494;
  border-radius: 3px;
  padding: 6px 11px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  max-width: 100%;
  min-width: 180px;
  color: #000;
  resize: vertical;
  min-height: 90px;
  height: 180px;
}

.select {
  border: 1px solid #949494;
  border-radius: 3px;
  padding: 5px 24px 5px 0px;
  font-size: 14px;
  min-height: 35px;
  font-family: "Roboto", sans-serif;
  max-width: 100%;
  cursor: pointer;
  min-width: 180px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #000;
}
.select optgroup {
  color: #000;
}
.select option {
  color: #000;
}
.select:not([multiple]):not(.readonly) {
  background: #fff url("/assets/images/icon-select.png") no-repeat right 10px center;
}
.select[multiple] {
  height: 91px;
  padding: 0;
}
.select[multiple] option {
  padding: 5px 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.select[multiple] option:checked {
  background: linear-gradient(0deg, #c6e0a9 0%, #c6e0a9 100%);
  font-weight: bold;
}
.select[multiple] option:checked:after {
  display: inline-block;
}

.form-popup > .row > .item {
  width: 48%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.form-popup > .row > .item.full {
  width: 100%;
  position: relative;
}
.form-popup > .row > .item.half {
  width: 66%;
  position: relative;
}
.form-popup > .row > .item.error {
  border-bottom-color: #F95738;
}
.form-popup > .row > .item.error .label {
  color: #F95738;
}
.form-popup > .row > .item.error .input, .form-popup > .row > .item.error app-datepicker {
  border-color: #F95738;
}
.form-popup > .row > .item.error .error-message {
  color: #F95738;
  font-size: 12px;
  margin-top: 5px !important;
}

.disabled-radio {
  opacity: 0.5;
}

.item-radio-button {
  flex-direction: row !important;
  color: #748394;
}

.input-text, .item .input {
  background-color: transparent;
  color: #000;
  height: 34px;
  border-radius: 3px;
  border: 1px solid #B7BCCB;
  font-size: 14px;
  display: block;
  text-align: left;
  padding: 6px 0;
  width: auto;
  margin: 0;
}
.input-text:disabled, .item .input:disabled {
  cursor: not-allowed;
  background: #edeff2;
}
.input-text::placeholder, .item .input::placeholder {
  text-transform: capitalize;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
.input-text:focus + .bottom, .item .input:focus + .bottom {
  width: 100%;
  margin-left: -50%;
}
.input-text:focus + .bottom + .label, .item .input:focus + .bottom + .label {
  transform: translateY(-19px);
}

.item .label {
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 2;
  transition: all linear 0.1s;
}
.item .label.opened {
  transform: translateY(-19px);
}
.item .label.required:after {
  content: "*";
  color: #F95738;
  margin-left: 3px;
}

.mat-form-field-wrapper {
  width: 100%;
}

tbody tr.primary td, tbody tr.primary:hover td {
  background-color: #fff;
  box-shadow: 0 1px 2px #c7cfd8;
}
tbody tr:hover td {
  background: #fff;
}
tbody td {
  padding: 11px 18px;
  background-color: transparent;
  color: #000;
  vertical-align: middle;
  border-bottom: 1px dashed #C4C4C4;
}
tbody td.warning {
  font-family: 900;
  color: #F95738;
}
tbody td.no-list-data {
  padding: 15px;
  text-align: center;
  font-family: 900;
}

th.checkbox {
  width: 56px;
}

.table {
  width: 100%;
}
.table th {
  border-bottom: 1px dashed #C4C4C4;
  background: transparent;
  text-align: left;
  font-weight: 400;
  font-family: 900;
  cursor: pointer;
  vertical-align: middle;
  color: #8089A0;
  padding: 11px 18px;
}
.table .actions {
  white-space: nowrap;
  width: 1%;
  text-align: center;
}
.table .centered {
  text-align: center;
}
.table .checkmark {
  top: -3px;
}
.table.w-100 {
  table-layout: fixed;
  width: 100%;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 10px;
}
.filters .item {
  margin-bottom: 15px;
  width: 180px;
  margin-right: 20px;
}
.filters .input-text, .filters .item .input, .item .filters .input, .filters .select, .filters .input-text-empty, .filters .select-empty {
  min-width: 100%;
  max-width: 100%;
  background: #fff;
  box-sizing: border-box;
  border-radius: 20px;
  height: 30px;
  font-size: 14px;
}
.filters .label-min {
  color: #333 !important;
  min-width: 150px !important;
}
.filters .input-text, .filters .item .input, .item .filters .input, .filters .select {
  border: 2px solid #5AA2E5;
}
.filters .input-text-empty, .filters .select-empty {
  border: 1px solid #B7BCCB;
  font-style: italic;
  font-size: 12px;
  text-transform: capitalize;
  color: #B7BCCB;
}
.filters option {
  color: #000;
}
.filters input, .filters select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.call-to-action__image {
  margin-right: 55px;
}

.call-to-action__header {
  display: flex;
  align-items: center;
  padding: 18px 0;
}

.call-to-action__title {
  display: flex;
}
.call-to-action__title img {
  width: 23px;
  margin-right: 10px;
}

.project__call-to-action {
  display: flex;
  margin-top: -80px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.project__call-to-action .button {
  border: 2px solid #5AA2E5;
  border-radius: 5px;
  text-transform: uppercase;
  height: 32px;
  margin-left: 16px;
  background: transparent;
  cursor: pointer;
}
.project__call-to-action .button.show .fa-chevron-down {
  transform: rotate(180deg);
  transition: all ease-in 0.1s;
}
.project__call-to-action .button .fa-chevron-down {
  transition: all ease-in 0.1s;
}
.project__call-to-action .button span {
  padding-right: 14px;
}

@media screen and (max-width: 1380px) {
  .project__call-to-action {
    margin-top: 45px;
    align-content: center;
    flex-direction: column;
  }
  .project__call-to-action .button {
    margin: 0 15px;
  }
}
@media screen and (max-width: 1100px) {
  .call-to-action__header {
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .call-to-action__title img {
    width: 23px;
    height: 23px;
  }
  .call-to-action__header {
    padding: 15px 18px;
  }
  .call-to-action__header .button {
    margin-top: 10px;
  }
  .call-to-action__image {
    margin-right: 0;
  }
  .call-to-action__text {
    margin-top: 15px;
  }
  .project__call-to-action {
    margin-top: 15px;
    border-radius: 0;
  }
}
.flow-info-text {
  width: 45vw;
  background-size: cover;
  display: flex;
  flex-direction: column;
  text-align: right;
  color: #fff;
  justify-content: center;
}
@media screen and (max-width: 1023px) {
  .flow-info-text {
    width: 100%;
    font-size: 45px;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .flow-info-text {
    display: none;
  }
}
.flow-info-text .inner {
  display: flex;
  text-align: right;
  font-weight: 900;
  line-height: 1;
  color: #fff;
  align-items: center;
  justify-content: center;
  height: 100%;
}
@media screen and (max-width: 1023px) {
  .flow-info-text .inner {
    padding: 30px 0;
  }
}
.flow-info-text .inner .sub-text {
  text-transform: none;
  font-size: 56px;
  line-height: 1.1;
}
.flow-info-text .inner .sub-text2 {
  display: none;
}

.flow-steps {
  width: 55vw;
  display: flex;
  padding: 0 20px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 1023px) {
  .flow-steps {
    height: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .flow-steps {
    margin-top: 75px;
  }
}
@media screen and (max-width: 460px) {
  .flow-steps {
    margin-top: 50px;
  }
}

.flow-progress-line {
  position: absolute;
  height: 100%;
  left: 45vw;
  margin-left: -40px;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flow-progress-line .item-line {
  height: 33.33%;
  display: flex;
  align-items: center;
}
.flow-progress-line .item-line .circle {
  width: 69px;
  height: 69px;
  display: flex;
  background: #F2F7FB;
  align-items: center;
  font-weight: 900;
  font-size: 24px;
  justify-content: center;
  color: #8089A0;
  border-radius: 50%;
}
.flow-progress-line .item-line .circle.isActiveCircle {
  color: #fff;
  background: #FFA800;
}
.flow-progress-line .item-line .circle__total {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.flow-progress-line .item-line .circle-finished {
  background: url("/assets/images/check.svg") center;
  background-size: 20px 15px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.flow-progress-line--mobile {
  display: flex;
  width: 100%;
  z-index: 19;
  background-color: #D9E8F2;
  box-shadow: 0px 4px 8px rgba(144, 170, 190, 0.4);
}
@media (max-width: 768px) {
  .flow-progress-line--mobile {
    position: fixed;
    top: 0;
    left: 0;
  }
}
.flow-progress-line--mobile .step-counter {
  background-color: #FFA800;
  color: #fff;
  padding: 14px;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
}
@media (max-width: 768px) {
  .flow-progress-line--mobile .step-counter {
    position: absolute;
    left: 0;
    bottom: 0;
    width: fit-content;
    height: 100%;
  }
}
.flow-progress-line--mobile .step-counter__total {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.flow-progress-line--mobile .step-counter__title {
  margin: 0 auto 0 36px;
  padding: 17px 0;
  font-weight: 900;
  font-size: 18px;
  line-height: 21px;
  text-align: left;
  color: #273C73;
}

.flow-button-wrapper {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
@media screen and (max-width: 767px) {
  .flow-button-wrapper {
    padding: 0;
    width: 100%;
  }
}
.flow-button-wrapper .button.yellow {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  width: 210px;
  height: 50px;
}
.flow-button-wrapper .button.transparent {
  min-width: 100px;
  width: 100px;
  font-size: 16px;
  font-weight: 700;
}
.flow-button-wrapper .cancel {
  color: #5AA2E5;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500;
}
.flow-button-wrapper .cancel .fas {
  margin-right: 10px;
}

.flow-steps-inner {
  padding: 20px 0;
}
@media screen and (max-width: 767px) {
  .flow-steps-inner {
    padding: 40px 0;
  }
}
.flow-steps-inner .step {
  max-width: 416px;
  width: 100%;
}

.flow-page.mobile-steps {
  display: none;
  margin-top: 72px;
  background: #253a6f;
  position: relative;
  margin-bottom: -50px;
  z-index: 2;
  padding: 0 20px 16px;
  color: #fff;
  align-items: center;
  justify-content: space-between;
}
.flow-page.mobile-steps .progress {
  font-size: 24px;
  font-weight: 900;
}
.flow-page.mobile-steps .total {
  font-size: 14px;
}
.flow-page.mobile-steps .step-title {
  font-weight: 900;
  font-size: 18px;
  text-align: center;
  width: calc(100% - 40px);
}
.flow-page.mobile-steps .line {
  position: absolute;
  bottom: -3px;
  left: 0;
  border-top: 3px solid #FFA800;
  max-width: 100%;
  transition: width ease-in-out 0.5s;
}
@media screen and (max-width: 460px) {
  .flow-page.mobile-steps {
    display: flex;
  }
}
.flow-page .title {
  font-weight: 900;
  font-size: 24px;
  color: #000;
  margin-bottom: 25px;
  text-align: center;
  text-transform: uppercase;
}
@media screen and (max-width: 460px) {
  .flow-page .title {
    display: none;
  }
}

.input-box {
  border: none;
  width: 100%;
  font-size: 16px;
  padding: 16px 20px 13px 20px;
  border-radius: 5px;
}

.popup-inner {
  max-width: 90vw;
  position: relative;
  padding: 40px 90px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .popup-inner {
    padding: 20px;
  }
}

.popup-title {
  font-size: 21px;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
}

.text {
  color: #8089A0;
}

.text-center {
  text-align: center;
}

.text-overflow-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grs-button, .grs-button--white, .grs-button--blue, .grs-button--orange {
  display: block;
  border-radius: 26px;
  padding: 12px 0;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  transition: all 0.2s ease-in;
  text-decoration: none;
  cursor: pointer;
}
.grs-button:hover, .grs-button--white:hover, .grs-button--blue:hover, .grs-button--orange:hover {
  text-decoration: none;
}
.grs-button--outline {
  border: 2px solid;
  background-color: #fff !important;
}
.grs-button--outline:hover {
  background-color: #fff !important;
}
.grs-button--orange {
  background-color: #FFA800;
  color: #fff;
  -webkit-text-fill-color: #fff;
}
.grs-button--orange:focus, .grs-button--orange:visited {
  color: #fff;
}
.grs-button--orange:hover {
  color: #fff;
  background-color: #e69700;
}
.grs-button--orange.grs-button--outline {
  -webkit-text-fill-color: #FFA800;
  color: #FFA800;
  border-color: #FFA800;
}
.grs-button--blue {
  background-color: #5AA2E5;
  color: #fff;
}
.grs-button--blue:focus, .grs-button--blue:visited {
  color: #fff;
}
.grs-button--blue:hover {
  color: #fff;
  background-color: #2e89de;
}
.grs-button--blue.grs-button--outline {
  color: #5AA2E5;
  border-color: #5AA2E5;
}
.grs-button--white {
  background-color: #fff;
  color: #FFA800;
}
.grs-button--white:focus, .grs-button--white:visited {
  color: #FFA800;
}
.grs-button--white:hover {
  color: #FFA800;
  background-color: #e6e6e6;
}

app-project .main-wrapper {
  width: 100%;
  position: relative;
  background: #fff;
}
app-project .image-wrapper {
  height: 474px;
  width: 100%;
  position: relative;
}
app-project app-card-project .card:hover {
  position: relative;
  z-index: 2;
}
app-project app-card-project .card-outer {
  margin: 0;
}
app-project app-card-project .card-image-wrapper {
  margin: 0;
}
app-project app-card-project .back {
  margin: 0;
}
app-project .play-button {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-project app-project-song-detail {
  display: flex;
  flex: 1;
}
app-project .project__detail-song-information {
  width: 100%;
  position: relative;
  display: flex;
  padding: 0 55px;
  flex-direction: column;
}
app-project .project__song-info {
  position: relative;
  width: 100%;
  margin-top: -118px;
  display: flex;
  z-index: 10;
  padding: 0 40px;
  justify-content: center;
}
app-project .project__box-header {
  display: flex;
  position: relative;
  width: 100%;
  background: #F2F7FB;
  padding: 26px 60px;
  box-shadow: 0 2px 4px rgba(144, 170, 190, 0.5);
  border-radius: 7px;
}
app-project .project__box-header .project__detail {
  flex-grow: 1;
}
app-project .grs-details__title {
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
  margin: 15px 0 18px 0;
}
app-project .profile-title {
  padding: 30px 0;
}
app-project .project__get-information {
  width: 300px;
  min-width: 300px;
}
app-project .project__get-information .get-title {
  margin: 25px 0 20px 0;
  text-align: center;
}
app-project .project__title-sub {
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
}
app-project .project__mobile-view-p {
  display: none;
}
app-project .project__subscribe {
  padding: 25px 90px;
}
app-project .project-blue-box {
  background: #DFEBF4;
  box-shadow: 0px 2px 4px rgba(144, 170, 190, 0.5);
  border-radius: 7px;
  margin-top: 20px;
}
app-project .album-logo {
  display: block;
  position: relative;
  background-size: cover;
}
app-project .album-logo::after {
  content: "";
  display: block;
  position: absolute;
  left: 17px;
  top: 8px;
  width: 21px;
  height: 21px;
  background-size: 21px;
  background-image: url("../../../assets/images/music.png");
}
app-project .gold-color, app-project app-card-information .item-highlighted.legacy, app-card-information app-project .item-highlighted.legacy, app-project .project-highlighted.legacy {
  background-image: -webkit-linear-gradient(#D2B652 0%, #ECCE63 100%);
  background-image: -o-linear-gradient(#D2B652 0%, #ECCE63 100%);
  background-image: linear-gradient(#D2B652 0%, #ECCE63 100%);
}
app-project .limited-edition {
  border: 2px solid #D2B652;
  border-radius: 5px;
  padding: 3px 10px;
  background: linear-gradient(to right, #D2B652 0%, #ECCE63 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  font-size: 36px;
  text-align: center;
  font-weight: bold;
  max-width: 360px;
}
app-project .profile-artist-info {
  display: flex;
}
app-project .project__logo-image {
  width: 15px;
  position: relative;
  margin-top: -18px;
}
app-project .project__logo-title {
  width: 140px;
  position: absolute;
  top: 3px;
  right: 20px;
}
app-project .project-highlighted {
  position: relative;
  padding: 4px 15px;
  width: auto;
  height: 45px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  background: #FFA800;
  border-radius: 5px 5px 0 0;
  text-align: center;
}
app-project .project-highlighted.ends {
  background: #F95738;
}
app-project .project-highlighted.newcomer {
  background: linear-gradient(180deg, #6182A8 42.62%, #8CABCF 100%);
}
app-project .project-highlighted.headliner {
  background: linear-gradient(180deg, #34476E 21.77%, #6783BC 100%);
}
app-project .project-highlighted.album {
  display: flex;
  position: relative;
  padding: 0;
  width: auto;
  overflow: hidden;
}
app-project .project-highlighted.album .text-uppercase {
  padding: 5px 15px;
}
app-project .project-highlighted.released {
  background: #9a463d;
}
app-project .project-highlighted.new {
  background: #27AE60;
}
app-project .project-highlighted.funded {
  background: #5AA2E5;
}
app-project .project-highlighted span {
  color: #fff;
  font-weight: bold;
  line-height: 20px;
  display: inline-block;
  padding: 0 10px;
  font-size: 15px;
}
app-project .project__sign-highlight {
  position: absolute;
  top: -27px;
  left: 40px;
  display: flex;
}
app-project .item-highlighted-album {
  position: relative;
  width: auto;
  z-index: 2;
}
app-project .album-bcg {
  width: 100%;
  display: block;
  position: relative;
  transform: translateX(-10%) skewX(-20deg);
  background: #C02424;
}
app-project .album-text {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  padding: 5px 20px 5px 30px;
  text-transform: uppercase;
  transform: translateX(0%) skew(20deg);
}
app-project .project__detail {
  position: relative;
  display: flex;
}
app-project .project__detail .item-content-title {
  line-height: 23px;
  font-weight: 900;
  font-size: 27px;
}
app-project .project-logo {
  margin-right: 35px;
  position: relative;
}
app-project .project-logo .project-logo-img {
  width: 160px;
  position: relative;
  height: 160px;
  background-size: cover;
  background-position: center center;
  border-radius: 80px;
}
app-project .profile-info {
  position: relative;
  padding: 0 90px 40px 90px;
}
app-project .project__title {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
}
app-project .project-first-section {
  margin-top: -80px;
}
app-project audio {
  max-width: 350px;
  width: 100%;
}
app-project .profile-title {
  padding: 25px 0;
}
app-project .img-copy {
  padding-left: 5px;
  cursor: pointer;
}
app-project .slider-item {
  background-position: center;
  background-size: cover;
  overflow: hidden;
  background-image: url("../../../assets/images/fallback.png");
}
app-project .slider-item .inner {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
app-project .profile-info-left {
  padding-right: 50px;
  flex-grow: 1;
}
app-project .call-to-action__body {
  visibility: hidden;
  background: #F2F7FB;
  display: flex;
  height: 0;
}
app-project .call-to-action__body .call-to-action__image {
  width: 197px;
  height: 197px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));
  border-radius: 14px;
}
app-project .call-to-action__body.show {
  padding: 25px 90px;
  visibility: visible;
  width: 100%;
  height: 100%;
}
app-project .get-text {
  color: #8089a0;
  list-style-type: disc;
}
app-project .get-text .item {
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
}
app-project .get-text li {
  color: #5aa2e5;
}
app-project .section__calculation {
  display: none;
}
app-project app-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
app-project app-loader .global-page-loader {
  margin: 0;
}

app-song-preview {
  position: fixed;
  bottom: 0;
}

@media screen and (max-width: 1490px) {
  app-project .project__detail-song-information {
    padding: 0 30px;
  }
  app-project .project__box-header {
    padding: 27px 54px;
  }
}
@media screen and (max-width: 1380px) {
  app-project .project__calculation {
    display: none;
  }
  app-project .profile-artist-info .project__get-information {
    display: none;
  }
  app-project .project__detail-song-information {
    padding: 0 0 0 27px;
  }
  app-project .project__box-header {
    padding: 27px 27px 27px 54px;
  }
  app-project .section__calculation {
    display: flex;
    margin: 20px auto 0;
    width: 95%;
    justify-content: space-between;
  }
  app-project .section__calculation app-calculation {
    width: 50%;
  }
  app-project .section__calculation app-calculation .wrapper {
    justify-content: unset;
    padding: 0 54px;
  }
  app-project .section__calculation .project__get-information {
    width: 50%;
    padding-left: 40px;
  }
  app-project .section__calculation .project__get-information .get-title {
    margin: 15px 0 20px 0;
  }
  app-project .profile-info {
    margin-top: 0;
    padding: 0 28px 20px 28px;
  }
}
@media screen and (max-width: 1100px) {
  app-project .project__box-header {
    padding: 27px 24px;
  }
  app-project .project__song-info {
    padding: 0 20px;
  }
  app-project app-calculation .wrapper {
    padding: 0 24px;
  }
}
@media screen and (max-width: 767px) {
  app-project .project__detail {
    margin-bottom: 20px;
  }
  app-project .project__detail-song {
    display: none;
  }
  app-project .call-to-action__body {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  app-project .call-to-action__body.show {
    padding: 25px 18px;
  }
  app-project .project__detail-song-information {
    padding: 0;
  }
  app-project .grs-details__title {
    display: none;
  }
  app-project .project__box-header {
    padding: 0;
    top: 0;
    background: transparent;
    box-shadow: none;
    width: 100%;
  }
  app-project .project__logo-image {
    margin-top: -16px;
  }
  app-project .project__mobile-view-p {
    display: block;
    margin-bottom: 15px;
    padding: 0 18px;
  }
  app-project .image-wrapper {
    display: none;
  }
  app-project .profile-info {
    display: none;
  }
  app-project .project__mobile-view-p {
    margin-top: 15px;
  }
  app-project .project__title-sub {
    padding-left: 18px;
    line-height: 14px;
  }
  app-project .get-text {
    margin-left: 18px;
  }
  app-project .get-text .item {
    padding-left: 0;
  }
  app-project .section__calculation {
    width: 100%;
    background: #F2F7FB;
    margin: 0;
    padding: 15px 18px 10px;
    border-radius: 0 7px 7px 7px;
  }
  app-project .section__calculation app-calculation {
    width: 100%;
  }
  app-project .section__calculation app-calculation .wrapper {
    padding: 0;
    margin: 0;
    justify-content: center;
  }
  app-project .section__calculation app-calculation .wrapper .inner {
    max-width: 100%;
  }
  app-project .section__calculation app-calculation .amount {
    max-width: 100%;
  }
  app-project .section__calculation app-calculation .item {
    min-width: 60px;
  }
  app-project .section__calculation app-calculation .item-dropdown, app-project .section__calculation app-calculation .button {
    width: 100%;
    max-width: 100%;
  }
  app-project .section__calculation .project__get-information {
    display: none;
  }
  app-project app-card-project {
    margin: 0 auto;
  }
  app-project .project-highlighted {
    width: 202px;
  }
  app-project .project-highlighted span {
    line-height: 25px;
  }
  app-project .project-highlighted.superRare {
    width: 150px;
  }
  app-project .sign-highlight__wrapper {
    width: 310px;
    margin: 0 auto;
  }
  app-project .project__sign-highlight {
    top: 90px;
    left: 0;
    width: 100%;
  }
  app-project .project__song-info {
    margin-top: 0;
    padding: 100px 0 0 0;
  }
  app-project .project__detail {
    flex-direction: column;
    padding: 0 18px;
  }
  app-project .project__subscribe {
    padding: 15px 18px;
  }
  app-project app-subscribe-newsletter .subscribe__button {
    margin: 0;
  }
  app-project app-subscribe-newsletter .subscribe__button-wrapper {
    flex-direction: column;
  }
  app-project app-subscribe-newsletter .subscribe__input {
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
  }
  app-project app-subscribe-newsletter .subscribe-title {
    font-size: 18px;
    margin-bottom: 20px;
    padding: 0 25px;
  }
}
@media screen and (max-width: 460px) {
  app-project .limited-edition {
    font-size: 28px;
  }
}
@media screen and (max-width: 320px) {
  app-project .project__detail {
    padding: 0 10px;
  }
}
app-collectible .main-wrapper {
  width: 100%;
  position: relative;
  background: #fff;
}
app-collectible .image-wrapper {
  height: 474px;
  width: 100%;
  position: relative;
}
app-collectible .call-to-action__body {
  visibility: hidden;
  background: #F2F7FB;
  display: flex;
  height: 0;
}
app-collectible .call-to-action__body .call-to-action__image {
  width: 197px;
  height: 197px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));
  border-radius: 14px;
}
app-collectible .call-to-action__body.show {
  padding: 25px 90px;
  visibility: visible;
  width: 100%;
  height: 100%;
}
app-collectible .play-button {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-collectible app-project-song-detail {
  display: flex;
  flex: 1;
}
app-collectible .project__song-info {
  position: relative;
  width: 100%;
  margin-top: -118px;
  display: flex;
  z-index: 10;
  padding: 0 40px;
  justify-content: center;
}
app-collectible app-card-project .card-outer {
  margin: 0;
}
app-collectible app-card-project .card-image-wrapper {
  margin: 0;
}
app-collectible app-card-project .back {
  margin: 0;
}
app-collectible .artist-profile-image img {
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
app-collectible .project__box-header {
  display: flex;
  position: relative;
  width: 100%;
  background: #F2F7FB;
  padding: 26px 60px;
  box-shadow: 0 2px 4px rgba(144, 170, 190, 0.5);
  border-radius: 7px;
}
app-collectible .project__box-header .project__detail {
  flex-grow: 1;
}
app-collectible .clearfix {
  clear: both;
}
app-collectible .profile-title {
  padding: 30px 0;
}
app-collectible .project__get-information {
  width: 300px;
  min-width: 300px;
}
app-collectible .project__get-information .get-title {
  margin: 25px 0 20px 0;
  text-align: center;
}
app-collectible .project__title-sub {
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
}
app-collectible .item-share-document-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
app-collectible .item-share-document {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
app-collectible .item-share-document span {
  padding-left: 10px;
}
app-collectible .item-share-mobile {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  visibility: hidden;
}
app-collectible .item-share-mobile .item-share-document-mobile {
  display: flex;
  align-items: center;
  text-decoration: none;
}
app-collectible .item-share-mobile .item-share-document-mobile span {
  padding-left: 16px;
}
app-collectible .item-share-mobile .share-img {
  width: 18px;
}
app-collectible .item-share-mobile .slider-btn {
  width: 334px;
  background: #FFA800;
  font-size: 21px;
  height: 56px;
}
app-collectible .item-share-mobile .slider-btn.disabled {
  background: #8089A0;
  color: #fff;
  opacity: 0.5;
}
app-collectible .item-share-mobile h3 {
  margin-top: 20px;
  cursor: pointer;
}
app-collectible .project__mobile-view-p {
  display: none;
}
app-collectible .project__subscribe {
  padding: 25px 90px;
}
app-collectible .project-blue-box {
  background: #DFEBF4;
  box-shadow: 0px 2px 4px rgba(144, 170, 190, 0.5);
  border-radius: 7px;
  margin-top: 20px;
}
app-collectible .album-logo {
  display: block;
  position: relative;
  background-size: cover;
}
app-collectible .album-logo::after {
  content: "";
  display: block;
  position: absolute;
  left: 17px;
  top: 8px;
  width: 21px;
  height: 21px;
  background-size: 21px;
  background-image: url("../../../assets/images/music.png");
}
app-collectible .profile-artist-info {
  display: flex;
}
app-collectible .project-highlighted {
  position: relative;
  padding: 4px 15px;
  width: auto;
  height: 45px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  background: #FFA800;
  border-radius: 5px 5px 0 0;
  text-align: center;
}
app-collectible .project-highlighted span {
  color: #fff;
  font-weight: bold;
  line-height: 20px;
  display: inline-block;
  padding: 0 10px;
  font-size: 15px;
}
app-collectible .project-highlighted__logo {
  width: 15px;
  position: relative;
  margin-top: -18px;
}
app-collectible .project__sign-highlight {
  position: absolute;
  top: -27px;
  left: 40px;
  display: flex;
}
app-collectible .item-highlighted-album {
  position: relative;
  width: auto;
  z-index: 2;
}
app-collectible .album-bcg {
  width: 100%;
  display: block;
  position: relative;
  transform: translateX(-10%) skewX(-20deg);
  background: #C02424;
}
app-collectible .album-text {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  padding: 5px 20px 5px 30px;
  text-transform: uppercase;
  transform: translateX(0%) skew(20deg);
}
app-collectible .item-song {
  margin-bottom: 10px;
  position: relative;
  height: 50px;
}
app-collectible .item-song .song-name {
  color: #8089A0;
}
app-collectible .project__detail {
  position: relative;
  display: flex;
}
app-collectible .project__detail .item-content-title {
  line-height: 23px;
  font-weight: 900;
  font-size: 27px;
}
app-collectible .project-logo {
  margin-right: 35px;
  position: relative;
}
app-collectible .project-logo .project-logo-img {
  width: 160px;
  position: relative;
  height: 160px;
  background-size: cover;
  background-position: center center;
  border-radius: 80px;
}
app-collectible .profile-info {
  position: relative;
  padding: 0 90px 40px 90px;
}
app-collectible .get-text {
  color: #8089a0;
  list-style-type: disc;
}
app-collectible .get-text .item {
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
}
app-collectible .get-text li {
  color: #5aa2e5;
}
app-collectible .profile-album-content .text-description {
  margin-top: 20px;
}
app-collectible .project__title {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
}
app-collectible .project-first-section {
  margin-top: -80px;
}
app-collectible .profile-album {
  display: flex;
}
app-collectible .profile-album .audio-list {
  max-height: 300px;
  width: 50%;
  min-width: 450px;
  overflow-y: auto;
  margin-right: 20px;
}
app-collectible .profile-album .audio-arrow {
  font-size: 18px;
  color: #5AA2E5;
  width: 350px;
  font-weight: bold;
  margin-left: 0;
  text-align: center;
  display: inline-block;
}
app-collectible .wrapper-scrollbar {
  min-height: 250px;
}
app-collectible audio {
  max-width: 350px;
  width: 100%;
}
app-collectible .img-block {
  width: 100px;
  margin-right: 25px;
  margin-bottom: 12px;
}
app-collectible .image-desc-float {
  float: left;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-image: url("../../../assets/images/fallback.png");
  overflow: hidden;
}
app-collectible .image-desc-float .inner {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
app-collectible .profile-title {
  padding: 25px 0;
}
app-collectible .followers-icon {
  margin-top: 4px;
}
app-collectible .followers-count {
  font-weight: 900;
  color: black;
  font-size: 14px;
  font-style: normal;
  padding-left: 30px;
  position: absolute;
  line-height: 16px;
}
app-collectible .img-copy {
  padding-left: 5px;
  cursor: pointer;
}
app-collectible app-footer-about-company .about-company {
  margin-top: 26px;
}
app-collectible .followers-count-twitter {
  font-weight: 900;
  color: black;
  font-size: 14px;
  font-style: normal;
  padding-left: 30px;
  position: absolute;
  line-height: 16px;
}
app-collectible .followers-text {
  color: #B7BCCB;
  font-style: normal;
  padding-left: 4px;
}
app-collectible .follow-caption {
  color: #B7BCCB;
}
app-collectible .follow-name {
  font-weight: 200;
}
app-collectible .project__followers-social-row {
  position: relative;
}
app-collectible .followers-social-row-mobile {
  height: fit-content;
  visibility: hidden;
  display: none;
  border-bottom: 1px dashed #C4C4C4;
}
app-collectible .followers-flex-container {
  display: grid;
  grid-template-columns: 100px 100px 100px;
  grid-template-rows: 50px auto;
  justify-content: left;
  padding-top: 11px;
  pointer-events: none;
}
app-collectible .slider-item {
  background-position: center;
  background-size: cover;
  overflow: hidden;
  background-image: url("../../../assets/images/fallback.png");
}
app-collectible .slider-item .inner {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
app-collectible .profile-info-left {
  padding-right: 50px;
  flex-grow: 1;
}
app-collectible .text-description {
  order: 2;
}
app-collectible .info-action-img-mobile {
  display: none;
}
app-collectible .item-detail-mobile {
  display: none;
  position: relative;
}
app-collectible .section__calculation {
  display: none;
}
app-collectible app-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
app-collectible app-loader .global-page-loader {
  margin: 0;
}

app-song-preview {
  position: fixed;
  bottom: 0;
}

@media screen and (max-width: 1490px) {
  app-collectible .project__box-header {
    padding: 27px 54px;
  }
}
@media screen and (max-width: 1380px) {
  app-collectible .project__calculation {
    display: none;
  }
  app-collectible .profile-info-left {
    padding-right: 0;
  }
  app-collectible .profile-artist-info .project__get-information {
    display: none;
  }
  app-collectible .project__box-header {
    padding: 27px 27px 27px 54px;
  }
  app-collectible .item-share-document {
    justify-content: center;
  }
  app-collectible .section__calculation {
    display: flex;
    min-height: 400px;
    margin: 20px auto 0;
    width: 95%;
    justify-content: space-between;
  }
  app-collectible .section__calculation app-calculation {
    width: 50%;
  }
  app-collectible .section__calculation app-calculation .wrapper {
    justify-content: unset;
    align-items: flex-start;
  }
  app-collectible .section__calculation .project__get-information {
    width: 50%;
  }
  app-collectible .section__calculation .project__get-information .get-title {
    margin: 15px 0 20px 0;
  }
  app-collectible .profile-info {
    margin-top: 0;
    padding: 0 28px 20px 28px;
  }
}
@media screen and (max-width: 1100px) {
  app-collectible .project__box-header {
    padding: 27px 24px;
  }
  app-collectible app-calculation .wrapper {
    padding: 0 24px;
  }
}
@media screen and (max-width: 767px) {
  app-collectible .mobile-view-p__body .detail-song-info__song-details {
    border: none;
    display: none;
  }
  app-collectible .call-to-action__body {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  app-collectible .call-to-action__body.show {
    padding: 25px 18px;
  }
  app-collectible .sign-highlight__wrapper {
    width: 310px;
    margin: 0 auto;
  }
  app-collectible .get-text {
    margin-left: 18px;
  }
  app-collectible .get-text .item {
    padding-left: 0;
  }
  app-collectible .project-highlighted {
    width: 160px;
  }
  app-collectible .project-highlighted span {
    line-height: 25px;
  }
  app-collectible .project-highlighted.superRare {
    width: 150px;
  }
  app-collectible .project-highlighted__logo {
    margin-top: -15px;
  }
  app-collectible .project__detail .grs-details {
    display: none;
  }
  app-collectible .project__detail .detail-song-info__song-details {
    border: none;
  }
  app-collectible .project__box-header {
    padding: 0;
    top: 0;
    background: transparent;
    box-shadow: none;
    width: 100%;
  }
  app-collectible .project__mobile-view-p {
    display: block;
    margin-bottom: 15px;
    padding: 0 18px;
  }
  app-collectible .image-wrapper {
    display: none;
  }
  app-collectible .profile-info {
    display: none;
  }
  app-collectible .project__mobile-view-p {
    margin-top: 15px;
  }
  app-collectible .mobile-view-p__title {
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-top: 1px dashed #A8C1D3;
  }
  app-collectible .mobile-view-p__title.mobile-view-p__title--last {
    border-bottom: 1px dashed #A8C1D3;
  }
  app-collectible .mobile-view-p__title.show {
    border-bottom: 1px dashed #A8C1D3;
  }
  app-collectible .project__title-sub {
    padding-left: 18px;
    line-height: 14px;
  }
  app-collectible .mobile-view-p__body {
    overflow: hidden;
    height: 0;
  }
  app-collectible .mobile-view-p__body.show {
    padding: 15px 0;
    height: auto;
  }
  app-collectible .section__calculation {
    width: 100%;
    background: #F2F7FB;
    margin: 0;
    padding: 0 18px 10px;
    border-radius: 0 7px 7px 7px;
  }
  app-collectible .section__calculation app-calculation {
    width: 100%;
  }
  app-collectible .section__calculation app-calculation .wrapper {
    padding: 0;
    margin: 0;
    justify-content: center;
  }
  app-collectible .section__calculation app-calculation .wrapper .inner {
    max-width: 100%;
  }
  app-collectible .section__calculation app-calculation .amount {
    max-width: 100%;
  }
  app-collectible .section__calculation app-calculation .item {
    min-width: 60px;
  }
  app-collectible .section__calculation app-calculation .item-dropdown, app-collectible .section__calculation app-calculation .button {
    width: 100%;
    max-width: 100%;
  }
  app-collectible .section__calculation .project__get-information {
    display: none;
  }
  app-collectible .project__sign-highlight {
    top: 90px;
    left: 0;
    width: 100%;
  }
  app-collectible app-card-project {
    margin: 0 auto;
  }
  app-collectible .project__song-info {
    margin-top: 0;
    padding: 120px 0 0 0;
  }
  app-collectible .project__detail {
    flex-direction: column;
    padding: 0 18px;
  }
  app-collectible .project__subscribe {
    padding: 15px 18px;
  }
  app-collectible app-subscribe-newsletter .subscribe__button {
    margin: 0;
  }
  app-collectible app-subscribe-newsletter .subscribe__button-wrapper {
    flex-direction: column;
  }
  app-collectible app-subscribe-newsletter .subscribe__input {
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
  }
  app-collectible app-subscribe-newsletter .subscribe-title {
    font-size: 18px;
  }
}
@media screen and (max-width: 460px) {
  app-collectible .limited-edition {
    font-size: 28px;
  }
  app-collectible .profile-album .audio-list {
    padding-right: 0;
  }
}
app-revenue {
  height: 100%;
}
app-revenue .main-wrapper {
  min-height: 100vh;
  background: #fff;
}
app-revenue main {
  flex: 1;
  width: 100%;
  margin: 0 auto;
}
app-revenue .data-list {
  width: 1300px;
  margin: 0 auto;
}
app-revenue .data-list .data-list--margin {
  margin-top: 32px;
  margin-bottom: 32px;
}
app-revenue .table-header {
  border-bottom: 2px solid #8089A0;
}
app-revenue .song__row td:first-child {
  max-width: 55px;
  width: 55px;
}
app-revenue .not-revenue {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
  color: #8089A0;
}
app-revenue .not-revenue .not-revenue__title {
  font-weight: 700;
}
app-revenue .table__nested table {
  width: 100%;
}
app-revenue .table__nested .table-open {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}
app-revenue .table__nested .table-open.openNested {
  max-height: initial;
  width: 100%;
  transition: max-height 0.5s ease-out;
}
app-revenue .table__nested .nested__td {
  padding: 0 55px;
  border: none;
}
app-revenue .table__nested .nested__td.active {
  border-bottom: 1px dashed #C4C4C4;
}
app-revenue .table__nested td {
  color: #8089A0;
}
app-revenue .table__nested .report {
  text-decoration: none;
}
app-revenue .table__nested .table-sub-header {
  border-bottom: 2px solid #8089A0;
}
app-revenue .table__nested .table-sub-header td {
  padding: 26px 18px 12px 18px;
}
app-revenue .table__nested .nested__body td {
  padding: 20px 18px;
}
app-revenue .table__nested .nested__body td:empty {
  display: none;
}
app-revenue .table__nested .nested__divider {
  height: 0px;
  border: 0;
  border-bottom: 1px dashed #B7BCCB;
}
app-revenue .filter-wrapper .item {
  margin-right: 14px;
}
app-revenue .wrapper-pagination-footer {
  border-bottom: 1px dashed #B7BCCB;
  margin: 0;
  padding: 20px 0;
}
app-revenue .investment-table {
  margin-top: 10px;
}
app-revenue .view {
  cursor: pointer;
}
app-revenue .action-tab {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
app-revenue .action-tab .button.yellow {
  width: auto;
  padding: 0 30px;
  height: 50px;
  font-size: 16px;
}
app-revenue .action-tab .button.yellow.disabled {
  background: #ECF1F5;
  color: #000;
  cursor: inherit;
}
app-revenue .action-tab .button.yellow.disabled span {
  font-size: 14px;
  font-weight: normal;
}
app-revenue .action-tab .text {
  color: #8089A0;
  margin-bottom: 12px;
}
app-revenue .action-tab .item {
  width: auto;
  margin-right: 20px;
}
app-revenue .action-tab .item:first-child {
  margin-left: 0;
}
app-revenue .action-tab .fa-question-circle {
  font-size: 14px;
  color: #5AA2E5;
  cursor: pointer;
}
app-revenue .action-tab .balance {
  margin-right: 60px;
  position: relative;
}
app-revenue .action-tab .balance .fa-question-circle {
  padding-left: 5px;
}
app-revenue .action-tab .balance .line {
  height: 100%;
  display: block;
  border-right: 1px solid #8089A0;
  position: absolute;
  right: -30px;
  top: 0;
  opacity: 0.2;
}
app-revenue .action-tab .value {
  white-space: nowrap;
  font-size: 20px;
  display: flex;
  align-items: center;
}
app-revenue .action-tab .value .voucher-value {
  font-size: 22px;
}
app-revenue .action-tab .link-blue {
  padding-top: 10px;
  display: inline-block;
}
app-revenue .action-tab .wrapper {
  align-items: center;
  width: auto;
}
app-revenue .action-tab .info-wrapper {
  position: relative;
  justify-content: space-between;
  width: 100%;
  text-align: right;
}
app-revenue .action-tab .info-amount {
  width: 100%;
  display: flex;
  flex-direction: column;
}
app-revenue .action-tab .info-amount .item .text {
  margin-bottom: 0;
}
app-revenue .action-tab .info-amount .text {
  white-space: nowrap;
}
app-revenue .button-activity {
  margin-top: 10px;
  display: flex;
}
app-revenue .wrapper-amount {
  display: flex;
}
app-revenue .summary-info__item {
  margin-right: 20px;
}
app-revenue .summary-info__item button {
  width: 155px;
  height: 40px;
}
app-revenue .summary-info__item .button-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}
app-revenue .summary-info__item mat-spinner {
  margin-right: 5px;
}
app-revenue .filter-section.desktop-screen {
  justify-content: flex-start;
  display: flex;
  border-top: 1px dashed #B7BCCB;
  width: 1300px;
  margin: 0 auto;
  padding-top: 18px;
}
app-revenue .filter-title {
  line-height: 48px;
  padding-right: 20px;
}
app-revenue .button.border-blue.disabled {
  background: transparent;
  border: 2px solid #C4C4C4;
  color: #C4C4C4;
  opacity: 0.7;
}
app-revenue .revenue {
  text-align: center;
}
app-revenue .revenuediv {
  font-weight: 900;
}
app-revenue .revenue .fa-question-circle {
  padding-left: 12px;
}
app-revenue .revenue .left {
  font-size: 14px;
  color: #F95738;
}
app-revenue .taken {
  color: #F95738;
  font-size: 11px;
}
app-revenue .mobile-screen {
  display: none;
}
app-revenue .wrapper-tooltip {
  display: block;
  position: relative;
  width: 0;
  height: 100%;
}
app-revenue .not-revenue-tooltip {
  font-size: 16px;
  color: #5AA2E5;
  cursor: pointer;
}
app-revenue .fa-question-circle:hover + .advance-hint .tooltip {
  display: block;
  left: -10px;
}
app-revenue .fa-question-circle:hover + .balance-hint .tooltip {
  display: block;
  left: -90px;
  top: 20px;
  white-space: nowrap;
}
app-revenue .fa-question-circle:hover + .balance-hint .tooltip.tooltip-wrap {
  white-space: normal;
  width: 250px;
}
app-revenue .tooltip {
  top: 8px;
  text-align: left;
  left: -70px;
  padding: 14px;
}
app-revenue .tooltip:after {
  content: "";
  display: block;
  position: absolute;
  top: -6px;
  right: 45px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.7);
}
app-revenue .tooltip.error__tooltip:after {
  right: 20px;
}
app-revenue .tooltip.tooltip-wrap:after {
  right: 160px;
}
app-revenue .rights-tooltip .tooltip {
  display: block;
  width: 200px;
  right: -73px;
  font-size: 12px;
  font-weight: 400;
  top: 24px;
  left: unset;
}
app-revenue .button-wrapper-refund {
  display: flex;
  width: auto;
  flex-direction: column;
}
app-revenue .button-wrapper-refund .button.withdraw {
  margin-bottom: 15px;
}
app-revenue .image-wrapper {
  height: 196px;
  width: 100%;
  position: relative;
}
app-revenue .lnr-chevron-right {
  display: inline-block;
  font-weight: 900;
}
app-revenue .lnr-chevron-right.opened {
  transition: all 0.25s ease-out 0s;
  transform: rotate(90deg);
}
app-revenue tbody td {
  padding: 30px 18px;
}
app-revenue tbody tr:hover td {
  background: transparent;
}
app-revenue .button-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
}
app-revenue .btn-check {
  justify-content: center;
  margin-bottom: 20px;
}
app-revenue .btn-check .button {
  font-size: 16px;
  padding: 0 30px;
}
app-revenue .apply-btn {
  height: 40px;
  min-width: 120px;
  line-height: 36px;
}
app-revenue .refund-money {
  border-bottom: 1px dashed #B7BCCB;
  width: 1300px;
  margin: 0 auto 32px;
}
app-revenue .refund-money .refund-title {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  padding: 20px 18px;
  cursor: pointer;
}
app-revenue .refund-money .refund-title .title-m {
  padding-left: 10px;
}
app-revenue .refund-money .button-wrapper {
  width: auto;
  margin-left: 45px;
  align-items: flex-start;
}
app-revenue .under-review {
  width: 1300px;
  margin: 32px auto;
  text-align: center;
}
app-revenue .under-review span {
  color: #F95738;
}
app-revenue .reinvest-btn {
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
}
app-revenue .reinvest-btn .button {
  width: 100%;
  font-size: 16px;
}
app-revenue .reinvest-btn .not-sepa-text {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 10px;
}
app-revenue .column {
  cursor: default;
}
app-revenue .caption {
  display: flex;
  align-content: center;
  height: 24px;
  color: #8089A0;
}
app-revenue .section-toggle-mob {
  height: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  transition: all 1s ease-out;
}
app-revenue .section-toggle-mob.openSetting {
  height: auto;
  transition: all 1s ease-out;
}
app-revenue .flex-align-column-filter mat-form-field {
  display: inline-block;
  position: relative;
  text-align: left;
  width: 60px;
  margin-right: 5px;
}
app-revenue .reset-filters {
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  margin-left: 15px;
  color: #5AA2E5;
  display: flex;
  white-space: nowrap;
  align-items: center;
}
app-revenue .reset-filters .lnr-cross {
  margin-left: 3px;
  color: #5AA2E5;
}
app-revenue .chev-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}
app-revenue .chevron-year {
  font-size: 8px;
  width: 16px;
  height: 16px;
  padding-top: 2px;
}
app-revenue .chevron-year.opened {
  padding-left: 2px;
  padding-top: 8px;
  transition: all 0.25s ease-out 0s;
  width: 16px;
  height: 16px;
  transform: rotate(90deg);
}
app-revenue .chevron-month {
  font-size: 8px;
  white-space: nowrap;
}
app-revenue .chevron-month.opened {
  transition: all 0.25s ease-out 0s;
  transform: rotate(90deg);
}
app-revenue .chevron-stats {
  margin-left: 20px !important;
}
app-revenue .nested__body .fa-download {
  color: #5AA2E5;
  padding-left: 18px;
}
app-revenue .nested__body tr:last-child td {
  border-bottom: none;
}
app-revenue .download-loaded div.global-page-loader {
  margin: 0;
  height: 16px;
}
app-revenue .download-loaded div.global-page-loader .lnr {
  font-size: 20px;
  left: 0;
  top: -2px;
}
app-revenue .revenue-icon .hover-block {
  position: relative;
  height: 20px;
  width: 20px;
}
app-revenue .revenue-icon .hover-block img {
  width: 100%;
  object-fit: contain;
}
app-revenue .revenue-icon .hover-block .tooltip-card::after {
  content: none;
  display: none;
}

@media screen and (max-width: 1370px) {
  app-revenue .refund-money {
    width: 90%;
  }
  app-revenue .filter-section.desktop-screen {
    width: 90%;
  }
  app-revenue .data-list {
    width: 90%;
  }
  app-revenue .under-review {
    width: 100%;
  }
}
@media screen and (max-width: 1023px) {
  app-revenue .button-activity {
    flex-direction: column;
    flex-wrap: wrap;
  }
  app-revenue main {
    width: 100%;
  }
  app-revenue tbody td, app-revenue .table__nested .nested__body td, app-revenue .table th {
    padding: 20px 4px;
  }
  app-revenue .wrapper-amount {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }
  app-revenue .action-tab .link-blue {
    display: none;
  }
  app-revenue .action-tab .info-amount .item.advances {
    text-align: left;
  }
  app-revenue .action-tab .info-amount .item.advances .value {
    justify-content: flex-start;
  }
  app-revenue .action-tab .button.yellow {
    font-size: 14px;
  }
  app-revenue .action-tab .flex-align-column-filter {
    width: 100%;
    margin-left: 0;
  }
  app-revenue .action-tab .item-title {
    padding-bottom: 5px;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  app-revenue .btn-check {
    padding: 0 15px;
  }
  app-revenue .summary-info__item {
    width: 100%;
    margin-right: 0;
    margin-top: 10px;
  }
  app-revenue .summary-info__item.summary-info__item--secure-btn button {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  app-revenue main {
    background: #fff;
  }
  app-revenue .summary-info__item.summary-info__item--secure-btn button {
    height: 50px;
  }
  app-revenue .refund-money {
    display: none;
  }
  app-revenue .mobile-screen {
    display: block;
  }
  app-revenue .not-revenue {
    justify-content: flex-end;
  }
  app-revenue .filter-section.desktop-screen {
    display: none;
  }
  app-revenue .rights-tooltip .tooltip {
    right: -5px;
    left: unset;
  }
  app-revenue .rights-tooltip .tooltip:after {
    right: 5px;
  }
  app-revenue .table__nested {
    font-size: 12px;
  }
  app-revenue .table__nested .nested__td {
    padding: 0;
  }
  app-revenue .table__nested .nested__body td {
    padding: 10px 5px;
  }
  app-revenue .table__nested .nested__body tr:last-child td {
    padding: 10px 5px;
  }
  app-revenue .table__nested .table-sub-header td {
    padding: 12px 5px 12px 5px;
  }
  app-revenue .table__nested .nested__amount {
    font-size: 14px;
  }
  app-revenue .table__nested .link-blue {
    font-size: 12px;
  }
  app-revenue .table__nested .fa-download {
    padding-left: 8px;
  }
  app-revenue .non-mobile {
    display: none;
  }
  app-revenue .table-header {
    border-bottom: 1px dashed #B7BCCB;
  }
  app-revenue .chev-wrapper {
    padding-left: 0px;
  }
  app-revenue .button.width-auto-button {
    height: 50px;
  }
  app-revenue .image-wrapper {
    height: 156px;
  }
  app-revenue .filter-wrapper {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  app-revenue .filter-wrapper .item {
    margin-right: 0;
    width: 48%;
  }
  app-revenue .filter-wrapper .item:last-child app-datepicker .calendar-wrapper {
    right: 0;
    left: auto;
  }
  app-revenue .filter-wrapper .item:first-child app-datepicker .calendar-wrapper {
    left: 0;
    right: auto;
  }
  app-revenue .data-list {
    padding: 0 15px;
    width: 100%;
  }
  app-revenue .min-screen {
    display: table-cell;
  }
  app-revenue .mid-screen {
    display: none;
    cursor: default;
  }
  app-revenue .button-wrapper .button {
    width: 100%;
  }
  app-revenue .song-name, app-revenue .artist-name {
    margin-bottom: 5px;
  }
  app-revenue .revenue {
    text-align: right;
    padding-right: 0;
  }
  app-revenue .action-tab {
    flex-direction: column;
    width: 100%;
  }
  app-revenue .action-tab .info-amount {
    justify-content: space-between;
  }
  app-revenue .action-tab .info-amount .item {
    width: 33.33%;
  }
  app-revenue .action-tab .info-amount .item.balance {
    width: 50%;
    margin: 0;
    text-align: left;
  }
  app-revenue .action-tab .info-amount .item.balance .line {
    display: none;
  }
  app-revenue .action-tab .info-amount .item.balance .value {
    font-size: 24px;
  }
  app-revenue .action-tab .info-amount .item.balance .current-value {
    justify-content: flex-start;
  }
  app-revenue .action-tab .info-amount .item:nth-child(3) {
    text-align: left;
  }
  app-revenue .action-tab .info-amount .item:nth-child(3) .value {
    width: 70%;
    justify-content: flex-start;
    text-align: center;
  }
  app-revenue .action-tab .info-amount .item:nth-child(5) {
    text-align: right;
  }
  app-revenue .action-tab .info-amount .item:nth-child(5) .value {
    width: 100%;
    justify-content: flex-end;
    text-align: center;
    margin-left: 0;
  }
  app-revenue .action-tab .info-amount .item .value {
    justify-content: center;
    line-height: 36px;
  }
  app-revenue .action-tab .withdraw-mobile-screen {
    margin-top: 30px;
  }
  app-revenue .action-tab .withdraw-mobile-screen button {
    width: 100% !important;
  }
  app-revenue .action-tab .desktop-screen {
    display: none;
  }
  app-revenue .action-tab app-kyc-status {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
  app-revenue .action-tab .mobile-screen-filter {
    margin-top: 20px;
    width: 100%;
  }
  app-revenue .action-tab .item {
    text-align: center;
    margin-top: 20px;
    margin-right: 0;
  }
  app-revenue .action-tab .item .button.yellow, app-revenue .action-tab .item .button.border-blue {
    width: 100%;
    justify-content: center;
  }
  app-revenue .action-tab .value {
    font-size: 18px;
  }
  app-revenue .action-tab .wrapper {
    width: 100%;
  }
  app-revenue .action-tab .wrapper:last-child {
    margin-left: 0;
  }
  app-revenue .action-tab .wrapper .item {
    margin-left: 0;
  }
  app-revenue .action-tab .wrapper:first-child {
    justify-content: space-around;
  }
  app-revenue .action-tab .wrapper:last-child {
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  app-revenue .action-tab .wrapper:last-child .item {
    width: 100%;
    margin-top: 20px;
  }
  app-revenue .action-tab .wrapper:last-child .button {
    width: 100%;
  }
  app-revenue tbody td:nth-child(3) {
    padding: 20px 15px 20px 0;
  }
  app-revenue .table th:nth-child(2) {
    padding: 11px 15px 11px 0;
  }
  app-revenue mat-form-field + .button {
    width: 100%;
  }
  app-revenue mat-form-field:nth-child(4) {
    margin: 0;
  }
  app-revenue mat-form-field.year {
    width: 70px;
  }
  app-revenue .reset-filters {
    margin: 5px auto 0;
  }
}
app-profile {
  height: 100%;
}
app-profile .main-wrapper {
  min-height: 100vh;
  background: #fff;
}
app-profile main {
  flex: 1;
  width: 1200px;
  margin: 0 auto;
}
app-profile .section-image {
  margin-top: -80px;
}
app-profile .section-image .name, app-profile .section-image .text {
  z-index: 10;
}
app-profile .section-image .name {
  text-transform: capitalize;
  color: #fff;
}
app-profile .section__kyc-status .title__item {
  display: inline-flex;
  padding-left: 10px;
  gap: 16px;
}
app-profile .section__kyc-status app-kyc-status .kyc-title {
  display: none;
}
app-profile .section__kyc-status app-kyc-status .kyc-status-block {
  margin-top: 0;
}
app-profile .wrapper-tooltip {
  display: block;
  position: relative;
  width: 0;
  height: 100%;
}
app-profile .tooltip-ubo {
  text-align: left;
  right: -178px;
  top: -235px;
  padding: 14px;
  width: 280px;
}
app-profile .tooltip-ubo:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -6px;
  right: 5px;
  width: 0;
  height: 0;
  border-top: 7px solid rgba(0, 0, 0, 0.7);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: none;
}
app-profile .tooltip-ubo.tooltip-wrap:after {
  right: 160px;
}
app-profile .nickname {
  position: relative;
  text-align: left;
}
app-profile .nickname mat-error {
  position: absolute;
  bottom: 0;
  font-size: 11px;
}
app-profile .button__check-name {
  height: 30px;
  min-width: 100px;
  margin-bottom: 20px;
  margin-left: 10px;
}
app-profile .nickname__input {
  width: 100%;
}
app-profile .user-name {
  padding: 15px 0 20px 37px;
  justify-content: space-between;
}
app-profile .user-name .name {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
app-profile .profile__section-padding {
  padding-bottom: 20px;
}
app-profile .section__wallet {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 1199px) {
  app-profile .section__wallet {
    flex-direction: column;
    align-items: flex-start;
  }
}
app-profile .shareholders__data {
  width: 100%;
}
app-profile .shareholders__ubos {
  margin: 20px 0;
}
app-profile .shareholders__wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding-bottom: 32px;
}
app-profile .shareholders__wrapper .hint_and_button {
  display: inline-flex;
}
app-profile .shareholders__wrapper .hint_and_button .ubo_hint {
  margin-left: 15px;
  align-self: center;
}
app-profile .shareholders__wrapper .hint_and_button .ubo_hint .ubo_hint_icon {
  font-size: 20px;
}
app-profile .fa-question-circle:hover + .wrapper-tooltip .tooltip-ubo {
  display: flex;
}
app-profile .wrapper {
  justify-content: space-between;
}
app-profile .shareholders__status {
  border-radius: 7px;
  width: auto;
  height: 24px;
  line-height: 26px;
  text-align: left;
}
app-profile .shareholders__status.pending {
  background-color: #FFA800;
}
app-profile .shareholders__status.refused {
  background-color: #F95738;
}
app-profile .shareholders__status.validated {
  background-color: #27AE60;
}
app-profile .shareholders__status span {
  color: #fff;
  padding: 0 10px;
  font-weight: bold;
}
app-profile .ubos__button-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 140px;
  margin: 10px 0;
}
app-profile .shareholders__delete {
  padding-left: 10px;
}
app-profile .shareholders__delete .fa-times-circle {
  cursor: pointer;
}
app-profile .user__company {
  margin-top: 40px;
}
app-profile .user__company .item {
  width: 100%;
}
app-profile .shareholders__button {
  height: 40px;
}
app-profile .ubos__button {
  height: 25px;
  min-width: 60px;
}
app-profile .image-wrapper {
  height: 196px;
  width: 100%;
  position: relative;
}
app-profile .warn-icon-image {
  height: 15px;
  width: 15px;
  position: relative;
}
app-profile .profile-image {
  position: absolute;
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
}
app-profile .profile-img-wrapper {
  width: 160px;
  min-width: 160px;
  position: relative;
  background-image: url("../../../assets/images/fallback.png");
  height: 160px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  border-radius: 80px;
  box-shadow: 0 0 3px #8089A0;
}
app-profile .input-block {
  border-bottom: 1px dashed #B7BCCB;
}
app-profile .project-detail {
  display: flex;
  padding: 0 80px 45px;
}
app-profile .project-detail .item-detail {
  width: 70%;
  padding-right: 50px;
}
app-profile .project-detail .item-share {
  width: 30%;
}
app-profile .project-detail .item-share .item-share-document {
  display: flex;
  margin-top: 25px;
  align-items: center;
  text-decoration: none;
}
app-profile .project-detail .item-share .item-share-document span {
  padding-left: 16px;
}
app-profile .section-info {
  margin-top: 50px;
}
app-profile .profile-title {
  padding: 30px 0;
}
app-profile .item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-profile .item-wrapper .item {
  width: 48%;
}
app-profile .investor-warning {
  padding: 15px;
  background-color: #f44336;
  color: white;
  left: 0px;
  right: 0px;
  position: fixed;
  text-align: center;
  height: 40px;
  animation-name: floatingWarning;
  animation-duration: 0.8s;
  animation-delay: 0.1s;
  animation-iteration-count: initial;
  animation-fill-mode: forwards;
}
@keyframes floatingWarning {
  from {
    bottom: -40px;
  }
  to {
    bottom: 0px;
  }
}
app-profile .section-image-wrapper {
  padding: 0 25px;
}
app-profile .profile__actions .button {
  margin-left: 60px;
}
app-profile .button-wrapper {
  align-items: center;
  justify-content: center;
  margin: 0 0 32px 0;
}
app-profile .button-wrapper .cancel {
  color: #5AA2E5;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500;
}
app-profile .button-wrapper .button {
  width: 213px;
  height: 50px;
  font-weight: bold;
  font-size: 16px;
}
app-profile .lnr-warning {
  font-size: 14px;
}
app-profile .genres-list {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
app-profile .genres-list .item-genre {
  border: 2px solid #5AA2E5;
  border-radius: 5px;
  padding: 0 12px;
  height: 40px;
  line-height: 36px;
  color: #5AA2E5;
  text-align: center;
  cursor: pointer;
  margin-right: 7px;
}
app-profile .genres-list .item-genre .fa-times {
  font-size: 14px;
  padding-right: 10px;
  display: inline-block;
}
app-profile .profile__section {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (max-width: 767px) {
  app-profile .profile__section {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
app-profile .profile__section.profile__section--col {
  padding-right: 25px;
  padding-left: 25px;
}
app-profile .profile__section .title {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}
app-profile .profile__section .title .title-m {
  padding: 0;
}
app-profile .profile__section .item-setting {
  width: 100%;
  margin: 0 -25px;
  display: flex;
}
app-profile .profile__section .item-setting .form-item-setting {
  width: 33.33%;
  padding: 0 25px;
  justify-content: space-between;
}
app-profile .profile__section .item-setting .form-item-setting:first-child {
  padding-top: 0;
}
app-profile .profile__section .item-setting .form-item-setting .toggle {
  display: flex;
  justify-content: space-between;
}
app-profile .profile__section .item-setting .form-item-setting .toggle-desc {
  color: #8089A0;
  margin-top: 26px;
}
app-profile .profile__section .item-setting .form-item-setting .toggle-desc-note {
  color: #8089A0;
  margin-top: 11px;
}
app-profile .profile__section .item-setting .form-item-setting .toggle-desc-note-red {
  color: red;
  margin-top: 11px;
}
app-profile .profile__section .item-setting .form-item-setting .label-red {
  font-size: 14px;
  width: 70%;
}
app-profile .profile__section .item-setting .form-item-setting .label-red span {
  display: inline-block;
  max-width: 200px;
  color: red;
}
app-profile .profile__section .item-setting .form-item-setting .label-gray {
  font-size: 14px;
  width: 70%;
}
app-profile .profile__section .item-setting .form-item-setting .label-gray span {
  display: inline-block;
  max-width: 200px;
  color: #8089A0;
}
app-profile .profile__section .item-setting .form-item-setting .label {
  font-size: 14px;
  width: 70%;
}
app-profile .profile__section .item-setting .form-item-setting .label span {
  display: inline-block;
  max-width: 200px;
  color: #000;
}
app-profile .profile__section .item-setting .form-item-setting .toggle-item {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-profile .toggle-item .status {
  color: #8089A0;
}
app-profile .activity-link {
  text-align: center;
  margin: 20px 0;
}
app-profile .activity-link .link-blue {
  cursor: pointer;
}
app-profile .profile-song-content {
  border-bottom: 1px dashed #C4C4C4;
  padding-bottom: 30px;
}
app-profile .gray-text {
  color: #8089A0;
  font-size: 14px;
  line-height: 18px;
}
app-profile .section__user-data {
  display: flex;
  padding-bottom: 0;
}
app-profile .section__user-data .section__wrapper {
  padding: 0 25px;
  width: 33.33%;
}
app-profile .section__user-social {
  padding-bottom: 0;
  margin-bottom: 18px;
}
app-profile .section__user-social .section__wrapper {
  width: 33.33%;
}
app-profile .section__wrapper {
  padding: 0 25px;
}
app-profile .section__wrapper.section__wrapper--align {
  display: flex;
  flex-direction: column;
}
app-profile .birth-data {
  margin: 40px 0;
}
app-profile .birth-data .item {
  width: 100%;
}
app-profile .change-picture {
  position: relative;
  overflow: hidden;
  padding: 10px 0;
}
app-profile .change-picture input {
  position: absolute;
  padding: 15px 10px;
  height: 0;
  left: 0;
  top: 5px;
  width: 120px;
  opacity: 0;
  cursor: pointer;
}
app-profile .section-toggle-mob {
  height: 100%;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  app-profile .section-toggle-mob {
    height: 0;
    overflow: hidden;
    margin-top: 0;
  }
  app-profile .section-toggle-mob.openSetting {
    height: auto;
    margin-top: 10px;
  }
}
app-profile .lnr-warning {
  fill: red;
}
app-profile .notification .lnr-chevron-right, app-profile .nft .lnr-chevron-right, app-profile .language-s .lnr-chevron-right, app-profile .bank-account .lnr-chevron-right, app-profile .section__kyc-status .lnr-chevron-right {
  display: none;
}
app-profile .nft .title {
  white-space: nowrap;
}
app-profile .nft .section-toggle-mob {
  margin: 0 0 0 20px;
}
app-profile .bank-account__forms {
  margin-bottom: 18px;
}
app-profile input[type=file] {
  cursor: pointer;
  padding: 20px;
}
app-profile .size-hint {
  color: #8089A0;
  margin-top: -7px;
}
app-profile .birth-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
app-profile .birth-wrapper mat-form-field {
  width: 32%;
}
app-profile .lnr-chevron-right {
  display: inline-block;
  font-weight: 900;
}
app-profile .lnr-chevron-right.opened {
  transition: all 0.25s ease-out 0s;
  transform: rotate(90deg);
}
app-profile .section-toggle-delete {
  height: 0;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.2s ease-out;
}
app-profile .section-toggle-delete .delete-account__text {
  width: 50%;
  min-width: 260px;
}
app-profile .section-toggle-delete.openSetting {
  height: auto;
  margin-top: 20px;
  transition: all 0.2s ease-out;
}
app-profile .section-toggle-delete .button {
  max-width: max-content;
  margin-right: 20px;
}
app-profile .section-toggle-delete .button.disabled {
  pointer-events: none;
}
app-profile .delete-account {
  border-bottom: 1px dashed #B7BCCB;
}
app-profile .delete-account .title {
  cursor: pointer;
}
app-profile .delete-account .title .title-m {
  padding-left: 10px;
}
app-profile .reinvest-btn {
  text-align: center;
  width: 100%;
}
app-profile .reinvest-btn .button {
  width: 100%;
  font-size: 16px;
}

@media screen and (max-width: 1199px) {
  app-profile main {
    width: 100%;
    background: #fff;
  }
  app-profile .section__user-data {
    flex-direction: column;
  }
  app-profile .section__user-data .section__wrapper {
    padding: 20px 25px;
    width: 100%;
  }
  app-profile .inner {
    padding: 0 20px;
    width: 100%;
  }
  app-profile .section__user-social .section__wrapper {
    width: 100%;
  }
  app-profile .shareholders__data {
    width: 50%;
    margin: 0 auto;
  }
  app-profile .nft .section-toggle-mob {
    margin: 0;
  }
  app-profile .nft .button {
    margin-top: 20px;
  }
  app-profile .profile__section .wrapper .item-setting {
    width: 100%;
    flex-direction: column;
  }
  app-profile .profile__section .wrapper .item-setting .form-item-setting {
    width: 100%;
    padding: 20px 25px;
  }
  app-profile .profile__section .wrapper .item-setting:nth-child(2) {
    padding-top: 12px;
  }
}
@media screen and (max-width: 1023px) {
  app-profile .section-info {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
  }
  app-profile .profile-img-wrapper {
    width: 120px;
    min-width: 120px;
    height: 120px;
  }
  app-profile .section-image {
    display: flex;
    justify-content: center;
    margin: -60px auto 0;
  }
  app-profile .section-image .name {
    font-size: 18px;
  }
  app-profile .profile__section .item-setting .form-item-setting .toggle-item {
    max-width: 100px;
  }
  app-profile .size-hint {
    width: 100%;
    word-break: break-all;
  }
  app-profile .section-image-wrapper {
    width: 90%;
    margin: 0 auto;
  }
  app-profile .link-blue {
    font-size: 16px !important;
  }
  app-profile .user-name {
    padding: 5px 0 20px 20px;
  }
  app-profile .user-name .name {
    font-size: 16px;
    width: 100%;
  }
  app-profile .profile-img-btn {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  app-profile .shareholders__data {
    width: 100%;
    margin-bottom: 10px;
  }
  app-profile .profile__wrapper {
    padding: 0;
  }
  app-profile .section__user-data .section__wrapper {
    padding: 15px 0;
  }
  app-profile .section__user-social .section__wrapper {
    padding: 0;
  }
  app-profile ::ng-deep .mat-select-panel {
    min-width: fit-content !important;
  }
  app-profile ::ng-deep .mat-select-panel::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #DFEBF4;
  }
  app-profile ::ng-deep .mat-select-panel::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5;
  }
  app-profile ::ng-deep .mat-select-panel::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #8089A0;
    box-shadow: 0 0 0 2px white;
  }
  app-profile .notification .lnr-chevron-right {
    display: inline-block;
  }
  app-profile .section-image-wrapper {
    width: 100%;
    padding: 0 20px;
  }
  app-profile .lnr.lnr-chevron-right {
    display: inline-block;
    font-weight: 900;
  }
  app-profile .profile__section {
    width: 100%;
  }
  app-profile .profile__section .title {
    padding: 0;
    font-size: 18px;
  }
  app-profile .profile__section .title .title-m {
    padding-left: 10px;
    display: inline-block;
  }
  app-profile .profile__section .wrapper .item-setting {
    padding: 0;
    margin: 0;
  }
  app-profile .profile__section .wrapper .item-setting .form-item-setting {
    padding: 20px 0;
  }
  app-profile .image-wrapper {
    height: 156px;
  }
  app-profile .backgroundImage {
    background-image: none !important;
    background-color: #253a6f;
  }
  app-profile .profile__section.profile__section--col {
    padding-right: 0;
    padding-left: 0;
  }
}
app-sell-bid {
  display: flex;
  min-height: 100vh;
  background: #fff;
}
app-sell-bid input::-webkit-input-placeholder { /* WebKit browsers */
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}
app-sell-bid input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: 18px;
  text-align: center;
}
@media screen and (max-width: 460px) {
  app-sell-bid {
    flex-direction: column;
  }
}
app-sell-bid .flow-info-text {
  font-weight: 900;
  line-height: 1;
  background-size: cover;
  background-image: url("../../../assets/images/nftwizard/collectible_sale.png");
  background-position: 50% 50%;
}
@media screen and (max-width: 1023px) {
  app-sell-bid .flow-info-text {
    display: none;
  }
}
app-sell-bid .inner {
  border-right: 1px dashed #C4C4C4;
}
app-sell-bid app-card-collectible .card-outer, app-sell-bid app-card-collectible .card-image-wrapper {
  border-radius: 20px;
}
app-sell-bid .circle {
  box-shadow: inset 0 0 8px rgba(144, 170, 190, 0.2);
}
app-sell-bid .flow-steps {
  color: #8089A0;
}
app-sell-bid .step__content {
  border-bottom: 1px dashed #C4C4C4;
}
app-sell-bid .step-one .step__content {
  border-top: 1px dashed #C4C4C4;
  padding: 48px 24px 30px;
}
@media screen and (max-width: 460px) {
  app-sell-bid .step-one .step__content {
    padding: 48px 0 30px;
  }
}
app-sell-bid .step-one .mat-radio-group {
  display: flex;
  flex-direction: column;
}
app-sell-bid .step-one .radio-text {
  margin-top: 15px;
}
app-sell-bid .step-one .title {
  width: 340px;
  margin-left: auto;
  margin-right: auto;
}
app-sell-bid .step-one h4 {
  font-weight: 700;
  font-size: 15px;
}
app-sell-bid .step-four {
  text-align: center;
}
app-sell-bid .step-four .title {
  margin-top: 26px;
}
app-sell-bid .step-four p {
  text-align: center;
  padding-bottom: 30px;
}
app-sell-bid .step-four .button.yellow {
  width: 373px;
}
app-sell-bid .mat-radio-group .mat-radio-container {
  width: 14px;
  height: 14px;
}
app-sell-bid .mat-radio-group .mat-radio-button {
  margin-bottom: 24px;
  padding: 10px 22px 10px 10px;
}
app-sell-bid .mat-radio-group .mat-radio-button:last-child {
  margin-bottom: 14px;
}
app-sell-bid .mat-radio-group .mat-radio-checked {
  border: 2px solid #5AA2E5;
  border-radius: 5px;
}
app-sell-bid .mat-radio-group .mat-radio-outer-circle {
  width: 14px;
  height: 14px;
  border-width: 1px;
}
app-sell-bid .mat-radio-group .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5AA2E5;
}
app-sell-bid .mat-radio-group .mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.6);
}
app-sell-bid .mat-radio-group .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #5AA2E5;
  width: 14px;
  height: 14px;
}
app-sell-bid .mat-radio-group .mat-radio-label {
  white-space: normal;
  align-items: flex-start;
}
app-sell-bid .flow-button-wrapper {
  margin: 30px 0;
}
app-sell-bid .flow-button-wrapper .button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-sell-bid .flow-button-wrapper .button mat-spinner {
  position: absolute;
  left: 10px;
  top: 10px;
}
app-sell-bid .flow-button-wrapper .button mat-spinner circle {
  stroke: #fff;
}
app-sell-bid .step-four + .flow-button-wrapper .button {
  margin: 0 auto;
}
app-sell-bid .price-amount {
  position: relative;
}
app-sell-bid .tooltip-price {
  position: absolute;
  top: 12px;
  right: 150px;
}
app-sell-bid .tooltip-price .tooltip-card {
  left: -14px;
  right: unset;
}
app-sell-bid .tooltip-price img {
  width: 25px;
  height: 25px;
}
app-sell-bid .price-selling {
  text-align: center;
  padding-bottom: 30px;
}
app-sell-bid .price-selling h5 {
  font-size: 14px;
  line-height: 28px;
}
app-sell-bid .input-box {
  background: #F2F7FB;
  max-width: 329px;
  text-align: center;
  font-weight: 900;
}
app-sell-bid .input-box.error {
  color: #F95738;
}
app-sell-bid .auction-detail {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px dashed #C4C4C4;
}
app-sell-bid .auction-detail .auction-detail__item {
  display: flex;
  line-height: 24px;
  align-items: center;
  padding: 5px 0;
  justify-content: space-between;
}
app-sell-bid .auction-detail .auction-detail__item p {
  position: relative;
}
app-sell-bid .auction-detail-edition {
  display: flex;
}
app-sell-bid .grs-fee .tooltip-price {
  top: 0;
  right: 0;
  position: relative;
}
app-sell-bid .grs-fee .tooltip-text {
  display: flex;
  align-items: center;
}
app-sell-bid .grs-fee img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
app-sell-bid .grs-fee .hover-block {
  display: flex;
  align-items: center;
}
app-sell-bid .bid-amount {
  font-weight: 900;
  font-size: 36px;
  color: #000;
}
app-sell-bid app-input-date-picker {
  display: none;
}
@media screen and (max-width: 460px) {
  app-sell-bid app-input-date-picker {
    display: block;
  }
}
app-sell-bid .auction-date {
  position: relative;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 460px) {
  app-sell-bid .auction-date {
    flex-direction: column;
  }
}
app-sell-bid .auction-date .button-date {
  position: relative;
  background-color: #5AA2E5;
  border-radius: 5px;
  padding: 0 17px;
  height: 42px;
  display: flex;
  justify-content: center;
  text-align: left;
  flex-direction: column;
  width: 196px;
}
@media screen and (max-width: 460px) {
  app-sell-bid .auction-date .button-date {
    width: 100%;
    margin-top: 10px;
  }
}
app-sell-bid .auction-date .button-date__period {
  font-size: 10px;
  display: inline-block;
}
app-sell-bid .auction-date .button-date__select-text {
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
}
app-sell-bid .auction-date .button-date__image {
  position: absolute;
  right: 16px;
  top: 16px;
}
app-sell-bid .default-cross {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #8089A0 !important;
}
app-sell-bid .error-message {
  text-align: left;
  width: 100%;
  margin: 0;
  font-size: 11px;
}
@media screen and (max-width: 460px) {
  app-sell-bid .error-message {
    display: inline-block;
  }
}
app-sell-bid .success-image {
  width: 75px;
  height: 75px;
}
app-sell-bid .terms {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px dashed #C4C4C4;
  padding-top: 30px;
  margin-top: 20px;
}
app-sell-bid .popup-picker {
  position: absolute;
  bottom: -114px;
  left: -77px;
  display: flex;
  width: 600px;
  justify-content: space-between;
  height: 423px;
  box-shadow: 0 2px 4px rgba(144, 170, 190, 0.5);
  background: #FFFFFF;
  border: 0.5px solid #F2F7FB;
  padding: 32px;
  z-index: 3;
}
@media screen and (max-width: 460px) {
  app-sell-bid .popup-picker {
    width: 100%;
    left: 0;
    bottom: -7px;
    height: auto;
  }
}
app-sell-bid .button.yellow.button-lower-case {
  text-transform: unset;
  width: 370px;
}
app-sell-bid .flow-steps-inner .step-two .step__content, app-sell-bid .flow-steps-inner .step-three .step__content {
  width: 416px;
}
@media screen and (max-width: 460px) {
  app-sell-bid .flow-steps-inner .step-two .step__content, app-sell-bid .flow-steps-inner .step-three .step__content {
    width: 100%;
  }
}
app-sell-bid .flow-steps-inner .step-four {
  max-width: 540px;
}
app-sell-bid .flow-steps-inner .step-four .step__content {
  max-width: 416px;
  margin: 0 auto;
}
@media screen and (max-width: 460px) {
  app-sell-bid .flow-steps-inner .step-four .step__content {
    max-width: 100%;
  }
}
@media screen and (max-width: 460px) {
  app-sell-bid .flow-steps-inner {
    width: 100%;
  }
}
@media screen and (max-width: 460px) {
  app-sell-bid .calendar {
    display: none;
  }
}
app-sell-bid .calendar-action .button {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  width: 196px;
  margin-top: 21px;
}
@media screen and (max-width: 460px) {
  app-sell-bid .calendar-action .button {
    width: 100%;
  }
}
app-sell-bid .calendar-action h3 {
  font-size: 14px;
  text-align: left;
}
app-sell-bid .calendar-action h3.picker-block__title {
  margin-top: 20px;
}
@media screen and (max-width: 460px) {
  app-sell-bid .calendar-action {
    width: 100%;
  }
}
app-sell-bid .button.blue:hover:not(:disabled) {
  background: #4a95db;
}
app-sell-bid app-tooltip .tooltip-card {
  top: 35px;
  z-index: 1;
  width: 124px;
  font-size: 10px;
  font-weight: 500;
}
app-sell-bid app-tooltip .tooltip-card:after {
  bottom: 0;
  top: -7px;
  right: 90px;
}
@media screen and (max-width: 460px) {
  app-sell-bid app-tooltip {
    display: none !important;
  }
}
app-sell-bid .tooltip-component {
  display: none;
}
app-sell-bid .picker-block {
  width: 196px;
  height: 42px;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: relative;
  font-size: 13px;
  font-weight: 900;
}
app-sell-bid .picker-block.errorTime span {
  color: #F95738;
}
app-sell-bid .picker-block.errorTime:hover .tooltip-component {
  display: block;
}
app-sell-bid .picker-block.picker-block--blue {
  background-color: #5AA2E5;
  color: #fff;
}
app-sell-bid .picker-block.picker-block--blue-border {
  border: 2px solid #5AA2E5;
  color: #5AA2E5;
}
app-sell-bid .picker-block .input {
  border: none;
  width: 90px;
  text-align: left;
  font-weight: 900;
  color: #5AA2E5;
}
app-sell-bid .picker-block .input.input-hours {
  text-align: right;
  padding-right: 5px;
}
app-sell-bid .picker-block .input.input-minutes {
  padding-left: 5px;
}
@media screen and (max-width: 460px) {
  app-sell-bid .picker-block {
    width: 100%;
  }
}
@media screen and (max-width: 460px) {
  app-sell-bid .picker-block__text {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  app-sell-bid .flow-progress-line {
    display: none;
  }
}

app-my-nft .nft-list {
  padding: 30px 0;
  display: grid;
  grid-template-columns: repeat(4, auto);
  row-gap: 40px;
  column-gap: 20px;
}
@media (max-width: 1480px) {
  app-my-nft .nft-list {
    margin: auto;
    grid-template-columns: repeat(3, auto);
  }
}
@media (max-width: 1100px) {
  app-my-nft .nft-list {
    grid-template-columns: repeat(2, auto);
  }
}
@media (max-width: 768px) {
  app-my-nft .nft-list {
    grid-template-columns: 1fr;
  }
}
app-my-nft app-tabs {
  margin-top: 10px;
}
app-my-nft app-tabs .tab-header {
  max-width: 1300px;
}
@media screen and (max-width: 767px) {
  app-my-nft app-tabs .tab-header {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 460px) {
  app-my-nft app-tabs .tab-header {
    padding: 0 10px;
  }
}
@media screen and (max-width: 767px) {
  app-my-nft app-tabs .tab-item:first-child {
    padding-left: 0;
  }
}
@media screen and (max-width: 767px) {
  app-my-nft app-kyc-status {
    max-width: initial;
  }
}
app-my-nft .section-projects {
  display: flex;
  flex-wrap: wrap;
  max-width: 1450px;
  margin: 0 auto;
}
app-my-nft .main-wrapper {
  min-height: 100vh;
  background: #fff;
}
app-my-nft main {
  flex: 1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
app-my-nft .page-section {
  border-top: 1px dashed #B7BCCB;
}
app-my-nft .summary-info {
  padding-top: 15px;
  padding-left: 37px;
}
app-my-nft .summary-info__row {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}
app-my-nft .summary-info__row .value {
  font-size: 27px;
  white-space: nowrap;
}
app-my-nft .summary-info__row .text {
  text-align: left;
  color: #8089A0;
}
app-my-nft .summary-info__row .item {
  width: auto;
}
app-my-nft .summary-info__list {
  display: flex;
  gap: 20px;
  width: 60%;
}
app-my-nft .circle {
  background-color: #b7bccb;
  position: absolute;
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: flex;
  right: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
app-my-nft .circle .tooltip-card {
  width: 190px;
  top: 45px;
}
app-my-nft .circle .tooltip-card:after {
  bottom: unset;
  top: -7px;
  right: 15px;
}
app-my-nft .circle img {
  width: 20px;
  margin-top: 4px;
}
app-my-nft .spinner {
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
  width: 100%;
}
app-my-nft .button-action .button {
  height: 50px;
  width: 200px;
  font-size: 16px;
}
app-my-nft .button.border-blue {
  background: transparent;
}
app-my-nft .nft-footer {
  display: flex;
  background: #fff;
  border-radius: 0 0 20px 20px;
  height: 80px;
  width: 310px;
  z-index: 0;
  position: relative;
  padding: 0 12px;
  margin: 0 12px 0;
  box-shadow: 0 4px 3px 0 rgba(128, 137, 160, 0.4);
  justify-content: center;
  align-items: center;
}
app-my-nft .card-nft-button {
  background-color: #B7BCCB;
  color: #fff;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  width: 160px;
  font-size: 18px;
  height: 40px;
}
app-my-nft .sell-button {
  background-color: #5AA2E5;
}
app-my-nft .no-list-data {
  font-style: italic;
}
app-my-nft .load-more {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
app-my-nft .load-more .loader {
  height: auto;
  margin: auto 5px;
}
app-my-nft .lnr.lnr-music-note {
  font-size: 14px;
}
app-my-nft .mobile-screen {
  display: none;
}
app-my-nft app-loader {
  height: 50px;
  display: flex;
}
app-my-nft .list__item:hover {
  z-index: 10;
}
app-my-nft .nft-image {
  width: 100%;
  min-height: 400px;
  object-fit: cover;
}

@media screen and (max-width: 1370px) {
  app-my-nft .section-projects {
    max-width: 95%;
  }
}
@media screen and (max-width: 1123px) {
  app-my-nft main {
    width: 100%;
  }
  app-my-nft .value {
    font-size: 36px;
  }
  app-my-nft .section-projects {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 950px) {
  app-my-nft .section-projects {
    max-width: -webkit-fill-available;
  }
  app-my-nft main {
    background: #fff;
  }
  app-my-nft .summary-info__list {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0;
  }
  app-my-nft .button-action {
    width: 100%;
    margin-top: 15px;
  }
  app-my-nft .load-more {
    margin: 0 0 15px 0;
  }
  app-my-nft .summary-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0 0;
  }
  app-my-nft .value {
    text-align: center;
    font-size: 24px;
  }
}
@media screen and (max-width: 950px) and (max-width: 767px) {
  app-my-nft .desktop-screen {
    display: none;
  }
  app-my-nft .mobile-screen {
    display: block;
  }
  app-my-nft .summary-info__list {
    flex-direction: column;
  }
  app-my-nft .summary-info__list .summary-info__item {
    width: 100%;
  }
  app-my-nft .summary-info__list .summary-info__item.item__mobile-screen {
    display: flex;
  }
}
@media screen and (max-width: 950px) and (max-width: 500px) {
  app-my-nft .nft-image {
    height: 300px;
  }
}
app-artist-details .wrapper-content {
  width: 95%;
  margin-bottom: 3rem;
}
app-artist-details section.about-artist {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto;
  max-width: 1380px;
  height: 475px;
  background: #F2F7FB;
  box-shadow: 0 2px 4px rgba(144, 170, 190, 0.5);
  border-radius: 7px;
}
app-artist-details section.about-artist .artist-info-block {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
app-artist-details section.about-artist .artist-details-list {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  list-style-type: none;
}
app-artist-details section.about-artist .artist-img {
  display: flex;
  width: 324px;
  height: 273px;
  border-radius: 5px;
  object-fit: cover;
}
app-artist-details section.about-artist .artist-text-block {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 551px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
app-artist-details section.about-artist .artist-text-title {
  line-height: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  text-transform: uppercase;
}
app-artist-details section.about-artist .artist-text-subtitle {
  line-height: 23px;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
}
app-artist-details section.about-artist .artist-text-info {
  white-space: pre-wrap;
  text-align: justify;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #8089A0;
}
app-artist-details section.about-artist ul.static li {
  margin: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}
app-artist-details section.about-artist ul.artist-object li {
  margin: 1.05rem;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #8089A0;
}
app-artist-details #video {
  margin: 20px;
}
app-artist-details section.investment-opportunity .investment-image {
  background-size: cover;
  justify-content: flex-end;
  display: flex;
  margin: auto;
  align-items: center;
  max-width: 1380px;
  height: 235px;
  border-radius: 7px;
}
app-artist-details section.investment-opportunity .investment-text {
  cursor: pointer;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 27px;
  line-height: 20px;
  color: #5AA2E5;
}
app-artist-details .latest-releases {
  text-align: center;
  padding: 80px 0;
}
app-artist-details .latest-releases h3, app-artist-details .latest-releases h2 {
  margin: 10px 0 30px 0;
}
app-artist-details .fas {
  color: white;
}
app-artist-details .artist-new-release {
  margin: auto;
  max-width: 1380px;
  height: 475px;
  background: #F2F7FB;
  box-shadow: 0px 2px 4px rgba(144, 170, 190, 0.5);
  border-radius: 7px;
}
app-artist-details .new-release-info {
  position: relative;
  top: 50px;
}
app-artist-details .new-release-info h2 {
  text-align: center !important;
}
app-artist-details section.videos .artist-videos {
  align-items: center;
  display: flex;
  margin: auto;
  flex-wrap: wrap;
  max-width: 1380px;
  min-height: 300px;
  justify-content: space-evenly;
}
app-artist-details section.videos .latest-videos {
  display: flex;
  width: 400px;
  height: 230px;
  background-image: url("/assets/images/testArtist/newvideo/image2.svg");
}
app-artist-details section.videos .artist-videos-mobile {
  display: none;
}
app-artist-details section.event {
  max-width: 1380px;
  margin: auto;
}
app-artist-details section.event .event-buttons {
  width: 103px;
  height: 30px;
  margin: 0.5rem;
  background: #FFFFFF;
  border: 2px solid #5AA2E5;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  text-transform: capitalize;
  color: #5AA2E5;
}
app-artist-details section.event .event-date-text {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
}
app-artist-details section.event .row {
  display: flex;
  align-items: center;
  border-top: 1px dashed #b7bccb;
  min-height: 69px;
  max-width: 1380px;
  margin: auto;
}
app-artist-details section.event .col {
  flex: 1;
  text-align: center;
}
app-artist-details .play-button {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid #FFFFFF;
  border-radius: 27px;
}
app-artist-details .titles {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 32px;
  line-height: 37px;
  text-transform: uppercase;
  color: #000000;
}
app-artist-details .social-icons {
  display: flex;
  gap: 3px;
}
app-artist-details .facebook {
  margin: 0.3rem;
  width: 30px;
  height: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  background-image: url("/assets/images/social/facebook.png");
}
app-artist-details .spotify {
  margin: 0.3rem;
  width: 30px;
  height: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  background-image: url("/assets/images/social/spotify.png");
}
app-artist-details .twitter {
  margin: 0.3rem;
  width: 30px;
  height: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  background-image: url("/assets/images/social/twitter.png");
}
app-artist-details .instagram {
  margin: 0.3rem;
  width: 30px;
  height: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  background-image: url("/assets/images/social/instagram.png");
}
app-artist-details .youtube {
  margin: 0.3rem;
  width: 30px;
  height: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  background-image: url("/assets/images/social/youtube.png");
}
app-artist-details .background-tone {
  background-color: white;
  position: relative;
  top: 1px;
  width: 100%;
  display: flex;
  justify-content: center;
}
app-artist-details .share-button {
  padding-right: 108px;
}
app-artist-details .play-button-web {
  padding-right: 230px;
}
app-artist-details .mobile {
  display: none !important;
}
app-artist-details .page-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #8089A0;
}

@media screen and (min-width: 100px) and (max-width: 960px) {
  app-artist-details app-new-music-slider .next-slide {
    position: absolute;
    left: -25px;
  }
  app-artist-details app-new-music-slider .prev-slide {
    position: absolute;
    right: -25px;
  }
  app-artist-details app-video-slider .next-slide {
    position: absolute;
    left: -25px;
  }
  app-artist-details app-video-slider .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
  }
  app-artist-details app-video-slider #video {
    margin: 0;
  }
  app-artist-details app-video-slider .nav-btn {
    color: #5AA2E5;
    width: 20px;
    height: 20px;
    z-index: 10;
    top: 50%;
  }
  app-artist-details app-video-slider .prev-slide {
    position: absolute;
    right: -25px;
  }
  app-artist-details section.about-artist {
    height: auto;
    background-color: white;
  }
  app-artist-details section.about-artist .artist-img {
    width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
  }
  app-artist-details section.about-artist .artist-info-block {
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }
  app-artist-details section.about-artist .artist-details-list {
    gap: 10px;
  }
  app-artist-details section.about-artist .social-icons {
    justify-content: center;
  }
  app-artist-details section.about-artist .artist-text-title {
    text-align: center;
  }
  app-artist-details section.about-artist .artist-text-info {
    padding: 0px 20px;
  }
  app-artist-details section.about-artist .artist-text-subtitle {
    text-align: center;
  }
  app-artist-details section.investment-opportunity {
    margin: 2rem;
  }
  app-artist-details section.latest-releases {
    background: white;
  }
  app-artist-details section.latest-releases .inner2 {
    flex-shrink: 0;
  }
  app-artist-details section.latest-releases .artist-slider {
    justify-content: center;
    gap: 16px;
    height: auto;
    background-color: white;
  }
  app-artist-details section.latest-releases .artist-slider-images {
    gap: 16px;
    justify-content: center;
    width: fit-content;
    margin: 3rem;
  }
  app-artist-details section.latest-releases .slider-mobile-image {
    width: 240px !important;
    height: 240px !important;
    background-position: 50% !important;
    background-repeat: no-repeat !important;
    background-size: contain;
  }
  app-artist-details section.artist-new-release {
    margin: 60px 20px 60px 20px;
    gap: 20px;
    height: auto;
    flex-direction: column;
    background-color: white;
  }
  app-artist-details section.artist-new-release .release-title {
    text-align: center;
    width: auto;
  }
  app-artist-details section.artist-new-release .release-text {
    text-align: justify;
  }
  app-artist-details section.artist-new-release .release-img {
    width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    width: 265px;
    height: 164px;
  }
  app-artist-details section.artist-new-release .release-text {
    margin: 1rem;
  }
  app-artist-details section.artist-new-release .new-release-info {
    position: unset;
  }
  app-artist-details section.videos .artist-videos {
    display: none;
  }
  app-artist-details section.videos .artist-videos-mobile {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  app-artist-details .background-tone {
    background-color: #F2F7FB;
  }
  app-artist-details .share-button {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 0px;
  }
  app-artist-details .play-button-web {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 0px;
    position: relative;
  }
  app-artist-details .web {
    display: none !important;
  }
  app-artist-details .mobile {
    display: flex !important;
  }
  app-artist-details .titles {
    text-align: center;
  }
}
app-my-auction .wrapper {
  min-height: 100vh;
  background: #fff;
}
app-my-auction .info-amount {
  width: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
app-my-auction .info-amount .item .text {
  margin-bottom: 0;
}
app-my-auction .info-amount .text {
  white-space: nowrap;
}
app-my-auction .table-header {
  border-bottom: 1px dashed #C4C4C4;
}
app-my-auction .table th {
  border-bottom: unset;
}
app-my-auction .auction__list {
  padding: 25px 0;
}
app-my-auction .spinner {
  margin-top: 40px;
}
app-my-auction .bids__button {
  height: 40px;
  margin: 0 23px;
  position: relative;
  text-transform: uppercase;
}
app-my-auction .bids__button .button-spinner {
  position: absolute;
  left: 10px;
  top: 10px;
}
app-my-auction .bids__button .button-spinner circle {
  stroke: #fff;
}
app-my-auction .bid-status {
  color: #FF0000;
}
app-my-auction .bid-status.isWinning {
  color: #27AE60;
}
app-my-auction .bid-status.isLoosing {
  color: #FF0000;
}
app-my-auction .bid-status.isQualified {
  color: #FFA800;
}
app-my-auction .spinner mat-spinner {
  left: 10px;
  top: 10px;
}
app-my-auction tr td {
  border-top: 1px dashed #C4C4C4;
}
app-my-auction tr:first-child td {
  border-top: none;
}
app-my-auction td {
  border-bottom: none;
  text-align: center;
  padding: 16px 0;
  color: #8089A0;
  font-weight: 400;
}
app-my-auction td.bid-amount {
  color: #000;
}
app-my-auction td .bid-song-name {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: #000;
}
app-my-auction th {
  text-align: center !important;
}
app-my-auction .auction-button {
  display: flex;
  justify-content: flex-end;
}
app-my-auction .timer__title {
  display: none;
}
app-my-auction .timer .timer__timing-item {
  background: #8089A0 !important;
}
app-my-auction .bids__row:nth-child(2n+1) td {
  background: #F2F7FB;
}
app-my-auction .auction__bids-amount {
  font-size: 27px;
}
app-my-auction .mobile-screen {
  display: none;
}
app-my-auction .auction__balance .text {
  color: #8089A0;
  font-size: 14px;
}
app-my-auction .auction__content {
  position: relative;
  border-top: 1px dashed #B7BCCB;
  width: 1300px;
  margin: 0 auto;
  padding-top: 18px;
}
@media screen and (max-width: 1370px) {
  app-my-auction .auction__content {
    width: 90%;
  }
}
@media screen and (max-width: 767px) {
  app-my-auction .auction__content {
    width: 100%;
    border-top: none;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 320px) {
  app-my-auction .auction__content {
    padding-left: 10px;
    padding-right: 10px;
  }
}
app-my-auction .mob-screen {
  display: none;
}

@media screen and (max-width: 767px) {
  app-my-auction .mob-screen {
    display: block;
  }
  app-my-auction app-timer {
    margin-top: 15px;
  }
  app-my-auction .timer-col {
    display: flex;
    padding-right: 15px;
    flex-direction: column;
    align-items: flex-end;
  }
  app-my-auction .timer-col app-timer {
    margin-top: 15px;
  }
  app-my-auction .timer-col app-timer .timer__timing-item {
    font-size: 14px !important;
    line-height: 28px !important;
    height: 26px !important;
    width: 24px !important;
  }
  app-my-auction .timer-col app-timer .timer__timing {
    justify-content: flex-end;
  }
  app-my-auction .bids__button {
    margin: 0;
    min-width: 120px;
  }
  app-my-auction .td-song {
    text-align: left;
    padding-left: 15px;
  }
  app-my-auction .td-song p {
    line-height: 20px;
  }
  app-my-auction .max-screen {
    display: none;
  }
  app-my-auction .bid-status {
    font-weight: 500;
  }
}
app-balance {
  height: 100%;
}
app-balance .main-wrapper {
  min-height: 100vh;
  background: #fff;
}
app-balance main {
  flex: 1;
  width: 100%;
  margin: 0 auto;
}
app-balance .section-image {
  margin: -80px auto 0 auto;
  width: 1300px;
  padding-left: 60px;
}
app-balance .section-image .name,
app-balance .section-image .text {
  z-index: 10;
}
app-balance .section-image .name {
  color: #fff;
}
app-balance .table-head tr {
  border-bottom: 2px solid #8089A0;
}
app-balance .link-blue {
  font-weight: normal;
}
app-balance .icon-blue {
  color: #5AA2E5;
}
app-balance .filter-title {
  white-space: nowrap;
  line-height: 48px;
  padding-right: 20px;
}
app-balance .filter-wrapper {
  display: flex;
  width: 100%;
}
app-balance .filter-wrapper .item {
  margin-right: 14px;
}
app-balance .filter-wrapper.filter-wrapper_space_between {
  justify-content: space-between;
}
app-balance .investment-table {
  margin-top: 27px;
}
app-balance .wrapper-img .fas {
  padding-right: 30px;
}
app-balance .mobile-screen {
  display: none;
}
app-balance .action-tab {
  padding-top: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
app-balance .action-tab .info-wrapper {
  justify-content: space-between;
  width: 100%;
  text-align: right;
}
app-balance .action-tab .value {
  font-size: 27px;
}
app-balance .action-tab .text {
  color: #8089A0;
}
app-balance .action-tab .item {
  width: auto;
  margin-right: 30px;
}
app-balance .action-tab .item:first-child {
  margin-left: 0;
}
app-balance .action-tab .balance {
  margin-right: 60px;
  position: relative;
}
app-balance .action-tab .balance span {
  height: 100%;
  display: block;
  border-right: 1px solid #8089a0;
  position: absolute;
  right: -30px;
  top: 0;
  opacity: 0.2;
}
app-balance .filter-section.desktop-screen {
  justify-content: flex-start;
  display: flex;
  border-top: 1px dashed #B7BCCB;
  width: 1300px;
  margin: 0 auto;
  padding-top: 18px;
}
app-balance .user-name {
  width: 100%;
  padding: 15px 0 25px 37px;
  justify-content: space-between;
}
app-balance .document {
  cursor: pointer;
}
app-balance .image-wrapper {
  height: 196px;
  width: 100%;
  position: relative;
}
app-balance .info-amount {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
app-balance .info-amount .item .text {
  margin-bottom: 0;
}
app-balance .info-amount .text {
  white-space: nowrap;
}
app-balance .user-info__button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-balance .user-info__button mat-spinner {
  margin-right: 5px;
}
app-balance .profile-img {
  width: 160px;
  min-width: 160px;
  position: relative;
  background: url("../../../assets/images/user/fallback_new.png");
  height: 160px;
  background-size: cover;
  background-position: center center;
  border-radius: 80px;
  box-shadow: 0 0 3px #8089A0;
}
app-balance .table-sub-header.download td {
  cursor: pointer;
}
app-balance .lnr-chevron-right {
  display: inline-block;
  font-weight: 900;
  cursor: pointer;
}
app-balance .lnr-chevron-right.opened {
  transition: all 0.25s ease-out 0s;
  transform: rotate(90deg);
}
@media (max-width: 376px) {
  app-balance .investment-table.table td, app-balance .investment-table.table th {
    padding: 10px 0;
  }
}
app-balance tbody td {
  padding: 20px 18px;
}
app-balance tbody tr:hover td {
  background: transparent;
}
app-balance .download-links {
  display: flex;
  color: #5AA2E5;
  justify-content: flex-start;
}
app-balance .download-links > .link-blue {
  width: 100px;
}
app-balance .no-list-data {
  border-bottom: none;
  font-style: italic;
}
app-balance .kyc-status-block {
  border-radius: 7px;
  width: 126px;
  height: 24px;
  line-height: 26px;
  text-align: left;
}
app-balance .kyc-status-block span {
  font-size: 13px;
  font-weight: 700;
  color: #fff;
}
app-balance .kyc-status-block .fas {
  padding: 0 10px;
}
app-balance .kyc-status-block.inactive {
  background: #F95738;
}
app-balance .kyc-status-block.active {
  background: #27AE60;
}
app-balance .button-wrapper {
  justify-content: center;
}
app-balance .button-wrapper .button {
  width: auto;
  padding: 0 30px;
  margin-bottom: 35px;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
}
app-balance .min-screen {
  display: none;
}
app-balance .warning__list {
  display: flex;
  justify-content: flex-end;
}
app-balance .warning__list .warning__title {
  white-space: nowrap;
}
app-balance .data-list {
  width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
app-balance .data-list.data-list--margin {
  margin: 0 auto 32px;
}
app-balance .td-amount__value {
  white-space: nowrap;
}
app-balance mat-form-field.month {
  width: 60px;
  margin-right: 5px;
}
app-balance mat-form-field.year {
  width: 80px;
  margin-right: 20px;
}
app-balance mat-form-field + .button {
  height: 40px;
  min-width: 120px;
}
app-balance .td-amount {
  font-size: 18px;
  font-weight: bold;
}
app-balance .reset-filters {
  height: 40px;
  /* line-height: 40px; */
  cursor: pointer;
  margin-left: 15px;
  color: #5aa2e5;
  white-space: nowrap;
  display: flex;
  /* width: 155px; */
  align-items: center;
}
app-balance .reset-filters .lnr-cross {
  margin-left: 8px;
  color: #5AA2E5;
}
app-balance .download-loaded {
  margin-left: 30px;
}
app-balance .download-loaded div.global-page-loader {
  margin: 0;
  height: 16px;
}
app-balance .download-loaded div.global-page-loader .lnr {
  font-size: 20px;
  left: 0;
  top: -2px;
}
app-balance app-loader {
  height: 200px;
  display: flex;
}

@media screen and (max-width: 1370px) {
  app-balance .section-image {
    width: 90%;
    padding-left: 20px;
  }
  app-balance .filter-section.desktop-screen {
    display: block;
    width: 90%;
  }
  app-balance .data-list {
    width: 90%;
  }
}
@media screen and (max-width: 1023px) {
  app-balance main {
    width: 100%;
  }
  app-balance .action-tab .wrapper {
    align-items: flex-end;
  }
  app-balance .action-tab .wrapper:last-child {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-left: 0;
  }
  app-balance .action-tab .wrapper:last-child .button {
    padding: 0 20px;
  }
  app-balance .action-tab .wrapper:last-child .item {
    margin-top: 10px;
    margin-left: 0;
  }
  app-balance .td-amount {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  app-balance main {
    padding: 0 15px !important;
    background: #fff;
  }
  app-balance .newline {
    word-break: break-word;
  }
  app-balance .mobile-screen {
    display: block;
  }
  app-balance td.mobile-screen {
    display: table-cell;
  }
  app-balance tr.mobile-screen {
    display: table-row;
  }
  app-balance .filter-section-upload {
    position: relative;
    width: 100%;
    margin: 10px 0 0 0;
  }
  app-balance .data-list.data-list--margin {
    margin: 0 auto 32px;
  }
  app-balance .filter-section.desktop-screen {
    width: 100%;
  }
  app-balance .image-wrapper {
    height: 156px;
  }
  app-balance .wrapper-img .fas {
    padding-right: 10px;
  }
  app-balance .filter-wrapper {
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  app-balance .filter-wrapper .button {
    width: 100%;
  }
  app-balance .filter-wrapper .item {
    margin-right: 0;
    width: 48%;
  }
  app-balance .data-list {
    width: 100%;
    margin: 0;
  }
  app-balance .min-screen {
    display: table-cell;
  }
  app-balance .desktop-screen-visible {
    display: none;
  }
  app-balance .section-image {
    width: 100%;
    padding: 0 !important;
  }
  app-balance .profile-img {
    display: none;
  }
  app-balance .button-wrapper .button {
    width: 100%;
  }
  app-balance .user-name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0 20px 0;
  }
  app-balance .user-name .name {
    font-size: 18px !important;
  }
  app-balance .song-name,
app-balance .artist-name {
    margin-bottom: 5px;
  }
  app-balance .user-info__button {
    width: 100%;
    height: 50px;
  }
  app-balance .action-tab {
    flex-direction: column;
    padding-top: 55px;
    width: 100%;
  }
  app-balance .action-tab .desktop-screen {
    display: none;
  }
  app-balance .action-tab .info-amount {
    justify-content: space-between;
    padding: 0;
  }
  app-balance .action-tab .info-amount .item {
    width: 33.33%;
    text-align: center;
    margin-right: 0;
    margin-top: 20px;
  }
  app-balance .action-tab .info-amount .item.balance {
    width: 50%;
    margin: 0;
    text-align: left;
  }
  app-balance .action-tab .info-amount .item.balance span {
    display: none;
  }
  app-balance .action-tab .info-amount .item.balance .value {
    font-size: 24px;
  }
  app-balance .action-tab .info-amount .item.button-load-csv {
    width: 100%;
  }
  app-balance .action-tab .info-amount .item:nth-child(3) {
    text-align: left;
  }
  app-balance .action-tab .info-amount .item:nth-child(3) .value {
    width: 70%;
    text-align: center;
  }
  app-balance .action-tab .info-amount .item:nth-child(5) {
    text-align: right;
  }
  app-balance .action-tab .info-amount .item:nth-child(5) .value {
    width: 100%;
    text-align: center;
    margin-left: 15px;
  }
  app-balance .action-tab .info-amount .item .value {
    line-height: 36px;
    font-size: 18px;
  }
  app-balance .value {
    font-size: 18px;
  }
  app-balance tbody td:nth-child(3) {
    padding: 20px 18px 20px 0;
  }
  app-balance .table th:nth-child(2) {
    padding: 11px 18px 11px 0;
  }
  app-balance .backgroundImage {
    background-image: none !important;
    background-color: #253a6f;
  }
  app-balance .image-desc-float {
    margin-right: 16px;
  }
  app-balance mat-form-field + .button {
    width: 100%;
    height: 50px;
  }
  app-balance mat-form-field:nth-child(4) {
    margin: 0;
  }
  app-balance mat-form-field.year {
    width: 70px;
  }
  app-balance .reset-filters {
    margin: 5px auto 0;
  }
}
.mobile-accordion {
  padding: 0 !important;
}

.mat-expansion-panel {
  width: 100%;
  box-shadow: none;
}
.mat-expansion-panel .mat-expansion-panel-body {
  padding: 20px 18px;
}

app-marketplace-lazy .page_content {
  width: 100%;
  position: relative;
  background: #fff;
  padding-bottom: 20px;
}
app-marketplace-lazy .position-block {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1023px) {
  app-marketplace-lazy .position-block {
    padding: 30px;
    margin-top: 0;
  }
}
app-marketplace-lazy .position-block .position-block-up {
  position: relative;
  width: 95%;
  margin-top: -60px;
  top: -60px;
  border-radius: 7px;
  background: #F2F7FB;
  box-shadow: 0 2px 4px rgba(144, 170, 190, 0.5);
  height: auto;
  padding: 0 30px;
}
@media screen and (max-width: 1023px) {
  app-marketplace-lazy .position-block .position-block-up {
    width: 100%;
    top: 0;
    margin-top: 0;
    padding: 0;
    background: #fff;
    box-shadow: none;
  }
}
app-marketplace-lazy .position-block .title {
  margin: 50px 0;
  text-align: center;
}
@media screen and (max-width: 1023px) {
  app-marketplace-lazy .position-block .title {
    display: none;
  }
}
app-marketplace-lazy .position-block .button {
  width: 357px;
  font-size: 21px;
  font-weight: 500;
  height: 60px;
  margin: 50px auto;
}
@media screen and (max-width: 1023px) {
  app-marketplace-lazy .position-block .button {
    margin: 15px auto;
    width: 269px;
    height: 52px;
    font-size: 18px;
  }
}
app-marketplace-lazy .position-block .how-works-img {
  width: 75px;
  height: 75px;
  min-width: 75px;
}
@media screen and (max-width: 767px) {
  app-marketplace-lazy app-main-slider .market-container {
    margin-top: 0;
  }
}
@media screen and (max-width: 767px) {
  app-marketplace-lazy app-main-slider .slider-btn {
    margin: 5px 0;
  }
}
app-marketplace-lazy .load-more {
  display: flex;
  justify-content: center;
}
app-marketplace-lazy .load-more .button {
  width: 356px;
}
app-marketplace-lazy .content-inner {
  display: flex;
  margin-bottom: 50px;
}
app-marketplace-lazy .content-inner .item {
  width: 33.33%;
  padding: 0 30px;
}
app-marketplace-lazy .content-inner .item .how-works-text {
  padding-left: 21px;
}
app-marketplace-lazy .content-inner .item .how-works-text .sub-title {
  line-height: 20px;
}
app-marketplace-lazy .content-inner .item .how-works-text .visible-part {
  padding-top: 20px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 1.5;
}
@media screen and (max-width: 1023px) {
  app-marketplace-lazy .content-inner .item .how-works-text .visible-part {
    padding-top: 10px;
    line-height: inherit;
  }
}
@media screen and (max-width: 1023px) {
  app-marketplace-lazy .content-inner {
    flex-direction: column;
    margin-bottom: 15px;
  }
  app-marketplace-lazy .content-inner .item {
    width: 100%;
    padding: 0 0 20px 0;
  }
  app-marketplace-lazy .content-inner .item .how-works-text {
    padding-left: 21px;
  }
}

app-about-grs {
  height: 100%;
}
app-about-grs .main-wrapper {
  background: #fff;
  padding-top: 74px;
  display: flex;
  flex-direction: column;
}
app-about-grs .main-wrapper .inner {
  max-width: 1180px;
  width: 1180px;
  margin: 0 auto;
  flex: 1;
}
app-about-grs .main-about {
  display: flex;
  justify-content: space-between;
}
app-about-grs .main-about .list {
  width: 68%;
}
app-about-grs .main-about .quote {
  width: 30%;
}
app-about-grs blockquote {
  font-size: 16px;
  line-height: 24px;
  font-style: italic;
  color: #000000;
  padding-left: 35px;
  position: relative;
}
app-about-grs blockquote:before {
  content: "“";
  font-size: 48px;
  line-height: 25px;
  color: #5AA2E5;
  position: absolute;
  left: 10px;
  top: -5px;
}
app-about-grs .main-title {
  margin: 54px 0 20px 0;
  text-align: center;
}
app-about-grs section.content {
  padding-bottom: 35px;
}
app-about-grs section.content .item {
  border-bottom: 1px dashed #C4C4C4;
  padding: 40px;
}
app-about-grs section.content .item:last-child {
  border-bottom: none;
}
app-about-grs section.content .sub-title {
  font-size: 20px;
  line-height: 18px;
  color: #000;
  margin-bottom: 20px;
}
app-about-grs section.content .text {
  text-align: center;
}
app-about-grs .list {
  line-height: 18px;
}
app-about-grs .founder {
  display: flex;
}
app-about-grs .founder .founder-photo-img {
  width: 190px;
  min-width: 190px;
  position: relative;
  height: 190px;
  background: url("/assets/images/user/fallback_new.png") center center;
  background-size: cover;
  border-radius: 50%;
}
app-about-grs .founder .founder-description {
  margin-left: 57px;
}
app-about-grs .founder .founder-text {
  line-height: 18px;
}
app-about-grs .team .team-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
app-about-grs .team .team-list .team-user {
  width: 30%;
  margin-top: 70px;
}
app-about-grs .team .team-user-img {
  width: 173px;
  min-width: 173px;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  height: 173px;
  background-size: cover !important;
  background: url("/assets/images/fallback.png") center center;
}
app-about-grs .team .team-user-img.user1 {
  background: url("/assets/images/fallback.png") center center;
}
app-about-grs .team .team-user-img.user2 {
  background: url("/assets/images/team/charlotte.png") center center;
}
app-about-grs .team .team-user-img.user3 {
  background: url("/assets/images/team/hofer.png") center center;
}
app-about-grs .team .team-user-img.user4 {
  background: url("/assets/images/team/Violeta-Zlabinger.jpg") center center;
}
app-about-grs .team .team-user-img.user5 {
  background: url("/assets/images/team/Patricia-Halva.jpg") center center;
}
app-about-grs .team .team-user-img.user6 {
  background: url("/assets/images/team/Satyam-Subhash.jpg") center center;
}
app-about-grs .team .team-user-img.user7 {
  background: url("/assets/images/team/jurgen.png") center center;
}
app-about-grs .team .team-user-img.user8 {
  background: url("/assets/images/team/Karl-Holaubeck.jpg") center center;
}
app-about-grs .team .team-user-img.user9 {
  background: url("/assets/images/team/philipp.png") center center;
}
app-about-grs .team .name {
  margin-top: 25px;
  font-weight: bold;
  text-align: left;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
}
app-about-grs .team .position {
  margin-top: 5px;
  text-align: left;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}
app-about-grs .team .user-desc {
  margin-top: 10px;
  line-height: 18px;
}
app-about-grs .team .team-sub-title {
  text-align: center;
  font-size: 20px;
  line-height: 18px;
  color: #000;
}
app-about-grs .mob-screen {
  display: none;
}
app-about-grs .mid-screen {
  display: block;
}

@media screen and (max-width: 1440px) {
  app-about-grs .main-wrapper .inner {
    margin: 0 60px;
    width: auto;
  }
}
@media screen and (max-width: 1023px) {
  app-about-grs section.content .item {
    padding: 40px 0;
  }
  app-about-grs section.content .item:first-child {
    padding: 20px 0 40px 0;
  }
}
@media screen and (max-width: 767px) {
  app-about-grs .main-wrapper {
    height: 100%;
  }
  app-about-grs .mob-screen {
    display: block;
  }
  app-about-grs .mid-screen {
    display: none;
  }
  app-about-grs section.content {
    width: 100%;
  }
  app-about-grs section.content .flex-align {
    flex-direction: column;
  }
  app-about-grs section.content .item {
    width: 100%;
    padding: 30px 0;
  }
  app-about-grs section.content .item:first-child {
    padding: 0 0 30px 0;
  }
  app-about-grs section.content .item .wrapper {
    width: 100%;
  }
  app-about-grs section.content .sub-title {
    text-align: center;
    font-size: 18px;
    line-height: 21px;
  }
  app-about-grs .main-about {
    flex-direction: column;
  }
  app-about-grs .main-about .list {
    width: 100%;
  }
  app-about-grs .main-about .quote {
    width: 100%;
  }
  app-about-grs blockquote {
    margin-top: 35px;
    padding-left: 20px;
    font-size: 14px;
    line-height: 20px;
  }
  app-about-grs blockquote:before {
    left: -5px;
  }
  app-about-grs .founder {
    flex-direction: column;
    display: block;
  }
  app-about-grs .founder .founder-photo-img {
    float: left;
    width: 125px;
    height: 125px;
    margin-right: 20px;
    margin-bottom: 15px;
    min-width: 125px;
  }
  app-about-grs .founder .founder-description {
    margin-left: 0;
    padding: 0;
  }
  app-about-grs .founder .founder-text p {
    margin-top: 15px;
  }
  app-about-grs .team .team-user-img {
    width: 120px;
    height: 120px;
    min-width: 120px;
  }
  app-about-grs .team .team-list .team-user {
    width: 100%;
    margin-top: 40px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  app-about-grs .team .team-list .team-user .name {
    text-align: center;
    font-size: 16px;
    line-height: 18px;
  }
  app-about-grs .team .team-list .team-user .position {
    text-align: center;
    font-size: 14px;
    line-height: 18px;
  }
  app-about-grs .team .team-list .team-user .user-desc {
    text-align: center;
    line-height: 18px;
  }
  app-about-grs .main-title {
    font-size: 20px;
    margin: 30px 0;
  }
  app-about-grs .main-wrapper .inner {
    margin: 0 40px;
    width: auto;
  }
}
@media screen and (max-width: 460px) {
  app-about-grs .main-wrapper .inner {
    margin: 0 20px;
    width: auto;
  }
}
app-terms-conditions {
  height: 100%;
}
app-terms-conditions .main-wrapper {
  background: #fff;
  padding-top: 74px;
}
app-terms-conditions .main-wrapper .inner {
  max-width: 1180px;
  margin: 0 auto;
}
app-terms-conditions .main-title {
  margin-top: 54px;
  text-align: center;
}
app-terms-conditions section.content {
  margin-bottom: 100px;
  font-size: 14px;
}
app-terms-conditions section.content p {
  line-height: 18px;
  margin-bottom: 15px;
}
app-terms-conditions section.content div:first-child {
  margin-top: 10px;
}
app-terms-conditions section.content div:first-child p:first-child {
  text-align: center;
  margin-bottom: 0;
}
app-terms-conditions section.content h3 {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: normal;
  color: #000;
  margin-bottom: 15px;
}
app-terms-conditions section.content ul, app-terms-conditions section.content ol {
  line-height: 18px;
  margin: 0 0 20px 20px;
  list-style-type: none;
}
app-terms-conditions section.content ul li, app-terms-conditions section.content ol li {
  position: relative;
  padding-left: 30px;
  line-height: 22px;
}
app-terms-conditions section.content ul li:before, app-terms-conditions section.content ol li:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  width: 8px;
  height: 8px;
  border: 2px solid #FFA800;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}

@media screen and (max-width: 1440px) {
  app-terms-conditions .main-wrapper .inner {
    margin: 0 60px;
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  app-terms-conditions section.content {
    margin-bottom: 50px;
  }
  app-terms-conditions .main-title {
    font-size: 26px;
  }
  app-terms-conditions .main-wrapper .inner {
    margin: 0 40px;
    width: auto;
  }
}
@media screen and (max-width: 460px) {
  app-terms-conditions .main-wrapper .inner {
    margin: 0 20px;
    width: auto;
  }
}
:host {
  height: 100%;
}
:host .main-wrapper {
  background: #fff;
  padding-top: 74px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
:host .main-wrapper .inner {
  max-width: 1180px;
  width: 1180px;
  margin: 0 auto;
  flex: 1;
}
:host .main-title {
  margin: 54px 0;
  text-align: center;
}
:host section.content {
  padding-bottom: 35px;
}
:host section.content .sub-title {
  font-size: 20px;
  text-align: center;
  color: #000;
  margin-bottom: 15px;
}
:host section.content .text {
  text-align: center;
}
:host section.contact {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px dashed #C4C4C4;
}
:host section.contact p {
  line-height: 22px;
}

@media screen and (max-width: 1440px) {
  app-imprint .main-wrapper .inner {
    margin: 0 60px;
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  app-imprint .main-wrapper {
    height: 100%;
  }
  app-imprint section.contact {
    padding: 40px 0;
    flex-direction: column;
    align-items: flex-start;
  }
  app-imprint section.contact .item {
    margin-top: 20px;
  }
  app-imprint section.contact .item:first-child {
    margin-top: 0;
  }
  app-imprint .main-title {
    font-size: 26px;
    margin: 54px 0 20px 0;
  }
  app-imprint .main-wrapper .inner {
    margin: 0 40px;
    width: auto;
  }
}
@media screen and (max-width: 460px) {
  app-imprint .main-wrapper .inner {
    margin: 0 20px;
    width: auto;
  }
}
app-user-dashboard {
  height: 100%;
}
app-user-dashboard .main-wrapper {
  min-height: 100vh;
  background: #fff;
}
app-user-dashboard .main {
  border-top: 1px dashed #B7BCCB;
  flex: 1;
  height: 100%;
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
}
app-user-dashboard .kyc__status {
  max-width: 1300px;
  margin: 20px auto;
}
app-user-dashboard .warning__list {
  display: flex;
  justify-content: flex-end;
}
app-user-dashboard .warning__list .warning__title {
  white-space: nowrap;
}
app-user-dashboard .section-list-title {
  width: 1300px;
  text-transform: uppercase;
  margin: 32px auto;
}
app-user-dashboard .action-tab {
  width: 100%;
  display: flex;
  align-items: center;
}
app-user-dashboard .action-tab .button.yellow {
  margin: 0 28px;
}
app-user-dashboard .action-tab .value {
  font-size: 27px;
  white-space: nowrap;
}
app-user-dashboard .action-tab .text {
  color: #8089A0;
}
app-user-dashboard .action-tab .info-item {
  width: 15%;
}
app-user-dashboard .action-tab .item {
  width: auto;
}
app-user-dashboard .list-buttons {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
app-user-dashboard .list-buttons .button {
  margin-left: 15px;
}
app-user-dashboard .button-wrapper {
  display: flex;
}
app-user-dashboard .info-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
app-user-dashboard .button-action .button {
  height: 50px;
  width: 200px;
  margin-bottom: 10px;
  font-size: 16px;
}
app-user-dashboard .action-tab-wrapper {
  padding-right: 20px;
  width: 60%;
}
app-user-dashboard .link-blue {
  cursor: pointer;
}
app-user-dashboard .profile-song-content {
  display: flex;
}
app-user-dashboard .profile-song-content .description {
  width: 100%;
  padding: 20px 0 0 40px;
}
app-user-dashboard .button.border-blue {
  background: transparent;
}
app-user-dashboard .no-list-data {
  font-style: italic;
}
app-user-dashboard .next-slide, app-user-dashboard .prev-slide {
  color: #5AA2E5;
  width: 20px;
  height: 20px;
  top: 50%;
  margin-top: -10px;
}
app-user-dashboard .next-slide .lnr, app-user-dashboard .prev-slide .lnr {
  font-size: 14px;
  font-weight: 700;
}
app-user-dashboard .prev-slide {
  position: absolute;
  right: 0;
}
app-user-dashboard .next-slide {
  position: absolute;
  left: 0;
}
app-user-dashboard .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
app-user-dashboard .img-circle {
  margin: 0 auto;
}
app-user-dashboard ngx-slick-carousel {
  padding: 0 30px;
}
app-user-dashboard ngx-slick-carousel .slick-track {
  display: flex !important;
  align-items: center !important;
}
app-user-dashboard ngx-slick-carousel .slick-current .img-circle {
  width: 150px !important;
  margin-top: 0 !important;
  height: 150px !important;
  border-radius: 100px !important;
  opacity: 1 !important;
}
app-user-dashboard ngx-slick-carousel .slick-slide .img-circle {
  opacity: 0.8;
}
app-user-dashboard .favorite-artist {
  border-top: 1px dashed #B7BCCB;
  width: 1300px;
  margin: 32px auto;
}
app-user-dashboard .favorite-artist .item {
  width: 33.33%;
}
app-user-dashboard .load-more {
  display: flex;
  justify-content: center;
}
app-user-dashboard .load-more .global-page-loader {
  height: auto;
  margin: 0;
}
app-user-dashboard .load-more .global-page-loader .lnr {
  font-size: 50px;
  left: 20px;
}
app-user-dashboard .mobile-screen {
  display: none;
}
app-user-dashboard .slider-item {
  background-position: center;
  background-size: cover;
  overflow: hidden;
  background-image: url("../../../assets/images/fallback.png");
}
app-user-dashboard .slider-item .inner {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
app-user-dashboard .slick-slider {
  margin: 60px 0;
}
app-user-dashboard app-loader {
  height: 200px;
  display: flex;
}

@media screen and (max-width: 1370px) {
  app-user-dashboard .main {
    max-width: 95%;
  }
  app-user-dashboard .section-list-title,
app-user-dashboard .favorite-artist {
    width: 90%;
  }
}
@media screen and (max-width: 1023px) {
  app-user-dashboard main {
    width: 100%;
  }
  app-user-dashboard .image-desc-float {
    width: 100px;
    min-width: 100px;
    height: 100px;
  }
  app-user-dashboard .profile-song-content .description {
    padding: 0;
  }
  app-user-dashboard .action-tab {
    align-items: flex-start;
  }
  app-user-dashboard .action-tab .button {
    width: 100%;
    margin: 5px 10px;
  }
  app-user-dashboard .action-tab .button.yellow {
    margin: 0 28px 0 0;
  }
  app-user-dashboard .value {
    font-size: 36px;
  }
}
@media screen and (max-width: 767px) {
  app-user-dashboard .main {
    padding: 0 15px;
    background: #fff;
  }
  app-user-dashboard .section-list-title {
    width: 100%;
  }
  app-user-dashboard .img-circle {
    width: 90px;
    height: 90px;
    min-width: 90px;
  }
  app-user-dashboard .desktop-screen {
    display: none;
  }
  app-user-dashboard .mobile-screen {
    display: block;
  }
  app-user-dashboard .action-tab .info-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 10px;
  }
  app-user-dashboard .section-list-title {
    margin: 0 auto 16px;
  }
  app-user-dashboard .load-more {
    margin: 0 0 15px 0;
  }
  app-user-dashboard ngx-slick-carousel .slick-current .img-circle {
    width: 100px !important;
    height: 100px !important;
  }
  app-user-dashboard .value {
    text-align: center;
    font-size: 24px;
  }
  app-user-dashboard .favorite-artist {
    width: 100%;
    margin: 0 auto 32px;
  }
  app-user-dashboard .favorite-artist .item {
    margin: 15px 0;
    width: 100%;
  }
  app-user-dashboard .action-tab {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  app-user-dashboard .action-tab .button {
    width: 100%;
    margin: 10px 0;
  }
  app-user-dashboard .action-tab .list-buttons {
    padding: 0;
  }
  app-user-dashboard .action-tab .button-wrapper {
    margin: 20px 0;
    width: 100%;
    justify-content: space-between;
  }
  app-user-dashboard .list {
    flex-direction: column;
  }
  app-user-dashboard .image-desc-float {
    margin-right: 16px;
  }
}
app-project-create {
  display: flex;
  min-height: 100vh;
  background-color: white;
}
app-project-create .info-text {
  width: 45vw;
  background: url("../../../assets/images/wizard.jpg") center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  text-align: right;
  font-weight: 900;
  font-size: 69px;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
}
app-project-create .info-text .inner {
  background: linear-gradient(to left, #134D66 0%, rgba(19, 77, 102, 0.01) 89.43%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
app-project-create .info-text .sub-text {
  text-transform: none;
  font-size: 56px;
  line-height: 1.1;
}
app-project-create button:disabled {
  cursor: initial;
}
app-project-create .opportunity__wrapper {
  padding: 0 25px;
}
app-project-create .item-song, app-project-create .item-song-wrapper {
  width: 100%;
}
app-project-create .item-song .audio, app-project-create .item-song-wrapper .audio {
  width: 100%;
}
app-project-create .wrapper {
  width: 55vw;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
app-project-create .wrapper .inner {
  max-width: 70%;
  padding: 100px 0 50px;
  width: 416px;
}
app-project-create .wrapper .agreement {
  border-bottom: 1px dashed #C4C4C4;
  padding: 20px 0 20px 25px;
}
app-project-create .wrapper .agreement .agreement-text {
  width: 270px;
  padding-left: 10px;
}
app-project-create .wrapper .button-wrapper {
  margin-top: 30px;
  padding: 0 25px;
  justify-content: space-between;
  align-items: center;
}
app-project-create .wrapper .button-wrapper .button.yellow {
  width: 210px;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
}
app-project-create .wrapper .button-wrapper .button.transparent {
  min-width: 100px;
  width: 100px;
  font-size: 16px;
  font-weight: 700;
}
app-project-create .wrapper .button-wrapper .cancel {
  color: #5AA2E5;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  width: 50%;
  font-weight: 500;
}
app-project-create .wrapper .button-wrapper .cancel .fas {
  margin-right: 10px;
}
app-project-create .wrapper .title {
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 25px;
  text-align: center;
}
app-project-create .wrapper .item-document {
  padding: 0 25px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
app-project-create .wrapper .upload-section {
  width: 100%;
}
app-project-create .wrapper .upload-section:last-child {
  margin-left: 0;
}
app-project-create .wrapper .upload-section label {
  flex-direction: row;
}
app-project-create .wrapper .upload-section .fa-music {
  font-size: 20px;
  padding-right: 18px;
}
app-project-create .wrapper .text {
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
  font-size: 16px;
}
app-project-create .wrapper .production {
  color: #8089A0;
  text-align: center;
  margin-top: 30px;
  font-size: 16px;
}
app-project-create .wrapper .production.error-message {
  margin-top: -30px;
}
app-project-create .wrapper mat-spinner {
  margin-right: -40px;
  margin-left: 10px;
  margin-top: 0;
}
app-project-create .wrapper .upload-section-audio {
  height: 50px;
}
app-project-create .wrapper .img-close {
  position: relative;
  font-size: 15px;
  padding-right: 10px;
  cursor: pointer;
  color: #F95738;
}
app-project-create .wrapper .picture-load__name {
  font-weight: bold;
  display: flex;
  align-items: center;
}
app-project-create .wrapper .picture-load {
  padding-top: 20px;
}
app-project-create .wrapper .picture-load__info {
  margin: 10px 0;
}
app-project-create .wrapper .provide-song {
  border-bottom: 1px dashed #A8C1D3;
}
app-project-create .wrapper .provide-song .user-raw {
  padding: 15px 25px 15px;
}
app-project-create .wrapper .mat-form-field {
  width: 100%;
}
app-project-create .wrapper .item {
  padding: 0 25px;
}
app-project-create .wrapper .item .mat-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-project-create .wrapper .item .mat-item .far, app-project-create .wrapper .item .mat-item .fas {
  color: #5AA2E5;
}
app-project-create .wrapper .item-text {
  color: #8089A0;
  padding: 0 25px;
}
app-project-create .wrapper .item-text.item-text__margin-top {
  margin-top: 10px;
}
app-project-create .wrapper .item-text.item-text__margin-top20 {
  margin-top: 20px;
}
app-project-create .wrapper .list-item {
  display: flex;
  position: relative;
  justify-content: space-between;
}
app-project-create .wrapper .list-item .list-controls {
  position: relative;
}
app-project-create .wrapper .list-item .add {
  padding-right: 25px;
  padding-top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-project-create .wrapper .list-item .fa-times-circle {
  color: #F95738;
}
app-project-create .wrapper .list-item .remove-control {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 20px;
}
app-project-create .wrapper .list-item .mat-item {
  width: 293px;
  padding-left: 25px;
}
app-project-create .wrapper .cancel-message.cancel {
  text-align: center;
  width: 100%;
}
app-project-create .wrapper .input-text-item.mat-item {
  margin-top: 10px;
}
app-project-create .wrapper .item-align-left {
  align-items: flex-start;
}
app-project-create .wrapper .span-label {
  color: #8089A0;
}
app-project-create .wrapper .user {
  color: #000;
  font-size: 18px;
  line-height: 32px;
  font-weight: bold;
}
app-project-create .wrapper .button {
  width: 100%;
  text-transform: uppercase;
}
app-project-create .opportunity__image {
  width: 100%;
}
app-project-create .sum-message .title-status {
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 25px;
  text-align: center;
}
app-project-create .sum-message .text {
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  color: #8089A0;
}
app-project-create .icon-cancel {
  cursor: pointer;
}
app-project-create .file-name {
  font-weight: 600;
  margin-top: 10px;
  display: inline-block;
}
app-project-create .file-name .icon-cancel {
  padding-right: 10px;
}
app-project-create .file-name .fa-times-circle {
  color: #F95738;
}
app-project-create .success, app-project-create .error {
  width: 75px;
  height: 75px;
  margin: 0 auto 20px;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-project-create .success {
  background: #8bc24a;
}
app-project-create .error {
  background: #F95738;
}

@media screen and (max-width: 1023px) {
  app-project-create {
    flex-direction: column;
    overflow: scroll;
  }
  app-project-create .info-text {
    width: 100%;
    height: 200px;
    font-size: 45px;
    text-align: center;
  }
  app-project-create .info-text br {
    display: none;
  }
  app-project-create .info-text .inner {
    align-items: flex-end;
  }
  app-project-create .info-text .sub-text {
    font-size: 30px;
    margin: 8px 0 25px;
  }
  app-project-create .wrapper {
    height: 80vh;
    width: 100%;
  }
  app-project-create .wrapper .inner {
    padding-top: 0;
    height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
  }
  app-project-create .wrapper #step-three {
    flex: 1;
  }
  app-project-create .wrapper .item {
    width: 368px;
  }
  app-project-create .button-wrapper {
    margin: 30px 0;
  }
  app-project-create .progress-line {
    display: none;
  }
  app-project-create .title {
    padding-top: 30px;
  }
}
@media screen and (max-width: 460px) {
  app-project-create .info-text {
    display: none;
  }
  app-project-create .sum-message .title-status {
    font-weight: normal;
    font-size: 18px;
  }
  app-project-create .sum-message .text {
    font-weight: 400;
    font-size: 14px;
  }
  app-project-create .wrapper {
    margin-top: 20px;
  }
  app-project-create .wrapper .list-item {
    flex-direction: column;
  }
  app-project-create .wrapper .list-item .add {
    padding-top: 0;
    padding-right: 0;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }
  app-project-create .wrapper .list-item .mat-item {
    width: 100%;
    padding: 0;
  }
  app-project-create .wrapper .list-item .remove-control {
    right: 0;
  }
  app-project-create .wrapper .item, app-project-create .wrapper .item-text {
    padding: 0;
  }
  app-project-create .wrapper .item.item-text__margin, app-project-create .wrapper .item-text.item-text__margin {
    margin-top: 30px;
  }
  app-project-create .wrapper .button-wrapper {
    padding: 0;
  }
  app-project-create .wrapper .mat-item {
    padding: 0;
  }
  app-project-create .wrapper .provide-song {
    border: none;
  }
  app-project-create .wrapper .provide-song .user-raw {
    padding: 15px 0 10px 0;
  }
  app-project-create .wrapper .item-document {
    padding: 0;
  }
  app-project-create .wrapper .inner {
    height: 100%;
    max-width: calc(100% - 20px);
    width: 100%;
    padding: 70px 14px 20px;
  }
  app-project-create .wrapper .stickFooter {
    height: calc(100vh - 84px);
  }
  app-project-create .wrapper .item {
    width: 100%;
  }
  app-project-create .wrapper .input-text-item.mat-item {
    margin-top: 0;
  }
  app-project-create .wrapper .input-text-item.input-text-item {
    margin-top: 20px;
  }
  app-project-create .wrapper .agreement {
    padding: 20px 0;
  }
  app-project-create .select-share .item {
    width: 60px;
  }
  app-project-create .select-wrapper {
    width: calc(100% - 201px);
  }
  app-project-create .progress-line {
    display: none;
  }
  app-project-create .title {
    display: none;
  }
  app-project-create .mobile-steps {
    display: flex;
  }
  app-project-create .payment-item {
    max-width: calc(50% - 20px);
  }
}
@media screen and (max-width: 320px) {
  app-project-create .wrapper {
    margin-top: 20px;
  }
  app-project-create .wrapper .button-wrapper {
    font-size: 13px;
  }
  app-project-create .wrapper .button-wrapper .cancel {
    font-size: 13px;
  }
  app-project-create .wrapper .button-wrapper .button.yellow {
    font-size: 13px;
    width: 180px;
  }
}
app-nft-wizard {
  display: flex;
  min-height: 100vh;
}
app-nft-wizard .button {
  font-size: 16px;
}
app-nft-wizard .flow-info-text {
  font-weight: 900;
  line-height: 1;
  font-size: 69px;
  background-image: url("../../../assets/images/wizard.jpg");
}
app-nft-wizard .flow-info-text .inner {
  font-size: 69px;
  background: linear-gradient(to left, #134D66 0%, rgba(19, 77, 102, 0.01) 89.43%);
}
app-nft-wizard .kyc-validation {
  width: 460px;
}
app-nft-wizard .kyc-validation button {
  text-transform: uppercase;
  margin: 0 auto;
}
app-nft-wizard .kyc-validation p {
  margin-bottom: 20px;
}
app-nft-wizard .nft-wizard__view-token {
  max-width: 210px;
  margin: 10px auto 0;
}
app-nft-wizard app-ubo .ubo__form {
  flex-direction: column;
  align-items: center;
  border-top: 1px dashed #a8c1d3;
  border-bottom: none;
  padding: 10px 0;
}
app-nft-wizard app-ubo .form__col {
  width: 100%;
  padding: 0;
}
app-nft-wizard .upload-wrapper {
  margin-bottom: 20px;
}
app-nft-wizard .step__ubo {
  max-width: 416px;
  margin: 38px 0;
}
app-nft-wizard .ubos__tilte {
  padding: 10px 0;
}
app-nft-wizard .ubo {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  max-width: 416px;
  gap: 20px;
}
app-nft-wizard .ubo .mat-progress-spinner circle,
app-nft-wizard .ubo .mat-spinner circle {
  stroke: #fff !important;
}
app-nft-wizard .ubo-wrapper {
  height: 0;
  overflow-y: scroll;
  padding: 0 20px;
}
app-nft-wizard .ubo-wrapper.addUBO {
  height: 600px;
}
app-nft-wizard .loader-picture__image {
  width: 100%;
}
app-nft-wizard app-checkbox {
  margin-right: 10px;
}
app-nft-wizard .shareholders__ubos {
  width: 100%;
}
app-nft-wizard .add-shareholder {
  display: flex;
  margin: 20px 0;
  justify-content: flex-end;
}
app-nft-wizard .add-shareholder a {
  padding-right: 20px;
  text-decoration: none;
}
app-nft-wizard .wizard__button {
  width: 264px;
  position: relative;
  margin: 0 auto;
  font-size: 16px;
}
app-nft-wizard .wizard__button mat-spinner {
  position: absolute;
  left: 10px;
  top: 10px;
}
app-nft-wizard .right-part {
  width: 55vw;
  background: #fff;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-nft-wizard .steps-container {
  border-top: 1px dashed #a8c1d3;
  border-bottom: 1px dashed #a8c1d3;
  padding: 40px 0;
  max-width: 416px;
}
app-nft-wizard .summary-info__checkbox-list {
  border-top: 1px dashed #a8c1d3;
  border-bottom: 1px dashed #a8c1d3;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
app-nft-wizard .summary-info__checkbox-list.checkbox-list--size {
  width: 416px;
}
app-nft-wizard .summary-info__checkbox-list.checkbox-list--pos {
  margin: 20px 0;
  padding: 20px 60px;
}
app-nft-wizard .summary-info .checkbox-container1 {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
app-nft-wizard .summary-info .checkbox-container1.hide {
  display: none;
}
app-nft-wizard .summary-info .checkbox-container1 .checkbox-text {
  font-weight: 500;
  white-space: nowrap;
  color: #8089a0;
}
app-nft-wizard .summary-info .block-title {
  margin-top: 10px;
  margin-bottom: 20px;
}
app-nft-wizard .summary-info .button {
  font-size: 16px;
  line-height: 50px;
}
app-nft-wizard .step {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
}
app-nft-wizard .step-number {
  align-items: center;
  width: 100%;
  justify-content: center;
  display: flex;
}
app-nft-wizard .step-number .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f2f7fb;
  box-shadow: inset 0 0 8px rgba(144, 170, 190, 0.2);
}
app-nft-wizard .step-number p {
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #8089a0;
}
app-nft-wizard .nft-wizard__text {
  color: #8089a0;
  max-width: 370px;
  margin: 0 auto;
  text-align: center;
}
app-nft-wizard .create-wallet .steps-container {
  margin-top: 20px;
  margin-bottom: 20px;
}
app-nft-wizard .create-wallet button {
  margin-top: 40px;
}
app-nft-wizard .kyc-validation .block-title {
  max-width: 460px;
}
app-nft-wizard .new-wallet-block {
  display: flex;
  flex-flow: column;
  width: 416px;
  height: 256px;
  justify-content: space-evenly;
  gap: 15px;
}
app-nft-wizard .decision-buttons {
  display: flex;
  align-items: center;
  gap: 51px;
  width: 100%;
  padding-left: 40px;
}
app-nft-wizard .decision-buttons .left-button {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  /* blue2 */
  color: #5aa2e5;
}
app-nft-wizard .decision-buttons .right-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 265px;
}
app-nft-wizard .success-background {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: url("../../../assets/images/nftwizard/greendelipse.svg");
  width: 75px;
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
app-nft-wizard .succes-finger {
  background: url("../../../assets/images/nftwizard/fingerup.svg");
  background-repeat: no-repeat;
  width: 49px;
  height: 64.46px;
  margin-left: 10px;
  margin-top: 15px;
}
app-nft-wizard .icon-lock {
  background: url("../../../assets/images/nftwizard/lock.svg");
  padding-right: 55px;
  background-repeat: no-repeat;
  width: 49px;
  height: 64.46px;
}
app-nft-wizard .step1-completed {
  background: url("../../../assets/images/nftwizard/done.svg");
  width: 30px;
  height: 30px;
}
app-nft-wizard .cancel {
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
}
app-nft-wizard .button-wrapper.nft-button-wrapper {
  align-self: center;
  display: flex;
  gap: 64px;
  align-items: center;
  justify-content: center;
}
app-nft-wizard .button-wrapper.nft-button-wrapper button {
  width: 213px;
}
app-nft-wizard .button-wrapper.nft-button-wrapper a {
  text-decoration: none;
}
app-nft-wizard .step-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
app-nft-wizard .cancel {
  align-self: flex-start;
  align-items: center;
}
app-nft-wizard .cancel .lnr {
  padding-right: 10px;
}
app-nft-wizard .spinner__button {
  position: relative;
}
app-nft-wizard .spinner__button mat-spinner {
  position: absolute;
  left: 10px;
  top: 10px;
}
app-nft-wizard .item-document,
app-nft-wizard .file-doc__input {
  width: 177px;
  height: 110px;
  background: #eefcff;
  position: relative;
  border: 2px dashed #5AA2E5;
  overflow: hidden;
}
app-nft-wizard .item-document:last-child,
app-nft-wizard .file-doc__input:last-child {
  margin-left: 15px;
}
app-nft-wizard .item-document label,
app-nft-wizard .file-doc__input label {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
app-nft-wizard .item-document span,
app-nft-wizard .item-document .fa-camera,
app-nft-wizard .file-doc__input span,
app-nft-wizard .file-doc__input .fa-camera {
  color: #5AA2E5;
}
app-nft-wizard .item-document img,
app-nft-wizard .file-doc__input img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
app-nft-wizard .item-document span,
app-nft-wizard .file-doc__input span {
  font-size: 14px;
}
app-nft-wizard .item-document .fa-camera,
app-nft-wizard .file-doc__input .fa-camera {
  font-size: 20px;
  line-height: 23px;
}
app-nft-wizard .file-doc__input {
  width: auto;
}
app-nft-wizard .file-doc__input:last-child {
  margin-left: 0;
}
app-nft-wizard .form {
  border-top: 1px dashed #C4C4C4;
}
app-nft-wizard .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-nft-wizard .block-title {
  font-weight: 900;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
}

@media screen and (max-width: 1023px) {
  app-nft-wizard {
    flex-direction: column;
  }
  app-nft-wizard .ubo__form {
    border-top: none;
  }
  app-nft-wizard .flow-info-text .sub-text {
    display: none;
  }
  app-nft-wizard .flow-info-text .sub-text2 {
    display: block !important;
    font-weight: 900;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  }
  app-nft-wizard .right-part {
    flex-grow: 1;
    width: 100%;
    align-items: flex-start;
  }
  app-nft-wizard .new-wallet-block {
    border-top: none;
    border-bottom: 1px dashed #a8c1d3;
  }
  app-nft-wizard .summary-info__checkbox-list.checkbox-list--size {
    width: 100%;
  }
  app-nft-wizard .success-background {
    margin-top: 100px;
  }
  app-nft-wizard .flow-progress-line {
    display: none !important;
  }
  app-nft-wizard .steps-container {
    border-top: none;
  }
  app-nft-wizard .ubo h3 {
    display: none;
  }
  app-nft-wizard .ubo .shareholders__ubos {
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  app-nft-wizard .kyc-button-wrapper {
    width: 100%;
    flex-direction: column;
  }
  app-nft-wizard .kyc-button-wrapper .button-readable {
    width: 100% !important;
    margin-top: 20px;
  }
  app-nft-wizard .nft-wizard .button-upload {
    margin-top: 20px;
    width: 100% !important;
  }
  app-nft-wizard .nft-wizard .crop__upload {
    width: 100% !important;
  }
  app-nft-wizard .nft-wizard .other-photo {
    margin-top: 20px;
  }
  app-nft-wizard .steps-container {
    margin-top: 0;
  }
  app-nft-wizard app-upload-file-popup .inner {
    padding: 30px 18px !important;
    max-width: 100vw !important;
    height: 100%;
  }
  app-nft-wizard app-upload-file-popup .mobile-button {
    justify-content: space-between;
  }
  app-nft-wizard app-upload-file-popup .mobile-button .button {
    width: auto !important;
    min-width: auto !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
  }
  app-nft-wizard app-upload-file-popup .mobile-button .button.take-photo-btn {
    width: 115px !important;
  }
  app-nft-wizard app-upload-file-popup .mobile-button .button:first-child {
    padding: 0 10px;
  }
  app-nft-wizard app-upload-file-popup .mobile-button .button.isActive {
    background: #5AA2E5;
    color: #fff;
  }
  app-nft-wizard app-upload-file-popup .mobile-button .button.disabled {
    opacity: 0.3;
    border: 2px solid #949494;
    cursor: default;
  }
  app-nft-wizard app-upload-file-popup .mobile-button label {
    justify-content: center;
    padding: 0 10px;
    white-space: nowrap;
    margin-left: 14px;
  }
  app-nft-wizard app-upload-file-popup .item-document {
    width: 100%;
    margin-top: 16px;
  }
  app-nft-wizard app-upload-file-popup .item-document:last-child {
    margin-left: 0;
  }
  app-nft-wizard app-upload-file-popup .popup-wrapper {
    width: 100% !important;
    max-height: 100%;
    height: 100%;
    top: 0;
  }
  app-nft-wizard app-upload-file-popup .button-wrapper {
    border: none;
    padding-top: 0;
  }
  app-nft-wizard app-upload-file-popup .button-wrapper button {
    max-width: 100%;
  }
  app-nft-wizard app-upload-file-popup .mobile-preview {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0 16px;
    background: white;
  }
  app-nft-wizard app-upload-file-popup .mobile-preview label {
    min-width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  app-nft-wizard app-upload-file-popup .mobile-preview img {
    width: 100%;
  }
  app-nft-wizard app-upload-file-popup .mobile-preview .img-block {
    max-width: 80%;
    margin: 0 auto;
    flex: 1;
    width: 100%;
    padding: 20px 0;
    text-align: center;
  }
  app-nft-wizard app-upload-file-popup .mobile-preview .button-wrapper {
    margin-bottom: 40px;
  }
  app-nft-wizard app-upload-file-popup .other-photo {
    line-height: 48px;
    margin-top: 16px;
  }
  app-nft-wizard app-upload-file-popup .form {
    padding: 0;
  }
  app-nft-wizard app-upload-file-popup .block-title {
    font-size: 18px;
  }
  app-nft-wizard app-upload-file-popup .form-wrapper {
    padding: 20px 0 0 0;
  }
  app-nft-wizard app-upload-file-popup .wrapper-upload {
    flex-direction: column;
    padding-bottom: 20px;
  }
  app-nft-wizard app-upload-file-popup .mobile-upload {
    display: block;
  }
  app-nft-wizard app-upload-file-popup .desktop-upload {
    display: none;
  }
}
@media screen and (max-width: 460px) {
  app-nft-wizard .nft-wizard .kyc-validation__upload {
    width: 100%;
  }
  app-nft-wizard .flow-progress-line {
    display: none !important;
  }
  app-nft-wizard .create-wallet, app-nft-wizard .kyc-validation, app-nft-wizard .summary-info {
    align-self: baseline;
    padding: 0 20px;
  }
  app-nft-wizard .create-wallet .steps-container, app-nft-wizard .kyc-validation .steps-container, app-nft-wizard .summary-info .steps-container {
    margin-top: 0;
  }
  app-nft-wizard .create-wallet .nft-wizard__text, app-nft-wizard .kyc-validation .nft-wizard__text, app-nft-wizard .summary-info .nft-wizard__text {
    text-align: center;
    font-size: 14px;
  }
  app-nft-wizard .summary-info {
    width: 100%;
  }
  app-nft-wizard .summary-info .block-title {
    font-size: 16px;
    margin-bottom: 20px;
  }
  app-nft-wizard .summary-info .checkbox-container1 {
    margin-bottom: 10px;
  }
  app-nft-wizard .summary-info .nft-wizard__text {
    font-size: 14px;
    margin-bottom: 20px;
  }
  app-nft-wizard .summary-info .summary-info__checkbox-list.checkbox-list--pos {
    padding: 20px;
    margin: 0 0 20px 0;
  }
  app-nft-wizard .kyc-validation {
    width: 100%;
  }
  app-nft-wizard .kyc-validation .block-title {
    font-size: 16px;
  }
  app-nft-wizard .button-wrapper.nft-button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    gap: 0;
  }
  app-nft-wizard .button-wrapper.nft-button-wrapper .button {
    padding: revert;
    width: auto;
  }
  app-nft-wizard .button-wrapper.nft-button-wrapper a {
    display: flex;
    flex-grow: 1;
    text-decoration: none;
    justify-content: center;
  }
  app-nft-wizard .add-shareholder {
    margin: 0;
  }
  app-nft-wizard .right-part {
    flex-grow: 1;
    align-items: center;
  }
  app-nft-wizard .ubo {
    max-width: -webkit-fill-available;
  }
}
app-artist-song {
  display: flex;
  min-height: 100vh;
  background-color: white;
}
app-artist-song .info-text {
  width: 45vw;
  background: url("../../../assets/images/wizard.jpg") center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  text-align: right;
  font-weight: 900;
  font-size: 69px;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
}
app-artist-song .info-text .inner {
  background: linear-gradient(to left, #134D66 0%, rgba(19, 77, 102, 0.01) 89.43%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
app-artist-song .info-text .sub-text {
  text-transform: none;
  font-size: 56px;
  line-height: 1.1;
}
app-artist-song .wrapper {
  width: 55vw;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-artist-song .wrapper .inner {
  max-width: 70%;
  padding: 100px 0 50px;
  width: 416px;
}
app-artist-song .wrapper .agreement {
  border-bottom: 1px dashed #C4C4C4;
  padding: 20px 0 20px 20px;
}
app-artist-song .wrapper .agreement .agreement-text {
  width: 270px;
  padding-left: 10px;
}
app-artist-song .wrapper .button-wrapper {
  margin-top: 30px;
  padding: 0 25px;
  justify-content: space-between;
  align-items: center;
}
app-artist-song .wrapper .button-wrapper .button.yellow {
  width: 210px;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
}
app-artist-song .wrapper .button-wrapper .button.transparent {
  min-width: 100px;
  width: 100px;
  font-size: 16px;
  font-weight: 700;
}
app-artist-song .wrapper .button-wrapper .cancel {
  color: #5AA2E5;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  width: 50%;
}
app-artist-song .wrapper .button-wrapper .cancel .fas {
  margin-right: 10px;
}
app-artist-song .wrapper .item-document {
  display: flex;
}
app-artist-song .wrapper .item-document mat-spinner {
  margin-right: -40px;
  margin-left: 10px;
  margin-top: 15px;
}
app-artist-song .wrapper .cancel-message.cancel {
  text-align: center;
  width: 100%;
}
app-artist-song .wrapper .title {
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 25px;
  text-align: center;
}
app-artist-song .wrapper .item-document {
  padding: 0 25px;
  margin-top: 10px;
}
app-artist-song .wrapper .upload-section {
  width: 100%;
  height: 50px;
}
app-artist-song .wrapper .upload-section:last-child {
  margin-left: 0;
}
app-artist-song .wrapper .upload-section label {
  flex-direction: row;
}
app-artist-song .wrapper .upload-section .fa-music {
  font-size: 20px;
  padding-right: 18px;
}
app-artist-song .wrapper .provide-song {
  border-bottom: 1px dashed #A8C1D3;
  margin: 15px 25px 15px;
}
app-artist-song .wrapper .provide-song .user-raw {
  padding: 15px 25px 15px;
}
app-artist-song .wrapper .item {
  color: #8089A0;
  padding: 0 25px;
  margin-top: 30px;
}
app-artist-song .wrapper .item + .social-item .mat-item {
  margin-top: 15px;
  padding: 0 25px 0 58px;
}
app-artist-song .wrapper .mat-item {
  width: 100%;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-artist-song .wrapper .mat-item .far, app-artist-song .wrapper .mat-item .fas {
  color: #5AA2E5;
}
app-artist-song .wrapper .input-text-item.mat-item {
  margin-top: 10px;
}
app-artist-song .wrapper .item-align-left {
  align-items: flex-start;
}
app-artist-song .wrapper .span-label {
  color: #8089A0;
}
app-artist-song .wrapper .social-item {
  position: relative;
}
app-artist-song .wrapper .social-item .mat-item {
  margin-top: 2px;
  padding: 0 25px 0 58px;
}
app-artist-song .wrapper .social-links {
  margin-top: 20px;
}
app-artist-song .wrapper .followers-container {
  position: absolute;
  left: 20px;
}
app-artist-song .wrapper .user {
  color: #000;
  font-size: 18px;
  line-height: 32px;
  font-weight: bold;
}
app-artist-song .wrapper .button {
  width: 100%;
  text-transform: uppercase;
}
app-artist-song .sum-message .title-status {
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 25px;
  text-align: center;
}
app-artist-song .sum-message .text {
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  color: #8089A0;
}
app-artist-song .icon-cancel {
  cursor: pointer;
}
app-artist-song .file-name {
  font-weight: 600;
}
app-artist-song .file-name .icon-cancel {
  padding-right: 10px;
}
app-artist-song .file-name .fa-times-circle {
  color: #F95738;
}
app-artist-song .success, app-artist-song .error {
  width: 75px;
  height: 75px;
  margin: 0 auto 20px;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-artist-song .success {
  background: #8bc24a;
}
app-artist-song .error {
  background: #F95738;
}
app-artist-song button:disabled {
  cursor: initial;
}
app-artist-song .links {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 28px;
}

.m25-left {
  margin-left: 25px;
}

@media screen and (max-width: 1023px) {
  app-artist-song {
    flex-direction: column;
    overflow: scroll;
  }
  app-artist-song .info-text {
    width: 100%;
    height: 200px;
    font-size: 45px;
    text-align: center;
  }
  app-artist-song .info-text br {
    display: none;
  }
  app-artist-song .info-text .inner {
    align-items: flex-end;
  }
  app-artist-song .info-text .sub-text {
    font-size: 30px;
    margin: 8px 0 25px;
  }
  app-artist-song .wrapper {
    width: 100%;
    height: 100vh;
  }
  app-artist-song .wrapper .inner {
    padding-top: 0;
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
  }
  app-artist-song .wrapper #step-three {
    flex: 1;
  }
  app-artist-song .wrapper .item {
    width: 368px;
  }
  app-artist-song .progress-line {
    display: none;
  }
  app-artist-song .title {
    padding-top: 30px;
  }
}
@media screen and (max-width: 460px) {
  app-artist-song .info-text {
    display: none;
  }
  app-artist-song .sum-message .title-status {
    font-weight: normal;
    font-size: 18px;
  }
  app-artist-song .sum-message .text {
    font-weight: 400;
    font-size: 14px;
  }
  app-artist-song .wrapper {
    margin: 20px 0;
    height: 100vh;
  }
  app-artist-song .wrapper .mat-item, app-artist-song .wrapper .item, app-artist-song .wrapper .item-document, app-artist-song .wrapper .button-wrapper {
    padding: 0 14px;
  }
  app-artist-song .wrapper .provide-song {
    border: none;
  }
  app-artist-song .wrapper .provide-song .user-raw {
    padding: 15px 14px 0;
  }
  app-artist-song .wrapper .followers-container {
    left: 14px;
  }
  app-artist-song .wrapper .inner {
    height: 100%;
    max-width: calc(100% - 20px);
    width: 100%;
    padding: 70px 0 20px 0;
  }
  app-artist-song .wrapper .social-item .mat-item {
    padding: 0 14px 0 58px;
  }
  app-artist-song .wrapper .stickFooter {
    height: calc(100vh - 84px);
  }
  app-artist-song .wrapper .item {
    width: 100%;
  }
  app-artist-song .select-share .item {
    width: 60px;
  }
  app-artist-song .select-wrapper {
    width: calc(100% - 201px);
  }
  app-artist-song .progress-line {
    display: none;
  }
  app-artist-song .title {
    display: none;
  }
  app-artist-song .mobile-steps {
    display: flex;
  }
  app-artist-song .payment-item {
    max-width: calc(50% - 20px);
  }
  app-artist-song .m25-left {
    margin-left: 10px;
  }
  app-artist-song .wrapper .provide-song {
    margin: 15px 10px 15px;
  }
}
@media screen and (max-width: 320px) {
  app-artist-song .wrapper {
    margin-top: 20px;
  }
  app-artist-song .wrapper .button-wrapper {
    font-size: 13px;
  }
  app-artist-song .wrapper .button-wrapper .cancel {
    font-size: 13px;
  }
  app-artist-song .wrapper .button-wrapper .button.yellow {
    font-size: 13px;
    width: 180px;
  }
}
app-navigation .hamburger {
  cursor: pointer;
  display: none;
  position: absolute;
  left: 0;
  top: 8px;
  padding: 15px;
  color: #fff;
  z-index: 2;
}
app-navigation .hamburger .hamburger-inner {
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  app-navigation .hamburger .hamburger-inner {
    width: 20px;
    height: 3px;
    top: 6px;
  }
}
app-navigation .hamburger .hamburger-inner::before {
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  app-navigation .hamburger .hamburger-inner::before {
    width: 20px;
    height: 3px;
    top: 10px;
  }
}
app-navigation .hamburger .hamburger-inner::after {
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  app-navigation .hamburger .hamburger-inner::after {
    width: 20px;
    height: 3px;
  }
}
app-navigation .hamburger.is-active .hamburger-inner {
  background-color: #fff;
}
app-navigation .hamburger.is-active .hamburger-inner::before {
  background-color: #fff;
}
app-navigation .hamburger.is-active .hamburger-inner::after {
  background-color: #fff;
}
@media screen and (max-width: 1199px) {
  app-navigation .hamburger {
    display: block;
  }
}
app-navigation .navigation-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
app-navigation .navigation-list .navigation__item {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 15px;
  color: #fff;
  padding: 0 26px;
  font-weight: 500;
  line-height: 74px;
  cursor: pointer;
  position: relative;
}
app-navigation .navigation-list .navigation__item .navigation-list__link {
  opacity: 0.7;
}
app-navigation .navigation-list .navigation__item.active .navigation-list__link {
  opacity: 1;
}
app-navigation .navigation-list .navigation__item.active:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 14px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 59px;
  height: 3px;
  background: #FFA800;
}
@media screen and (max-width: 1490px) {
  app-navigation .navigation-list .navigation__item {
    padding: 0 18px;
  }
}
@media screen and (max-width: 1370px) {
  app-navigation .navigation-list .navigation__item {
    padding: 0 10px;
  }
}
@media screen and (max-width: 1490px) {
  app-navigation .navigation-list {
    width: 100%;
  }
}
@media screen and (max-width: 1199px) {
  app-navigation .navigation-list {
    display: none;
  }
}
app-navigation .dropdown-menu {
  position: absolute;
  background-color: #fff;
  left: 6px;
  transition: all 0.2s ease-in;
  height: 0;
  overflow: hidden;
  right: 6px;
  border-radius: 7px;
  top: 60px;
}
app-navigation .sub-menu-mobile {
  border-top: 1px dashed #C4C4C4;
}
app-navigation .sub-menu-mobile .sub-menu-item-mobile {
  padding: 20px 40px;
}
app-navigation .sub-menu-mobile .sub-menu-item-mobile:last-child {
  border-top: 1px dashed #C4C4C4;
}
app-navigation .navigation-mobile-list.active {
  transition: all 0.2s ease-in;
  height: 285px;
}
@media screen and (max-width: 767px) {
  app-navigation .navigation-mobile-list.active {
    height: 80px;
  }
}
app-navigation .navigation-mobile-list__link {
  padding: 20px 28px;
  border-bottom: 1px dashed #c4c4c4;
  color: #8089a0;
}
@media screen and (max-width: 767px) {
  app-navigation .navigation-mobile-list__link {
    padding: 7px 18px;
    font-size: 10px;
  }
}
app-navigation .navigation-mobile-list__item .navigation-item:hover .sub-menu {
  height: 88px;
  display: block;
}
app-navigation .fas {
  border: 10px solid transparent;
  cursor: pointer;
  transition: all 0.25s ease-out 0s;
}
app-navigation .fas.opened {
  transform: rotate(-180deg);
}
app-navigation .sub-menu {
  position: absolute;
  display: none;
  top: 65px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 0 3px #8089A0;
  left: 0;
  overflow: hidden;
  width: 240px;
}
app-navigation .sub-menu .sub-menu-item {
  padding: 0 20px;
  color: #8089A0;
  line-height: 44px;
  border-top: 1px dashed #C4C4C4;
}
app-navigation .sub-menu .sub-menu-item:first-child {
  border-top: none;
}
app-navigation .sub-menu .sub-menu-item:hover {
  color: #fff;
  background: #FFA800;
}

app-checkbox {
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  line-height: 1;
  display: inline-flex;
  align-items: center;
}
app-checkbox.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
app-checkbox .hidden-button {
  display: none;
}
app-checkbox .hidden-button:not(:checked) + .button .check-mark {
  background: transparent;
}
app-checkbox .hidden-button:checked + .checkbox {
  background: #5AA2E5;
  border: 1px solid #5AA2E5;
}
app-checkbox .checkbox {
  width: 30px;
  height: 30px;
  overflow: hidden;
  background: white;
  border: 1px solid #949494;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
app-checkbox .checkbox .check-mark {
  position: absolute;
  top: 8px;
  left: 6px;
  width: 14px;
  height: 11px;
  background: url("/assets/images/check.png");
}
app-checkbox .checkbox .check-mark-grey {
  position: absolute;
  top: 8px;
  left: 6px;
  width: 14px;
  height: 11px;
  background: url("/assets/images/check-grey.png");
}
app-checkbox .toggle-button__container {
  cursor: pointer;
  position: relative;
  margin: 0 0.5rem;
}
app-checkbox .mode-toggle__input {
  -webkit-appearance: none;
  -moz-appearance: none;
}
app-checkbox .mode-toggle__bg {
  height: 18px;
  width: 36px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  display: inline-block;
  transition: background-color 300ms linear;
}
app-checkbox .mode-toggle__circle {
  height: 14px;
  width: 14px;
  background-color: #fff;
  position: absolute;
  top: 2px;
  border-radius: 50%;
  transition: left 300ms linear;
  left: 2px;
}
app-checkbox .mode-toggle__circle-checked {
  background-color: #fff;
  left: 20px;
}
app-checkbox .mode-toggle__bg-checked {
  background-color: #8bc24a;
}

app-music-item-list .music-cards {
  display: flex;
  flex-wrap: wrap;
  max-width: 1350px;
  justify-content: center;
  margin: 0 auto;
}
app-music-item-list .music-cards__item:hover {
  z-index: 10;
}
app-music-item-list .empty-item {
  width: 310px;
  margin: 0 12px;
}
app-music-item-list .fa-music {
  font-size: 12px;
  background: white;
  position: absolute;
  top: 10px;
  left: 10px;
}
app-music-item-list .lnr-calendar-full {
  cursor: auto;
}
app-music-item-list .fa-question-circle {
  font-size: 16px;
  color: #5AA2E5;
  cursor: pointer;
}
app-music-item-list .fa-question-circle:hover + .timer-hint .tooltip {
  display: block;
  right: -20px;
  top: -200px;
}
app-music-item-list .image {
  display: block;
  height: 240px;
  width: auto;
}
app-music-item-list .tooltip {
  text-align: left;
  right: -20px;
  top: -120px;
  padding: 14px;
  width: 250px;
}
app-music-item-list .tooltip:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -6px;
  right: 5px;
  width: 0;
  height: 0;
  border-top: 7px solid rgba(0, 0, 0, 0.7);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: none;
}
app-music-item-list .tooltip.tooltip-wrap:after {
  right: 160px;
}
app-music-item-list .wrapper-tooltip {
  display: block;
  position: relative;
  width: 0;
  height: 100%;
}
app-music-item-list .action-footer-news {
  justify-content: flex-end;
  padding: 0;
  cursor: auto;
  align-items: center;
  flex-direction: row;
}
app-music-item-list .social-wrapper {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
}
app-music-item-list .followers-container {
  width: 30px;
  margin: 0 4px;
  height: 28px;
  justify-content: flex-end;
  word-wrap: normal;
}
app-music-item-list .followers-container:last-child {
  margin-right: 0;
}
app-music-item-list .followers-container a {
  display: block;
}
app-music-item-list .status-footer {
  padding: 0 20px;
  height: 77px;
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-top: 1px dashed #A8C1D3;
  font-weight: 900;
  font-size: 24px;
}
app-music-item-list .status-footer .button {
  width: 240px;
  margin: 0 auto;
  font-size: 16px;
}
app-music-item-list .status-footer .status-declined {
  font-weight: bold;
}
app-music-item-list .play-button {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-music-item-list .play-button:hover {
  background: rgba(0, 0, 0, 0.7);
}
app-music-item-list .play-button .fa-play {
  font-size: 12px;
}

@media screen and (max-width: 1400px) {
  app-music-item-list .empty-item {
    margin: 0 10px 0;
  }
  app-music-item-list .music-cards {
    max-width: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  app-music-item-list .music-cards__item {
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px) {
  .card {
    margin: 0 auto;
  }
  .play-button {
    position: absolute;
    top: 45%;
  }
  .play-button.desktop {
    display: none;
  }
  .play-button.mobile {
    display: flex;
  }
}
@media screen and (max-width: 320px) {
  .card-outer {
    width: 290px;
  }
  .card__background {
    width: 290px;
  }
}
app-main-slider .section-slider {
  width: 100%;
  position: relative;
}
@media screen and (max-width: 460px) {
  app-main-slider .section-slider {
    margin-top: 72px;
  }
}
app-main-slider .section-slider .slick-slider {
  width: 100%;
  margin: auto;
  height: 555px;
}
@media screen and (max-width: 767px) {
  app-main-slider .section-slider .slick-slider {
    height: 400px;
  }
}
app-main-slider .section-slider .market-container {
  display: flex;
  order: 3;
}
app-main-slider .section-slider .slick-dots {
  bottom: 5px !important;
}
app-main-slider .section-slider .slick-dots li button:before {
  color: #fff !important;
  opacity: 0.7;
  font-size: 12px;
}
app-main-slider .section-slider .slick-dots li.slick-active button:before {
  color: #FFA800 !important;
  font-size: 15px;
  opacity: 1;
}
app-main-slider .section-slider .image-wrapper {
  height: 556px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  position: relative;
}
@media screen and (max-width: 767px) {
  app-main-slider .section-slider .image-wrapper {
    height: 400px;
  }
  app-main-slider .section-slider .image-wrapper.mobile {
    display: block;
  }
  app-main-slider .section-slider .image-wrapper.desktop {
    display: none;
  }
  app-main-slider .section-slider .image-wrapper img {
    width: 100%;
    height: 400px;
    min-height: 400px;
  }
}
app-main-slider .section-slider .slider-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-main-slider .section-slider .slider-title {
  margin-bottom: 20px;
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
@media (max-width: 767px) {
  app-main-slider .section-slider .slider-title {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
app-main-slider .section-slider .slider-title span {
  font-weight: 900;
}
app-main-slider .section-slider .slider-subtitle {
  margin-bottom: 20px;
  font-size: 45px;
  font-weight: 900;
  line-height: normal;
  color: #fff;
  text-align: center;
}
@media (max-width: 767px) {
  app-main-slider .section-slider .slider-subtitle {
    font-size: 25px;
  }
}
app-main-slider .slider-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
app-main-slider .slider-wrapper .slider-title {
  order: 1;
}
app-main-slider .slider-wrapper p {
  order: 2;
}
app-main-slider .slider-wrapper .button {
  order: 3;
}
app-main-slider .slider-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 357px;
  font-size: 21px;
  height: 60px;
  margin-top: 20px;
}
app-main-slider .image-wrapper.mobile {
  height: 0;
  overflow: hidden;
}
app-main-slider .image-wrapper.desktop {
  display: block;
}
app-main-slider .image {
  height: 556px;
  width: 100%;
  min-height: 556px;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  app-main-slider .section-slider .slider-title {
    font-size: 29px;
    line-height: 40px;
  }
  app-main-slider .section-slider p {
    font-size: 18px;
  }
  app-main-slider .slider-btn {
    width: 270px;
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  app-main-slider .market-container {
    flex-direction: column;
    margin-top: 20px;
  }
  app-main-slider .slider-wrapper {
    padding: 0 10px;
    margin-bottom: 20px;
  }
  app-main-slider .slider-wrapper .slider-title {
    order: 1;
  }
  app-main-slider .slider-wrapper .button {
    order: 3;
  }
  app-main-slider .slider-wrapper p {
    text-align: center;
    line-height: 18px;
    order: 2;
  }
  app-main-slider .slider-btn {
    margin-top: 10px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    width: 200px;
  }
  app-main-slider .section-slider .slider-title {
    font-size: 14px;
    line-height: 34px;
    margin-top: 18px;
    text-align: center;
  }
  app-main-slider .section-slider .slider-text {
    align-items: center;
  }
  app-main-slider .section-slider p {
    text-align: center;
  }
}
@media screen and (max-width: 320px) {
  app-main-slider .section-slider .slider-title {
    font-size: 24px;
    line-height: 30px;
  }
  app-main-slider .section-slider p {
    font-size: 16px;
    line-height: 24px;
  }
  app-main-slider .header {
    padding: 0 5px !important;
  }
  app-main-slider .header .login span {
    padding-right: 5px !important;
  }
  app-main-slider .hamburger {
    left: 10px !important;
  }
}
app-song-preview {
  width: 100%;
  min-width: 320px;
  overflow: hidden;
  z-index: 10;
  left: 0;
}
app-song-preview .wrapper {
  background: rgba(0, 0, 0, 0.8);
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-song-preview .desktop-button {
  margin-left: 30px;
}
app-song-preview .project-image {
  width: 54px;
  height: 54px;
  border: 3px solid #fff;
  margin-right: 30px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
app-song-preview progress::-webkit-progress-value {
  background-color: #FFA800 !important;
}
app-song-preview .share-button {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  width: 230px;
  line-height: 40px;
  height: 40px;
  margin: 0;
}
app-song-preview .share-button.disabled {
  background: #8089A0;
  color: #fff;
  opacity: 0.5;
}
app-song-preview .share-button.mobile {
  display: none;
}
app-song-preview .share-button.desktop {
  display: flex;
  align-items: center;
  justify-content: center;
}
app-song-preview .name {
  width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  color: #fff;
}
app-song-preview .artist {
  color: #8089A0;
}
app-song-preview .progress-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
  width: 500px;
  color: #fff;
}
app-song-preview .progress {
  height: 5px;
  width: 390px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.4);
}
app-song-preview .progress .inner {
  height: 5px;
  border-radius: 5px;
  background: #FFA800;
}
app-song-preview .volume {
  margin-left: 30px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
app-song-preview .muted {
  margin: 0.3rem;
}
app-song-preview .volume-level {
  height: 5px;
  width: 40px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.4);
}
app-song-preview .volume-level .volume-inner {
  height: 5px;
  border-radius: 5px;
  background: #FFA800;
}

@media screen and (max-width: 1024px) {
  app-song-preview .progress-wrapper, app-song-preview .name {
    width: 380px;
  }
  app-song-preview .progress {
    width: 290px;
  }
}
@media screen and (max-width: 767px) {
  app-song-preview {
    padding-top: 5px;
    height: 93px;
  }
  app-song-preview .project-image {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  app-song-preview .progress-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
  app-song-preview .progress-wrapper .played, app-song-preview .progress-wrapper .total {
    display: none;
  }
  app-song-preview .progress-wrapper .progress {
    border-radius: 0;
    width: 100%;
  }
  app-song-preview .name {
    width: calc(100vw - 140px);
    text-align: center;
  }
  app-song-preview .share-button.mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }
  app-song-preview .share-button.desktop {
    display: none;
  }
}
@media screen and (max-width: 460px) {
  app-song-preview .name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 auto 8px;
  }
}
app-notification-info-popup .inner {
  width: 560px;
  max-width: 90vw;
  position: relative;
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  app-notification-info-popup .inner {
    padding: 40px 20px;
  }
}
app-notification-info-popup .popup-content {
  position: relative;
  color: #8089A0;
}
app-notification-info-popup .popup-title {
  font-size: 21px;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
}
app-notification-info-popup .popup-sub-title {
  font-weight: 400;
  font-size: 15px;
  text-align: center;
}
app-notification-info-popup .popup-content .popup-button {
  margin: 15px auto 0;
  width: 200px;
  min-width: auto;
  padding: 0 15px;
  font-weight: 500;
  font-size: 16px;
}

app-calculation {
  display: flex;
}
app-calculation input::-webkit-input-placeholder { /* WebKit browsers */
  font-size: 22px;
  font-weight: 900;
  text-align: center;
}
app-calculation input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: 22px;
  text-align: center;
}
app-calculation .lnr {
  cursor: inherit;
}
app-calculation .inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-calculation .input-box, app-calculation .bid__reminder {
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}
app-calculation .bid__reminder {
  margin-top: 15px;
  margin-bottom: 0;
}
app-calculation .button.disable {
  opacity: 0.6;
  cursor: pointer;
}
app-calculation .select-share {
  margin-bottom: 10px;
}
app-calculation #bid-closed__button-join {
  margin-top: 15px;
}
app-calculation .bid-closed__remind-submitted {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
app-calculation .bid-closed__remind-submitted p {
  margin-top: 15px;
  font-weight: 900;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
}
app-calculation .bid-closed__remind-submitted #bid-closed__button-explore {
  margin-top: 15px;
}
app-calculation .button.button-explore {
  margin-top: 15px !important;
  margin-bottom: 0 !important;
}
app-calculation .bid__text {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #8089A0;
}
app-calculation .bid__text .link-blue {
  font-size: 12px;
}
app-calculation .bid-closed {
  width: 330px;
  min-height: 202px;
  position: absolute;
  padding: 15px;
  background: #DFEBF4;
  box-shadow: 0 2px 4px rgba(144, 170, 190, 0.5);
  border-radius: 7px;
  top: 50px;
}
app-calculation .bid-closed__sold-out {
  width: 220px;
  height: 50px;
  background: #6182A8;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  line-height: 50px;
  text-align: center;
  font-size: 15px;
  margin: 15px auto;
}
app-calculation .bid-closed__text {
  font-weight: 900;
  font-size: 18px;
  text-align: center;
  padding: 0 20px;
}
app-calculation #bid-closed__button {
  margin: 15px auto;
  width: 300px;
  max-width: 300px;
}
app-calculation .bid-closed__button {
  line-height: 50px;
}
app-calculation #bid-closed__sing-up {
  margin: 10px 0;
  line-height: 50px;
}
app-calculation .bid-closed__sing-up-text {
  padding: 0;
}
app-calculation .calculation__inner.blur {
  pointer-events: none;
  filter: blur(3px);
  -webkit-filter: blur(3px);
}
app-calculation .mat-progress-spinner circle, app-calculation .mat-spinner circle {
  stroke: #fff;
}
app-calculation mat-spinner {
  position: absolute !important;
  left: 10px;
}
app-calculation .info-text {
  width: 45vw;
  background: url("/assets/images/calculate.jpg") center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  text-align: right;
  font-weight: 900;
  font-size: 69px;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
}
app-calculation .info-text .inner {
  background: linear-gradient(to left, #134D66 0%, rgba(19, 77, 102, 0.01) 89.43%);
  display: flex;
  align-items: center;
  justify-content: center;
}
app-calculation .info-text .sub-text {
  text-transform: none;
  font-size: 56px;
  line-height: 1.1;
}
app-calculation .button-s {
  background: #2ebd59;
  border-radius: 500px;
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  line-height: 11px;
  min-width: 92px;
  padding-top: 2px;
  padding-bottom: 3px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  margin: 0 10px 0 0;
  float: left;
  width: fit-content;
  border: 0;
}
app-calculation .bt-icon {
  background-image: url(https://open.scdn.co/cdn/images/follow/sprite@1.b994540b.png);
  background-position: 0 0;
  content: "";
  display: inline-block;
  height: 17px;
  position: absolute;
  top: 3px;
  border: 0;
  width: 17px;
  left: 3px;
}
app-calculation .bt-text {
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 13px;
  border: 0;
  padding: 4px 4px 3px 8px;
}
app-calculation .profile-title {
  padding: 15px 0;
  font-weight: 700;
  text-align: center;
  font-size: 15px;
}
app-calculation .followers-icon {
  margin-top: 4px;
}
app-calculation .followers-count {
  font-weight: 900;
  color: black;
  font-size: 14px;
  font-style: normal;
  padding-left: 30px;
  position: absolute;
  line-height: 16px;
}
app-calculation .followers-count-twitter {
  font-weight: 900;
  color: black;
  font-size: 14px;
  font-style: normal;
  padding-left: 30px;
  position: absolute;
  line-height: 16px;
}
app-calculation .followers-text {
  color: #B7BCCB;
  font-style: normal;
  padding-left: 4px;
}
app-calculation .followers-text-tap {
  color: #B7BCCB;
  font-style: normal;
  padding-left: 4px;
}
app-calculation .follow-caption {
  color: #B7BCCB;
}
app-calculation .follow-name {
  font-weight: 200;
}
app-calculation .followers-container {
  width: 100px;
  height: 40px;
  word-wrap: normal;
}
app-calculation .followers-social-row {
  height: fit-content;
}
app-calculation .followers-flex-container {
  display: grid;
  grid-template-columns: 100px 100px 100px;
  grid-template-rows: 50px auto;
  justify-content: left;
  padding-top: 11px;
  pointer-events: none;
}
app-calculation .row {
  width: 320px;
  display: flex;
  justify-content: flex;
  margin-top: 10px;
}
app-calculation .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
app-calculation .wrapper .inner {
  max-width: 330px;
}
app-calculation .wrapper .button {
  text-transform: uppercase;
  width: 100%;
  height: 50px;
  max-width: 350px;
  font-weight: 500;
  font-size: 15px;
  margin: 0 auto 10px;
}
app-calculation .calculation__timer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
app-calculation .calculation__description {
  font-weight: 400;
  font-size: 12px;
  color: #8089A0;
  text-align: center;
}
app-calculation .get-title {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
}
app-calculation .get-text {
  color: #8089A0;
  list-style-type: disc;
  margin: 0px 40px;
}
app-calculation .get-text li {
  color: #5AA2E5;
}
app-calculation .get-text .item {
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
}
app-calculation .get-text .lnr-chevron-right {
  position: absolute;
  top: 3px;
  left: 0;
  font-size: 9px;
  color: #5AA2E5;
  font-weight: 900;
}
app-calculation .select-wrapper {
  border: 2px solid #5AA2E5;
  border-radius: 5px;
  height: 40px;
  width: 90px;
}
app-calculation .collectible-scheduled {
  display: flex;
  flex-direction: column;
}
app-calculation .collectible-scheduled input::-webkit-input-placeholder { /* WebKit browsers */
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
app-calculation .collectible-scheduled input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: 18px;
  text-align: center;
}
app-calculation .collectible-scheduled .timer {
  background: #fff;
}
app-calculation mat-select {
  padding: 0;
  border: none;
}

@media screen and (max-width: 1380px) {
  app-calculation .input-box {
    background: #F2F7FB;
    border-radius: 5px;
  }
  app-calculation .wrapper {
    padding: 0 54px;
  }
  app-calculation .bid-closed {
    top: 40px;
  }
}
@media screen and (max-width: 1100px) {
  app-calculation .wrapper {
    padding: 0 54px 0 24px;
  }
}
@media screen and (max-width: 1023px) {
  app-calculation {
    flex-direction: column;
  }
  app-calculation .info-text {
    width: 100%;
    height: 200px;
    font-size: 45px;
    text-align: center;
  }
  app-calculation .info-text br {
    display: none;
  }
  app-calculation .info-text .inner {
    align-items: flex-end;
  }
  app-calculation .info-text .sub-text {
    font-size: 30px;
    margin: 8px 0 25px;
  }
  app-calculation .followers-social-row {
    visibility: hidden;
    display: none;
  }
  app-calculation .wrapper {
    height: auto;
    width: 100%;
    margin-top: 18px;
  }
}
@media screen and (max-width: 767px) {
  app-calculation .followers-social-row {
    visibility: hidden;
    display: none;
  }
  app-calculation .input-box {
    background: #fff;
  }
  app-calculation .bid-closed {
    margin: auto;
  }
}
@media screen and (max-width: 460px) {
  app-calculation .info-text {
    display: none;
  }
  app-calculation .followers-social-row {
    visibility: hidden;
    display: none;
  }
  app-calculation .wrapper {
    margin-top: 100px;
  }
  app-calculation .wrapper .inner {
    max-width: calc(100% - 20px);
  }
  app-calculation .select-share .item {
    width: 60px;
  }
  app-calculation .select-wrapper {
    width: calc(100% - 201px);
  }
}
app-cookies .cookies-info {
  align-self: center;
}
app-cookies .cookies-popup {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  height: auto;
  padding: 20px;
  left: 0;
  z-index: 100;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
}
app-cookies .cookies-popup .container {
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
  justify-content: space-between;
}
app-cookies .cookies-popup .container.settings {
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  app-cookies .cookies-popup .container.settings {
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  app-cookies .cookies-popup {
    padding: 20px 0 0 0;
  }
}
app-cookies .cookies-image {
  height: 50px;
  aspect-ratio: auto 27/25;
}
@media screen and (max-width: 767px) {
  app-cookies .cookies-image {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  app-cookies .container {
    flex-direction: column;
  }
}
app-cookies .cookies-settings {
  display: flex;
  justify-content: flex-start;
}
@media screen and (max-width: 767px) {
  app-cookies .cookies-settings {
    flex-direction: column;
  }
}
app-cookies .cookies-button-wrapper {
  margin: 1.2em 0;
  align-items: center;
}
app-cookies .cookies-button-wrapper .button {
  margin-left: 0.8em;
  white-space: nowrap;
  width: 100%;
  padding: 0 20px;
  min-width: auto;
}
@media screen and (max-width: 767px) {
  app-cookies .cookies-button-wrapper {
    width: 100%;
    justify-content: space-between;
    margin: 1.2em;
  }
  app-cookies .cookies-button-wrapper .button {
    margin: 0;
  }
}
@media screen and (max-width: 460px) {
  app-cookies .cookies-button-wrapper .button {
    padding: 0;
    font-size: 13px;
  }
}
app-cookies .read-more {
  transition: all 0.2s ease-out;
}
app-cookies .read-more.showReadMore {
  height: 60px;
  transition: all 0.2s ease-out;
}
@media screen and (max-width: 414px) {
  app-cookies .read-more.showReadMore {
    height: 90px;
  }
}
@media screen and (max-width: 460px) {
  app-cookies .read-more {
    height: 0;
    overflow: hidden;
  }
}
app-cookies .read-more-title {
  display: none;
  margin-top: 10px;
}
@media screen and (max-width: 460px) {
  app-cookies .read-more-title {
    display: block;
  }
}
app-cookies .toggle {
  display: flex;
  align-items: center;
}
app-cookies .toggle .toggle-item {
  display: flex;
  align-items: center;
  color: #fff;
  margin-right: 60px;
}
app-cookies .toggle .status {
  width: 30px;
}
app-cookies .toggle .status.off {
  color: #8089A0;
}
app-cookies .toggle .toggle-text.off {
  color: #8089A0;
}
app-cookies .toggle .toggle-box {
  margin: 0 1em;
}
@media screen and (max-width: 767px) {
  app-cookies .toggle {
    flex-direction: column;
    padding: 0 20px;
    align-items: flex-start;
  }
  app-cookies .toggle .toggle-item {
    margin: 0.5em 0;
  }
}
app-cookies .cookies-text {
  color: #fff;
  font-weight: normal;
  line-height: 18px;
}
@media screen and (max-width: 460px) {
  app-cookies .cookies-text {
    font-size: 13px;
  }
}
app-cookies .cookies-text.cookies-text__padding {
  padding: 0 20px;
}
app-cookies .cookies-text .cookies-text__headline {
  line-height: 18px;
}

app-apple apple-pay-button {
  display: block;
  margin: 0 auto;
  --apple-pay-button-width: 240px;
  --apple-pay-button-height: 45px;
  --apple-pay-button-border-radius: 5px;
  --apple-pay-button-padding: 5px 0px;
  --apple-pay-button-box-sizing: border-box;
}
app-apple .apple-pay-button {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: buy; /* Use any supported button type. */
}
app-apple .apple-pay-button-black {
  -apple-pay-button-style: black;
}
app-apple .apple-pay-button-white {
  -apple-pay-button-style: white;
}
app-apple .apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}

app-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: rgba(5, 27, 34, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
app-popup > .inner {
  border: 1px solid #949494;
  border-radius: 5px;
  background: #fff;
}
app-popup > .inner .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 12px;
  font-weight: 600;
  top: 18px;
  cursor: pointer;
  color: #1ac5d0;
}
app-popup > .inner > .title {
  border-bottom: 1px solid #949494;
  margin-bottom: 4px;
  padding: 18px 24px;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 1;
  background: #F6F9FC;
  color: #084C61;
}
app-popup > .inner .row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
app-popup .content {
  padding: 0 24px;
  margin-top: 22px;
}
app-popup .popup-wrapper {
  background: #fff;
  position: relative;
  border: 1px solid #c7cfd8;
  border-radius: 5px;
  max-height: 90%;
  overflow: hidden;
}
app-popup .popup-wrapper .title {
  border-bottom: 1px solid #949494;
  margin-bottom: 4px;
  padding: 18px 24px;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 600;
}
app-popup .popup-wrapper .row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
app-popup .lnr.default-cross {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 24px;
  color: #8089A0;
  opacity: 0.7;
  border: 10px solid transparent;
}

@media screen and (max-width: 768px) {
  app-popup .inner {
    width: 100% !important;
    max-height: 90% !important;
    overflow: auto !important;
  }
  app-popup .form > .row {
    display: block;
  }
  app-popup .form > .row > .item {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  app-popup .popup-wrapper {
    width: 90%;
    max-height: 650px;
    overflow-y: scroll;
    top: 10px;
  }
  app-popup .inner {
    width: 100% !important;
  }
}
app-bid-popup .inner {
  width: 360px;
  max-width: 90vw;
  position: relative;
  padding: 0;
  text-align: center;
}
app-bid-popup .button {
  height: 40px;
}
app-bid-popup .content {
  padding: 0 20px;
}
app-bid-popup .content .item {
  width: 100%;
}
app-bid-popup .buttons-wrapper {
  justify-content: space-between;
}

app-reminder-nft-popup {
  position: relative;
}
app-reminder-nft-popup .inner {
  width: 560px;
  max-width: 90vw;
  position: relative;
  padding: 40px 90px;
  text-align: center;
}
app-reminder-nft-popup .popup-content {
  position: relative;
}
app-reminder-nft-popup .popup-title {
  font-size: 21px;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
}
app-reminder-nft-popup .subscribe__input {
  border-bottom: 1px solid #B7BCCB;
  width: 100%;
  outline: 0;
  border-width: 0 0 1px 0;
  padding: 0 0 10px 40px;
  font-size: 14px;
}
app-reminder-nft-popup .popup-input {
  margin: 30px 0;
  position: relative;
}
app-reminder-nft-popup .popup-sub-title {
  font-weight: 400;
  font-size: 15px;
  text-align: center;
}
app-reminder-nft-popup .popup-image {
  position: absolute;
  left: 5px;
  top: 0;
}
app-reminder-nft-popup input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  padding: 0;
  font-size: 14px;
}
app-reminder-nft-popup .popup-button {
  margin: 0 auto 15px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
}
app-reminder-nft-popup .terms {
  font-weight: 400;
  font-size: 12px;
  color: #8089A0;
}
app-reminder-nft-popup .terms .link-blue {
  font-weight: 400;
  font-size: 12px;
}

app-reminder-kyc-popup .inner {
  width: 560px;
  max-width: 90vw;
  position: relative;
  padding: 40px 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  app-reminder-kyc-popup .inner {
    padding: 40px 20px;
  }
}
app-reminder-kyc-popup .popup-content {
  position: relative;
}
app-reminder-kyc-popup .popup-title {
  font-size: 21px;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
}
app-reminder-kyc-popup .popup-sub-title {
  font-weight: 400;
  font-size: 15px;
  text-align: center;
}
app-reminder-kyc-popup .popup-image {
  position: absolute;
  left: 5px;
  top: 0;
}
app-reminder-kyc-popup .popup-content .popup-button {
  margin-top: 15px;
  width: 100%;
  min-width: auto;
  padding: 0 15px;
  font-weight: 500;
  font-size: 16px;
}

.lnr.default-cross {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 24px;
  color: #fff;
  opacity: 1;
  border: 10px solid transparent;
}

app-carousel .section-slider {
  width: 100%;
  position: relative;
}
app-carousel .section-slider .slick-slider {
  width: 100%;
  margin: auto;
  height: 500px;
}
app-carousel .section-slider .slick-dots {
  bottom: 5px !important;
}
app-carousel .section-slider .slick-dots li button:before {
  color: #fff !important;
  opacity: 0.7;
  font-size: 12px;
}
app-carousel .section-slider .slick-dots li.slick-active button:before {
  color: #FFA800 !important;
  font-size: 15px;
  opacity: 1;
}
app-carousel .section-slider .image-wrapper {
  height: 556px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  position: relative;
}
app-carousel .section-slider .slider-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-carousel .section-slider .slider-title {
  font-size: 64px;
  color: #fff;
  font-weight: 400;
  line-height: 75px;
}
app-carousel .section-slider .slider-title span {
  font-weight: 900;
}
app-carousel .section-slider p {
  font-style: normal;
  color: #fff;
  line-height: 42px;
  font-weight: 300;
  font-size: 36px;
}
app-carousel .slider-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
app-carousel .slider-wrapper .slider-title {
  order: 1;
}
app-carousel .slider-wrapper p {
  order: 2;
}
app-carousel .slider-wrapper .button {
  order: 3;
}
app-carousel .slider-btn {
  width: 357px;
  background: #FFA800;
  font-size: 21px;
  height: 60px;
  line-height: 60px;
  margin-top: 32px;
}
app-carousel .slider-btn:hover {
  color: #fff;
}
app-carousel .image-wrapper.mobile {
  display: none;
}
app-carousel .image-wrapper.desktop {
  display: block;
}

@media screen and (max-width: 1024px) {
  app-carousel .section-slider .slider-title {
    font-size: 29px;
    line-height: 40px;
  }
  app-carousel .section-slider p {
    font-size: 18px;
  }
  app-carousel .slider-btn {
    width: 270px;
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  app-carousel .slider-wrapper {
    padding: 0 10px;
  }
  app-carousel .slider-wrapper .slider-title {
    order: 2;
  }
  app-carousel .slider-wrapper .button {
    order: 1;
  }
  app-carousel .slider-wrapper p {
    text-align: center;
    line-height: 18px;
    order: 3;
  }
  app-carousel .slider-btn {
    margin-top: 0;
    height: 50px;
    line-height: 50px;
    width: 250px;
  }
  app-carousel .section-slider .slider-title {
    font-size: 26px;
    line-height: 34px;
    margin-top: 18px;
    text-align: center;
  }
  app-carousel .section-slider .slider-text {
    align-items: flex-start;
    margin-top: 100px;
  }
  app-carousel .section-slider p {
    text-align: center;
  }
  app-carousel .section-slider .slick-slider {
    height: 400px;
  }
  app-carousel .section-slider .image-wrapper {
    height: 400px;
  }
  app-carousel .section-slider .image-wrapper.mobile {
    display: block;
  }
  app-carousel .section-slider .image-wrapper.desktop {
    display: none;
  }
}
@media screen and (max-width: 320px) {
  app-carousel .section-slider .slider-title {
    font-size: 24px;
    line-height: 30px;
  }
  app-carousel .section-slider p {
    font-size: 16px;
    line-height: 24px;
  }
  app-carousel .header {
    padding: 0 5px !important;
  }
  app-carousel .header .login span {
    padding-right: 5px !important;
  }
  app-carousel .hamburger {
    left: 10px !important;
  }
}
app-nickname-popup .inner {
  width: 560px;
}
app-nickname-popup .popup-content {
  position: relative;
}
app-nickname-popup .subscribe__input {
  border-bottom: 1px solid #B7BCCB;
  width: 100%;
  outline: 0;
  border-width: 0 0 1px 0;
  padding: 0 0 10px 40px;
  font-size: 14px;
}
app-nickname-popup .popup-input {
  margin: 30px 0;
  position: relative;
}
app-nickname-popup .popup-input.error .subscribe__input {
  color: #F95738;
}
app-nickname-popup .popup-input.error .popup-error {
  height: 10px;
  transition: all 1s ease-out;
}
app-nickname-popup .popup-error {
  transition: all 1s ease-out;
  color: #F95738;
  font-size: 12px;
  text-align: left;
  line-height: 20px;
  height: 0;
}
app-nickname-popup .popup-sub-title {
  font-weight: 400;
  font-size: 15px;
  text-align: center;
}
app-nickname-popup .popup-image {
  position: absolute;
  left: 5px;
  top: 0;
}
app-nickname-popup input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  padding: 0;
  font-size: 14px;
}
app-nickname-popup .popup-button {
  margin: 0 auto 15px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
}
app-nickname-popup .terms {
  font-weight: 400;
  font-size: 12px;
  color: #8089A0;
}
app-nickname-popup .terms .link-blue {
  font-weight: 400;
  font-size: 12px;
}

app-date-picker .mat-calendar-body-label {
  display: none;
}
app-date-picker .mat-calendar-body-cell-content {
  border-radius: 0;
}
app-date-picker .mat-calendar-body-selected {
  background-color: #5AA2E5;
}
app-date-picker .mat-calendar-body-in-range::before {
  background-color: #DFEBF4;
}
app-date-picker .mat-card {
  background: rgba(242, 247, 251, 0.57);
  box-shadow: unset !important;
  border-radius: 0;
  padding: 0 16px 16px;
}
app-date-picker tbody td {
  border-bottom: 0;
}
app-date-picker tr:hover td {
  background: unset;
}

date-picker-header {
  width: 100%;
}
date-picker-header .c_arrow-left, date-picker-header .c_arrow-right {
  width: 14px;
  top: 11px;
  position: absolute;
}
date-picker-header .example-header {
  display: flex;
  align-items: center;
  padding: 0.5em;
}
date-picker-header .example-header-label {
  flex: 1;
  height: 1em;
  font-weight: 900;
  text-align: center;
}
date-picker-header .example-double-arrow .mat-icon {
  margin: -22%;
}

app-dropdown {
  position: relative;
  z-index: 2;
  cursor: pointer;
}
app-dropdown .item-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
}
app-dropdown .fa-chevron-down {
  padding-left: 5px;
}
app-dropdown .menu {
  background: #fff;
  border-radius: 5px;
  position: absolute;
  top: 20px;
  right: 0;
  width: 216px;
  overflow: hidden;
  box-shadow: 0 0 3px #8089A0;
  height: 0;
  transition: all 0.25s ease-out 0s;
}
app-dropdown .menu.active {
  height: auto;
  overflow-y: scroll;
}
app-dropdown .menu-item {
  font-size: 15px;
  font-weight: 900;
  padding: 10px;
  color: #5AA2E5;
  cursor: pointer;
}
app-dropdown .menu-item:hover {
  background: #e0f1ff;
}
app-dropdown .fa-chevron-down {
  padding-left: 5px;
}

app-base-select-amount {
  display: flex;
  flex-direction: column;
  width: 100%;
}
app-base-select-amount input::-webkit-input-placeholder { /* WebKit browsers */
  font-size: 22px;
  font-weight: 900;
  text-align: center;
}
app-base-select-amount input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: 22px;
  text-align: center;
}
app-base-select-amount .lnr {
  cursor: inherit;
}
app-base-select-amount .inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-base-select-amount .button.disable {
  opacity: 0.6;
  cursor: pointer;
}
app-base-select-amount .button.button-explore {
  margin-top: 15px !important;
  margin-bottom: 0 !important;
}
app-base-select-amount .mat-progress-spinner circle, app-base-select-amount .mat-spinner circle {
  stroke: #fff;
}
app-base-select-amount mat-spinner {
  position: absolute !important;
  left: 10px;
}
app-base-select-amount .nft-explain {
  display: inline-block;
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  width: 100%;
  color: #8089A0;
}
app-base-select-amount .amount {
  background: #FFFFFF;
  border-radius: 5px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  font-weight: 900;
  width: 100%;
  font-size: 32px;
  margin: 10px auto;
  max-width: 350px;
}
app-base-select-amount .amount .error-message {
  font-size: 28px;
  margin-top: -10px;
}
app-base-select-amount .amount .origin-amount {
  font-size: 12px;
  line-height: 12px;
  color: #8089A0;
  font-weight: normal;
  margin-top: -10px;
}
app-base-select-amount .amount .origin-amount .origin-amount-underline {
  text-decoration: line-through;
}
app-base-select-amount .amount .origin-amount .sale-amount {
  color: #EF3E42;
  display: inline-block;
  padding: 0 5px;
  font-weight: bold;
}
app-base-select-amount .get-title {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
}
app-base-select-amount .select-share {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}
app-base-select-amount .select-share .item {
  border: 2px solid #5AA2E5;
  border-radius: 5px;
  width: 67px;
  height: 40px;
  line-height: 36px;
  color: #5AA2E5;
  font-weight: 900;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  margin-right: 7px;
}
app-base-select-amount .select-share .item.active {
  background: #5AA2E5;
  color: #fff;
}
app-base-select-amount .select-share .item.disabled {
  opacity: 0.4;
  cursor: default;
}
app-base-select-amount .select-share .item-dropdown {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-base-select-amount .select-share .fa-chevron-down {
  padding-left: 5px;
}
app-base-select-amount .select-wrapper {
  border: 2px solid #5AA2E5;
  border-radius: 5px;
  height: 40px;
  width: 90px;
}
app-base-select-amount .menu {
  background: #fff;
  border-radius: 5px;
  position: absolute;
  top: 50px;
  right: 0;
  width: 216px;
  overflow: hidden;
  box-shadow: 0 0 3px #8089A0;
  height: 0;
  transition: all 0.25s ease-out 0s;
}
app-base-select-amount .menu.active {
  height: 200px;
  overflow-y: scroll;
  z-index: 1;
}
app-base-select-amount .menu-item {
  font-size: 15px;
  font-weight: 900;
  padding: 10px;
  color: #5AA2E5;
  cursor: pointer;
}
app-base-select-amount .menu-item:hover {
  background: #e0f1ff;
}
app-base-select-amount mat-select {
  padding: 0;
  border: none;
}
app-base-select-amount .amount-not-allowed {
  color: #8089A0;
  text-align: center;
  margin: -6px 0 14px;
}

@media screen and (max-width: 1380px) {
  app-calculation .input-box {
    background: #F2F7FB;
    border-radius: 5px;
  }
  app-calculation .wrapper {
    padding: 0 54px;
  }
  app-calculation .bid-closed {
    top: 40px;
  }
}
@media screen and (max-width: 1100px) {
  app-calculation .wrapper {
    padding: 0 54px 0 24px;
  }
}
@media screen and (max-width: 1023px) {
  app-calculation {
    flex-direction: column;
  }
  app-calculation .info-text {
    width: 100%;
    height: 200px;
    font-size: 45px;
    text-align: center;
  }
  app-calculation .info-text br {
    display: none;
  }
  app-calculation .info-text .inner {
    align-items: flex-end;
  }
  app-calculation .info-text .sub-text {
    font-size: 30px;
    margin: 8px 0 25px;
  }
  app-calculation .followers-social-row {
    visibility: hidden;
    display: none;
  }
  app-calculation .wrapper {
    height: auto;
    width: 100%;
    margin-top: 18px;
  }
}
@media screen and (max-width: 767px) {
  app-calculation .followers-social-row {
    visibility: hidden;
    display: none;
  }
  app-calculation .input-box {
    background: #fff;
  }
  app-calculation .bid-closed {
    margin: auto;
  }
}
@media screen and (max-width: 460px) {
  app-calculation .info-text {
    display: none;
  }
  app-calculation .followers-social-row {
    visibility: hidden;
    display: none;
  }
  app-calculation .wrapper {
    margin-top: 100px;
  }
  app-calculation .wrapper .inner {
    max-width: calc(100% - 20px);
  }
  app-calculation .select-share .item {
    width: 60px;
  }
  app-calculation .select-wrapper {
    width: calc(100% - 201px);
  }
}
app-footer-about-company .about-company {
  background: #F2F7FB;
  border-radius: 0 7px 7px 7px;
  padding: 26px 36px;
  margin: 26px auto 0;
}
@media screen and (max-width: 1380px) {
  app-footer-about-company .about-company {
    padding: 28px;
  }
}
@media screen and (max-width: 767px) {
  app-footer-about-company .about-company {
    background: #F2F7FB;
    margin: 15px 0;
    padding: 15px 18px;
    border-radius: 0 7px 7px 7px;
  }
}
app-footer-about-company .about-company .about-company-title {
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  app-footer-about-company .about-company .about-company-title {
    font-size: 15px;
    font-weight: 700;
  }
}
app-footer-about-company .about-company .gray-text {
  margin-top: 4px;
}
app-footer-about-company .about-company p {
  margin-top: 4px;
}

app-payment-methods google-pay-button {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
}
app-payment-methods .payment-item {
  border: 2px solid #5AA2E5;
  position: relative;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  margin: 10px;
  cursor: pointer;
}
app-payment-methods mat-radio-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}
app-payment-methods .mat-radio-button {
  border: 2px solid #5AA2E5;
  border-radius: 5px;
  height: 45px;
  margin-top: 15px;
}
app-payment-methods .mat-radio-button.mat-radio-disabled {
  border: 2px solid #D9D9D9;
}
app-payment-methods .mat-radio-button.mat-radio-disabled img {
  filter: contrast(0);
  opacity: 0.3;
}
app-payment-methods .mat-radio-button.mat-radio-disabled .payment_name {
  color: #D9D9D9;
}
app-payment-methods .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: #D9D9D9;
}
app-payment-methods .mat-radio-label-content {
  width: 100%;
}
app-payment-methods .mat-radio-label {
  padding: 0 20px;
}
app-payment-methods .radio-content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 42px;
  align-items: center;
}
app-payment-methods .klarna-wrapper {
  display: flex;
  align-items: center;
}
app-payment-methods .tooltip-klarna {
  margin-right: 15px;
}
app-payment-methods .tooltip-klarna__title {
  font-weight: 700;
}
app-payment-methods .tooltip-klarna .m-b-10 {
  margin-bottom: 10px;
}
app-payment-methods .tooltip-klarna .tooltip-card {
  top: 40px;
  width: 200px;
  right: -40px;
  white-space: pre-wrap;
}
app-payment-methods .tooltip-klarna .tooltip-card:after {
  top: -6px;
  right: 138px;
}
app-payment-methods .tooltip-klarna .hover-block {
  height: 22px;
}
app-payment-methods .tooltip-klarna img {
  width: 22px;
  height: 22px;
}
app-payment-methods .card-payment {
  display: flex;
  align-items: center;
}
app-payment-methods .card-payment .visa {
  margin-right: 10px;
}
app-payment-methods .mat-radio-outer-circle {
  border-color: #5AA2E5;
}
app-payment-methods .mat-radio-button.active {
  background-color: #D9E8F2;
}
app-payment-methods .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5AA2E5;
}
app-payment-methods .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #5AA2E5;
}
app-payment-methods .payment_name {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-left: 12px;
  color: #000;
}
app-payment-methods .klarna {
  height: 18px;
}
app-payment-methods .maestro {
  height: 35px;
  margin-right: -5px;
}
app-payment-methods .payments {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 576px) {
  app-payment-methods .payments {
    padding: 0 22px;
  }
}
app-payment-methods .direct-payment {
  margin: 15px 0;
  width: 100%;
}
app-payment-methods .direct-payment .google-pay {
  display: flex;
  justify-content: center;
  width: 100%;
}
app-payment-methods .direct-payment.disablePayment {
  pointer-events: none;
  opacity: 0.4;
}

app-reinvest-payment {
  margin: 10px 0;
  display: block;
  padding: 10px 15px;
  border: 2px solid #5AA2E5;
  border-radius: 5px;
  background: #fff;
}
app-reinvest-payment:has(app-checkbox.active) {
  background-color: #D9E8F2;
}
app-reinvest-payment app-checkbox.disabled img {
  filter: contrast(0);
}
app-reinvest-payment app-checkbox.disabled .checkbox {
  border: 2px solid #D9D9D9;
}
app-reinvest-payment app-checkbox.voucher-checkbox {
  margin-top: 20px;
}
app-reinvest-payment app-checkbox.voucher-checkbox p,
app-reinvest-payment app-checkbox.voucher-checkbox .checkbox {
  margin-top: 0;
}
app-reinvest-payment .checkbox-content {
  margin-left: 16px;
}
app-reinvest-payment .checkbox-content p {
  font-size: 12px;
  margin-top: 9px;
  line-height: 16px;
  color: #8089A0;
}
app-reinvest-payment .logo {
  max-width: 192px;
  margin-left: 15px;
}

app-tabs {
  width: 100%;
}
app-tabs .tab-header {
  display: flex;
  width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  app-tabs .tab-header {
    justify-content: flex-start;
  }
}
app-tabs .tab-item {
  padding: 0 30px;
  line-height: 42px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
  align-items: flex-end;
}
@media screen and (max-width: 767px) {
  app-tabs .tab-item {
    line-height: 30px;
    font-size: 12px;
    padding: 0 20px;
  }
}
@media screen and (max-width: 320px) {
  app-tabs .tab-item {
    font-size: 14px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 767px) {
  app-tabs .tab-item.active {
    bottom: 0;
  }
}
app-tabs .tab-item.active span {
  display: block;
  position: absolute;
  height: 5px;
  width: 40px;
  border-radius: 50px;
  margin: 0 auto;
  transition: all ease-in 0.2s;
  background-color: #FFA800;
  bottom: -2px;
}
app-tabs .tab-item:first-child {
  padding-left: 0;
}
@media screen and (max-width: 767px) {
  app-tabs .tab-item:first-child {
    padding-left: 15px;
  }
}

app-tooltip {
  z-index: 2;
}
app-tooltip .hover-block {
  height: 28px;
}
app-tooltip .tooltip-card {
  position: absolute;
  text-align: left;
  display: block;
  right: 0;
  z-index: 1;
  line-height: 16px;
  border-radius: 5px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  font-size: 12px;
  padding: 14px;
}
app-tooltip .tooltip-card:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -6px;
  right: 5px;
  width: 0;
  height: 0;
  border-top: none;
  border-bottom: 7px solid rgba(0, 0, 0, 0.7);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
app-tooltip .tooltip-card.tooltip-wrap:after {
  right: 160px;
}
app-tooltip .tooltip-box {
  padding: 20px;
  position: absolute;
  top: 0px;
  left: -10px;
}
app-tooltip .wrapper-tooltip {
  display: block;
  position: relative;
  width: 0;
  height: 100%;
}

app-footer-about-company .about-company {
  background: #F2F7FB;
  border-radius: 0 7px 7px 7px;
  padding: 26px 36px;
  margin: 26px auto 0;
}
@media screen and (max-width: 1380px) {
  app-footer-about-company .about-company {
    padding: 28px;
  }
}
@media screen and (max-width: 767px) {
  app-footer-about-company .about-company {
    background: #F2F7FB;
    margin: 15px 0;
    padding: 15px 18px;
    border-radius: 0 7px 7px 7px;
  }
}
app-footer-about-company .about-company .about-company-title {
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  app-footer-about-company .about-company .about-company-title {
    font-size: 15px;
    font-weight: 700;
  }
}
app-footer-about-company .about-company .gray-text {
  margin-top: 4px;
}
app-footer-about-company .about-company p {
  margin-top: 4px;
}

app-page-header .background-image {
  background-color: #253a6f;
  height: 196px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  position: relative;
}
app-page-header .user__details {
  margin: -80px auto 0 auto;
  width: 1300px;
  display: flex;
  padding-left: 60px;
}
app-page-header .user__details .text {
  z-index: 10;
}
app-page-header main {
  flex: 1;
  width: 100%;
  margin: 0 auto;
}
app-page-header .page-header__title {
  z-index: 10;
  color: #fff;
  font-weight: bold;
  font-size: 27px;
}
app-page-header .user__add-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 0 25px 37px;
  justify-content: space-between;
}
app-page-header .user__profile-img {
  width: 160px;
  min-width: 160px;
  position: relative;
  background: url("/assets/images/fallback.png");
  height: 160px;
  background-size: cover;
  background-position: center center;
  border-radius: 80px;
  box-shadow: 0 0 3px #8089A0;
}
app-page-header .page-header__content {
  padding-top: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 1370px) {
  app-page-header .user__details {
    width: 90%;
    padding-left: 20px;
  }
}
@media screen and (max-width: 767px) {
  app-page-header .background-image {
    background-image: none !important;
    background-color: #253a6f;
    height: 156px;
  }
  app-page-header .user__add-info {
    padding: 0 15px;
  }
  app-page-header .page-header__content {
    padding-bottom: 25px;
  }
  app-page-header .page-header__title {
    font-size: 18px;
    padding-top: 30px;
    text-align: center;
  }
  app-page-header .user__details {
    width: 100%;
    padding-left: 0;
  }
  app-page-header .user__profile-img {
    display: none;
  }
}
app-project-bids {
  color: #8089A0;
}
app-project-bids .bids__table {
  width: 100%;
}
app-project-bids .table-header {
  font-weight: 700;
  font-size: 14px;
}
app-project-bids .table-header th {
  padding: 18px 0;
}
app-project-bids .bid-status {
  white-space: nowrap;
  color: #FF0000;
}
app-project-bids .bid-status.isWinning {
  color: #27AE60;
}
app-project-bids .bid-status.isLoosing {
  color: #FF0000;
}
app-project-bids .bids__button {
  height: 40px;
  text-transform: uppercase;
  margin: 0 auto;
  position: relative;
}
app-project-bids .bids__button.disable {
  opacity: 0.6;
  cursor: pointer;
}
app-project-bids .mat-progress-spinner circle, app-project-bids .mat-spinner circle {
  stroke: #fff;
}
app-project-bids #button-spinner {
  position: absolute;
  top: 10px;
  left: 10px;
}
app-project-bids .bids__row:nth-child(2n+1) {
  background: #F2F7FB;
}
app-project-bids .bids__row td {
  border-top: 1px dashed #C4C4C4;
  border-bottom: none;
  text-align: center;
  padding: 16px 0;
  color: #8089A0;
  font-weight: 400;
}
app-project-bids .bids__row td.bid-song-name {
  text-transform: uppercase;
  font-weight: 500;
}
app-project-bids .d-date {
  display: block;
}
app-project-bids .m-date {
  display: none;
}

@media screen and (max-width: 767px) {
  app-project-bids .bids__row td {
    border: none;
    padding: 10px 0;
  }
  app-project-bids .bids__row td.bid-song-name, app-project-bids .bids__row td.bid-amount {
    display: none;
  }
  app-project-bids .bids__button {
    height: 36px;
    min-width: 100px;
  }
  app-project-bids .table-header {
    display: none;
  }
  app-project-bids .m-date {
    display: block;
  }
  app-project-bids .d-date {
    display: none;
  }
}
app-edit-password-popup .inner {
  max-width: 400px;
  width: 90vw;
  position: relative;
  padding: 0 !important;
}
app-edit-password-popup .buttons-wrapper {
  margin: 25px 23px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
app-edit-password-popup .buttons-wrapper .button {
  max-width: 45%;
}
app-edit-password-popup .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-edit-password-popup .form {
  padding: 0 24px;
}
app-edit-password-popup .item {
  width: 100%;
  margin-top: 10px;
}
app-edit-password-popup .label {
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  font-size: 14px;
  color: #F2F7FB;
  line-height: 2;
  transition: all linear 0.1s;
}
app-edit-password-popup .label.opened {
  transform: translateY(-19px);
}
app-edit-password-popup .error-message.align-block {
  display: flex;
}
app-edit-password-popup .error-message .error-message-text {
  display: inline-block;
}
app-edit-password-popup .select[multiple] {
  min-height: 80px;
}

.section-slider-music {
  width: 90%;
  position: relative;
  margin: 0 auto;
}

app-new-music-slider .img-block {
  cursor: pointer;
  text-align: -webkit-center;
}
app-new-music-slider .img-block img {
  border-radius: 5px;
  width: -webkit-fill-available;
  object-fit: cover;
}
app-new-music-slider .section-slider {
  width: 70%;
  position: relative;
  margin: 0 auto;
}
app-new-music-slider .section-slider .slick-slider {
  width: 100%;
  margin: auto;
  height: 555px;
}
app-new-music-slider .section-slider .image-wrapper {
  height: 556px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  position: relative;
}
app-new-music-slider .section-slider .slider-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-new-music-slider .section-slider .slider-title {
  font-size: 64px;
  color: #fff;
  font-weight: 400;
  line-height: 75px;
}
app-new-music-slider .section-slider .slider-title span {
  font-weight: 900;
}
app-new-music-slider .section-slider p {
  font-style: normal;
  color: #fff;
  line-height: 42px;
  font-weight: 300;
  font-size: 36px;
}
app-new-music-slider .slider-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
app-new-music-slider .slider-wrapper .slider-title {
  order: 1;
}
app-new-music-slider .slider-wrapper p {
  order: 2;
}
app-new-music-slider .slider-wrapper .button {
  order: 3;
}
app-new-music-slider .prev-slide {
  position: absolute;
  right: -25px;
  color: #5AA2E5;
  width: 20px;
  height: 20px;
  z-index: 10;
  top: 50%;
  margin-top: -10px;
}
app-new-music-slider .next-slide {
  position: absolute;
  left: -25px;
  color: #5AA2E5;
  width: 20px;
  height: 20px;
  z-index: 10;
  top: 50%;
  margin-top: -10px;
}
app-new-music-slider .slider-btn {
  width: 357px;
  background: #FFA800;
  font-size: 21px;
  height: 60px;
  line-height: 60px;
  margin-top: 32px;
}
app-new-music-slider .slider-btn:hover {
  color: #fff;
}
app-new-music-slider .image-wrapper.mobile {
  display: none;
}
app-new-music-slider .image-wrapper.desktop {
  display: block;
}
app-new-music-slider .section-slider {
  width: 100%;
  position: relative;
}
app-new-music-slider .section-slider slick-active {
  transform: scale(1.3);
  border: 1px solid rgb(17, 17, 17);
}
app-new-music-slider .section-slider .slick-slider {
  width: 100%;
  margin: auto;
  height: 555px;
}
app-new-music-slider .section-slider ::ng-deep .slick-dots {
  bottom: -40px;
}
app-new-music-slider .section-slider ::ng-deep .slick-dots li button:before {
  color: #fff !important;
  opacity: 0.7;
  font-size: 12px;
}
app-new-music-slider .section-slider ::ng-deep .slick-dots li.slick-active button:before {
  color: #FFA800 !important;
  font-size: 15px;
  opacity: 1;
}
app-new-music-slider .section-slider .image-wrapper {
  height: 556px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  position: relative;
}
app-new-music-slider .section-slider .slider-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-new-music-slider .section-slider .slider-title {
  font-size: 64px;
  color: #fff;
  font-weight: 400;
  line-height: 75px;
}
app-new-music-slider .section-slider .slider-title span {
  font-weight: 900;
}
app-new-music-slider .section-slider p {
  font-style: normal;
  color: #fff;
  line-height: 42px;
  font-weight: 300;
  font-size: 36px;
}
app-new-music-slider .slider-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
app-new-music-slider .slider-wrapper .slider-title {
  order: 1;
}
app-new-music-slider .slider-wrapper p {
  order: 2;
}
app-new-music-slider .slider-wrapper .button {
  order: 3;
}
app-new-music-slider .slider-btn {
  width: 357px;
  background: #FFA800;
  font-size: 21px;
  height: 60px;
  line-height: 60px;
  margin-top: 32px;
}
app-new-music-slider .slider-btn:hover {
  color: #fff;
}
app-new-music-slider .image-wrapper.mobile {
  display: none;
}
app-new-music-slider .image-wrapper.desktop {
  display: block;
}

.slick-dots {
  bottom: -70px !important;
}

.img-block img {
  border-radius: 5px;
}

app-new-music-slider .section-slider {
  width: 100%;
  position: relative;
}
app-new-music-slider .section-slider .slick-dots {
  bottom: 5px !important;
}
app-new-music-slider .section-slider .slick-dots li button:before {
  color: #fff !important;
  opacity: 0.7;
  font-size: 12px;
}
app-new-music-slider .section-slider .slick-dots li.slick-active button:before {
  color: #FFA800 !important;
  font-size: 15px;
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  app-new-music-slider .section-slider .slider-title {
    font-size: 29px;
    line-height: 40px;
  }
  app-new-music-slider .section-slider p {
    font-size: 18px;
  }
  app-new-music-slider .slider-btn {
    width: 270px;
    font-size: 18px;
  }
  app-new-music-slider .img-block img {
    border-radius: 5px;
  }
  .slick-dots {
    bottom: -25px !important;
  }
}
@media screen and (max-width: 767px) {
  app-new-music-slider .slider-wrapper {
    padding: 0 10px;
  }
  app-new-music-slider .slider-wrapper .slider-title {
    order: 2;
  }
  app-new-music-slider .slider-wrapper .button {
    order: 1;
  }
  app-new-music-slider .slider-wrapper p {
    text-align: center;
    line-height: 18px;
    order: 3;
  }
  app-new-music-slider .slider-btn {
    margin-top: 0;
    height: 50px;
    line-height: 50px;
    width: 250px;
  }
  app-new-music-slider .section-slider .slider-title {
    font-size: 26px;
    line-height: 34px;
    margin-top: 18px;
    text-align: center;
  }
  app-new-music-slider .section-slider .slider-text {
    align-items: flex-start;
    margin-top: 100px;
  }
  app-new-music-slider .section-slider p {
    text-align: center;
  }
  app-new-music-slider .section-slider .slick-slider {
    height: 400px;
  }
  app-new-music-slider .section-slider .image-wrapper {
    height: 400px;
  }
  app-new-music-slider .section-slider .image-wrapper.mobile {
    display: block;
  }
  app-new-music-slider .section-slider .image-wrapper.desktop {
    display: none;
  }
}
@media screen and (max-width: 320px) {
  app-new-music-slider .section-slider .slider-title {
    font-size: 24px;
    line-height: 30px;
  }
  app-new-music-slider .section-slider p {
    font-size: 16px;
    line-height: 24px;
  }
  app-new-music-slider .header {
    padding: 0 5px !important;
  }
  app-new-music-slider .header .login span {
    padding-right: 5px !important;
  }
  app-new-music-slider .hamburger {
    left: 10px !important;
  }
}
app-subscribe-popup .inner {
  width: 460px;
  max-width: 90vw;
  position: relative;
  padding: 40px 10%;
}
app-subscribe-popup .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-subscribe-popup .block-title {
  font-weight: 900;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 10px;
}
app-subscribe-popup .text {
  font-size: 15px;
  text-align: center;
  margin-bottom: 10px;
}
app-subscribe-popup .image {
  display: block;
  margin: 0 auto;
  height: 130px;
}
app-subscribe-popup mat-form-field {
  width: 100%;
}
app-subscribe-popup .fa-envelope {
  color: #5AA2E5;
  margin: 0 20px 0 12px;
}
app-subscribe-popup .button {
  width: 100%;
}

@media screen and (max-width: 767px) {
  app-subscribe-popup .inner {
    padding: 40px 24px;
  }
  app-subscribe-popup .form {
    padding: 0;
  }
}
app-card-project {
  color: #595A5A;
  position: relative;
}
app-card-project .card {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}
app-card-project .card__video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1 !important;
  transition: opacity 1s;
}
app-card-project .card__video-overlay.opaque {
  opacity: 0 !important;
  transition: opacity 0.5s 0.5s;
}
app-card-project mat-spinner {
  z-index: 2;
  position: absolute !important;
  top: 45%;
  left: 45%;
}
app-card-project .sale_corner-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 110px;
}
app-card-project .icon-musical img {
  max-width: 20px;
}
app-card-project .icon-disk, app-card-project .icon-melody {
  position: relative;
}
app-card-project .tooltip-card {
  width: 190px;
  top: 30px;
  right: -20px;
}
app-card-project .tooltip-card:after {
  top: -6px;
  right: 22px;
}
app-card-project .icon-melody, app-card-project .icon-disk {
  margin-left: 10px;
}
app-card-project .icon-melody img, app-card-project .icon-disk img {
  aspect-ratio: auto 1/1;
}
app-card-project .flipped {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
app-card-project .card-image-wrapper {
  width: 310px;
  height: 473px;
  overflow: hidden;
  border-radius: 20px;
  z-index: 2;
  margin: 0 12px 48px;
  position: absolute;
}
app-card-project .card-image-wrapper .card__background {
  width: 100%;
}
app-card-project .front {
  -webkit-backface-visibility: hidden;
}
app-card-project .back {
  top: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transform: rotateY(180deg);
  position: absolute;
  margin: 0 12px 48px;
  border-radius: 20px;
  width: 310px;
  overflow: hidden;
  height: 473px;
  display: flex !important;
  flex-direction: column;
  background-color: red;
  box-shadow: 0 4px 3px 0 rgba(128, 137, 160, 0.4);
}
app-card-project .video, app-card-project .upl__image {
  top: 0px;
  position: absolute;
  width: 310px;
  height: 473px;
  border-radius: 20px;
  object-fit: cover;
}
app-card-project .upl__image {
  z-index: 2;
}
app-card-project .card-outer {
  position: relative;
  margin: 0 12px 48px;
  border-radius: 20px;
  width: 309px;
  overflow: unset;
  height: 473px;
  display: flex !important;
  flex-direction: column;
  box-shadow: 0 4px 3px 0 rgba(128, 137, 160, 0.4);
}
app-card-project .card-outer .card__img {
  border-radius: 20px;
  min-height: 196px;
}
app-card-project .card-outer .card__img .image {
  width: 196px;
  height: 196px;
  object-fit: cover;
}
app-card-project .card-outer .lnr-calendar-full {
  cursor: auto;
}
app-card-project .card-outer .fa-question-circle {
  font-size: 16px;
  color: #5AA2E5;
  cursor: pointer;
}
app-card-project .card-outer .fa-question-circle:hover + .timer-hint .tooltip {
  display: block;
  right: -20px;
  top: -200px;
}
app-card-project .card-outer .card__logo {
  height: 100%;
  align-items: flex-start;
}
app-card-project .card-outer .card__logo .tooltip-card {
  width: 190px;
  top: 40px;
  right: -10px;
}
app-card-project .card-outer .card__logo .tooltip-card:after {
  top: -6px;
  right: 25px;
}
app-card-project .card-outer .informative__item {
  width: 134px;
  font-size: 12px;
}
app-card-project .card-outer .card__status-box {
  display: none;
}
app-card-project .card-outer .card__genres {
  font-size: 12px;
  width: 100%;
  line-height: 14px;
  height: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-card-project .card-outer .card__background {
  width: 310px;
}
app-card-project .card-outer .social-wrapper {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
}
app-card-project .card-outer .status-footer {
  padding: 0 20px;
  height: 77px;
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-top: 1px dashed #A8C1D3;
  font-weight: 900;
  font-size: 24px;
}
app-card-project .card-outer .status-footer .button {
  width: 240px;
  margin: 0 auto;
  font-size: 16px;
}
app-card-project .card-outer .status-footer .status-declined {
  font-weight: bold;
}
app-card-project .play-button {
  position: absolute;
  top: 45%;
  left: calc(50% - 25px);
  width: 50px;
  height: 50px;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
app-card-project .play-button.mobile {
  display: none;
}
app-card-project .footer-detail {
  position: relative;
  display: flex;
  align-items: flex-end;
}
app-card-project .footer-detail .auction-edition {
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  width: max-content;
}
app-card-project .card__footer {
  height: 80px;
  z-index: 4;
  padding: 10px 0 12px 0;
  margin: 0 12px;
  border-top: 1px solid #FFFFFF;
}
app-card-project .auction__title {
  font-weight: 700;
  font-size: 12px;
  color: #FFFFFF;
  margin-bottom: 10px;
}
app-card-project .card__content {
  height: 45%;
  flex: 1 0 auto;
  padding: 10px 13px 0;
  display: flex;
  z-index: 9;
  flex-direction: column;
}
app-card-project .headliner-project {
  position: relative;
}
app-card-project .card-collectible__auction-sh {
  display: flex;
  justify-content: space-between;
}
app-card-project .card-collectible__auction-sh .timer__timing {
  justify-content: flex-start;
}
app-card-project .card-collectible__auction-sh .timer__title {
  display: none;
}
app-card-project .card-collectible__auction-sh .auction__title {
  text-align: left;
}
app-card-project .card-collectible__auction-sh .tooltip-card {
  right: -20px;
  top: 30px;
  width: 190px;
}
app-card-project .card-collectible__auction-sh .tooltip-card:after {
  top: -6px;
  right: 20px;
}
app-card-project .card-collectible__auction-sh .icon-key, app-card-project .card-collectible__auction-sh .icon-gift {
  position: relative;
}
app-card-project .card-collectible__auction-sh .icon-gift {
  margin-left: 10px;
}
app-card-project .card-collectible__auction-sh .footer-detail {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}
app-card-project .card-collectible__auction-sh .footer-detail__icons {
  height: 20px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
app-card-project .card-collectible__auction-run {
  display: flex;
  justify-content: space-between;
}
app-card-project .card-collectible__auction-run .timer__timing {
  justify-content: flex-end;
}
app-card-project .card-collectible__auction-run .timer__title {
  display: none;
}
app-card-project .card-collectible__auction-run .auction__title {
  text-align: right;
}
app-card-project .card-collectible__auction-run .tooltip-card {
  left: -20px;
  top: 30px;
  width: 190px;
}
app-card-project .card-collectible__auction-run .tooltip-card:after {
  top: -6px;
  left: 20px;
}
app-card-project .card-collectible__auction-run .icon-key, app-card-project .card-collectible__auction-run .icon-gift {
  position: relative;
}
app-card-project .card-collectible__auction-run .icon-key {
  margin-right: 10px;
}
app-card-project .card-collectible__auction-run .footer-detail {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}
app-card-project .card-collectible__auction-run .footer-detail__icons {
  height: 20px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.card__informative {
  display: flex;
  justify-content: space-between;
}

.informative__item {
  font-size: 14px;
  padding: 2px 0;
  box-sizing: border-box;
  line-height: 16px;
  border-radius: 5px;
  visibility: hidden;
  text-align: center;
}
.informative__item.active {
  visibility: visible;
}

.info__holder {
  display: flex;
  justify-content: space-between;
}

.card__name {
  margin-bottom: 10px;
  z-index: 2;
  text-align: left;
  color: #fff;
}

.daysleft {
  color: #F95738;
}

.card__title-h2 {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  line-height: 24px;
  text-transform: uppercase;
}

.card__title-h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.card__name--padding {
  padding: 0 12px;
}

.card__song-name {
  font-size: 15px;
  text-align: left;
  font-weight: 500;
  line-height: 16px;
}

.card__status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.card__progress-bar {
  background: #FFFFFF;
  border-radius: 50px;
  margin: 10px 0 8px 0;
  overflow: hidden;
  height: 12px;
  width: 100%;
}
.card__progress-bar .result {
  position: absolute;
  height: 12px;
  border-radius: 50px;
}

.price__per-share {
  font-size: 12px;
  color: #fff;
  text-align: left;
  font-weight: 700;
}
.price__per-share .price__per-share-underline {
  text-decoration: line-through;
  padding-left: 5px;
}

.card__share {
  font-size: 12px;
  display: flex;
  color: #fff;
  justify-content: space-between;
  font-weight: 700;
}

.card__genres {
  text-align: center;
  width: calc(100% - 140px);
  font-size: 16px;
  font-weight: 800;
  color: #595A5A;
}

.card__status-box {
  width: 65px;
  font-size: 11px;
  z-index: 1;
  line-height: 25px;
  border-radius: 5px;
  color: #FFFFFF;
  text-align: center;
}
.card__status-box.new {
  background: #27AE60;
}
.card__status-box.invest {
  background: #FFA800;
}
.card__status-box.failed {
  background: #F95738;
}

.image-pin {
  position: absolute;
  top: 10px;
}

.card__end {
  width: 100%;
  font-size: 14px;
  margin: 6px auto;
  height: 40px;
  display: flex;
  background: black;
  font-weight: 700;
  color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  text-transform: uppercase;
}

.track__numbers {
  margin-bottom: 10px;
  align-self: flex-end;
  font-weight: 400;
  z-index: 2;
  padding: 0 12px;
  font-size: 12px;
  color: #fff;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
}

.card__logo {
  display: flex;
  z-index: 5;
  justify-content: flex-end;
}
.card__logo a {
  color: #ffffff;
}
.card__logo img {
  width: 18px;
  display: flex;
  align-items: flex-start;
  aspect-ratio: auto 9/11;
}

@media screen and (max-width: 767px) {
  app-card-project .card {
    margin: 0 auto;
  }
  app-card-project .play-button {
    position: absolute;
    top: 45%;
  }
  app-card-project .play-button.desktop {
    display: none;
  }
  app-card-project .play-button.mobile {
    display: flex;
  }
}
@media screen and (max-width: 320px) {
  app-card-project .card-outer {
    width: 290px;
  }
  app-card-project .card__background {
    width: 290px;
  }
}
app-card-news {
  color: #595A5A;
}
app-card-news .card-outer {
  position: relative;
  display: flex !important;
  flex-direction: column;
  background-color: #fff;
  margin: 0 12px 48px;
  box-shadow: 0 4px 4px rgba(128, 137, 160, 0.25);
}
app-card-news .card-outer .card__content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
app-card-news .card-outer .card__footer {
  padding: 10px 20px;
  height: 68px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
app-card-news .card__description {
  font-weight: 400;
  z-index: 2;
  font-size: 13px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
}
app-card-news .card__inner {
  padding: 0 20px;
  height: 240px;
  overflow: hidden;
}
app-card-news .card__date-box {
  display: flex;
  align-items: center;
}
app-card-news .followers-container {
  width: 30px;
  margin: 0 4px;
  height: 28px;
  justify-content: flex-end;
  word-wrap: normal;
}
app-card-news .social-wrapper {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}
app-card-news .card__date {
  padding-left: 8px;
}
app-card-news .card__content-inner {
  margin-top: 12px;
}
app-card-news .social-view {
  font-weight: 500;
  font-size: 14px;
  width: 144px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #5AA2E5;
}
app-card-news .social-view a:hover {
  text-decoration: none;
}
app-card-news .social-view iframe {
  padding-top: 15px;
}
app-card-news .card__title {
  font-size: 18px;
  font-weight: bold;
  line-height: 36px;
}
app-card-news .card__image {
  width: 100%;
}
app-card-news .card__image img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  app-card-news .card-outer {
    width: 310px;
  }
}
app-card-nft {
  color: #595A5A;
  position: relative;
  display: block;
  z-index: 2;
}
app-card-nft .inner-card {
  perspective: 1000px;
}
app-card-nft .card {
  -webkit-transition: transform 1.5s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
}
app-card-nft .flipped {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
app-card-nft .fa-play {
  font-size: 12px;
}
app-card-nft .card-image-wrapper {
  width: 310px;
  height: 473px;
  overflow: hidden;
  border-radius: 20px 20px 0px 0px;
  z-index: 2;
  margin: 0 12px 0px;
  position: absolute;
}
app-card-nft .card-image-wrapper .card__background {
  width: 100%;
}
app-card-nft .front {
  -webkit-perspective: 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
app-card-nft .back {
  top: 0px;
  -webkit-perspective: 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: rotateY(180deg);
  position: absolute;
  margin: 0 12px 0px;
  border-radius: 20px 20px 0px 0px;
  width: 310px;
  height: 473px;
  display: flex !important;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 4px 3px 0 rgba(128, 137, 160, 0.4);
}
app-card-nft .headliner__icons .tooltip-card {
  left: -5px;
  right: unset;
}
app-card-nft .headliner__icons .tooltip-card:after {
  right: unset;
  left: 5px;
}
app-card-nft .tooltip-component {
  display: block;
}
app-card-nft app-tooltip .hover-block {
  display: contents;
}
app-card-nft .video, app-card-nft .image-gif {
  top: 0;
  position: absolute;
  width: 310px;
  height: 473px;
  border-radius: 20px;
  object-fit: cover;
  border-radius: 20px 20px 0px 0px;
}
app-card-nft .nft-footer {
  display: flex;
  border-radius: 0px 0px 20px 20px;
  height: 100px;
  width: 310px;
  margin: 0 12px 24px;
  box-shadow: 0 4px 3px 0 rgba(128, 137, 160, 0.4);
  justify-content: center;
  align-items: center;
}
app-card-nft .sell {
  background-color: #5AA2E5 !important;
  color: #FFFFFF !important;
  font-style: normal;
  font-weight: 700;
  width: 220px;
  height: 50px;
}
app-card-nft .card-outer {
  position: relative;
  margin: 0 12px 0px;
  border-radius: 20px 20px 0px 0px;
  width: 310px;
  overflow: unset;
  height: 473px;
  display: flex !important;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 -1px 2px 0 rgba(128, 137, 160, 0.4);
}
app-card-nft .card-outer .card__img {
  border-radius: 20px;
  min-height: 196px;
}
app-card-nft .card-outer .card__img .image {
  width: 196px;
  height: 196px;
  object-fit: cover;
}
app-card-nft .card-outer .lnr-calendar-full {
  cursor: auto;
}
app-card-nft .card-outer .fa-question-circle {
  font-size: 16px;
  color: #5AA2E5;
  cursor: pointer;
}
app-card-nft .card-outer .fa-question-circle:hover + .timer-hint .tooltip {
  display: block;
  right: -20px;
  top: -200px;
}
app-card-nft .card-outer .card__logo-title {
  width: 140px;
}
app-card-nft .card-outer .card__logo {
  height: 100%;
  align-items: flex-start;
}
app-card-nft .card-outer .card__logo .tooltip-card {
  width: 190px;
  top: 40px;
  right: -10px;
}
app-card-nft .card-outer .card__logo .tooltip-card:after {
  top: -6px;
  right: 25px;
}
app-card-nft .card-outer .card__title {
  font-weight: 700;
  font-size: 14px;
}
app-card-nft .card-outer .informative__item {
  width: 134px;
  font-size: 12px;
}
app-card-nft .card-outer .card__status-box {
  display: none;
}
app-card-nft .card-outer .card__genres {
  font-size: 12px;
  width: 100%;
  line-height: 14px;
  height: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-card-nft .card-outer .card__background {
  width: 310px;
}
app-card-nft .card-outer .social-wrapper {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
}
app-card-nft .card-outer .status-footer {
  padding: 0 20px;
  height: 77px;
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-top: 1px dashed #A8C1D3;
  font-weight: 900;
  font-size: 24px;
}
app-card-nft .card-outer .status-footer .button {
  width: 240px;
  margin: 0 auto;
  font-size: 16px;
}
app-card-nft .card-outer .status-footer .status-declined {
  font-weight: bold;
}
app-card-nft .chvr-left {
  position: absolute;
  top: 46.5%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  background: url("/assets/images/chvr-left.png") no-repeat center;
}
app-card-nft .chvr-right {
  position: absolute;
  right: calc(15% - 23px);
  top: 46.5%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background: url("/assets/images/chvr-right.png") no-repeat center;
  display: flex;
}
app-card-nft .play-button {
  position: absolute;
  top: 45%;
  left: calc(50% - 25px);
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-card-nft .footer-detail {
  position: relative;
  display: flex;
  align-items: flex-end;
}
app-card-nft .footer-detail .auction-edition {
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}
app-card-nft .card__footer {
  height: 80px;
  z-index: 4;
  padding: 6px 0;
  margin: 0 12px;
  border-top: 1px solid #FFFFFF;
}
app-card-nft .auction__title {
  font-weight: 700;
  font-size: 12px;
  color: #FFFFFF;
  margin-bottom: 10px;
}
app-card-nft .card__content {
  height: 45%;
  flex: 1 0 auto;
  padding: 10px 13px 0;
  display: flex;
  z-index: 2;
  flex-direction: column;
}
app-card-nft .card__header {
  height: 49px;
  z-index: 2;
  padding: 12px 12px 0;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
}
app-card-nft .card__header:hover {
  z-index: 11 !important;
}
app-card-nft .card__header .card__header-col {
  width: 33.33%;
}
app-card-nft .headliner-project {
  position: relative;
  z-index: 0;
}
app-card-nft .card-collectible__auction-sh {
  display: flex;
  justify-content: space-between;
}
app-card-nft .card-collectible__auction-sh .timer__timing {
  justify-content: flex-start;
}
app-card-nft .card-collectible__auction-sh .timer__title {
  display: none;
}
app-card-nft .card-collectible__auction-sh .tooltip-card {
  right: -20px;
  top: 30px;
  width: 190px;
}
app-card-nft .card-collectible__auction-sh .tooltip-card:after {
  top: -6px;
  right: 20px;
}
app-card-nft .card-collectible__auction-sh .icon-key, app-card-nft .card-collectible__auction-sh .icon-gift {
  position: relative;
}
app-card-nft .card-collectible__auction-sh .icon-gift {
  margin-left: 10px;
}
app-card-nft .card-collectible__auction-sh .footer-detail {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}
app-card-nft .card-collectible__auction-sh .footer-detail__icons {
  height: 20px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
app-card-nft .card-collectible__auction-run {
  display: flex;
  justify-content: space-between;
}
app-card-nft .card-collectible__auction-run .timer__timing {
  justify-content: flex-end;
}
app-card-nft .card-collectible__auction-run .timer__title {
  display: none;
}
app-card-nft .card-collectible__auction-run .auction__title {
  text-align: right;
}
app-card-nft .card-collectible__auction-run .tooltip-card {
  left: -20px;
  top: 30px;
  width: 190px;
}
app-card-nft .card-collectible__auction-run .tooltip-card:after {
  top: -7px;
  left: 20px;
}
app-card-nft .card-collectible__auction-run .icon-key, app-card-nft .card-collectible__auction-run .icon-gift {
  position: relative;
}
app-card-nft .card-collectible__auction-run .icon-key {
  margin-right: 10px;
}
app-card-nft .card-collectible__auction-run .footer-detail {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}
app-card-nft .card-collectible__auction-run .footer-detail__icons {
  height: 20px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
app-card-nft .tooltip-link {
  color: #fff;
}
app-card-nft .rights-text {
  margin-left: 10px;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
}
app-card-nft .track-numbers {
  font-size: 12px;
  align-self: flex-end;
  padding-right: 12px;
}
app-card-nft .artist-collection-text {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  display: inline-block;
  color: #FFFFFF;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
app-card-nft .headliner__icons {
  position: relative;
  display: flex;
  z-index: 2;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
}
app-card-nft .headliner__icons .icon-melody {
  margin-left: 10px;
}
app-card-nft .headliner__icons .icon-disk, app-card-nft .headliner__icons .icon-melody {
  position: relative;
  height: 18px;
}
app-card-nft .headliner__icons .tooltip-card {
  width: 190px;
  top: 30px;
  right: -20px;
}
app-card-nft .headliner__icons .tooltip-card:after {
  top: -7px;
  right: 22px;
}
app-card-nft .year {
  color: #ffffff;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 22px;
}
app-card-nft .card__title {
  font-weight: bold;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-transform: uppercase;
  color: #ffffff;
}
app-card-nft .card__informative {
  display: flex;
  justify-content: space-between;
}
app-card-nft .informative__item {
  font-size: 14px;
  padding: 2px 0;
  box-sizing: border-box;
  line-height: 16px;
  border-radius: 5px;
  visibility: hidden;
  text-align: center;
}
app-card-nft .informative__item.active {
  visibility: visible;
}
app-card-nft .card__name {
  margin-bottom: 10px;
  z-index: 2;
  color: #fff;
  display: flex;
  justify-content: space-between;
}
app-card-nft .daysleft {
  color: #F95738;
}
app-card-nft .card__title-h3 {
  font-size: 16px;
  text-align: left;
}
app-card-nft .card__title-h2 {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  line-height: 24px;
  text-transform: uppercase;
}
app-card-nft .card__name--padding {
  padding: 0 12px;
}
app-card-nft .card__song-name {
  font-size: 15px;
  text-align: left;
  font-weight: 500;
  line-height: 16px;
}
app-card-nft .card__status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
app-card-nft .card__progress-bar {
  background: #FFFFFF;
  border-radius: 50px;
  margin: 10px 0 8px 0;
  overflow: hidden;
  height: 12px;
  width: 100%;
}
app-card-nft .card__progress-bar .result {
  height: 12px;
  border-radius: 50px;
}
app-card-nft .price__per-share {
  font-size: 12px;
  color: #fff;
  font-weight: 700;
}
app-card-nft .card__share {
  font-size: 12px;
  display: flex;
  color: #fff;
  justify-content: space-between;
  font-weight: 700;
}
app-card-nft .card__genres {
  text-align: center;
  width: calc(100% - 140px);
  font-size: 16px;
  font-weight: 800;
  color: #595A5A;
}
app-card-nft .card__status-box {
  width: 65px;
  font-size: 11px;
  z-index: 1;
  line-height: 25px;
  border-radius: 5px;
  color: #FFFFFF;
  text-align: center;
}
app-card-nft .card__status-box.new {
  background: #27AE60;
}
app-card-nft .card__status-box.invest {
  background: #FFA800;
}
app-card-nft .card__status-box.failed {
  background: #F95738;
}
app-card-nft .image-pin {
  position: absolute;
  top: 10px;
}
app-card-nft .card__end {
  width: 100%;
  font-size: 14px;
  height: 40px;
  display: flex;
  background: black;
  font-weight: 700;
  color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  text-transform: uppercase;
}
app-card-nft .card__description {
  font-weight: 400;
  z-index: 2;
  padding: 0 12px;
  font-size: 12px;
  color: #fff;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
}
app-card-nft .card__logo {
  display: flex;
  z-index: 5;
  justify-content: flex-end;
}
app-card-nft .card__logo img {
  width: 18px;
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: 767px) {
  app-card-nft .card {
    margin: 0 auto;
  }
}
@media screen and (max-width: 320px) {
  app-card-nft .card-outer {
    width: 290px;
  }
  app-card-nft .card__background {
    width: 290px;
  }
}
app-timer .timer {
  width: 217px;
  height: 133px;
  background: #DFEBF4;
  box-shadow: 0 2px 4px rgba(144, 170, 190, 0.5);
  border-radius: 7px;
}
app-timer .timer.cardBlack {
  background: transparent;
  margin: 0 auto;
  box-shadow: none;
  height: auto;
}
app-timer .timer.cardBlack .timer__title {
  font-size: 11px;
  padding: 0 0 8px 0;
}
app-timer .timer.cardBlack .timer__timing-item {
  height: 32px;
  width: 30px;
  margin: 0 4px;
  background: #000;
  font-size: 20px;
  line-height: 32px;
}
app-timer .timer.cardBlack .timer__timing-item:first-child {
  margin-left: 0;
}
app-timer .timer.cardBlack .timer__timing-item:last-child {
  margin-right: 0;
}
app-timer .timer__title {
  font-weight: 700;
  font-size: 15px;
  text-align: center;
  color: #000000;
  padding: 15px 0;
}
app-timer .timer__timing {
  display: flex;
  justify-content: center;
}
app-timer .timer__timing-item {
  width: fit-content;
  height: 38px;
  min-width: 34px;
  padding: 0 5px;
  text-align: center;
  line-height: 38px;
  font-size: 22px;
  color: #FFFFFF;
  font-weight: 700;
  margin: 0 4px;
  background: #8089A0;
  border-radius: 5px;
  outline: 1px solid rgba(255, 255, 255, 0.6);
}

app-card-information {
  color: #595A5A;
}
app-card-information .card-outer {
  position: relative;
  display: flex !important;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 12px 48px;
  box-shadow: 0 4px 4px rgba(128, 137, 160, 0.25);
}
app-card-information .card-outer .card__content {
  flex: 1 0 auto;
  display: flex;
  padding: 40px 15px;
  flex-direction: column;
}
app-card-information .description {
  font-size: 14px;
}
app-card-information .item-highlighted {
  position: absolute;
  left: 0;
  width: 140px;
  padding: 7px 0;
  background: #FFA800;
  top: 0;
  border-radius: 5px 0;
  text-align: center;
}
app-card-information .item-highlighted.ends {
  background: #F95738;
}
app-card-information .item-highlighted.album {
  position: relative;
  left: -20px;
  width: auto;
  display: flex;
  padding-right: 15px;
  padding-left: 25px;
}
app-card-information .item-highlighted.released {
  background: #9a463d;
}
app-card-information .item-highlighted.news {
  background: #F95738;
}
app-card-information .item-highlighted.new {
  background: #27AE60;
}
app-card-information .item-highlighted.newcomer {
  background: #006e8d;
}
app-card-information .item-highlighted.headliner {
  background: #00c7f8;
}
app-card-information .item-highlighted.talents {
  background: #B7BCCB;
}
app-card-information .item-highlighted.funded {
  background: #5AA2E5;
}
app-card-information .item-highlighted.failed {
  background: #8089A0;
}
app-card-information .item-highlighted span {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}
app-card-information .img-block {
  width: 100px;
  margin-right: 25px;
  margin-bottom: 12px;
}
app-card-information .img-block img {
  width: 100%;
}

:host {
  display: flex;
  gap: 16px;
}
@media (max-width: 576px) {
  :host {
    flex-direction: column;
    gap: 0;
  }
}

.refund__text {
  padding-top: 8px;
}
@media (max-width: 576px) {
  .refund__select-type {
    width: 100%;
  }
}

app-subscribe-newsletter {
  display: block;
  position: relative;
}
app-subscribe-newsletter .subscribe-title {
  text-align: center;
  font-weight: 900;
  font-size: 27px;
  margin-bottom: 25px;
}
app-subscribe-newsletter .subscribe__button-wrapper {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}
app-subscribe-newsletter .subscribe__button {
  margin-left: 20px;
}
app-subscribe-newsletter .subscribe__input {
  border-radius: 50px;
  border: none;
  width: 325px;
  padding: 0 20px;
  font-size: 16px;
}
app-subscribe-newsletter input::-webkit-input-placeholder {
  /* WebKit browsers */
  font-size: 16px;
  text-align: center;
}
app-subscribe-newsletter input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 16px;
  text-align: center;
}
app-subscribe-newsletter input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 16px;
  text-align: center;
}
app-subscribe-newsletter input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 16px;
  text-align: center;
}
app-subscribe-newsletter .subscribe__text {
  text-align: center;
  margin-top: 15px;
  font-weight: 400;
  font-size: 12px;
  color: #8089A0;
}
app-subscribe-newsletter .subscribe__text .link-blue {
  font-size: 12px;
}
app-subscribe-newsletter .subscribe__success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
app-subscribe-newsletter .subscribe__success img {
  width: 50px;
  object-fit: contain;
}
app-subscribe-newsletter .subscribe__success p {
  font-weight: 900;
  font-size: 27px;
  margin-top: 16px;
  text-align: center;
}
app-subscribe-newsletter .subscribe--new .subscribe-title {
  font-size: 20px;
  margin-bottom: 18px;
  text-align: center;
}
app-subscribe-newsletter .subscribe--new .subscribe__input {
  border-radius: 27px;
  border: 1px solid #8089A0;
  width: 100%;
  padding: 16px 20px;
  font-size: 18px;
  text-transform: none;
}
app-subscribe-newsletter .subscribe--new .subscribe__button {
  width: 100%;
  margin: 15px auto;
}

@media screen and (max-width: 767px) {
  app-subscribe-newsletter .subscribe-title {
    padding: 0 20px;
    margin-bottom: 15px;
  }
  app-subscribe-newsletter .subscribe__text span {
    display: inline-block;
    width: 100%;
  }
}
app-project-song-detail .song-value {
  white-space: nowrap;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-project-song-detail .legacy-block {
  position: relative;
}
app-project-song-detail .legacy-block .fa-music {
  position: relative;
  font-size: 14px;
  top: 0;
  left: 0;
  margin-right: 8px;
  color: #5AA2E5;
}
app-project-song-detail .legacy-block span {
  font-weight: 500;
}
app-project-song-detail .legacy-block .legacy__text {
  margin-top: 10px;
  font-size: 14px;
  color: #8089A0;
}
app-project-song-detail .legacy-block .legacy__text .legacy__text-blue {
  color: #5AA2E5;
}
app-project-song-detail .project__detail {
  position: relative;
  display: flex;
}
app-project-song-detail .project__detail .item-content-title {
  line-height: 23px;
  font-weight: 900;
  font-size: 27px;
}
app-project-song-detail .detail-song-info__static {
  display: flex;
  padding-top: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}
app-project-song-detail .grs-details {
  width: 100%;
}
app-project-song-detail .grs-details__body {
  display: flex;
  color: #8089A0;
  justify-content: space-between;
}
app-project-song-detail .grs-details__body .grs-details__col {
  width: 100%;
}
app-project-song-detail .grs-details__body .grs-details__col li {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  min-height: 24px;
}
app-project-song-detail .grs-details__body .grs-details__col .grs-details__line {
  color: #000;
  font-weight: bold;
}

@media screen and (max-width: 1490px) {
  app-project-song-detail .detail-song-info__static-text {
    padding: 0 12px;
  }
}
@media screen and (max-width: 767px) {
  app-project-song-detail .artist-name-wrapper {
    display: none;
  }
  app-project-song-detail .detail-song-info__static {
    flex-direction: column;
    padding-top: 10px;
    height: unset;
  }
  app-project-song-detail .detail-song-info__static-col {
    width: 100%;
    padding: 5px 0;
    margin-right: 0;
  }
  app-project-song-detail .detail-song-info__static-text {
    display: flex;
  }
  app-project-song-detail .detail-song-info__static-text p {
    padding-left: 5px;
  }
  app-project-song-detail .grs-details__col li {
    height: 25px;
  }
  app-project-song-detail .teaser {
    margin: 15px 0 0;
    display: block;
    height: auto;
    overflow: unset;
  }
  app-project-song-detail .song-value {
    padding-left: 20px;
  }
  app-project-song-detail .grs-details__col p {
    white-space: nowrap;
  }
}
app-menu-search .search {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
app-menu-search .search__col {
  display: flex;
  align-items: center;
}
app-menu-search .search__col.search__col--direction {
  flex-direction: column;
}
app-menu-search .search__col.item__col_j-end {
  justify-content: center;
  align-items: flex-end;
}
app-menu-search .search__clear {
  cursor: pointer;
}
app-menu-search .search__clear .button {
  border: #C4C4C4;
  color: #C4C4C4;
  height: 40px;
  min-width: 120px;
  background: transparent;
}
app-menu-search .search__clear .button.active {
  border: #5AA2E5;
  color: #5AA2E5;
}
app-menu-search .search__clear span {
  color: #5AA2E5;
  font-size: 18px;
}
app-menu-search .sort .sort__value {
  color: #5AA2E5;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
app-menu-search .sort .sort__title {
  font-size: 12px;
}
app-menu-search .sort .sort__text {
  padding-right: 10px;
}
app-menu-search .sort .fas {
  color: #C4C4C4;
}
app-menu-search .sort .fas.active {
  color: #5AA2E5;
}
app-menu-search .arrows-tablet {
  display: flex;
  align-items: center;
}
app-menu-search .arrows-tablet .fa-calendar-minus {
  padding-right: 10px;
}
app-menu-search .arrows-tablet svg path {
  fill: white;
}
app-menu-search .search__arrow {
  display: none;
}
app-menu-search .clear__desktop {
  display: block;
}
app-menu-search .clear__mobile {
  display: none;
}
app-menu-search .sort__dropdown {
  display: flex;
}

@media screen and (max-width: 1400px) {
  app-menu-search .search {
    max-width: 1240px;
  }
  app-menu-search .mobile__view {
    width: 160px;
  }
}
@media screen and (max-width: 1280px) {
  app-menu-search .search {
    max-width: 90%;
  }
  app-menu-search .clear__desktop {
    display: none;
  }
  app-menu-search .clear__mobile {
    display: block;
  }
  app-menu-search .mobile__view {
    width: 150px;
  }
}
@media screen and (max-width: 1120px) {
  app-menu-search .mobile__view {
    width: 140px;
  }
}
@media screen and (max-width: 1024px) {
  app-filter-mobile .search {
    width: 100%;
  }
  app-filter-mobile .search__arrow {
    display: flex;
  }
}
app-pdf-popup .lnr.default-cross {
  position: absolute;
  right: -50px;
  top: 0;
  cursor: pointer;
  font-size: 24px;
  color: #fff;
  opacity: 1;
  border: 10px solid transparent;
}

@media screen and (max-width: 767px) {
  app-pdf-popup embed {
    width: 100%;
  }
  app-pdf-popup .lnr.default-cross {
    right: 0;
    top: -50px;
  }
  app-pdf-popup .popup-wrapper {
    overflow: unset;
  }
  app-pdf-popup .embed-image {
    width: 100%;
  }
}
:host {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
}
:host.active {
  opacity: 1;
}
:host.active .item, :host.active .prev, :host.active .next {
  cursor: pointer;
}
:host .item, :host .prev, :host .next {
  cursor: default;
  color: #5AA2E5;
}
:host .item {
  padding: 0 5px;
  display: inline-block;
  line-height: 26px;
  margin: 0 2px;
}
:host .item.active, :host .item:hover {
  color: #B7BCCB;
}
:host .item.active {
  font-family: 700;
  cursor: default;
}
:host .prev {
  margin-right: 40px;
  text-transform: uppercase;
}
:host .next {
  margin-left: 40px;
  text-transform: uppercase;
}
:host .splitter {
  margin: 0 3px;
}
:host .disabled, :host .disabled:hover {
  color: #cacaca;
  background-color: transparent;
  cursor: default;
}
:host .range__text {
  font-size: 12px;
}
:host .mat-form-field {
  margin: 0 20px;
  width: 50px;
}

app-sorting-home-mobile .m-sort-tab {
  display: none;
}
@media screen and (max-width: 1024px) {
  app-sorting-home-mobile .m-sort-tab {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 0;
    overflow: hidden;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.15);
    background: #fff;
    z-index: 500;
    width: 100%;
    transition: height 0.25s ease-out;
  }
  app-sorting-home-mobile .m-sort-tab.active {
    transition: height 0.25s ease-out;
    height: 365px;
    width: 100%;
  }
  app-sorting-home-mobile .m-sort-tab .sort-tab__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 0;
    font-weight: 700;
    color: #000;
  }
  app-sorting-home-mobile .m-sort-tab .sort-tab__header .picture-cross {
    position: absolute;
    right: 15px;
    top: 18px;
  }
  app-sorting-home-mobile .m-sort-tab .sort-tab__item-s {
    border-top: 1px dashed #A8C1D3;
  }
  app-sorting-home-mobile .m-sort-tab .item-s {
    display: flex;
    align-items: center;
  }
  app-sorting-home-mobile .m-sort-tab .item-s:last-child {
    justify-content: flex-end;
  }
  app-sorting-home-mobile .m-sort-tab .item-s__title {
    font-size: 14px;
    padding-left: 50px;
    font-weight: normal;
    color: #8089A0;
  }
  app-sorting-home-mobile .m-sort-tab .item-s__title.active {
    color: #000;
  }
  app-sorting-home-mobile .m-sort-tab .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 30px;
  }
  app-sorting-home-mobile .m-sort-tab .col-50:first-child {
    width: 70%;
  }
  app-sorting-home-mobile .m-sort-tab .col-50:last-child {
    width: 30%;
  }
  app-sorting-home-mobile .sort-icon {
    position: relative;
  }
  app-sorting-home-mobile .sort-icon:before {
    content: "";
    position: absolute;
    transform: translateY(-50%);
    top: 0;
    left: 2px;
    width: 19px;
    height: 21px;
  }
  app-sorting-home-mobile .sort-icon.sort-icon--end-soonest-active:before {
    background-image: url("/assets/images/home-sorting/date_asc.svg");
  }
  app-sorting-home-mobile .sort-icon.sort-icon--end-soonest-disable:before {
    background-image: url("/assets/images/home-sorting/date_desc.svg");
  }
  app-sorting-home-mobile .sort-icon.sort-icon--share-disable:before {
    width: 23px;
    background-image: url("/assets/images/home-sorting/share-disable.svg");
  }
  app-sorting-home-mobile .sort-icon.sort-icon--share-enable:before {
    width: 23px;
    background-image: url("/assets/images/home-sorting/share-enable.svg");
  }
  app-sorting-home-mobile .sort-icon.sort-icon--newest-enable:before {
    width: 23px;
    left: -1px;
    background-image: url("/assets/images/home-sorting/newest_first.svg");
  }
  app-sorting-home-mobile .sort-icon.sort-icon--newest-disable:before {
    width: 23px;
    left: -1px;
    background-image: url("/assets/images/home-sorting/newest_last.svg");
  }
}

app-delete-popup .inner {
  width: 460px;
  max-width: 90vw;
  position: relative;
  padding: 40px 20px;
  text-align: center;
}
app-delete-popup .button {
  min-width: 160px;
}
app-delete-popup .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-delete-popup .block-title {
  font-weight: 900;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 10px;
}
app-delete-popup .sub-text {
  font-size: 15px;
  text-align: center;
  margin-bottom: 10px;
  color: #000;
  line-height: 24px;
}
app-delete-popup .text {
  margin: 30px 0 10px;
  font-weight: bold;
  font-size: 21px;
}
app-delete-popup .text.text-uppercase {
  text-transform: uppercase;
}
app-delete-popup .image {
  display: block;
  margin: 0 auto;
  height: 130px;
}
app-delete-popup .button-wrapper {
  display: flex;
  width: 100%;
  font-size: 14px;
  margin: 30px auto 0;
  justify-content: space-around;
  align-items: center;
}
app-delete-popup .button-wrapper .cancel {
  color: #5AA2E5;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  app-delete-popup .text br {
    display: none;
  }
  app-delete-popup .inner {
    padding: 40px 10px;
  }
  app-delete-popup .button {
    min-width: 150px;
  }
}
app-alert-popup .inner {
  width: 460px;
  max-width: 90vw;
  position: relative;
  padding: 40px 20px;
  text-align: center;
  flex-direction: column;
}
app-alert-popup .button {
  min-width: 160px;
  max-width: 180px;
}
app-alert-popup .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-alert-popup .block-title {
  font-weight: 900;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 10px;
}
app-alert-popup .text, app-alert-popup .sub-text {
  font-size: 15px;
  text-align: center;
  margin-bottom: 10px;
}
app-alert-popup .text {
  margin-top: 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}
app-alert-popup .sub-text {
  color: #8089A0;
}
app-alert-popup .image {
  display: block;
  margin: 0 auto;
  height: 130px;
}
app-alert-popup .button-wrapper {
  display: flex;
  width: 100%;
  font-size: 14px;
  margin: 30px auto 0;
  justify-content: space-around;
  align-items: center;
}
app-alert-popup .button-wrapper .cancel {
  color: #5AA2E5;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  app-alert-popup .text br {
    display: none;
  }
  app-alert-popup .inner {
    padding: 40px 10px;
  }
  app-alert-popup .button {
    min-width: 150px;
  }
}
app-call-event-popup .inner {
  width: 560px;
  max-width: 90vw;
  position: relative;
  padding: 40px;
  text-align: center;
}
app-call-event-popup .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-call-event-popup .block-title {
  font-weight: 900;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 10px;
}
app-call-event-popup .text {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 30px;
}
app-call-event-popup .text h1 {
  font-size: 24px;
  font-weight: bold;
}
app-call-event-popup .sign-in {
  margin: 30px 20px;
  padding: 30px 20px;
  border-top: 1px dashed #C4C4C4;
  border-bottom: 1px dashed #C4C4C4;
}
app-call-event-popup .sub-text {
  margin-top: 20px;
  font-weight: normal;
  color: #000;
}
app-call-event-popup .checkbox-container {
  align-items: flex-start;
}
app-call-event-popup .agreement-text {
  font-weight: normal;
  text-align: left;
  padding-left: 15px;
}
app-call-event-popup .agreement-text span {
  font-weight: 500;
}
app-call-event-popup .button-wrapper {
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  padding: 0 20px;
}
app-call-event-popup .button-wrapper .cancel {
  color: #5AA2E5;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500;
}
app-call-event-popup .button-wrapper .button {
  width: 213px;
  margin-left: 60px;
  height: 50px;
  font-weight: bold;
  font-size: 16px;
}
app-call-event-popup .image {
  display: block;
  margin: 0 auto;
  height: 130px;
}

@media screen and (max-width: 767px) {
  app-verify-email-popup .text br {
    display: none;
  }
}
app-investor-popup .inner {
  width: 580px;
  max-width: 90vw;
  position: relative;
  padding: 40px;
  text-align: left;
}
app-investor-popup .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-investor-popup .text-head {
  text-align: center;
  font-weight: bold;
}
app-investor-popup .sub-text-bold {
  font-weight: bold;
  color: #000;
}
app-investor-popup .block-title {
  font-weight: 900;
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 10px;
}
app-investor-popup .text {
  text-align: left;
  margin-bottom: 10px;
  margin-top: 20px;
}
app-investor-popup .text h1 {
  font-size: 24px;
  font-weight: bold;
}
app-investor-popup .sign-in {
  margin: 30px 20px;
  padding: 30px 20px;
  border-top: 1px dashed #C4C4C4;
  border-bottom: 1px dashed #C4C4C4;
}
app-investor-popup .sub-text {
  margin-top: 20px;
  font-weight: normal;
  color: #000;
}
app-investor-popup .agreement-text {
  font-weight: normal;
  text-align: left;
  padding-left: 15px;
}
app-investor-popup .agreement-text span {
  font-weight: 500;
}
app-investor-popup .button-wrapper {
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  padding: 0 20px;
}
app-investor-popup .button-wrapper .cancel {
  color: #B7BCCB;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  border-color: #8089A0;
}
app-investor-popup .button-wrapper .cancel:hover {
  color: #5AA2E5;
}
app-investor-popup .button-wrapper .button {
  width: 213px;
  margin-left: 170px;
  margin-right: 700x;
  height: 50px;
  font-weight: bold;
  font-size: 16px;
}
app-investor-popup .image {
  display: block;
  margin: 0 auto;
  height: 130px;
}

@media screen and (max-width: 767px) {
  app-investor-popup .text br {
    display: none;
  }
  app-investor-popup .text {
    text-align: center;
  }
  app-investor-popup .button-wrapper .button {
    width: 213px;
    margin-left: 37px;
    margin-right: 700x;
    height: 50px;
    font-weight: bold;
    font-size: 16px;
  }
}
app-share-popup .inner {
  width: 580px;
  max-width: 90vw;
  position: relative;
  padding: 37px 20px 40px 20px;
  text-align: left;
  overflow: hidden;
}
app-share-popup .facebook-icon {
  width: 50px;
  height: 50px;
  background: url("../../../../assets/images/social/facebook_new.png") center;
  background-size: cover;
  font-size: 69px;
}
app-share-popup .icon-block {
  display: block;
  height: 50px;
}
app-share-popup .btn-share {
  width: 100%;
  height: 50px;
}
app-share-popup .social {
  width: 50px;
  cursor: pointer;
}
app-share-popup .social .circle:before {
  width: 50px;
  height: 50px;
}
app-share-popup .social.facebook .fa-facebook-f {
  font-size: 22px;
  left: 18px;
  top: 14px;
}
app-share-popup .social.twitter .fa-twitter {
  font-size: 22px;
  left: 15px;
  top: 15px;
}
app-share-popup .social.linkedin .fa-linkedin {
  font-size: 22px;
  left: 16px;
  top: 15px;
}
app-share-popup .social.pinterest .fa-pinterest-p {
  font-size: 26px;
  left: 15px;
  top: 12px;
}
app-share-popup .social.whatsapp .fa-whatsapp {
  font-size: 28px;
  left: 12px;
  top: 11px;
}
app-share-popup .mat-mini-fab.mat-accent {
  background-color: #0077b5;
}
app-share-popup .text-icon {
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
}
app-share-popup .mat-fab.mat-accent {
  background-color: #ffffff !important;
}
app-share-popup .mat-fab {
  box-shadow: none !important;
}
app-share-popup .mat-fab .mat-button-wrapper {
  height: 100%;
  position: relative;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
app-share-popup .mat-mini-fab .mat-button-wrapper {
  padding: 14px 0;
  display: inline-block;
  line-height: 24px;
}
app-share-popup .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-share-popup .text-head {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 24px;
}
app-share-popup section {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
app-share-popup .share-button-row {
  width: 100%;
  border-top: 1px dashed #C4C4C4;
  padding-top: 34px;
}
app-share-popup .share-button-row button {
  display: table-cell;
}
app-share-popup .share-button-container.ws {
  display: none;
}
app-share-popup .share-flex-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
app-share-popup .share-button-container {
  display: flex;
  justify-content: center;
  color: #000;
  width: 25%;
  flex-direction: column;
}
app-share-popup h1 {
  font-size: 20px;
  font-weight: bold;
}

.agreement-text {
  font-weight: normal;
  text-align: left;
  padding-left: 15px;
}
.agreement-text span {
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  app-share-popup .text br {
    display: none;
  }
  app-share-popup .inner {
    width: 100%;
    max-height: 90%;
    overflow: hidden !important;
    padding: 20px 30px 30px 30px;
    height: 290px !important;
  }
  app-share-popup .share-flex-container {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 85px 85px;
    justify-content: space-evenly;
    align-items: flex-start;
  }
  app-share-popup .share-button-row {
    padding-top: 30px;
  }
  app-share-popup .share-button-container.ws {
    display: flex;
  }
  app-share-popup .share-button-row {
    display: grid;
  }
  app-share-popup .text-head {
    font-size: 18px;
    text-transform: initial;
  }
  app-share-popup .share-button-container {
    display: flex;
    justify-content: flex-end;
    color: #000;
    width: 100%;
    height: 100%;
  }
  app-share-popup .share-button-container:nth-child(1), app-share-popup .share-button-container:nth-child(2), app-share-popup .share-button-container:nth-child(3) {
    justify-content: flex-start;
  }
  app-share-popup .share-button-container:first-child .mat-button-wrapper, app-share-popup .share-button-container:nth-child(4) .mat-button-wrapper {
    justify-content: flex-start;
  }
  app-share-popup .share-button-container:first-child .text-icon, app-share-popup .share-button-container:nth-child(4) .text-icon {
    text-align: left;
  }
  app-share-popup .share-button-container:nth-child(3) .mat-button-wrapper {
    justify-content: flex-end;
  }
  app-share-popup .share-button-container:nth-child(3) .text-icon {
    text-align: right;
  }
}
app-menu-search .deposit__button {
  margin: 0;
}

app-transfer-money-popup .inner {
  width: 300px;
  max-width: 90vw;
  position: relative;
  padding: 40px 20px;
  text-align: center;
}
app-transfer-money-popup .payment-item {
  border: 2px solid #5AA2E5;
  position: relative;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 45px;
  margin: 10px;
  cursor: pointer;
}
app-transfer-money-popup .payment-item .payment-item-text {
  position: relative;
  width: 50%;
  font-size: 11px;
  z-index: 50;
}
app-transfer-money-popup .payment-item .payment-item-text-pay {
  position: relative;
  display: block;
  font-size: 12px;
  font-weight: 900;
}
app-transfer-money-popup .payment-item img {
  max-width: 70%;
}
app-transfer-money-popup .payment-item .maestro-logo {
  width: 21%;
}
app-transfer-money-popup .payment-item .payment-klarna {
  padding: 0 50px;
}
app-transfer-money-popup .payment-item .klarna-logo.klarnaMore10 {
  max-width: 100%;
  width: 100%;
  text-align: center;
  padding-left: 0;
}
app-transfer-money-popup .payment-item .klarna-logo .klarna-img {
  width: 55px;
  max-width: 55px;
}
app-transfer-money-popup .payment-item .klarna-text {
  padding-left: 10px;
}
app-transfer-money-popup .payment-item:hover:not(.disabled), app-transfer-money-popup .payment-item.active {
  background: #DFEBF4;
}
app-transfer-money-popup .payment-item.disabled {
  opacity: 0.5;
  filter: grayscale(100%);
  cursor: default;
}
app-transfer-money-popup .block-title {
  font-weight: 900;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 10px;
}
app-transfer-money-popup .transfer-money__amount {
  width: 240px;
}
app-transfer-money-popup .transfer-money__payment-type {
  display: flex;
  flex-direction: column;
  align-items: center;
}
app-transfer-money-popup .direct-payment {
  margin: 10px;
  width: 100%;
}
app-transfer-money-popup .direct-payment .google-pay {
  display: flex;
  justify-content: center;
  width: 100%;
}
app-transfer-money-popup .direct-payment.disablePayment {
  pointer-events: none;
  opacity: 0.4;
}
app-transfer-money-popup #deposit {
  font-size: 16px;
}
app-transfer-money-popup .transfer-money__button {
  margin-top: 20px;
  width: 240px !important;
  position: relative;
  text-transform: uppercase;
}
app-transfer-money-popup .transfer-money__button mat-spinner {
  position: absolute;
  left: 10px;
  top: 10px;
}
app-transfer-money-popup .transfer-money__button mat-spinner circle {
  stroke: #ffffff;
}

@media screen and (max-width: 767px) {
  app-transfer-money-popup #deposit.transfer-money__button {
    font-size: 16px;
  }
  app-transfer-money-popup #deposit-popup .popup-wrapper {
    width: 100%;
    top: 0;
    height: 100%;
    max-height: 100%;
    max-width: 100vw;
  }
  app-transfer-money-popup #deposit-popup .inner {
    max-width: 100vw;
  }
}
app-withdraw-money-popup .inner {
  max-width: 420px;
  width: 90vw;
  position: relative;
  padding: 0 !important;
}
app-withdraw-money-popup .buttons-wrapper {
  margin: 25px 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-withdraw-money-popup .buttons-wrapper .button {
  padding: 0 20px;
}
app-withdraw-money-popup .current-balance {
  display: inline-block;
  color: #8089A0;
  margin-top: -10px;
  margin-bottom: 10px;
}
app-withdraw-money-popup .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-withdraw-money-popup .form {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
}
app-withdraw-money-popup .item {
  width: 100%;
  margin-top: 5px;
}
app-withdraw-money-popup .label {
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  font-size: 14px;
  color: #F2F7FB;
  line-height: 2;
  transition: all linear 0.1s;
}
app-withdraw-money-popup .label.opened {
  transform: translateY(-19px);
}
app-withdraw-money-popup .error-message.align-block {
  display: flex;
}
app-withdraw-money-popup .error-message .error-message-text {
  display: inline-block;
}
app-withdraw-money-popup .select[multiple] {
  min-height: 80px;
}

@media screen and (max-width: 767px) {
  app-withdraw-money-popup .popup-wrapper {
    width: 100%;
    min-height: 100%;
    top: 0;
  }
  app-withdraw-money-popup .buttons-wrapper {
    flex-direction: column;
  }
  app-withdraw-money-popup .buttons-wrapper .button {
    margin-bottom: 30px;
    width: 100%;
  }
}
app-fees-popup .inner {
  width: 660px;
  max-width: 90vw;
  position: relative;
  padding: 40px 20px;
  text-align: center;
}
app-fees-popup table th {
  font-weight: bold !important;
  color: #000 !important;
  text-align: center !important;
}
app-fees-popup table th span {
  white-space: nowrap;
}
app-fees-popup td span {
  white-space: nowrap;
}
app-fees-popup .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-fees-popup .block-title {
  font-weight: 900;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 10px;
}
app-fees-popup .text, app-fees-popup .sub-text {
  font-size: 15px;
  text-align: center;
  margin-bottom: 10px;
}
app-fees-popup .text {
  margin-top: 20px;
  font-weight: bold;
}
app-fees-popup .sub-text {
  color: #8089A0;
}
app-fees-popup .image {
  display: block;
  margin: 0 auto;
  height: 130px;
}
app-fees-popup .button-wrapper {
  display: flex;
  width: 80%;
  font-size: 14px;
  margin: 30px auto 0;
  justify-content: space-around;
  align-items: center;
}
app-fees-popup .button-wrapper .cancel {
  color: #5AA2E5;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  app-fees-popup .text br {
    display: none;
  }
  app-fees-popup table th {
    padding: 11px 5px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    color: #000 !important;
    text-align: center !important;
  }
  app-fees-popup table th span {
    white-space: normal;
  }
  app-fees-popup td {
    padding: 11px 5px;
    font-size: 12px;
  }
  app-fees-popup td span {
    white-space: normal;
  }
}
app-upload-file-popup .inner {
  width: 556px;
  max-width: 90vw;
  position: relative;
  padding: 20px 36px 36px 36px;
  max-height: 95% !important;
}
app-upload-file-popup .form {
  padding: 0;
  width: 100%;
  border-top: 1px dashed #C4C4C4;
}
app-upload-file-popup .form-wrapper {
  padding: 20px 60px 10px 60px;
}
app-upload-file-popup .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-upload-file-popup .mat-progress-spinner circle, app-upload-file-popup .mat-spinner circle {
  stroke: #fff;
}
app-upload-file-popup .spinner__button {
  position: relative;
}
app-upload-file-popup .spinner__button mat-spinner {
  position: absolute;
  left: 10px;
  top: 10px;
}
app-upload-file-popup .block-title {
  font-weight: 900;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  margin: 10px 0 25px;
}
app-upload-file-popup .text {
  font-size: 15px;
  text-align: center;
  margin-bottom: 10px;
}
app-upload-file-popup .item-document, app-upload-file-popup .file-doc__input {
  width: 177px;
  height: 110px;
  background: #EEFCFF;
  position: relative;
  border: 2px dashed #5AA2E5;
  overflow: hidden;
}
app-upload-file-popup .item-document:last-child, app-upload-file-popup .file-doc__input:last-child {
  margin-left: 15px;
}
app-upload-file-popup .item-document label, app-upload-file-popup .file-doc__input label {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
app-upload-file-popup .item-document span, app-upload-file-popup .item-document .fa-camera, app-upload-file-popup .file-doc__input span, app-upload-file-popup .file-doc__input .fa-camera {
  color: #5AA2E5;
}
app-upload-file-popup .item-document img, app-upload-file-popup .file-doc__input img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
app-upload-file-popup .item-document span, app-upload-file-popup .file-doc__input span {
  font-size: 14px;
}
app-upload-file-popup .item-document .fa-camera, app-upload-file-popup .file-doc__input .fa-camera {
  font-size: 20px;
  line-height: 23px;
}
app-upload-file-popup .file-doc__input {
  width: auto;
}
app-upload-file-popup .file-doc__input:last-child {
  margin-left: 0;
}
app-upload-file-popup .loader-picture__image {
  width: 100%;
}
app-upload-file-popup .upload__file-doc {
  margin-bottom: 20px;
}
app-upload-file-popup #button-upload {
  width: 200px;
}
app-upload-file-popup .file-doc__title {
  font-size: 14px;
  text-align: left;
  color: #8089A0;
}
app-upload-file-popup .file-doc__title.file-doc__title_padding {
  padding: 10px 0;
}
app-upload-file-popup .wrapper-upload.disabled {
  opacity: 0.5;
}
app-upload-file-popup .passport, app-upload-file-popup .idCard {
  width: 100%;
  text-align: center;
}
app-upload-file-popup .wrapper-spinner {
  display: flex;
  justify-content: center;
}
app-upload-file-popup .idCard__text {
  font-size: 14px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
}
app-upload-file-popup .idCard.backside {
  margin-top: 10px;
}
app-upload-file-popup .idCard .loader-picture__wrapper {
  height: 150px;
  text-align: center;
}
app-upload-file-popup .idCard .loader-picture__wrapper img {
  height: 100%;
  width: auto;
}
app-upload-file-popup input[type=file] {
  display: none;
}
app-upload-file-popup .image {
  display: block;
  margin: 0 auto;
  height: 130px;
}
app-upload-file-popup mat-form-field {
  width: 100%;
}
app-upload-file-popup .fa-envelope {
  color: #5AA2E5;
  margin: 0 20px 0 12px;
}
app-upload-file-popup .button-wrapper {
  border-top: 1px dashed #C4C4C4;
  padding-top: 27px;
  flex-direction: column;
}
app-upload-file-popup .button-wrapper .reload-photo {
  margin-top: 20px;
}
app-upload-file-popup .button {
  width: 100%;
  max-width: 230px;
  margin: 0 auto;
  font-size: 16px;
}
app-upload-file-popup .mobile-upload {
  display: none;
}
app-upload-file-popup .desktop-upload {
  display: block;
}
app-upload-file-popup app-popup .popup-wrapper {
  overflow-y: scroll;
}

@media screen and (max-width: 767px) {
  app-upload-file-popup .inner {
    padding: 30px 20px !important;
    max-width: 100vw !important;
    height: 100%;
  }
  app-upload-file-popup .kyc-upload-popup .button-readable {
    width: 100% !important;
    font-size: 16px !important;
  }
  app-upload-file-popup .kyc-upload-popup .button-upload {
    font-size: 16px !important;
    width: 100% !important;
  }
  app-upload-file-popup .mobile-button {
    justify-content: space-between;
  }
  app-upload-file-popup .mobile-button .button {
    width: auto !important;
    min-width: auto !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
  }
  app-upload-file-popup .mobile-button .button.take-photo-btn {
    width: 115px !important;
  }
  app-upload-file-popup .mobile-button .button:first-child {
    padding: 0 10px;
  }
  app-upload-file-popup .mobile-button .button.isActive {
    background: #5AA2E5;
    color: #fff;
  }
  app-upload-file-popup .mobile-button .button.disabled {
    opacity: 0.3;
    border: 2px solid #949494;
    cursor: default;
  }
  app-upload-file-popup .mobile-button label {
    justify-content: center;
    padding: 0 10px;
    white-space: nowrap;
    margin-left: 14px;
  }
  app-upload-file-popup .item-document {
    width: 100%;
    margin-top: 16px;
  }
  app-upload-file-popup .item-document:last-child {
    margin-left: 0;
  }
  app-upload-file-popup .popup-wrapper {
    width: 100% !important;
    max-height: 100%;
    height: 100%;
    top: 0;
  }
  app-upload-file-popup .button-wrapper {
    border: none;
    padding-top: 0;
  }
  app-upload-file-popup .button-wrapper button {
    max-width: 100%;
    width: 100%;
  }
  app-upload-file-popup .mobile-preview {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    top: 0;
    left: 0;
    background: white;
  }
  app-upload-file-popup .mobile-preview label {
    min-width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  app-upload-file-popup .mobile-preview img {
    width: 100%;
  }
  app-upload-file-popup .mobile-preview .img-block {
    max-width: 80%;
    margin: 0 auto;
    flex: 1;
    width: 100%;
    padding: 20px 0;
    text-align: center;
  }
  app-upload-file-popup .mobile-preview .button-wrapper {
    margin-bottom: 40px;
  }
  app-upload-file-popup .other-photo {
    line-height: 48px;
    margin-top: 16px;
  }
  app-upload-file-popup .form {
    padding: 0;
  }
  app-upload-file-popup .block-title {
    font-size: 18px;
  }
  app-upload-file-popup .form-wrapper {
    padding: 20px 0 0 0;
  }
  app-upload-file-popup .wrapper-upload {
    flex-direction: column;
    padding-bottom: 20px;
  }
  app-upload-file-popup .mobile-upload {
    display: block;
  }
  app-upload-file-popup .desktop-upload {
    display: none;
  }
}
app-kyc-status {
  text-align: right;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 140px;
  width: 100%;
}
app-kyc-status .kyc-title {
  color: #8089A0;
  font-size: 14px;
}
app-kyc-status .wrapper-kyc {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
app-kyc-status .wrapper-kyc:hover + .wrapper-tooltip-kyc .tooltip-kyc {
  display: block;
  width: 180px;
}
app-kyc-status .warning {
  margin-top: 5px;
  display: inline-block;
}
app-kyc-status .error {
  position: relative;
  width: 100%;
}
app-kyc-status .error .fa-question-circle {
  font-size: 18px;
  color: white;
  cursor: pointer;
  top: -21px;
  right: 8px;
  position: absolute;
}
app-kyc-status .error .error__item {
  margin-bottom: 10px;
}
app-kyc-status .error-hint {
  position: absolute;
  width: 100%;
  right: 58px;
}
app-kyc-status .fa-question-circle:hover + .error-hint .tooltip-kyc {
  display: block;
  right: -20px;
  width: 250px;
  top: 10px;
}
app-kyc-status .kyc-status-block {
  margin-top: 5px;
  border-radius: 7px;
  width: 126px;
  height: 24px;
  line-height: 26px;
  text-align: left;
}
app-kyc-status .kyc-status-block span {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
}
app-kyc-status .kyc-status-block .fas {
  padding: 0 10px;
}
app-kyc-status .kyc-status-block.inactive {
  background: #F95738;
}
app-kyc-status .kyc-status-block.active {
  background: #27AE60;
}
app-kyc-status .kyc-status-block.pending {
  background: #FFA800;
}
app-kyc-status .tooltip-kyc {
  top: 60px;
  text-align: left;
  left: -50px;
  padding: 14px;
}
app-kyc-status .tooltip-kyc:after {
  top: -7px;
  right: 45px;
  width: 0;
  height: 0;
}
app-kyc-status .tooltip-kyc.error__tooltip:after {
  right: 12px;
}

@media screen and (max-width: 767px) {
  app-kyc-status .kyc-status-block {
    margin-top: 5px;
  }
  app-kyc-status app-popup .popup-wrapper {
    max-height: 100vh;
    top: 0;
  }
}
app-kyc-upload-document {
  text-align: right;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
app-kyc-upload-document .form {
  width: 100%;
  padding: 0;
}
app-kyc-upload-document .document-type {
  width: 100%;
}
app-kyc-upload-document .file-doc__title {
  text-align: left;
  width: 100%;
  color: #8089A0;
  font-size: 14px;
}
app-kyc-upload-document .file-doc__title.file-doc__title_padding {
  padding: 10px 0;
}
app-kyc-upload-document .wrapper-upload {
  width: 100%;
}
app-kyc-upload-document .item-document,
app-kyc-upload-document .file-doc__input {
  width: 100%;
  height: 110px;
  background: #eefcff;
  position: relative;
  border: 2px dashed #5AA2E5;
  overflow: hidden;
}
app-kyc-upload-document .item-document:last-child,
app-kyc-upload-document .file-doc__input:last-child {
  margin-left: 15px;
}
app-kyc-upload-document .item-document label,
app-kyc-upload-document .file-doc__input label {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
app-kyc-upload-document .item-document span,
app-kyc-upload-document .item-document .fa-camera,
app-kyc-upload-document .file-doc__input span,
app-kyc-upload-document .file-doc__input .fa-camera {
  color: #5AA2E5;
}
app-kyc-upload-document .item-document img,
app-kyc-upload-document .file-doc__input img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
app-kyc-upload-document .item-document span,
app-kyc-upload-document .file-doc__input span {
  font-size: 14px;
}
app-kyc-upload-document .item-document .fa-camera,
app-kyc-upload-document .file-doc__input .fa-camera {
  font-size: 20px;
  line-height: 23px;
}
app-kyc-upload-document .upload__file-doc {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
app-kyc-upload-document input[type=file] {
  display: none;
}
app-kyc-upload-document .file-doc__pdf {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: 500;
}
app-kyc-upload-document .file-doc__pdf span {
  padding-left: 10px;
}
app-kyc-upload-document .file-doc__pdf .fa-times-circle {
  cursor: pointer;
  color: #F95738;
}
app-kyc-upload-document .other-photo {
  display: flex;
  align-items: center;
  justify-content: center;
}
app-kyc-upload-document .id__passport {
  display: block;
  margin: auto;
  height: 200px;
  width: auto;
}
app-kyc-upload-document .form-wrapper {
  padding: 20px 60px 10px 60px;
}
app-kyc-upload-document .upload-wrapper {
  padding: 0 20px;
}
app-kyc-upload-document .loader-picture__image {
  width: 100%;
}
app-kyc-upload-document .idCard__text {
  text-align: center;
  text-transform: uppercase;
  color: #8089A0;
  padding: 10px 0;
  font-weight: bold;
  width: 100%;
  display: inline-block;
}
app-kyc-upload-document .image__passport {
  width: -webkit-fill-available;
}
app-kyc-upload-document .kyc-button-wrapper {
  justify-content: center;
  display: flex;
  gap: 8px;
}
app-kyc-upload-document .kyc-button-wrapper .button {
  margin-top: 10px;
  margin-bottom: 20px;
}
app-kyc-upload-document .crop__upload {
  text-transform: uppercase;
}
app-kyc-upload-document .image__passport {
  height: 250px;
  margin-top: 15px;
}
app-kyc-upload-document .title {
  color: #8089A0;
}
app-kyc-upload-document .wrapper-kyc {
  cursor: pointer;
}
app-kyc-upload-document .wrapper-kyc:hover + .wrapper-tooltip-kyc .tooltip {
  display: block;
  width: 180px;
}
app-kyc-upload-document .mat-progress-spinner circle, app-kyc-upload-document .mat-spinner circle {
  stroke: #fff !important;
}
app-kyc-upload-document .warning {
  margin-top: 5px;
  display: inline-block;
}
app-kyc-upload-document .kyc-status-block {
  margin-top: 5px;
  border-radius: 7px;
  width: 126px;
  height: 24px;
  line-height: 26px;
  text-align: left;
}
app-kyc-upload-document .kyc-status-block span {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
}
app-kyc-upload-document .kyc-status-block .fas {
  padding: 0 10px;
}
app-kyc-upload-document .kyc-status-block.inactive {
  background: #F95738;
}
app-kyc-upload-document .kyc-status-block.active {
  background: #27AE60;
}
app-kyc-upload-document .tooltip {
  top: 60px;
  text-align: left;
  left: -50px;
  padding: 14px;
}
app-kyc-upload-document .tooltip:after {
  top: -7px;
  right: 45px;
  width: 0;
  height: 0;
}

@media screen and (max-width: 767px) {
  app-kyc-upload-document .kyc-status-block {
    margin-top: 5px;
  }
  app-kyc-upload-document .form-wrapper {
    max-width: 486px;
    margin: auto;
    padding: 20px 0 0 0;
  }
  app-kyc-upload-document .button {
    font-size: 16px;
    max-width: 100%;
    width: 100%;
  }
  app-kyc-upload-document .upload-wrapper {
    padding: 0;
  }
  app-kyc-upload-document .img-block img {
    width: 100%;
    object-fit: contain;
  }
}
app-submit-song-alert-popup .inner {
  width: 460px;
  max-width: 90vw;
  position: relative;
  padding: 40px 20px;
  text-align: center;
}
app-submit-song-alert-popup .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-submit-song-alert-popup .button {
  margin: 0 auto;
}
app-submit-song-alert-popup .block-title {
  font-weight: 900;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 10px;
}
app-submit-song-alert-popup .text, app-submit-song-alert-popup .sub-text {
  font-size: 15px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
app-submit-song-alert-popup .text {
  font-weight: bold;
}
app-submit-song-alert-popup .sub-text {
  color: #8089A0;
}
app-submit-song-alert-popup .image {
  display: block;
  margin: 0 auto;
  height: 130px;
}

@media screen and (max-width: 767px) {
  app-verify-email-popup .text br {
    display: none;
  }
}
app-voucher-popup .popup-wrapper .title {
  margin-bottom: 10px;
}
app-voucher-popup .inner {
  max-width: 400px;
  width: 90vw;
  position: relative;
  padding: 0 !important;
}
app-voucher-popup .buttons-wrapper {
  margin: 0 23px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-voucher-popup .buttons-wrapper .button {
  max-width: 45%;
}
app-voucher-popup .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-voucher-popup .form {
  padding: 0 24px;
  margin: 20px 0;
}
app-voucher-popup .item {
  width: 100%;
  margin-top: 5px;
}
app-voucher-popup .label {
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  font-size: 14px;
  color: #F2F7FB;
  line-height: 2;
  transition: all linear 0.1s;
}
app-voucher-popup .label.opened {
  transform: translateY(-19px);
}
app-voucher-popup .error-message.align-block {
  display: flex;
}
app-voucher-popup .error-message .error-message-text {
  display: inline-block;
}
app-voucher-popup .select[multiple] {
  min-height: 80px;
}

app-verify-conditions-popup .inner {
  width: 460px;
  max-width: 90vw;
  position: relative;
  padding: 40px 20px;
  text-align: center;
}
app-verify-conditions-popup .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-verify-conditions-popup .block-title {
  font-weight: 900;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 10px;
}
app-verify-conditions-popup .text, app-verify-conditions-popup .sub-text {
  font-size: 15px;
  text-align: center;
  margin-bottom: 10px;
}
app-verify-conditions-popup ul {
  list-style: initial;
  padding-left: 20px;
  margin: 10px 0;
}
app-verify-conditions-popup li {
  padding-left: 10px;
}
app-verify-conditions-popup .text {
  margin: 25px 0;
  text-align: justify;
}
app-verify-conditions-popup .sub-text {
  color: #8089A0;
}
app-verify-conditions-popup .image {
  display: block;
  margin: 0 auto;
  height: 130px;
}
app-verify-conditions-popup .button {
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  app-verify-conditions-popup .text br {
    display: none;
  }
}
app-tfa-popup .inner {
  width: 460px;
  max-width: 90vw;
  position: relative;
  padding: 40px 20px;
  text-align: center;
}
app-tfa-popup .text-block {
  text-align: center;
  margin: 25px auto;
  width: 85%;
}
app-tfa-popup .text-block .text-center {
  text-align: center;
  width: 100%;
  display: inline-block;
}
app-tfa-popup .text-block .text-center.name {
  line-height: 28px;
  font-size: 16px;
}
app-tfa-popup .text-block .text {
  font-size: 14px;
}
app-tfa-popup .button-wrapper {
  display: flex;
  justify-content: space-between;
}
app-tfa-popup .image {
  display: block;
  margin: 0 auto;
  height: 130px;
  border-radius: 50%;
}
app-tfa-popup .button {
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  app-tfa-popup .text br {
    display: none;
  }
}
app-tfa {
  margin: 30px 0;
}
app-tfa .title {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}
app-tfa .lnr-chevron-right {
  display: none;
}
app-tfa .tfa__process {
  width: 20%;
  padding: 0 25px;
  margin: 0 auto;
}
app-tfa .tfa .toggle-item {
  display: flex;
  align-items: center;
  height: 40px;
}
app-tfa .tfa .toggle-item .status {
  padding-right: 15px;
}
app-tfa .tfa .tfa__key {
  color: #8089A0;
}
app-tfa .tfa .tfa__info {
  margin-top: 15px;
}
app-tfa .tfa .tfa__info span {
  padding-left: 5px;
}
app-tfa .tfa .tfa-input {
  width: 100%;
}
app-tfa .tfa .button {
  margin: 0 auto;
}
app-tfa .section-toggle-mob {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  app-tfa .section-toggle-mob {
    margin-top: 0;
    height: 0;
    overflow: hidden;
  }
  app-tfa .section-toggle-mob.openSetting {
    height: auto;
    padding-top: 20px;
  }
}

@media screen and (max-width: 1199px) {
  app-tfa .tfa__process {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  app-tfa .lnr.lnr-chevron-right {
    display: inline-block;
    font-weight: 900;
  }
  app-tfa .tfa {
    padding-bottom: 0;
  }
  app-tfa .tfa.profile__section.profile__section-padding {
    padding-bottom: 0;
  }
  app-tfa .tfa .toggle-item, app-tfa .tfa .tfa__info {
    padding: 0;
  }
  app-tfa .tfa .title .title-m {
    padding-left: 10px;
  }
}
app-reinvest-popup .inner {
  width: 480px;
  max-width: 90vw;
  position: relative;
  padding: 40px;
  text-align: left;
}
app-reinvest-popup .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-reinvest-popup .text-head {
  text-align: center;
  font-weight: bold;
}
app-reinvest-popup .sub-text-bold {
  font-weight: bold;
  color: #000;
}
app-reinvest-popup .block-title {
  font-weight: 900;
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 10px;
}
app-reinvest-popup .text {
  text-align: left;
  margin-bottom: 10px;
  margin-top: 20px;
}
app-reinvest-popup .text h1 {
  font-size: 24px;
  font-weight: bold;
}
app-reinvest-popup .sign-in {
  margin: 30px 20px;
  padding: 30px 20px;
  border-top: 1px dashed #C4C4C4;
  border-bottom: 1px dashed #C4C4C4;
}
app-reinvest-popup .sub-text {
  margin-top: 20px;
  font-weight: normal;
  color: #000;
}
app-reinvest-popup .agreement-text {
  font-weight: normal;
  text-align: left;
  padding-left: 15px;
}
app-reinvest-popup .agreement-text span {
  font-weight: 500;
}
app-reinvest-popup .button-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
app-reinvest-popup .button-wrapper .cancel {
  color: #B7BCCB;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  border-color: #8089A0;
}
app-reinvest-popup .button-wrapper .cancel:hover {
  color: #5AA2E5;
}
app-reinvest-popup .button-wrapper .button {
  width: auto;
  padding: 0 15px;
  height: 50px;
  font-weight: bold;
  font-size: 14px;
}
app-reinvest-popup .image {
  display: block;
  margin: 0 auto;
  height: 130px;
}

@media screen and (max-width: 767px) {
  app-investor-popup .text br {
    display: none;
  }
  app-investor-popup .text {
    text-align: center;
  }
  app-investor-popup .button-wrapper .button {
    width: 213px;
    margin-left: 37px;
    margin-right: 700px;
    height: 50px;
    font-weight: bold;
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) and (max-width: 700px) {
  app-reinvest-popup .reinvest-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 26px !important;
  }
  app-reinvest-popup .button {
    margin: 0.2rem !important;
    min-width: 110px !important;
    font-size: 12px !important;
  }
}
app-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: rgba(5, 27, 34, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
app-popup > .inner {
  border: 1px solid #949494;
  border-radius: 5px;
  background: #fff;
}
app-popup > .inner .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 12px;
  font-weight: 600;
  top: 18px;
  cursor: pointer;
  color: #1ac5d0;
}
app-popup > .inner > .title {
  border-bottom: 1px solid #949494;
  margin-bottom: 4px;
  padding: 18px 24px;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 1;
  background: #F6F9FC;
  color: #084C61;
}
app-popup > .inner .row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
app-popup .content {
  padding: 0 24px;
  margin-top: 22px;
}
app-popup .popup-wrapper {
  background: #fff;
  position: relative;
  border: 1px solid #c7cfd8;
  border-radius: 5px;
  max-height: 90%;
  overflow: hidden;
}
app-popup .popup-wrapper .title {
  border-bottom: 1px solid #949494;
  margin-bottom: 4px;
  padding: 18px 24px;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 600;
}
app-popup .popup-wrapper .row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
app-popup .lnr.default-cross {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 24px;
  color: #8089A0;
  opacity: 0.7;
  border: 10px solid transparent;
}

@media screen and (max-width: 768px) {
  app-popup .inner {
    width: 100% !important;
    max-height: 90% !important;
    overflow: auto !important;
  }
  app-popup .form > .row {
    display: block;
  }
  app-popup .form > .row > .item {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  app-popup .popup-wrapper {
    width: 90%;
    max-height: 650px;
    overflow-y: scroll;
    top: 10px;
  }
  app-popup .inner {
    width: 100% !important;
  }
}
app-image-cropper {
  display: block;
}
app-image-cropper .desktop-upload {
  display: none;
}
app-image-cropper .crop__upload {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 250px;
}
app-image-cropper .cropper-save {
  margin-bottom: 20px;
  text-transform: uppercase;
  flex: 1;
  max-width: 250px;
}
app-image-cropper .cropper__buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 16px;
}

app-ubo .ubo__form {
  display: flex;
  padding: 20px 0;
}
app-ubo .form__col {
  width: 33.33%;
  padding: 0 25px;
}
app-ubo .form__item {
  width: 100%;
}
app-ubo .col__wrapper {
  display: flex;
}
app-ubo .form__birthdate {
  justify-content: space-between;
}
app-ubo .form__birthdate .form__item {
  width: 32%;
}
app-ubo .form__address {
  justify-content: space-between;
}
app-ubo .form__address .form__item {
  width: 48%;
}
app-ubo .ubo__submit {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 25px;
}
app-ubo .ubo__submit .button {
  height: 40px;
}

@media screen and (max-width: 1199px) {
  .ubo__form {
    flex-direction: column;
  }
  .ubo__form .form__col {
    width: 100%;
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  .follow-wrapper {
    visibility: hidden;
    display: none;
  }
  .followers-social-row {
    visibility: hidden;
    display: none;
  }
}
@media screen and (max-width: 460px) {
  app-ubo .info-text {
    display: none;
  }
  app-ubo .follow-wrapper {
    visibility: hidden;
    display: none;
  }
  app-ubo .followers-social-row {
    visibility: hidden;
    display: none;
  }
  app-ubo .wrapper {
    margin-top: 100px;
  }
  app-ubo .wrapper .inner {
    max-width: calc(100% - 20px);
  }
  app-ubo .follow-wrapper {
    border-bottom: 1px dashed #C4C4C4;
    min-height: 40px;
    justify-content: center;
    width: 100%;
    grid-auto-columns: 38%;
    padding-top: 6px;
  }
  app-ubo .select-share .item {
    width: 60px;
  }
  app-ubo .select-wrapper {
    width: calc(100% - 201px);
  }
}
app-song-player {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 320px;
  overflow: hidden;
  z-index: 10;
}
app-song-player .project-image-wrapper {
  height: 36px;
}
app-song-player .project-image {
  width: 36px;
  height: 36px;
  min-width: 36px;
  margin-right: 20px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  background-position: center;
  background-size: cover;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
app-song-player .share-button {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  width: 230px;
  line-height: 40px;
  height: 40px;
  margin: 0;
}
app-song-player .share-button.disabled {
  background: #8089A0;
  color: #fff;
  opacity: 0.5;
}
app-song-player .share-button.mobile {
  display: none;
}
app-song-player .share-button.desktop {
  display: flex;
  align-items: center;
  justify-content: center;
}
app-song-player .fa-play, app-song-player .fa-pause {
  font-size: 10px;
}
app-song-player .name {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  width: 95%;
  max-width: 300px;
}
app-song-player .name .song-name, app-song-player .name .artist {
  display: block;
}
app-song-player .name .song-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-song-player .wrapper {
  display: flex;
  flex-direction: column;
}
app-song-player .wrapper-info {
  margin-bottom: 10px;
  width: 95%;
  display: flex;
}
app-song-player .artist {
  color: #000;
  font-weight: bold;
}
app-song-player .song-name, app-song-player .played, app-song-player .total {
  color: #8089A0;
}
app-song-player .song-time {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
app-song-player .progress-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #fff;
}
app-song-player .progress {
  height: 3px;
  width: 95%;
  border-radius: 5px;
  background: #DFEBF4;
}
app-song-player .progress .inner {
  height: 3px;
  border-radius: 5px;
  background: #FFA800;
}

@media screen and (max-width: 1024px) {
  app-song-player .progress-wrapper, app-song-player .name {
    width: 380px;
  }
  app-song-player .progress {
    width: 290px;
  }
}
@media screen and (max-width: 767px) {
  app-song-player .progress-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
  app-song-player .progress-wrapper .played, app-song-player .progress-wrapper .total {
    display: none;
  }
  app-song-player .progress-wrapper .progress {
    border-radius: 0;
    width: 100%;
  }
  app-song-player .name {
    width: calc(100vw - 140px);
    text-align: center;
    max-width: 100%;
  }
  app-song-player .share-button.mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }
  app-song-player .share-button.desktop {
    display: none;
  }
}
@media screen and (max-width: 460px) {
  app-song-preview .name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 auto 8px;
  }
}
app-filter-mobile .m-filters {
  position: fixed;
  background: #fff;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 120;
}
app-filter-mobile .m-filters .wrapper {
  padding: 0 10px;
}
app-filter-mobile .m-filters .m-filters__page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
app-filter-mobile .m-filters .m-filters__footer {
  height: 80px;
  width: 100%;
  box-shadow: 0 0 8px rgba(144, 170, 190, 0.4);
}
app-filter-mobile .m-filters .m-filters__footer .wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
app-filter-mobile .m-filters .m-filters__footer .button {
  font-size: 16px;
  min-width: 210px;
}
app-filter-mobile .m-filters .m-filters__header {
  height: 62px;
  width: 100%;
  background: #F2F7FB;
  box-shadow: inset 0 0 8px rgba(144, 170, 190, 0.2);
}
app-filter-mobile .m-filters .m-filters__header .wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-filter-mobile .m-filters .m-filters__title {
  font-size: 18px;
}
app-filter-mobile .m-filters .m-filters__body {
  height: 85%;
  overflow: scroll;
}
app-filter-mobile .cancel {
  color: #5AA2E5;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500;
}
app-filter-mobile .body__item {
  border-bottom: 1px dashed #A8C1D3;
}
app-filter-mobile .body__item:last-child {
  border: none;
}
app-filter-mobile .body__item .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-filter-mobile .body__item .body__text {
  font-size: 14px;
  color: #8089A0;
}

app-video-player {
  background: rgba(0, 0, 0, 0.8);
  height: 76px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 320px;
  overflow: hidden;
  z-index: 10;
  left: 0;
  position: fixed;
  bottom: 0;
}
app-video-player .project-image {
  width: 54px;
  height: 54px;
  border: 3px solid #fff;
  margin-right: 30px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
app-video-player .desktop-butt {
  margin-left: 30px;
}
app-video-player progress::-webkit-progress-value {
  background-color: #FFA800 !important;
}
app-video-player .share-button {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  width: 230px;
  line-height: 40px;
  height: 40px;
  margin: 0;
}
app-video-player .share-button.disabled {
  background: #8089A0;
  color: #fff;
  opacity: 0.5;
}
app-video-player .share-button.mobile {
  display: none;
}
app-video-player .share-button.desktop {
  display: flex;
  align-items: center;
  justify-content: center;
}
app-video-player .name {
  width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  color: #fff;
}
app-video-player .artist {
  color: #8089A0;
}
app-video-player .progress-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
  color: #fff;
}
app-video-player .progress {
  height: 5px;
  width: 390px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.4);
  cursor: pointer;
}
app-video-player .progress .inner {
  height: 5px;
  border-radius: 5px;
  background: #FFA800;
}
app-video-player .volume {
  margin-left: 30px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
app-video-player .muted {
  margin: 0.3rem;
}
app-video-player .volume-level {
  height: 5px;
  width: 40px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.4);
}
app-video-player .volume-level .volume-inner {
  height: 5px;
  border-radius: 5px;
  background: #FFA800;
}

@media screen and (max-width: 1024px) {
  app-video-player .progress-wrapper, app-video-player .name {
    width: 400px;
  }
  app-video-player .progress {
    width: 290px;
  }
}
@media screen and (max-width: 767px) {
  app-video-player {
    padding-top: 5px;
    height: 93px;
    margin-left: -15px;
  }
  app-video-player .progress-wrapper, app-video-player .name {
    width: unset;
    flex-wrap: wrap;
  }
  app-video-player .mobile {
    display: flex;
    flex-direction: column;
  }
  app-video-player .project-image {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    margin-left: 10px;
  }
  app-video-player .volume {
    margin-left: 20px;
  }
  app-video-player .progress-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  app-video-player .progress-wrapper .played, app-video-player .progress-wrapper .total {
    display: none;
  }
  app-video-player .progress-wrapper .progress {
    border-radius: 0;
    width: 100%;
  }
  app-video-player .name {
    width: 200px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  app-video-player .desktop-butt {
    display: none;
  }
  app-video-player .share-button.mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    align-self: center;
  }
  app-video-player .share-button.desktop {
    display: none;
  }
}
@media screen and (max-width: 460px) {
  app-video-player .name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 auto 8px;
  }
}
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2196f3;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5722;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #2196f3;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5722;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #2196f3;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #e91e63;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5722;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #2196f3;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #e91e63;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #ff5722;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #2196f3;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #e91e63;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5722;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #2196f3;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #e91e63;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #ff5722;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #2196f3;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #e91e63;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5722;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #2196f3;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #e91e63;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5722;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #2196f3;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5722;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #e91e63;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(33, 150, 243, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #2196f3;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(33, 150, 243, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(33, 150, 243, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(33, 150, 243, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 87, 34, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5722;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 87, 34, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 87, 34, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 87, 34, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #2196f3;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #e91e63;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #ff5722;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #2196f3;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #e91e63;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5722;
}

.mat-focused .mat-form-field-required-marker {
  color: #e91e63;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #2196f3;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #e91e63;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5722;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #2196f3;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #e91e63;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5722;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5722;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5722;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5722;
}

.mat-error {
  color: #ff5722;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #2196f3;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #e91e63;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5722;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5722;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #2196f3;
}
.mat-icon.mat-accent {
  color: #e91e63;
}
.mat-icon.mat-warn {
  color: #ff5722;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #2196f3;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #e91e63;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #ff5722;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5722;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #c4e1f8;
}

.mat-progress-bar-buffer {
  background-color: #c4e1f8;
}

.mat-progress-bar-fill::after {
  background-color: #2196f3;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #f6c3d4;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #f6c3d4;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #e91e63;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbd1c4;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbd1c4;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5722;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #2196f3;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #e91e63;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #ff5722;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2196f3;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #2196f3;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5722;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5722;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #2196f3;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #e91e63;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5722;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5722;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #2196f3;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(33, 150, 243, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #2196f3;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5722;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 87, 34, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5722;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #2196f3;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(33, 150, 243, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #e91e63;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff5722;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 87, 34, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #2196f3;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff5722;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5722;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5722;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 222, 251, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #2196f3;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(136, 14, 79, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #e91e63;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5722;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 222, 251, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #2196f3;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(136, 14, 79, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff5722;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #2196f3;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #e91e63;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #ff5722;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #e91e63;
}

mat-select .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
mat-select .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-option:hover:not(.mat-option-disabled), mat-select .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
mat-select .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
mat-select .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2196f3;
}
mat-select .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffee58;
}
mat-select .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
mat-select .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-pseudo-checkbox::after {
  color: #fafafa;
}
mat-select .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
mat-select .mat-primary .mat-pseudo-checkbox-checked,
mat-select .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #2196f3;
}
mat-select .mat-pseudo-checkbox-checked,
mat-select .mat-pseudo-checkbox-indeterminate,
mat-select .mat-accent .mat-pseudo-checkbox-checked,
mat-select .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffee58;
}
mat-select .mat-warn .mat-pseudo-checkbox-checked,
mat-select .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
mat-select .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
mat-select .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
mat-select .mat-app-background, mat-select.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
mat-select .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

mat-select .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
mat-select .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-badge-content {
  color: white;
  background: #2196f3;
}
.cdk-high-contrast-active mat-select .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

mat-select .mat-badge-accent .mat-badge-content {
  background: #ffee58;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
mat-select .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-button, mat-select .mat-icon-button, mat-select .mat-stroked-button {
  color: inherit;
  background: transparent;
}
mat-select .mat-button.mat-primary, mat-select .mat-icon-button.mat-primary, mat-select .mat-stroked-button.mat-primary {
  color: #2196f3;
}
mat-select .mat-button.mat-accent, mat-select .mat-icon-button.mat-accent, mat-select .mat-stroked-button.mat-accent {
  color: #ffee58;
}
mat-select .mat-button.mat-warn, mat-select .mat-icon-button.mat-warn, mat-select .mat-stroked-button.mat-warn {
  color: #f44336;
}
mat-select .mat-button.mat-primary.mat-button-disabled, mat-select .mat-button.mat-accent.mat-button-disabled, mat-select .mat-button.mat-warn.mat-button-disabled, mat-select .mat-button.mat-button-disabled.mat-button-disabled, mat-select .mat-icon-button.mat-primary.mat-button-disabled, mat-select .mat-icon-button.mat-accent.mat-button-disabled, mat-select .mat-icon-button.mat-warn.mat-button-disabled, mat-select .mat-icon-button.mat-button-disabled.mat-button-disabled, mat-select .mat-stroked-button.mat-primary.mat-button-disabled, mat-select .mat-stroked-button.mat-accent.mat-button-disabled, mat-select .mat-stroked-button.mat-warn.mat-button-disabled, mat-select .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
mat-select .mat-button.mat-primary .mat-button-focus-overlay, mat-select .mat-icon-button.mat-primary .mat-button-focus-overlay, mat-select .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #2196f3;
}
mat-select .mat-button.mat-accent .mat-button-focus-overlay, mat-select .mat-icon-button.mat-accent .mat-button-focus-overlay, mat-select .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffee58;
}
mat-select .mat-button.mat-warn .mat-button-focus-overlay, mat-select .mat-icon-button.mat-warn .mat-button-focus-overlay, mat-select .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
mat-select .mat-button.mat-button-disabled .mat-button-focus-overlay, mat-select .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, mat-select .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
mat-select .mat-button .mat-ripple-element, mat-select .mat-icon-button .mat-ripple-element, mat-select .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
mat-select .mat-button-focus-overlay {
  background: black;
}
mat-select .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
mat-select .mat-flat-button, mat-select .mat-raised-button, mat-select .mat-fab, mat-select .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
mat-select .mat-flat-button.mat-primary, mat-select .mat-raised-button.mat-primary, mat-select .mat-fab.mat-primary, mat-select .mat-mini-fab.mat-primary {
  color: white;
}
mat-select .mat-flat-button.mat-accent, mat-select .mat-raised-button.mat-accent, mat-select .mat-fab.mat-accent, mat-select .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-flat-button.mat-warn, mat-select .mat-raised-button.mat-warn, mat-select .mat-fab.mat-warn, mat-select .mat-mini-fab.mat-warn {
  color: white;
}
mat-select .mat-flat-button.mat-primary.mat-button-disabled, mat-select .mat-flat-button.mat-accent.mat-button-disabled, mat-select .mat-flat-button.mat-warn.mat-button-disabled, mat-select .mat-flat-button.mat-button-disabled.mat-button-disabled, mat-select .mat-raised-button.mat-primary.mat-button-disabled, mat-select .mat-raised-button.mat-accent.mat-button-disabled, mat-select .mat-raised-button.mat-warn.mat-button-disabled, mat-select .mat-raised-button.mat-button-disabled.mat-button-disabled, mat-select .mat-fab.mat-primary.mat-button-disabled, mat-select .mat-fab.mat-accent.mat-button-disabled, mat-select .mat-fab.mat-warn.mat-button-disabled, mat-select .mat-fab.mat-button-disabled.mat-button-disabled, mat-select .mat-mini-fab.mat-primary.mat-button-disabled, mat-select .mat-mini-fab.mat-accent.mat-button-disabled, mat-select .mat-mini-fab.mat-warn.mat-button-disabled, mat-select .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
mat-select .mat-flat-button.mat-primary, mat-select .mat-raised-button.mat-primary, mat-select .mat-fab.mat-primary, mat-select .mat-mini-fab.mat-primary {
  background-color: #2196f3;
}
mat-select .mat-flat-button.mat-accent, mat-select .mat-raised-button.mat-accent, mat-select .mat-fab.mat-accent, mat-select .mat-mini-fab.mat-accent {
  background-color: #ffee58;
}
mat-select .mat-flat-button.mat-warn, mat-select .mat-raised-button.mat-warn, mat-select .mat-fab.mat-warn, mat-select .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
mat-select .mat-flat-button.mat-primary.mat-button-disabled, mat-select .mat-flat-button.mat-accent.mat-button-disabled, mat-select .mat-flat-button.mat-warn.mat-button-disabled, mat-select .mat-flat-button.mat-button-disabled.mat-button-disabled, mat-select .mat-raised-button.mat-primary.mat-button-disabled, mat-select .mat-raised-button.mat-accent.mat-button-disabled, mat-select .mat-raised-button.mat-warn.mat-button-disabled, mat-select .mat-raised-button.mat-button-disabled.mat-button-disabled, mat-select .mat-fab.mat-primary.mat-button-disabled, mat-select .mat-fab.mat-accent.mat-button-disabled, mat-select .mat-fab.mat-warn.mat-button-disabled, mat-select .mat-fab.mat-button-disabled.mat-button-disabled, mat-select .mat-mini-fab.mat-primary.mat-button-disabled, mat-select .mat-mini-fab.mat-accent.mat-button-disabled, mat-select .mat-mini-fab.mat-warn.mat-button-disabled, mat-select .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
mat-select .mat-flat-button.mat-primary .mat-ripple-element, mat-select .mat-raised-button.mat-primary .mat-ripple-element, mat-select .mat-fab.mat-primary .mat-ripple-element, mat-select .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
mat-select .mat-flat-button.mat-accent .mat-ripple-element, mat-select .mat-raised-button.mat-accent .mat-ripple-element, mat-select .mat-fab.mat-accent .mat-ripple-element, mat-select .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
mat-select .mat-flat-button.mat-warn .mat-ripple-element, mat-select .mat-raised-button.mat-warn .mat-ripple-element, mat-select .mat-fab.mat-warn .mat-ripple-element, mat-select .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
mat-select .mat-stroked-button:not([class*=mat-elevation-z]), mat-select .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
mat-select .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-fab:not([class*=mat-elevation-z]), mat-select .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), mat-select .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
mat-select .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), mat-select .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
mat-select .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
mat-select .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
mat-select .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
mat-select .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
mat-select .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
mat-select .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
mat-select [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
mat-select .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
mat-select .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
mat-select .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
mat-select .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
mat-select .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
mat-select .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
mat-select .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-checkbox-checkmark {
  fill: #fafafa;
}
mat-select .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
mat-select .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
mat-select .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, mat-select .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #2196f3;
}
mat-select .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, mat-select .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffee58;
}
mat-select .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, mat-select .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
mat-select .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, mat-select .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
mat-select .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
mat-select .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-checkbox .mat-ripple-element {
  background-color: black;
}
mat-select .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
mat-select .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #2196f3;
}
mat-select .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
mat-select .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffee58;
}
mat-select .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
mat-select .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
mat-select .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
mat-select .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
mat-select .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
mat-select .mat-chip.mat-standard-chip::after {
  background: black;
}
mat-select .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #2196f3;
  color: white;
}
mat-select .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
mat-select .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
mat-select .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
mat-select .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
mat-select .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
mat-select .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffee58;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
mat-select .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
mat-select .mat-table {
  background: white;
}
mat-select .mat-table thead, mat-select .mat-table tbody, mat-select .mat-table tfoot,
mat-select mat-header-row, mat-select mat-row, mat-select mat-footer-row,
mat-select [mat-header-row], mat-select [mat-row], mat-select [mat-footer-row],
mat-select .mat-table-sticky {
  background: inherit;
}
mat-select mat-row, mat-select mat-header-row, mat-select mat-footer-row,
mat-select th.mat-header-cell, mat-select td.mat-cell, mat-select td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
mat-select .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-cell, mat-select .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
mat-select .mat-datepicker-toggle,
mat-select .mat-datepicker-content .mat-calendar-next-button,
mat-select .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
mat-select .mat-calendar-table-header,
mat-select .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-calendar-body-cell-content,
mat-select .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
mat-select .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
mat-select .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
mat-select .mat-calendar-body-in-range::before {
  background: rgba(33, 150, 243, 0.2);
}
mat-select .mat-calendar-body-comparison-identical,
mat-select .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
mat-select .mat-calendar-body-comparison-bridge-start::before,
mat-select [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
mat-select .mat-calendar-body-comparison-bridge-end::before,
mat-select [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
mat-select .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
mat-select .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
mat-select .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
mat-select .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
mat-select .mat-calendar-body-selected {
  background-color: #2196f3;
  color: white;
}
mat-select .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(33, 150, 243, 0.4);
}
mat-select .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
mat-select .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
mat-select .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(33, 150, 243, 0.3);
}
@media (hover: hover) {
  mat-select .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(33, 150, 243, 0.3);
  }
}
mat-select .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 238, 88, 0.2);
}
mat-select .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
mat-select .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
mat-select .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
mat-select .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 238, 88, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
mat-select .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
mat-select .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 238, 88, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
mat-select .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
mat-select .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
mat-select .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
mat-select .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
mat-select .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffee58;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 238, 88, 0.4);
}
mat-select .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
mat-select .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
mat-select .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 238, 88, 0.3);
}
@media (hover: hover) {
  mat-select .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 238, 88, 0.3);
  }
}
mat-select .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
mat-select .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
mat-select .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
mat-select .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
mat-select .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
mat-select .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
mat-select .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
mat-select .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
mat-select .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
mat-select .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
mat-select .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
mat-select .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
mat-select .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
mat-select .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
mat-select .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
mat-select .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  mat-select .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
mat-select .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
mat-select .mat-datepicker-toggle-active {
  color: #2196f3;
}
mat-select .mat-datepicker-toggle-active.mat-accent {
  color: #ffee58;
}
mat-select .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
mat-select .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
mat-select .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
mat-select .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
mat-select .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), mat-select .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), mat-select .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  mat-select .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
mat-select .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-expansion-panel-header-description,
mat-select .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
mat-select .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
mat-select .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
mat-select .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
mat-select .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
mat-select .mat-form-field.mat-focused .mat-form-field-label {
  color: #2196f3;
}
mat-select .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffee58;
}
mat-select .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
mat-select .mat-focused .mat-form-field-required-marker {
  color: #ffee58;
}
mat-select .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #2196f3;
}
mat-select .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffee58;
}
mat-select .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
mat-select .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #2196f3;
}
mat-select .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffee58;
}
mat-select .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
mat-select .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
mat-select .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
mat-select .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
mat-select .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
mat-select .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
mat-select .mat-error {
  color: #f44336;
}
mat-select .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
mat-select .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
mat-select .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
mat-select .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
mat-select .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
mat-select .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
mat-select .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
mat-select .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
mat-select .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
mat-select .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #2196f3;
}
mat-select .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffee58;
}
mat-select .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
mat-select .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
mat-select .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
mat-select .mat-icon.mat-primary {
  color: #2196f3;
}
mat-select .mat-icon.mat-accent {
  color: #ffee58;
}
mat-select .mat-icon.mat-warn {
  color: #f44336;
}
mat-select .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-input-element:disabled,
mat-select .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-input-element {
  caret-color: #2196f3;
}
mat-select .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
mat-select .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
mat-select .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
mat-select .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
mat-select .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffee58;
}
mat-select .mat-form-field.mat-warn .mat-input-element,
mat-select .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
mat-select .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
mat-select .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-list-option:hover, mat-select .mat-list-option:focus,
mat-select .mat-nav-list .mat-list-item:hover,
mat-select .mat-nav-list .mat-list-item:focus,
mat-select .mat-action-list .mat-list-item:hover,
mat-select .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
mat-select .mat-list-single-selected-option, mat-select .mat-list-single-selected-option:hover, mat-select .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
mat-select .mat-menu-panel {
  background: white;
}
mat-select .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-menu-item[disabled],
mat-select .mat-menu-item[disabled] .mat-menu-submenu-icon,
mat-select .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-menu-item .mat-icon-no-color,
mat-select .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-menu-item:hover:not([disabled]),
mat-select .mat-menu-item.cdk-program-focused:not([disabled]),
mat-select .mat-menu-item.cdk-keyboard-focused:not([disabled]),
mat-select .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
mat-select .mat-paginator {
  background: white;
}
mat-select .mat-paginator,
mat-select .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-paginator-decrement,
mat-select .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
mat-select .mat-paginator-first,
mat-select .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
mat-select .mat-icon-button[disabled] .mat-paginator-decrement,
mat-select .mat-icon-button[disabled] .mat-paginator-increment,
mat-select .mat-icon-button[disabled] .mat-paginator-first,
mat-select .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-progress-bar-background {
  fill: #c4e1f8;
}
mat-select .mat-progress-bar-buffer {
  background-color: #c4e1f8;
}
mat-select .mat-progress-bar-fill::after {
  background-color: #2196f3;
}
mat-select .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbf7d2;
}
mat-select .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbf7d2;
}
mat-select .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffee58;
}
mat-select .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
mat-select .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
mat-select .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
mat-select .mat-progress-spinner circle, mat-select .mat-spinner circle {
  stroke: #2196f3;
}
mat-select .mat-progress-spinner.mat-accent circle, mat-select .mat-spinner.mat-accent circle {
  stroke: #ffee58;
}
mat-select .mat-progress-spinner.mat-warn circle, mat-select .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
mat-select .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2196f3;
}
mat-select .mat-radio-button.mat-primary .mat-radio-inner-circle,
mat-select .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), mat-select .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, mat-select .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #2196f3;
}
mat-select .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffee58;
}
mat-select .mat-radio-button.mat-accent .mat-radio-inner-circle,
mat-select .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), mat-select .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, mat-select .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffee58;
}
mat-select .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
mat-select .mat-radio-button.mat-warn .mat-radio-inner-circle,
mat-select .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), mat-select .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, mat-select .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
mat-select .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
mat-select .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
mat-select .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-radio-button .mat-ripple-element {
  background-color: black;
}
mat-select .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
mat-select .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-select-panel {
  background: white;
}
mat-select .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
mat-select .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #2196f3;
}
mat-select .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffee58;
}
mat-select .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
mat-select .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
mat-select .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-drawer.mat-drawer-push {
  background-color: white;
}
mat-select .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
mat-select .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
mat-select .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
mat-select [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
mat-select [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
mat-select .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
mat-select .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffee58;
}
mat-select .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 238, 88, 0.54);
}
mat-select .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffee58;
}
mat-select .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #2196f3;
}
mat-select .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(33, 150, 243, 0.54);
}
mat-select .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #2196f3;
}
mat-select .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
mat-select .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
mat-select .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
mat-select .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
mat-select .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
mat-select .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
mat-select .mat-slider.mat-primary .mat-slider-track-fill,
mat-select .mat-slider.mat-primary .mat-slider-thumb,
mat-select .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #2196f3;
}
mat-select .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
mat-select .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(33, 150, 243, 0.2);
}
mat-select .mat-slider.mat-accent .mat-slider-track-fill,
mat-select .mat-slider.mat-accent .mat-slider-thumb,
mat-select .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ffee58;
}
mat-select .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 238, 88, 0.2);
}
mat-select .mat-slider.mat-warn .mat-slider-track-fill,
mat-select .mat-slider.mat-warn .mat-slider-thumb,
mat-select .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
mat-select .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
mat-select .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
mat-select .mat-slider:hover .mat-slider-track-background,
mat-select .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-slider.mat-slider-disabled .mat-slider-track-background,
mat-select .mat-slider.mat-slider-disabled .mat-slider-track-fill,
mat-select .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
mat-select .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
mat-select .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
mat-select .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
mat-select .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
mat-select .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
mat-select .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
mat-select .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, mat-select .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, mat-select .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
mat-select .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
mat-select .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
mat-select .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
mat-select .mat-step-header.cdk-keyboard-focused, mat-select .mat-step-header.cdk-program-focused, mat-select .mat-step-header:hover:not([aria-disabled]), mat-select .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
mat-select .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  mat-select .mat-step-header:hover {
    background: none;
  }
}
mat-select .mat-step-header .mat-step-label,
mat-select .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
mat-select .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
mat-select .mat-step-header .mat-step-icon-selected,
mat-select .mat-step-header .mat-step-icon-state-done,
mat-select .mat-step-header .mat-step-icon-state-edit {
  background-color: #2196f3;
  color: white;
}
mat-select .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-step-header.mat-accent .mat-step-icon-selected,
mat-select .mat-step-header.mat-accent .mat-step-icon-state-done,
mat-select .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffee58;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
mat-select .mat-step-header.mat-warn .mat-step-icon-selected,
mat-select .mat-step-header.mat-warn .mat-step-icon-state-done,
mat-select .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
mat-select .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
mat-select .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
mat-select .mat-stepper-horizontal, mat-select .mat-stepper-vertical {
  background-color: white;
}
mat-select .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
mat-select .mat-horizontal-stepper-header::before,
mat-select .mat-horizontal-stepper-header::after,
mat-select .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
mat-select .mat-sort-header-arrow {
  color: #757575;
}
mat-select .mat-tab-nav-bar,
mat-select .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
mat-select .mat-tab-group-inverted-header .mat-tab-nav-bar,
mat-select .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
mat-select .mat-tab-label, mat-select .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-tab-label.mat-tab-disabled, mat-select .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
mat-select .mat-tab-group[class*=mat-background-] > .mat-tab-header,
mat-select .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
mat-select .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), mat-select .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
mat-select .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
mat-select .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), mat-select .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), mat-select .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
mat-select .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
mat-select .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 222, 251, 0.3);
}
mat-select .mat-tab-group.mat-primary .mat-ink-bar, mat-select .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #2196f3;
}
mat-select .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, mat-select .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, mat-select .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, mat-select .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
mat-select .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), mat-select .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
mat-select .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
mat-select .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), mat-select .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), mat-select .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
mat-select .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
mat-select .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 249, 196, 0.3);
}
mat-select .mat-tab-group.mat-accent .mat-ink-bar, mat-select .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffee58;
}
mat-select .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, mat-select .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, mat-select .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, mat-select .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), mat-select .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
mat-select .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
mat-select .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), mat-select .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), mat-select .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
mat-select .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
mat-select .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
mat-select .mat-tab-group.mat-warn .mat-ink-bar, mat-select .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
mat-select .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, mat-select .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, mat-select .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, mat-select .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
mat-select .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), mat-select .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
mat-select .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
mat-select .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), mat-select .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), mat-select .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
mat-select .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
mat-select .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 222, 251, 0.3);
}
mat-select .mat-tab-group.mat-background-primary > .mat-tab-header, mat-select .mat-tab-group.mat-background-primary > .mat-tab-link-container, mat-select .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #2196f3;
}
mat-select .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, mat-select .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
mat-select .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, mat-select .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
mat-select .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
mat-select .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
mat-select .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
mat-select .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
mat-select .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
mat-select .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
mat-select .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
mat-select .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
mat-select .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
mat-select .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
mat-select .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), mat-select .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
mat-select .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
mat-select .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), mat-select .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), mat-select .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
mat-select .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
mat-select .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 249, 196, 0.3);
}
mat-select .mat-tab-group.mat-background-accent > .mat-tab-header, mat-select .mat-tab-group.mat-background-accent > .mat-tab-link-container, mat-select .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ffee58;
}
mat-select .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, mat-select .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, mat-select .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
mat-select .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
mat-select .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
mat-select .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
mat-select .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
mat-select .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
mat-select .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
mat-select .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
mat-select .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
mat-select .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
mat-select .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), mat-select .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
mat-select .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
mat-select .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), mat-select .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), mat-select .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
mat-select .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
mat-select .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
mat-select .mat-tab-group.mat-background-warn > .mat-tab-header, mat-select .mat-tab-group.mat-background-warn > .mat-tab-link-container, mat-select .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
mat-select .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, mat-select .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
mat-select .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, mat-select .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
mat-select .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
mat-select .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
mat-select .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
mat-select .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
mat-select .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
mat-select .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
mat-select .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
mat-select .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
mat-select .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
mat-select .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
mat-select .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-toolbar.mat-primary {
  background: #2196f3;
  color: white;
}
mat-select .mat-toolbar.mat-accent {
  background: #ffee58;
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
mat-select .mat-toolbar .mat-form-field-underline,
mat-select .mat-toolbar .mat-form-field-ripple,
mat-select .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
mat-select .mat-toolbar .mat-form-field-label,
mat-select .mat-toolbar .mat-focused .mat-form-field-label,
mat-select .mat-toolbar .mat-select-value,
mat-select .mat-toolbar .mat-select-arrow,
mat-select .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
mat-select .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
mat-select .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
mat-select .mat-tree {
  background: white;
}
mat-select .mat-tree-node,
mat-select .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
mat-select .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
mat-select .mat-simple-snackbar-action {
  color: #ffee58;
}

app-input-date-picker .input-width {
  min-width: 100%;
  line-height: 50px;
  display: block;
  width: 100%;
  height: 42px;
  padding: 6px 12px;
  font-size: 14px;
  border: 2px solid #5AA2E5;
  color: #5AA2E5;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0px 0px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #fff;
}

.hamburger-box {
  width: 20px;
  height: 18px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 20px;
  height: 2px;
  background-color: #fff;
  border-radius: 2px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -8px;
}
.hamburger-inner::after {
  bottom: -8px;
}

/*
 * 3DX
 */
.hamburger--3dx .hamburger-box {
  perspective: 40px;
}
.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg);
}
.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
 * 3DX Reverse
 */
.hamburger--3dx-r .hamburger-box {
  perspective: 40px;
}
.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg);
}
.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
 * 3DY
 */
.hamburger--3dy .hamburger-box {
  perspective: 40px;
}
.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg);
}
.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
 * 3DY Reverse
 */
.hamburger--3dy-r .hamburger-box {
  perspective: 40px;
}
.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg);
}
.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
 * 3DXY
 */
.hamburger--3dxy .hamburger-box {
  perspective: 40px;
}
.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg);
}
.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
 * 3DXY Reverse
 */
.hamburger--3dxy-r .hamburger-box {
  perspective: 40px;
}
.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}
.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

/*
 * Arrow
 */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-4px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-4px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
 * Arrow Right
 */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(4px, 0, 0) rotate(45deg) scale(0.7, 1);
}
.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(4px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Alt
 */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-4px, -5px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-4px, 5px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
 * Arrow Alt Right
 */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(4px, -5px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(4px, 5px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
 * Arrow Turn
 */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}
.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Turn Right
 */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}
.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
 * Boring
 */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}
.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}
.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*
 * Collapse
 */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Collapse Reverse
 */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Elastic
 */
.hamburger--elastic .hamburger-inner {
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic .hamburger-inner::before {
  top: 8px;
  transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic .hamburger-inner::after {
  top: 16px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(135deg);
  transition-delay: 0.075s;
}
.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

/*
 * Elastic Reverse
 */
.hamburger--elastic-r .hamburger-inner {
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r .hamburger-inner::before {
  top: 8px;
  transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic-r .hamburger-inner::after {
  top: 16px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(-135deg);
  transition-delay: 0.075s;
}
.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(270deg);
  transition-delay: 0.075s;
}

/*
 * Emphatic
 */
.hamburger--emphatic {
  overflow: hidden;
}
.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::after {
  top: 8px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -40px;
  top: -40px;
  transform: translate3d(40px, 40px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -40px;
  top: -40px;
  transform: translate3d(-40px, 40px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
 * Emphatic Reverse
 */
.hamburger--emphatic-r {
  overflow: hidden;
}
.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::after {
  top: 8px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -40px;
  top: 40px;
  transform: translate3d(40px, -40px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -40px;
  top: 40px;
  transform: translate3d(-40px, -40px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
 * Minus
 */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}
.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}
.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}
.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}

/*
 * Slider
 */
.hamburger--slider .hamburger-inner {
  top: 1px;
}
.hamburger--slider .hamburger-inner::before {
  top: 8px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider .hamburger-inner::after {
  top: 16px;
}
.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-2.8571428571px, -6px, 0);
  opacity: 0;
}
.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}

/*
 * Slider Reverse
 */
.hamburger--slider-r .hamburger-inner {
  top: 1px;
}
.hamburger--slider-r .hamburger-inner::before {
  top: 8px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider-r .hamburger-inner::after {
  top: 16px;
}
.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(-45deg);
}
.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(2.8571428571px, -6px, 0);
  opacity: 0;
}
.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(90deg);
}

/*
 * Spin
 */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Spin Reverse
 */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Spring
 */
.hamburger--spring .hamburger-inner {
  top: 1px;
  transition: background-color 0s 0.13s linear;
}
.hamburger--spring .hamburger-inner::before {
  top: 8px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring .hamburger-inner::after {
  top: 16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}
.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 8px, 0) rotate(-45deg);
}

/*
 * Spring Reverse
 */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}
.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}
.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Stand
 */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}
.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}
.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Stand Reverse
 */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}
.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}
.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Squeeze
 */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Vortex
 */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}
.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}
.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
 * Vortex Reverse
 */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}
.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}
.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.app-popup-shared .inner {
  width: 560px;
  max-width: 90vw;
  position: relative;
  padding: 40px 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .app-popup-shared .inner {
    padding: 40px 20px;
  }
}
.app-popup-shared .popup-content {
  position: relative;
}
.app-popup-shared .popup-title {
  font-size: 21px;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
}
.app-popup-shared .popup-sub-title {
  font-weight: 400;
  font-size: 15px;
  text-align: center;
}
.app-popup-shared .popup-image {
  position: absolute;
  left: 5px;
  top: 0;
}
.app-popup-shared .popup-content .popup-button {
  margin-top: 15px;
  width: 100%;
  min-width: auto;
  padding: 0 15px;
  font-weight: 500;
  font-size: 16px;
}

.home-card--pale-blue {
  background-color: #DFEBF4;
}

.home-card--white {
  background-color: #fff;
}

.home-card--solid-blue {
  background-color: #273C73;
}

.home-card {
  max-width: 260px;
  min-height: 243px;
  padding: 20px;
  border-radius: 20px;
}
.home-card__icon {
  margin: 0 auto 12px 0;
  background-color: #fff;
}
.home-card__title, .home-card__title--small {
  font-weight: 900;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #000;
}
.home-card__title--small {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.4px;
}
.home-card__text {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #8089A0;
  white-space: pre-line;
}
.home-card--solid-blue .home-card__title, .home-card--solid-blue .home-card__title--small {
  color: #fff;
}
.home-card--solid-blue .home-card__text {
  color: #fff;
}
.home-card--solid-blue .home-icon, .home-card--solid-blue .home-icon--130, .home-card--solid-blue .home-icon--68, .home-card--solid-blue .home-icon--58, .home-card--solid-blue .home-icon--43 {
  background-color: #fff;
}
.home-card--wrapper {
  max-width: 100% !important;
  margin-left: auto;
  margin-right: auto;
}

.artist-card, .artist-card--info {
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 402px;
  border-radius: 16px;
  background-size: cover;
  color: #fff;
  text-decoration: none;
  overflow: hidden;
}
.artist-card:hover, .artist-card--info:hover, .artist-card:focus, .artist-card--info:focus, .artist-card:active, .artist-card--info:active, .artist-card:visited, .artist-card--info:visited {
  color: #fff;
  text-decoration: none;
}
.artist-card--info {
  position: relative;
  background-image: url("~assets/images/homepage/covers/cover.webp");
}
.artist-card--info::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
.artist-card.clickable, .clickable.artist-card--info {
  cursor: pointer;
}
.artist-card__header {
  margin: 0 0 auto;
  padding: 10px 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}
.artist-card__header span {
  font-weight: 900;
  font-size: 10px;
  line-height: 11px;
}
.artist-card__header .logo {
  max-width: 120px;
  object-fit: contain;
  aspect-ratio: auto 60/7;
}
.artist-card__header .icon {
  max-width: 12px;
  object-fit: contain;
  aspect-ratio: auto 6/7;
}
.artist-card__content {
  margin-top: auto;
  margin-bottom: 0;
  padding: 36px 10px 10px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%);
}
.artist-card__name {
  font-weight: 700;
  font-size: 17px;
  line-height: 17px;
  text-transform: uppercase;
}
.artist-card__song {
  font-size: 13px;
  line-height: 13px;
}
.artist-card__divider {
  width: 100%;
  height: 1px;
  border: none;
  background-color: #fff;
  margin: 8px 0;
}
.artist-card .details, .artist-card--info .details {
  display: flex;
  gap: 16px;
  align-items: center;
  border-radius: 100px;
}
.artist-card .details__icon, .artist-card--info .details__icon {
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
}
.artist-card .details__value, .artist-card--info .details__value {
  font-weight: 800;
  font-size: 31px;
  line-height: 31px;
}
.artist-card .details__text, .artist-card--info .details__text {
  font-weight: 500;
  font-size: 17px;
  line-height: 18px;
}
.artist-card .info, .artist-card--info .info {
  margin: auto;
  text-align: center;
  max-width: 80%;
  z-index: 2;
}
.artist-card .info__icon, .artist-card--info .info__icon {
  background-color: #5AA2E5;
  margin: 0 auto 4px;
}
.artist-card .info__value, .artist-card--info .info__value {
  font-weight: 800;
  font-size: 31px;
  line-height: 41px;
}
.artist-card .info__text, .artist-card--info .info__text {
  font-weight: 500;
  font-size: 17px;
  line-height: 18px;
  margin: auto;
}

.home-icon, .home-icon--130, .home-icon--68, .home-icon--58, .home-icon--43 {
  position: relative;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}
.home-icon__image {
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home-icon--43 {
  width: 43px;
  height: 43px;
}
.home-icon--43 .home-icon__image {
  height: auto;
  max-height: 23px;
}
@media (max-width: 768px) {
  .home-icon--43 {
    width: 35px;
    height: 35px;
  }
  .home-icon--43 .home-icon__image {
    max-height: 20px;
  }
}
.home-icon--58 {
  width: 58px;
  height: 58px;
}
.home-icon--68 {
  width: 68px;
  height: 68px;
}
.home-icon--68 .home-icon__image {
  height: auto;
  max-height: 36px;
}
.home-icon--130 {
  width: 130px;
  height: 130px;
}
.home-icon--130 .home-icon__image {
  height: auto;
  max-height: 70px;
  min-height: 70px;
}
.home-icon.white, .white.home-icon--43, .white.home-icon--58, .white.home-icon--68, .white.home-icon--130 {
  background-color: #fff;
}

.home-accordion {
  display: block;
  border-radius: 20px;
  padding: 5px 0;
  background-color: #DFEBF4;
  overflow: hidden;
}
.home-accordion .mat-expansion-panel {
  background: transparent !important;
}
.home-accordion .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.home-accordion .mat-expansion-panel:not(.last)::after {
  content: "";
  display: block;
  width: calc(100% - 30px);
  height: 1px;
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1px dashed #C4C4C4;
}
@media (min-width: 768px) {
  .home-accordion .mat-expansion-panel:not(.last)::after {
    width: calc(100% - 80px);
  }
}
.home-accordion .mat-expansion-panel.mat-expanded .mat-expansion-indicator {
  transform: rotate(0deg) !important;
}
.home-accordion .mat-expansion-panel-header {
  padding-left: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #273C73;
  background: transparent !important;
}
.home-accordion .mat-expansion-panel-header-title {
  color: #273C73;
}
.home-accordion .mat-expansion-indicator {
  transform: rotate(270deg) !important;
}
.home-accordion .mat-expansion-indicator::after {
  margin-bottom: 4px;
  color: #273C73;
}
.home-accordion .mat-expansion-panel-body {
  padding: 0 15px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #8089A0;
  text-align: left;
}
@media (min-width: 768px) {
  .home-accordion .mat-expansion-panel-body {
    padding: 0 60px 20px 40px;
  }
}
.home-accordion .mat-expansion-panel-body a {
  font-weight: 600;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 24px;
  color: #4DA3E1;
}
.home-accordion .mat-expansion-panel-body ol {
  list-style: decimal;
  padding-left: 20px;
}
.home-accordion .mat-expansion-panel-body p + ol {
  margin-top: 24px;
}

.wizard__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 0 20px;
}
.wizard__button {
  width: 100%;
  max-width: 212px;
  position: relative;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
}
.wizard__button--center {
  margin: 0 auto;
}
.wizard__button mat-spinner {
  position: absolute;
  left: 10px;
  top: 10px;
}
.wizard__button .fa-chevron-left {
  margin-right: 10px;
  font-size: 14px;
}
.wizard__button.cancel {
  color: #5AA2E5;
  cursor: pointer;
}
@media (max-width: 768px) {
  .wizard__button.cancel {
    width: fit-content;
  }
}
.wizard__text, .wizard__text--underline {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #8089A0;
}
.wizard__text--underline {
  text-decoration: underline;
}

.kyc-label {
  width: 126px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  border-radius: 7px;
}
.kyc-label i {
  margin: auto 2px auto 10px;
}
.kyc-label span {
  width: 100%;
  text-align: center;
}
.kyc-label.verified {
  background-color: #8FCB0E;
}
.kyc-label.pending {
  background-color: #FFA800;
}
.kyc-label.failed {
  background-color: #F95738;
}