@import '../../../assets/scss/variables';

app-user-dashboard {
  height: 100%;

  .main-wrapper {
    min-height: 100vh;
    background: $white;
  }

  .main {
    border-top: 1px dashed #B7BCCB;
    flex: 1;
    height: 100%;
    width: 100%;
    max-width: 1350px;
    margin: 0 auto;
  }
  .kyc__status{
    max-width: 1300px;
    margin: 20px auto;
  }
  .warning__list {
    display: flex;
    justify-content: flex-end;
    .warning__title{
      white-space: nowrap;
    }
  }

  .section-list-title {
    width: 1300px;
    text-transform: uppercase;
    margin: 32px auto;
  }

  .action-tab {
    width: 100%;
    display: flex;
    align-items: center;
    .button.yellow {
      margin: 0 28px;
    }
    .value {
      font-size: 27px;
      white-space: nowrap;
    }
    .text {
      color: $gray-color3;
    }
    .info-item {
      width: 15%;
    }
    .item {
      width: auto;
    }
  }
  .list-buttons {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    .button {
      margin-left: 15px;
    }
  }
  .button-wrapper {
    display: flex;
  }
  .info-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .button-action {
    .button {
      height: 50px;
      width: 200px;
      margin-bottom: 10px;
      font-size: 16px;
    }
  }

  .action-tab-wrapper {
    padding-right: 20px;
    width: 60%;
  }

  .link-blue {
    cursor: pointer;
  }

  .profile-song-content {
    display: flex;
    .description {
      width: 100%;
      padding: 20px 0 0 40px;
    }
  }

  .button.border-blue {
    background: transparent;
  }
  .no-list-data {
    font-style: italic;
  }

  .next-slide, .prev-slide {
    color: $blue-color1;
    width: 20px;
    height: 20px;
    top: 50%;
    margin-top: -10px;

    .lnr {
      font-size: 14px;
      font-weight: $font-weight-700;
    }
  }

  .prev-slide {
    position: absolute;
    right: 0;
  }

  .next-slide {
    position: absolute;
    left: 0;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .img-circle {
    margin: 0 auto;
  }
  ngx-slick-carousel {
    padding: 0 30px;
    .slick-track {
      display: flex !important;
      align-items: center !important;
    }

    .slick-current {
      .img-circle {
        width: 150px !important;
        margin-top: 0 !important;
        height: 150px !important;
        border-radius: 100px !important;
        opacity: 1 !important;
      }
    }

    .slick-slide {
      .img-circle {
        opacity: 0.8;
      }
    }
  }

  .favorite-artist {
    border-top: 1px dashed $gray-color1;
    width: 1300px;
    margin: 32px auto;

    .item {
      width: 33.33%;
    }
  }
  .load-more {
    display: flex;
    justify-content: center;
    .global-page-loader {
      height: auto;
      margin: 0;
      .lnr {
        font-size: 50px;
        left: 20px;
      }
    }
  }
  .mobile-screen {
    display: none;
  }
  .slider-item {
    background-position: center;
    background-size: cover;
    overflow: hidden;
    background-image: url('../../../assets/images/fallback.png');
    .inner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
    }
  }
  .slick-slider {
    margin: 60px 0;
  }
  app-loader {
    height: 200px;
    display: flex;
  }
}

@media screen and (max-width: 1370px) {
  app-user-dashboard {
    .main{
      max-width: 95%;
    }
    .section-list-title,
    .favorite-artist {
      width: 90%;
    }
  }
}

@media screen and (max-width: 1023px) {
  app-user-dashboard {
    main {
      width: 100%;
    }
    .image-desc-float {
      width: 100px;
      min-width: 100px;
      height: 100px;
    }
    .profile-song-content {
      .description {
        padding: 0;
      }
    }
    .action-tab {
      align-items: flex-start;
      .button {
        width: 100%;
        margin: 5px 10px;
      }
      .button.yellow {
        margin: 0 28px 0 0;
      }
    }
    .value {
      font-size: 36px;
    }
  }
}

@media screen and (max-width: 767px) {

  app-user-dashboard {
    .main {
      padding: 0 15px;
      background: $white;
    }
    .section-list-title {
      width: 100%;
    }

    .img-circle {
      width: 90px;
      height: 90px;
      min-width: 90px;
    }
    .desktop-screen {
      display: none;
    }
    .mobile-screen {
      display: block;
    }
    .action-tab {
      .info-item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 10px;
      }
    }
    .section-list-title {
      margin: 0 auto 16px;
    }
    .load-more {
      margin: 0 0 15px 0;
    }
    ngx-slick-carousel .slick-current .img-circle {
      width: 100px !important;
      height: 100px !important;
    }

    .value {
      text-align: center;
      font-size: 24px;
    }
    .favorite-artist {
      width: 100%;
      margin: 0 auto 32px;
      .item {
        margin: 15px 0;
        width: 100%;
      }
    }
    .action-tab {
      flex-direction: column;
      width: 100%;
      align-items: center;
      .button {
        width: 100%;
        margin: 10px 0;
      }
      .list-buttons {
        padding: 0;
      }
      .button-wrapper {
        margin: 20px 0;
        width: 100%;
        justify-content: space-between;
      }
    }
    .list {
      flex-direction: column;
    }
    .image-desc-float {
      margin-right: 16px;
    }
  }
}
