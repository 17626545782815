@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

app-tabs {
  width: 100%;
  .tab-header {
    display: flex;
    width: 100%;
    margin: 0 auto;
    @include screen767 {
      justify-content: flex-start;
    }
  }
  .tab-item {
    padding: 0 30px;
    line-height: 42px;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;
    align-items: flex-end;
    @include screen767 {
      line-height: 30px;
      font-size: 12px;
      padding: 0 20px;
    }
    @include screen320 {
      font-size: 14px;
      padding: 0 10px;
    }

    &.active {
      @include screen767 {
        bottom: 0;
      }
      span {
        display: block;
        position: absolute;
        height: 5px;
        width: 40px;
        border-radius: 50px;
        margin: 0 auto;
        transition: all ease-in 0.2s;
        background-color: $yellow-color2;
        bottom: -2px;

      }
    }
    &:first-child {
      padding-left: 0;
      @include screen767 {
        padding-left: 15px;
      }
    }
  }
}
