@import "variables";

html {
  height: 100%;
  font-family: $default-font;
  scroll-behavior: smooth;
}

body {
  font-family: $default-font;
  font-weight: normal;
  background: $gray-color2;
  font-size: $default-font-size;
  color: $default-font-color;
  line-height: $default-line-height-computed;
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.1;
  font-weight: normal;
}

a {
  text-decoration: none;
}

audio {
  width: 100%;
}
.card-outer{
  overflow: hidden;
  border-radius: 20px;
  width: 310px;
  height: 473px;
}
.lineHeight10 {
  line-height: 1;
}

.lineHeight20 {
  line-height: 1.4;
}

.spinner {
  display: flex;
  justify-content: center;
}

.lineHeight40 {
  line-height: 1.8;
}

.lineHeight60 {
  line-height: 2.2;
}

.lineHeight80 {
  line-height: 2.6;
}

.lineHeight100 {
  line-height: 3;
}

.gray-text {
  color: $gray-color3;
  font-size: 14px;
  line-height: 18px;
}

.global-spinner-wrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.default-link {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  &.link-block {
    display: block;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

input, button, textarea {
  font-family: $default-font;
  -webkit-appearance: none
}

input, select {
  font-size: 100%;
}

div {
  display: block;
}

.wizard__button {
  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: white;
  }
}

#style-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: $gray-color6;
}

#style-scrollbar::-webkit-scrollbar {
  width: 7px;
  background-color: #F5F5F5;
}

#style-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: $gray-color3;
  box-shadow: 0 0 0 2px white;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.item-share-document {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-top: 20px;
  span {
    padding-left: 10px;
  }
}
.color-white {
  color: $white
}

.color-light-grey {
  color: $gray-color3
}

.black-color {
  color: #02080ab3;
}

.item-highlighted {
  position: relative;
  padding: 5px 18px;
  margin-right: 18px;
  background: $yellow-color2;
  border-radius: 5px 5px 0 0;
  text-align: center;

  &.ends {
    background: $red-color1;
  }

  &.released {
    background: $red-color9;
  }

  &.new {
    background: $green-color10;
  }

  &.funded {
    background: $blue-color1;
  }

  span {
    color: $white;
    font-weight: bold;
    font-size: $menu-font-size;
  }
}

.title14 {
  font-weight: normal;
  font-size: 14px;
}

.small-toggle {
  .mat-slide-toggle-bar {
    height: 26px !important;
    width: 48px !important;
    border-radius: 30px;
    background-color: #5AA2E5;
    border: 2px solid #5AA2E5;
  }

  &.yellow {
    .mat-slide-toggle-bar {
      height: 26px !important;
      width: 48px !important;
      border-radius: 30px;
      background-color: $yellow-color2;
      border: 2px solid $yellow-color2;
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
      background-color: transparent;
      border: 2px solid $gray-color3;
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
      background-color: transparent;
      border: 2px solid $gray-color3;
    }
  }

  &.disable {
    .mat-slide-toggle-bar {
      height: 26px !important;
      width: 48px !important;
      border-radius: 30px;
      background-color: $gray-color3;
      border: 2px solid $gray-color3;
    }
  }

  .mat-slide-toggle-thumb-container {
    transform: translate3d(46px, 0, 0);
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: transparent;
    border: 2px solid #B7BCCB;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: transparent;
    border: 2px solid #B7BCCB;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(26px, 0, 0);
  }

  .mat-slide-toggle-thumb {
    width: 20px;
    height: 20px;
  }

  .mat-slide-toggle.mat-checked .mat-ripple-element {
    background-color: transparent;
  }

  .mat-slide-toggle .mat-slide-toggle-ripple {
    top: calc(50% - 17px);
    left: calc(50% - 17px);
  }

  .mat-slide-toggle-thumb-container {
    top: 1px;
    left: -24px;
  }
}

.xs-toggle {
  $height: 18px !important;
  $width: 44px !important;

  @extend .small-toggle;

  .mat-slide-toggle-bar {
    height: $height;
    width: $width;
  }

  &.disable {
    .mat-slide-toggle-bar {
      height: $height;
      width: $width;
    }
  }

  .mat-slide-toggle-thumb-container {
    transform: translate3d(44px, 0, 0);
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(24px, 0, 0);
  }

  .mat-slide-toggle-thumb {
    width: 13px;
    height: 13px;
  }

  .mat-slide-toggle-thumb-container {
    top: 0;
    left: -22px;
  }
}

.mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.5) !important;
}

.fa-question-circle {
  font-size: 14px;
  color: $blue-color1;

}

.profile-page {
  .mat-slide-toggle-bar {
    height: 34px !important;
    width: 68px !important;
    border-radius: 30px;
    background-color: #5AA2E5;
    border: 2px solid #5AA2E5;
  }

  .mat-slide-toggle-thumb-container {
    transform: translate3d(46px, 0, 0);
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: transparent;
    border: 2px solid #B7BCCB;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: transparent;
    border: 2px solid #B7BCCB;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(14px, 0, 0);
  }

  .mat-slide-toggle-thumb {
    width: 26px;
    height: 26px;
  }

  .mat-slide-toggle.mat-checked .mat-ripple-element {
    background-color: transparent;
  }

  .mat-slide-toggle .mat-slide-toggle-ripple {
    top: calc(50% - 17px);
    left: calc(50% - 17px);
  }

  .mat-slide-toggle-thumb-container {
    top: 2px;
    left: -10px;
  }
}

.stickyMenu {
  .mat-select-value, .mat-form-field-appearance-legacy, .mat-form-field-underline {
    color: white !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: white !important;
  }

  .mat-select .mat-select-arrow {
    color: white !important;
  }
}

.title15 {
  font-weight: normal;
  font-size: 15px;
}

.title16 {
  font-weight: normal;
  font-size: 16px;
}

.title18 {
  font-size: 18px;
  font-weight: normal;
}

.title27 {
  font-size: 27px;
  font-weight: normal;
}

.title28 {
  font-size: 28px;
  font-weight: normal;
}

.title32 {
  font-size: 32px;
  font-weight: normal;
}

.title36 {
  font-weight: normal;
  font-size: 36px;
}

.title48 {
  font-weight: normal;
  font-size: 48px;
}

.font-bold {
  font-weight: $font-weight-900;
}

.link-blue {
  font-weight: 500;
  font-size: 14px;
  color: $blue-color1;
}

.link-red {
  font-weight: 500;
  font-size: 14px;
  color: $red-color1;
}

.wrapper {
  width: 100%;
}

.upload-section {
  background: #EEFCFF;
  position: relative;
  border: 2px dashed $blue-color1;
  overflow: hidden;

  &:last-child {
    margin-left: 15px;

  }

  &.passport {
    width: 100%;
    margin-left: 0;
  }

  label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }

  span, .fa-camera, .fa-music {
    color: $blue-color1
  }

  img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    //top: 55px;
    // margin-top: -50%;
  }

  input[type="file"] {
    display: none;
  }

  span {
    font-size: 14px;
  }

  .fa-camera {
    font-size: 20px;
    line-height: 23px;
  }
}

.nowrap {
  white-space: nowrap;
}

.lnr-chevron-right {
  display: inline-block;
  font-weight: $font-weight-900;
  cursor: pointer;

  &.opened {
    transition: all .25s ease-out 0s;
    transform: rotate(90deg);
  }
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-raw {
  display: flex;
  justify-content: space-between;
}

.flex-raw-center {
  display: flex;
  justify-content: center;
}

app-reinvest-payment {
  app-checkbox .checkbox {
    min-width: 30px;
    margin-top: 35px;
    border: 2px solid $blue-color1;
  }
}

.payments {
  .mat-radio-button .mat-radio-ripple {
    display: none;
  }
}

.social-item {
  .mat-form-field-appearance-legacy {
    padding: 0 10px;
  }

  .mat-form-field-infix {
    border-top: 0;
  }
}

.faq-description {
  .content-text {
    text-align: left;
    margin-bottom: 15px;
    line-height: 18px;
  }

  ul li:before {
    content: "";
    position: absolute;
    top: 30%;
    left: -25px;
    width: 8px;
    height: 8px;
    border: 2px solid #07a3e4;
    border-radius: 50% 50% 50% 50%;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  li {
    display: list-item;
    position: relative;
    text-align: -webkit-match-parent;
  }
}

.social {
  .circle {
    position: relative;
    z-index: 0;
    display: block;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 28px;
      height: 28px;
      top: 0;
      left: 0;
      border-radius: 100%;
    }
  }

  &.twitter {
    position: relative;

    .circle {
      &:before {
        background-color: $blue-color8;
      }
    }

    .fa-twitter {
      position: absolute;
      font-size: 14px;
      left: 8px;
      top: 8px;
      color: #ffffff;
    }
  }

  &.linkedin {
    position: relative;

    .circle {
      &:before {
        background-color: $blue-color10;
      }
    }

    .fa-linkedin {
      position: absolute;
      font-size: 14px;
      left: 8px;
      top: 8px;
      color: #ffffff;
    }
  }

  &.pinterest {
    position: relative;

    .circle {
      &:before {
        background-color: $red-color10;
      }
    }

    .fa-pinterest-p {
      position: absolute;
      font-size: 22px;
      left: 8px;
      top: 8px;
      color: #ffffff;
    }
  }

  &.whatsapp {
    position: relative;

    .circle {
      &:before {
        background-color: $green-color12;
      }
    }

    .fa-whatsapp {
      position: absolute;
      font-size: 22px;
      left: 8px;
      top: 8px;
      color: #ffffff;
    }
  }

  &.instagram {
    position: relative;

    .circle {
      &:before {
        background-color: $red-color7;
      }
    }

    .fa-instagram {
      position: absolute;
      font-size: 19px;
      left: 6px;
      top: 4px;
      color: #ffffff;
    }
  }

  &.youtube {
    position: relative;

    .circle {
      &:before {
        background-color: $red-color1;
      }
    }

    .fa-youtube {
      position: absolute;
      font-size: 15px;
      left: 6px;
      top: 7px;
      color: #ffffff;
    }
  }

  &.dropbox {
    .circle {
      &:before {
        background-color: $blue-color1;
      }
    }

    .fa-dropbox {
      position: absolute;
      font-size: 15px;
      left: 6px;
      top: 7px;
      color: #ffffff;
    }
  }

  &.soundcloud {
    .circle {
      &:before {
        background-color: $yellow-color4;
      }
    }

    .fa-soundcloud {
      position: absolute;
      font-size: 15px;
      left: 4px;
      top: 6px;
      color: #ffffff;
    }
  }

  &.tiktok {
    .circle {
      &:before {
        background-color: $black-color1;
      }
    }

    .fa-tiktok {
      position: absolute;
      font-size: 15px;
      left: 8px;
      top: 6px;
      color: #ffffff;
    }
  }

  &.facebook {
    position: relative;

    .circle {
      &:before {
        background-color: $blue-color9;
      }
    }

    .fa-facebook-f {
      position: absolute;
      font-size: 16px;
      left: 9px;
      top: 6px;
      color: #ffffff;
    }
  }

  &.spotify {
    position: relative;

    .fa-spotify {
      font-size: 28px;
      color: $green-color11;
    }
  }
}

.tooltip {
  position: absolute;
  display: none;
  font-size: 14px;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  z-index: 2;
  color: $white-color;
  border: 1px solid #e1e1e1;

  &:after {
    content: '';
    display: block;
    position: absolute;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(0, 0, 0, 0.7);
  }
}

.flex-align-justify {
  display: flex;
  justify-content: center;
}

.width70 {
  width: 70%;
}

.width30 {
  width: 30%;
}

.flex-align {
  display: flex;
}

.flex-align-column {
  display: flex;
  flex-direction: column;
}

.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: $blue-color1 !important;
}

.flex-item {
  width: 50%;
}

.grey-text {
  color: $gray-color3;
}

.img-circle {
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 50px;
  background-position: center;
  background-size: cover;
}

.backgroundImage {
  background-size: cover;
  background-position: center center;
}

.lnr {
  &.disabled {
    opacity: .5;
  }

  &.lnr-warning {
    font-size: 12px;
    color: $red-color3;
    padding-right: 10px;
  }

  &.vertical-align {
    display: flex;
    align-items: center;
  }
}

.warning {
  font-size: 12px;
  color: $red-color3;
}

.warning-text {
  color: $red-color1;
}

.no-list-data {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}

div.global-page-loader {
  position: relative;
  width: 100%;
  height: 100px;
  margin: auto;
  color: $blue-color1;

  .lnr {
    font-size: 60px;
    left: calc(50% - 30px);
    position: absolute;
    top: 0;
    animation-name: loading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.wrapper-pagination-footer {
  margin: 20px 0;
}

.loader {
  .lnr {
    animation-name: loading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes loading {
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
}

.lnr {
  font-size: 15px;
  margin-left: 20px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }

  &:first-child {
    margin-left: 0;
  }

  &.lnr-pencil {
    color: $blue-color1;
  }

  &.lnr-file-empty {
    color: $blue-color1;
  }

  &.lnr-chevron-right {
    color: $blue-color1;
  }

  &.lnr-users {
    color: $blue-color1;
    font-size: 18px;
    font-weight: bold;
  }

  &.lnr-calendar-full {
    color: $blue-color1;
    font-size: 16px;
    font-weight: bold;
  }

  &.lnr-map-marker {
    color: $blue-color1;
    font-size: 18px;
    font-weight: bold;
  }

  &.lnr-user {
    color: $blue-color1;
    font-size: 14px;
    font-weight: bold;
  }

  &.lnr-magnifier {
    color: $green-color1;
  }

  &.lnr-checkmark-circle {
    color: $green-color1;
  }

  &.lnr-cross-circle {
    color: $red-color1;
  }

  &.lnr-trash {
    color: $red-color1;
  }

  &.lnr-cross {
    color: $red-color1;
  }

  &.disabled {
    opacity: .5;
  }
}

//******Checkbox***********//

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: $blue-color1;
  border: 1px solid $blue-color1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.min-screen, .mid-screen {
  display: none;
}

.max-screen {
  display: table-cell;
}


.loader {
  position: relative;
  width: 30px;
  height: 30px;

  .image-load {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-name: loading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.loader.hide {
  display: none;
}

@keyframes loading {
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
}
