@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

app-notification-info-popup {
  .inner {
    width: 560px;
    max-width: 90vw;
    position: relative;
    padding: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include screen767 {
      padding: 40px 20px;
    }
  }
  .popup-content {
    position: relative;
    color: $gray-color3;
  }
  .popup-title {
    font-size: 21px;
    line-height: 60px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
  }
  .popup-sub-title {
    font-weight: 400;
    font-size: 15px;
    text-align: center;
  }

  .popup-content {
    .popup-button {
      margin: 15px auto 0;
      width: 200px;
      min-width: auto;
      padding: 0 15px;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

