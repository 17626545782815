@import '../../../../assets/scss/variables';

app-date-picker {
  .mat-calendar-body-label {
    display: none;
  }
  .mat-calendar-body-cell-content{
    border-radius: 0;
  }
  .mat-calendar-body-selected{
    background-color: $blue-color1;
  }
  .mat-calendar-body-in-range::before{
    background-color: $gray-color6;
  }
  .mat-card {
    background: rgba(242, 247, 251, 0.57);
    box-shadow: unset !important;
    border-radius: 0;
    padding: 0 16px 16px;
  }
  tbody td {
    border-bottom: 0;
  }
  tr:hover {
    td {
      background: unset;
    }
  }
}

date-picker-header {
  width: 100%;
  .c_arrow-left, .c_arrow-right {
    width: 14px;
    top: 11px;
    position: absolute;
  }

  .example-header {
    display: flex;
    align-items: center;
    padding: 0.5em;
  }

  .example-header-label {
    flex: 1;
    height: 1em;
    font-weight: 900;
    text-align: center;
  }

  .example-double-arrow .mat-icon {
    margin: -22%;
  }
}
