@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';

app-cookies {

  .cookies-info {
    align-self: center;
  }
  .cookies-popup {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    height: auto;
    padding: 20px;
    left: 0;
    z-index: 100;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    .container {
      display: flex;
      max-width: 1300px;
      margin: 0 auto;
      justify-content: space-between;
      &.settings {
        flex-direction: column;
        @include screen767 {
          height: auto;
        }
      }
    }
    @include screen767 {
      padding: 20px 0 0 0;
    }
  }
  .cookies-image {
    height: 50px;
    aspect-ratio: auto 27 / 25;
    @include screen767 {
      display: none;
    }
  }
  .container {
    @include screen767 {
      flex-direction: column;
    }
  }
  .cookies-settings {
    display: flex;
    justify-content: flex-start;
    @include screen767 {
      flex-direction: column;
    }
  }
  .cookies-button-wrapper {
    margin: 1.2em 0;
    align-items: center;
    .button {
      margin-left: 0.8em;
      white-space: nowrap;
      width: 100%;
      padding: 0 20px;
      min-width: auto;
    }
    @include screen767 {
      width: 100%;
      justify-content: space-between;
      margin: 1.2em;
      .button {
        margin: 0;
      }
    }
    @include screen460 {
      .button {
        padding: 0;
        font-size: 13px;
      }
    }
  }
  .read-more{
    transition: all .2s ease-out;
    &.showReadMore{
      height: 60px;
      transition: all .2s ease-out;
      @include screen414{
        height: 90px;
      }
    }
    @include screen460{
      height: 0;
      overflow: hidden;
    }
  }
  .read-more-title{
    display: none;
    margin-top: 10px;
    @include screen460{
      display: block;
    }
  }
  .toggle {
    display: flex;
    align-items: center;
    .toggle-item {
      display: flex;
      align-items: center;
      color: $white-color;
      margin-right: 60px;
    }
    .status {
      width: 30px;
      &.off {
        color: $gray-color3;
      }
    }
    .toggle-text {
      &.off {
        color: $gray-color3;
      }
    }
    .toggle-box {
      margin: 0 1em;
    }
    @include screen767 {
      flex-direction: column;
      padding: 0 20px;
      align-items: flex-start;
      .toggle-item {
        margin: 0.5em 0;
      }

    }
  }
  .cookies-text {
    color: $white-color;
    font-weight: normal;
    line-height: $small-text-headliner;
    @include screen460 {

      font-size: 13px;

    }
    &.cookies-text__padding {
      padding: 0 20px;
    }
    .cookies-text__headline {
      line-height: 18px;
    }
  }
}
