@import '../../../assets/scss/variables';

:host {
  height: 100%;

  .main-wrapper {
    background: $white-color;
    padding-top: 74px;
    display: flex;
    flex-direction: column;
    height: 100vh;

    .inner {
      max-width: 1180px;
      width: 1180px;
      margin: 0 auto;
      flex: 1;
    }
  }

  .main-title {
    margin: 54px 0;
    text-align: center;
  }

  section.content {
    padding-bottom: 35px;

    .sub-title {
      font-size: 20px;
      text-align: center;
      color: $black-color1;
      margin-bottom: 15px;
    }

    .text {
      text-align: center;

    }
  }

  section.contact {
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: $default-border-dash;

    p {
      line-height: 22px;
    }
  }
}

@media screen and (max-width: 1440px) {
  app-imprint {
    .main-wrapper .inner {
      margin: 0 60px;
      width: auto;
    }
  }
}

@media screen and (max-width: 767px) {
  app-imprint {
    .main-wrapper {
      height: 100%
    }

    section.contact {
      padding: 40px 0;
      flex-direction: column;
      align-items: flex-start;

      .item {
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .main-title {
      font-size: 26px;
      margin: 54px 0 20px 0;
    }

    .main-wrapper .inner {
      margin: 0 40px;
      width: auto;
    }
  }
}

@media screen and (max-width: 460px) {
  app-imprint {
    .main-wrapper .inner {
      margin: 0 20px;
      width: auto;
    }
  }
}
