@import '../../../assets/scss/variables';

app-kyc-status {
  text-align: right;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 140px;
  width: 100%;
  .kyc-title {
    color: $gray-color3;
    font-size: 14px;
  }
  .wrapper-kyc {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .wrapper-kyc:hover + .wrapper-tooltip-kyc {
    .tooltip-kyc {
      display: block;
      width: 180px;
    }
  }
  .warning {
    margin-top: 5px;
    display: inline-block;
  }
  .error {
    position: relative;
    width: 100%;

    .fa-question-circle {
      font-size: 18px;
      color: white;
      cursor: pointer;
      top: -21px;
      right: 8px;
      position: absolute;
    }
    .error__item {
      margin-bottom: 10px;
    }
  }
  .error-hint {
    position: absolute;
    width: 100%;
    right: 58px;
  }
  .fa-question-circle:hover + .error-hint {
    .tooltip-kyc {
      display: block;
      right: -20px;
      width: 250px;
      top: 10px;
    }
  }
  .kyc-status-block {
    margin-top: 5px;
    border-radius: 7px;
    width: 126px;
    height: 24px;
    line-height: 26px;
    text-align: left;
    span {
      font-size: 13px;
      font-weight: bold;
      color: $white-color;
    }
    .fas {
      padding: 0 10px;
    }
    &.inactive {
      background: $red-color1;
    }
    &.active {
      background: $green-color10;
    }
    &.pending {
      background: $yellow-color2;
    }
  }
  .tooltip-kyc {
    top: 60px;
    text-align: left;
    left: -50px;
    padding: 14px;
    &:after {
      top: -7px;
      right: 45px;
      width: 0;
      height: 0;
    }
    &.error__tooltip {
      &:after {
        right: 12px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  app-kyc-status {
    .kyc-status-block {
      margin-top: 5px;
    }
    app-popup {
      .popup-wrapper {
        max-height: 100vh;
        top:0;
      }

    }
  }
}
