@import "assets/scss/variables";
@import "assets/scss/mixin";

// Cards
$colors: (
  "pale-blue": $gray-color6,
  "white": $white-color,
  "solid-blue": $blue-color7,
);

@each $key, $value in $colors {
  .home-card--#{$key} {
    background-color: #{$value};
  }
}

.home-card {
  max-width: 260px;
  min-height: 243px;
  padding: 20px;
  border-radius: 20px;

  &__icon {
    margin: 0 auto 12px 0;
    background-color: $white-color;
  }

  &__title {
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: $black-color1;

    &--small {
      @extend .home-card__title;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.4px;
    }
  }

  &__text {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $gray-color3;
    white-space: pre-line;
  }

  &--solid-blue {
    .home-card {
      &__title {
        color: $white-color;
      }
      &__text {
        color: $white-color;
      }
    }

    .home-icon {
      background-color: $white-color;
    }
  }

  &--wrapper {
    max-width: 100% !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.artist-card {
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 402px;
  border-radius: 16px;
  background-size: cover;
  color: $white;
  text-decoration: none;
  overflow: hidden;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: $white;
    text-decoration: none;
  }

  &--info {
    @extend .artist-card;

    position: relative;
    background-image: url("~assets/images/homepage/covers/cover.webp");

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 1;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &__header {
    margin: 0 0 auto;
    padding: 10px 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );

    span {
      font-weight: 900;
      font-size: 10px;
      line-height: 11px;
    }

    .logo {
      max-width: 120px;
      object-fit: contain;
      aspect-ratio: auto 60 / 7;
    }

    .icon {
      max-width: 12px;
      object-fit: contain;
      aspect-ratio: auto 6 / 7;
    }
  }

  &__content {
    margin-top: auto;
    margin-bottom: 0;
    padding: 36px 10px 10px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &__name {
    font-weight: 700;
    font-size: 17px;
    line-height: 17px;
    text-transform: uppercase;
  }

  &__song {
    font-size: 13px;
    line-height: 13px;
  }

  &__divider {
    width: 100%;
    height: 1px;
    border: none;
    background-color: $white-color;
    margin: 8px 0;
  }

  .details {
    display: flex;
    gap: 16px;
    align-items: center;
    border-radius: 100px;

    &__icon {
      box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
    }

    &__value {
      font-weight: 800;
      font-size: 31px;
      line-height: 31px;
    }

    &__text {
      font-weight: 500;
      font-size: 17px;
      line-height: 18px;
    }
  }

  .info {
    margin: auto;
    text-align: center;
    max-width: 80%;
    z-index: 2;

    &__icon {
      background-color: $blue-color1;
      margin: 0 auto 4px;
    }

    &__value {
      font-weight: 800;
      font-size: 31px;
      line-height: 41px;
    }

    &__text {
      font-weight: 500;
      font-size: 17px;
      line-height: 18px;
      margin: auto;
    }
  }
}

// Icons
.home-icon {
  position: relative;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

  &__image {
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &--43 {
    @extend .home-icon;
    width: 43px;
    height: 43px;

    .home-icon__image {
      height: auto;
      max-height: 23px;
    }

    @include maxW(768px) {
      width: 35px;
      height: 35px;

      .home-icon__image {
        max-height: 20px;
      }
    }
  }

  &--58 {
    @extend .home-icon;
    width: 58px;
    height: 58px;
  }

  &--68 {
    @extend .home-icon;
    width: 68px;
    height: 68px;

    .home-icon__image {
      height: auto;
      max-height: 36px;
    }
  }

  &--130 {
    @extend .home-icon;
    width: 130px;
    height: 130px;

    .home-icon__image {
      height: auto;
      max-height: 70px;
      min-height: 70px;
    }
  }

  &.white {
    background-color: $white;
  }
}
