@import '../../../../assets/scss/variables';

app-apple {
  apple-pay-button {
    display: block;
    margin: 0 auto;
    --apple-pay-button-width: 240px;
    --apple-pay-button-height: 45px;
    --apple-pay-button-border-radius: 5px;
    --apple-pay-button-padding: 5px 0px;
    --apple-pay-button-box-sizing: border-box;
  }
  .apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: buy; /* Use any supported button type. */
  }
  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }
  .apple-pay-button-white {
    -apple-pay-button-style: white;
  }
  .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
  }
}
