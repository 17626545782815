@import '../../../../assets/scss/variables';

app-checkbox {
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  &.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }

  .hidden-button {
    display: none;
    &:not(:checked) {
      + .button .check-mark {
        background: transparent;
      }
    }
    &:checked {
      + .checkbox{
        background: $blue-color1;
        border: 1px solid $blue-color1;
      }
    }
  }

  .checkbox {
    width: 30px;
    height: 30px;
    overflow: hidden;
    background: white;
    border: $default-border;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .check-mark {
      position: absolute;
      top: 8px;
      left: 6px;
      width: 14px;
      height: 11px;
      background: url('/assets/images/check.png');
    }
    .check-mark-grey {
      position: absolute;
      top: 8px;
      left: 6px;
      width: 14px;
      height: 11px;
      background: url('/assets/images/check-grey.png');
    }
  }

  .toggle-button__container {
    cursor: pointer;
    position: relative;
    margin: 0 0.5rem;
  }
  .mode-toggle__input {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .mode-toggle__bg {
    height: 18px;
    width: 36px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.5);
    display: inline-block;
    transition: background-color 300ms linear;
  }
  .mode-toggle__circle {
    height: 14px;
    width: 14px;
    background-color: $white-color;
    position: absolute;
    top: 2px;
    border-radius: 50%;
    transition: left 300ms linear;
    left: 2px;
  }
  .mode-toggle__circle-checked {
    background-color: $white-color;
    left: 20px;
  }

  .mode-toggle__bg-checked {
    background-color: $green-color2;
  }

  //.label {
  //  margin-left: 13px;
  //}
}
