.filters {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 10px;

  .item {
   margin-bottom: 15px;
    width: 180px;
    margin-right: 20px;
  }

  .input-text, .select, .input-text-empty, .select-empty {
    min-width: 100%;
    max-width: 100%;
    background: $white-color;
    box-sizing: border-box;
    border-radius: 20px;
    height: 30px;
    font-size: 14px;
  }
  .label-min{
    color: #333!important;
    min-width: 150px!important;
  }
  .input-text, .select {
    border: 2px solid $blue-color1;
  }

  .input-text-empty, .select-empty {
    border: 1px solid $gray-color1;
    font-style: italic;
    font-size: 12px;
    text-transform: capitalize;
    color: $gray-color1;
  }

  option {
    color: $default-font-color;
  }

  input, select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

}
