@import '../../../../assets/scss/variables';

app-edit-password-popup {
  .inner {
    max-width: 400px;
    width: 90vw;
    position: relative;
    padding: 0 !important;
  }

  .buttons-wrapper {
    margin: 25px 23px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .button {
      max-width: 45%;
    }
  }

  .lnr-cross {
    position: absolute;
    right: 20px;
    font-size: 16px;
    font-weight: 600;
    top: 20px;
    cursor: pointer;
    color: $blue-color1;
  }

  .form {
    padding: 0 24px;
  }

  .item {
    width: 100%;
    margin-top: 10px;
  }

  .label {
    font-family: $default-font;
    text-transform: capitalize;
    font-size: $default-font-size;
    color: $gray-color2;
    line-height: 2;
    transition: all linear .1s;

    &.opened {
      transform: translateY(-19px);
    }
  }

  .error-message {
    &.align-block {
      display: flex;
    }

    .error-message-text {
      display: inline-block;
    }
  }

  .select[multiple] {
    min-height: 80px;
  }
}

