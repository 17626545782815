@import "../../../../assets/scss/variables";

app-reinvest-payment {
  margin: 10px 0;
  display: block;
  padding: 10px 15px;
  border: 2px solid $blue-color1;
  border-radius: 5px;
  background: $white-color;

  &:has(app-checkbox.active) {
    background-color: $blue-color14;
  }

  app-checkbox {
    &.disabled {
      img {
        filter: contrast(0);
      }
      .checkbox {
        border: 2px solid $gray-color10;
      }
    }

    &.voucher-checkbox {
      margin-top: 20px;

      p,
      .checkbox {
        margin-top: 0;
      }
    }
  }

  .checkbox-content {
    margin-left: 16px;

    p {
      font-size: 12px;
      margin-top: 9px;
      line-height: 16px;
      color: $gray-color3;
    }
  }

  .logo {
    max-width: 192px;
    margin-left: 15px;
  }
}
