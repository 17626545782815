@import '../../../../assets/scss/variables';

app-subscribe-popup {
  .inner {
    width: 460px;

    max-width: 90vw;
    position: relative;
    padding: 40px 10%;
  }


  .lnr-cross {
    position: absolute;
    right: 20px;
    font-size: 16px;
    font-weight: 600;
    top: 20px;
    cursor: pointer;
    color: $blue-color1;
  }

  .block-title {
    font-weight: 900;
    font-size: 21px;
    text-transform: uppercase;
    text-align: center;
    margin: 30px 0 10px;
  }

  .text {
    font-size: 15px;
    text-align: center;
    margin-bottom: 10px;
  }

  .image {
    display: block;
    margin: 0 auto;
    height: 130px;
  }

  mat-form-field {
    width: 100%;
  }

  .fa-envelope {
    color: $link-color;
    margin: 0 20px 0 12px;
  }

  .button {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  app-subscribe-popup {
    .inner {
      padding: 40px 24px;
    }

    .form {
      padding: 0;
    }
  }
}
