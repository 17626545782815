@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';
app-tfa {
  margin: 30px 0;
  .title {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
  }
  .lnr-chevron-right {
    display: none;
  }
  .tfa__process {
    width: 20%;
    padding: 0 25px;
    margin: 0 auto;
  }
  .tfa {
    .toggle-item {
      display: flex;
      align-items: center;
      height: 40px;
      .status {
        padding-right: 15px;
      }
    }
    .tfa__key {
      color: $gray-color3;
    }
    .tfa__info {
      margin-top: 15px;
      span {
        padding-left: 5px;
      }
    }
    .tfa-input {
      width: 100%;
    }
    .button {
      margin: 0 auto;
    }
  }
  .section-toggle-mob{
    margin-top: 20px;
    @include screen767{
      margin-top: 0;
      height: 0;
      overflow: hidden;
      &.openSetting {
        height: auto;
        padding-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  app-tfa {
    .tfa__process {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  app-tfa {
    .lnr.lnr-chevron-right {
      display: inline-block;
      font-weight: 900;
    }
    .tfa {
      padding-bottom: 0;
      &.profile__section.profile__section-padding {
        padding-bottom: 0;
      }
      .toggle-item, .tfa__info {
        padding: 0;
      }
      .title {
        .title-m {
          padding-left: 10px;
        }
      }
    }
  }
}
