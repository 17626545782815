@import "../variables";

.call-to-action__image {
  margin-right: 55px;
}

.call-to-action__header {
  display: flex;
  align-items: center;
  padding: 18px 0;
}

.call-to-action__title {
  display: flex;
  img {
    width: 23px;
    margin-right: 10px;
  }
}



.project__call-to-action {
  display: flex;
  margin-top: -80px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .button {
    border: 2px solid #5AA2E5;
    border-radius: 5px;
    text-transform: uppercase;
    height: 32px;
    margin-left: 16px;
    background: transparent;
    cursor: pointer;
    &.show {
      .fa-chevron-down {
        transform: rotate(180deg);
        transition: all ease-in .1s;
      }
    }
    .fa-chevron-down {
      transition: all ease-in .1s;
    }
    span{
      padding-right: 14px;
    }
  }


}

@media screen and (max-width: 1380px) {
  .project__call-to-action {
    margin-top: 45px;
    align-content: center;
    flex-direction: column;
    .button {
      margin: 0 15px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .call-to-action__header {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {

  .call-to-action__title {
    img {
      width: 23px;
      height: 23px;
    }
  }
  .call-to-action__header {
    padding: 15px 18px;
    .button {
      margin-top: 10px;
    }
  }
  .call-to-action__image {
    margin-right: 0;
  }
  .call-to-action__text {
    margin-top: 15px;
  }
  .project__call-to-action {
    margin-top: 15px;
    border-radius: 0;
  }
}
