@import '../../../assets/scss/variables';

app-menu-search {
  .deposit__button {
    margin: 0;
  }
}

@media screen and (max-width: 1024px) {

}
