@import '../../../../assets/scss/variables';

app-page-header {
  .background-image {
    background-color: #253a6f;
    height: 196px;
    width: 100%;
    background-size: cover;
    background-position: center center;
    position: relative;
  }
  .user__details {
    margin: -80px auto 0 auto;
    width: 1300px;
    display: flex;
    padding-left: 60px;
    .text {
      z-index: 10;
    }
  }
  main {
    flex: 1;
    width: 100%;
    margin: 0 auto;
  }
  .page-header__title {
    z-index: 10;
    color: $white;
    font-weight: bold;
    font-size: 27px;
  }
  .user__add-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 0 25px 37px;
    justify-content: space-between;
  }
  .user__profile-img {
    width: 160px;
    min-width: 160px;
    position: relative;
    background: url('/assets/images/fallback.png');
    height: 160px;
    background-size: cover;
    background-position: center center;
    border-radius: 80px;
    box-shadow: $default-shadow;
  }
  .page-header__content {
    padding-top: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1370px) {
  app-page-header {
    .user__details {
      width: 90%;
      padding-left: 20px;
    }
  }
}
@media screen and (max-width: 767px) {
  app-page-header {
    .background-image {
      background-image: none !important;
      background-color: #253a6f;
      height: 156px;
    }
    .user__add-info {
      padding: 0 15px;
    }
    .page-header__content {
      padding-bottom: 25px;
    }
    .page-header__title {
      font-size: 18px;
      padding-top: 30px;
      text-align: center;
    }
    .user__details {
      width: 100%;
      padding-left: 0;
    }
    .user__profile-img {
      display: none;
    }
  }
}
