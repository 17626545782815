@import '../../../../assets/scss/variables';

app-image-cropper {
  display: block;

  .desktop-upload {
    display: none;
  }
  .crop__upload {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    max-width: 250px;
  }
  .cropper-save {
    margin-bottom: 20px;
    text-transform: uppercase;
    flex: 1;
    max-width: 250px;
  }

  .cropper {
    &__buttons {
      display: flex;
      justify-content: center;
      width: 100%;
      gap: 16px;
    }
  }
}
