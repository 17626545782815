@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';

app-marketplace-lazy {
  .page_content {
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 20px;
  }
  .position-block {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    @include screen1023 {
      padding: 30px;
      margin-top: 0;
    }
    .position-block-up {
      position: relative;
      width: 95%;
      margin-top: -60px;
      top: -60px;
      border-radius: 7px;
      background: $gray-color2;
      box-shadow: 0 2px 4px rgba(144, 170, 190, 0.5);
      height: auto;
      padding: 0 30px;
      @include screen1023 {
        width: 100%;
        top: 0;
        margin-top: 0;
        padding: 0;
        background: $white-color;
        box-shadow: none;
      }
    }
    .title {
      margin: 50px 0;
      text-align: center;
      @include screen1023 {
        display: none;
      }
    }
    .button {
      width: 357px;
      font-size: 21px;
      font-weight: 500;
      height: 60px;
      margin: 50px auto;
      @include screen1023 {
        margin: 15px auto;
        width: 269px;
        height: 52px;
        font-size: 18px;
      }
    }
    .how-works-img {
      width: 75px;
      height: 75px;
      min-width: 75px;
    }
  }
  app-main-slider{
    .market-container{
      @include screen767 {
       margin-top: 0;
      }
    }
    .slider-btn{
      @include screen767 {
        margin: 5px 0;
      }
    }
  }

  .load-more {
    display: flex;
    //margin: 1rem 0;
    justify-content: center;
    .button {
      width: 356px;
    }
  }

  .content-inner {
    display: flex;
    margin-bottom: 50px;
    .item {
      width: 33.33%;
      padding: 0 30px;
      .how-works-text {
        padding-left: 21px;
        .sub-title {
          line-height: 20px;
        }
        .visible-part {
          padding-top: 20px;
          text-overflow: ellipsis;
          word-wrap: break-word;
          overflow: hidden;
          line-height: 1.5;
          @include screen1023 {
            padding-top: 10px;
            line-height: inherit;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .content-inner {
      flex-direction: column;
      margin-bottom: 15px;
      .item {
        width: 100%;
        padding: 0 0 20px 0;

        .how-works-text {
          padding-left: 21px;
        }
      }
    }
  }
}
