@import "../variables";

.grs-button {
  display: block;
  border-radius: 26px;
  padding: 12px 0;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  transition: all 0.2s ease-in;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &--outline {
    border: 2px solid;
    background-color: $white-color !important;
    &:hover {
      background-color: $white-color !important;
    }
  }

  &--orange {
    @extend .grs-button;
    background-color: $yellow-color2;
    color: $white-color;
    -webkit-text-fill-color: $white-color;

    &:focus, &:visited {
      color: $white-color;
    }

    &:hover {
    color: $white-color;
      background-color: #e69700;
    }

    &.grs-button--outline {
      -webkit-text-fill-color: $yellow-color2;
      color: $yellow-color2;
      border-color: $yellow-color2;
    }
  }

  &--blue {
    @extend .grs-button;
    background-color: $blue-color1;
    color: $white-color;

    &:focus, &:visited {
      color: $white-color;
    }

    &:hover {
    color: $white-color;
      background-color: darken($blue-color1, 10%);
    }

    &.grs-button--outline {
      color: $blue-color1;
      border-color: $blue-color1;
    }
  }

  &--white {
    @extend .grs-button;
    background-color: $white-color;
    color: $yellow-color2;

    &:focus, &:visited {
      color: $yellow-color2;
    }

    &:hover {
      color: $yellow-color2;
      background-color: darken($white-color, 10%);
    }
  }
}
