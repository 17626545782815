@import '../../../assets/scss/variables';

app-revenue {
  height: 100%;

  .main-wrapper {
    min-height: 100vh;
    background: $white;
  }

  main {
    flex: 1;
    width: 100%;
    margin: 0 auto;
  }

  .data-list {
    width: 1300px;
    margin: 0 auto;

    .data-list--margin {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }

  .table-header {
    border-bottom: 2px solid $gray-color3;
  }

  .song__row {
    td:first-child {
      max-width: 55px;
      width: 55px;
    }
  }

  .not-revenue {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: right;
    color: $gray-color3;

    .not-revenue__title {
      font-weight: 700;
    }
  }

  .table__nested {
    table {
      width: 100%;
    }

    .table-open {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease-out;

      &.openNested {
        max-height: initial;
        width: 100%;
        transition: max-height 0.5s ease-out;
      }
    }

    .nested__td {
      padding: 0 55px;
      border: none;

      &.active {
        border-bottom: 1px dashed $gray-color4;
      }
    }

    td {
      color: $gray-color3
    }

    .report {
      text-decoration: none;
    }

    .table-sub-header {
      border-bottom: 2px solid $gray-color3;

      td {
        padding: 26px 18px 12px 18px;
      }
    }

    .nested{
      &__body {
        td {
          padding: 20px 18px;
        }

        td:empty {
          display: none;
        }
      }

      &__divider {
        height: 0px;
        border: 0;
        border-bottom: 1px dashed $gray-color1;
      }
    }
  }

  .filter-wrapper {
    .item {
      margin-right: 14px;
    }
  }

  .wrapper-pagination-footer {
    border-bottom: 1px dashed #B7BCCB;
    margin: 0;
    padding: 20px 0;
  }

  .investment-table {
    margin-top: 10px;
  }

  .view {
    cursor: pointer;
  }

  .action-tab {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .button.yellow {
      width: auto;
      padding: 0 30px;
      height: 50px;
      font-size: 16px;

      &.disabled {
        background: $gray-color5;
        color: $black-color1;
        cursor: inherit;

        span {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }

    .text {
      color: $gray-color3;
      margin-bottom: 12px;
    }

    .item {
      width: auto;
      margin-right: 20px;

      &:first-child {
        margin-left: 0;
      }
    }

    .fa-question-circle {
      font-size: 14px;
      color: $blue-color1;
      cursor: pointer;
    }

    .balance {
      margin-right: 60px;
      position: relative;

      .fa-question-circle {
        padding-left: 5px;
      }

      .line {
        height: 100%;
        display: block;
        border-right: 1px solid #8089A0;
        position: absolute;
        right: -30px;
        top: 0;
        opacity: 0.2;
      }

    }

    .value {
      white-space: nowrap;
      font-size: 20px;
      display: flex;
      align-items: center;

      .voucher-value {
        font-size: 22px;
      }
    }

    .link-blue {
      padding-top: 10px;
      display: inline-block;
    }

    .wrapper {
      align-items: center;
      width: auto;
    }

    .info-wrapper {
      position: relative;
      justify-content: space-between;
      width: 100%;
      text-align: right;
    }

    .info-amount {
      width: 100%;
      //  align-items: center;
      display: flex;
      flex-direction: column;

      .item .text {
        margin-bottom: 0;
      }

      .text {
        white-space: nowrap;
      }
    }

  }

  .button-activity {
    margin-top: 10px;
    display: flex;
  }

  .wrapper-amount {
    display: flex;
  }

  .summary-info__item {
    margin-right: 20px;
    button {
      width: 155px;
      height: 40px;
    }
    .button-spinner {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    mat-spinner{
      margin-right: 5px;
    }
  }


  .filter-section.desktop-screen {
    justify-content: flex-start;
    display: flex;
    border-top: 1px dashed $gray-color1;
    width: 1300px;
    margin: 0 auto;
    padding-top: 18px

  }

  .filter-title {
    line-height: 48px;
    padding-right: 20px;
  }

  .button.border-blue {
    &.disabled {
      background: transparent;
      border: 2px solid $gray-color4;
      color: $gray-color4;
      opacity: 0.7;
    }
  }

  .revenue {
    text-align: center;

    &div {
      font-weight: 900;
    }

    .fa-question-circle {
      padding-left: 12px;
    }

    .left {
      font-size: 14px;
      color: $red-color1;
    }
  }

  .taken {
    color: $red-color1;
    font-size: 11px;
  }

  .mobile-screen {
    display: none;
  }

  .wrapper-tooltip {
    display: block;
    position: relative;
    width: 0;
    height: 100%;
  }

  .not-revenue-tooltip {
    font-size: 16px;
    color: $blue-color1;
    cursor: pointer;
    //padding-right: 20px;
  }

  //.not-revenue-tooltip:hover + .timer-hint {
  //  .tooltip {
  //    display: block;
  //    width: 180px;
  //  }
  //}

  .fa-question-circle:hover + .advance-hint {
    .tooltip {
      display: block;
      left: -10px;
      // width: 180px;
    }
  }

  .fa-question-circle:hover + .balance-hint {
    .tooltip {
      display: block;
      left: -90px;
      top: 20px;
      white-space: nowrap;

      &.tooltip-wrap {
        white-space: normal;
        width: 250px;
      }
    }
  }

  .tooltip {
    top: 8px;
    text-align: left;
    left: -70px;
    padding: 14px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -6px;
      right: 45px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid rgba(0, 0, 0, 0.7);
    }

    &.error__tooltip {
      &:after {
        right: 20px;
      }
    }

    &.tooltip-wrap {
      &:after {
        right: 160px;
      }
    }
  }

  .rights-tooltip .tooltip {
    display: block;
    width: 200px;
    right: -73px;
    font-size: 12px;
    font-weight: 400;
    top: 24px;
    left: unset;
  }

  .button-wrapper-refund {
    display: flex;
    width: auto;
    flex-direction: column;

    .button.withdraw {
      margin-bottom: 15px;
    }
  }

  .image-wrapper {
    height: 196px;
    width: 100%;
    position: relative;
  }

  .lnr-chevron-right {
    display: inline-block;
    font-weight: $font-weight-900;

    &.opened {
      transition: all .25s ease-out 0s;
      transform: rotate(90deg);
    }
  }

  tbody {
    td {
      padding: 30px 18px;
    }

    tr {
      &:hover {
        td {
          background: transparent;
        }
      }
    }
  }

  .button-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .btn-check {
    justify-content: center;
    margin-bottom: 20px;

    .button {
      font-size: 16px;
      padding: 0 30px;
    }
  }

  .apply-btn {
    height: 40px;
    min-width: 120px;
    line-height: 36px;
  }

  .refund-money {
    // border-top: 1px dashed $gray-color1;
    border-bottom: 1px dashed $gray-color1;
    width: 1300px;
    margin: 0 auto 32px;

    .refund-title {
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      padding: 20px 18px;
      cursor: pointer;

      .title-m {
        padding-left: 10px;
      }
    }

    .button-wrapper {
      width: auto;
      margin-left: 45px;
      align-items: flex-start;
    }
  }

  .under-review {
    width: 1300px;
    margin: 32px auto;
    text-align: center;

    span {
      color: $red-color1;
    }
  }

  .reinvest-btn {
    text-align: center;
    width: 100%;
    margin-bottom: 15px;

    .button {
      width: 100%;
      font-size: 16px;
    }

    .not-sepa-text {
      display: inline-block;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .column {
    cursor: default;
  }

  .caption {
    display: flex;
    align-content: center;
    height: 24px;
    color: $gray-color3;
  }

  .section-toggle-mob {
    height: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    transition: all 1s ease-out;

    &.openSetting {
      height: auto;
      transition: all 1s ease-out;
    }
  }

  .flex-align-column-filter mat-form-field {
    display: inline-block;
    position: relative;
    text-align: left;
    width: 60px;
    margin-right: 5px;
  }

  .reset-filters {
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    margin-left: 15px;
    color: $link-color;
    display: flex;
    white-space: nowrap;
    align-items: center;

    .lnr-cross {
      margin-left: 3px;
      color: $link-color;
    }
  }

  .chev-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    //padding-left: 8%;
  }

  .chevron-year {
    font-size: 8px;
    width: 16px;
    height: 16px;
    padding-top: 2px;

    &.opened {
      padding-left: 2px;
      padding-top: 8px;
      transition: all .25s ease-out 0s;
      width: 16px;
      height: 16px;
      // margin-right: 6px;
      //margin-left: -4px;
      transform: rotate(90deg);
    }
  }

  .chevron-month {
    font-size: 8px;
    white-space: nowrap;
    // margin-left: 20px;
    &.opened {

      transition: all .25s ease-out 0s;

      transform: rotate(90deg);
    }
  }

  .chevron-stats {
    margin-left: 20px !important;
  }

  .nested__body {
    .fa-download {
      color: $blue-color1;
      padding-left: 18px;
    }

    tr {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }

  .download-loaded {

    div.global-page-loader {
      margin: 0;
      height: 16px;

      .lnr {
        font-size: 20px;
        left: 0;
        top: -2px;
      }
    }
  }

  .revenue-icon {
      .hover-block {
        position: relative;
        height: 20px;
        width: 20px;

        img {
          width: 100%;
          object-fit: contain;
        }

        .tooltip-card {
          &::after {
            content: none;
            display: none;
          }
        }
      }
  }
}

@media screen and (max-width: 1370px) {
  app-revenue {
    .refund-money {
      width: 90%;
    }

    .filter-section.desktop-screen {
      width: 90%;
    }

    .data-list {
      width: 90%;
    }

    .under-review {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1023px) {
  app-revenue {
    .button-activity {
      flex-direction: column;
      flex-wrap: wrap;
    }
    main {
      width: 100%;
    }

    tbody td, .table__nested .nested__body td, .table th{
      padding: 20px 4px;
    }
    .wrapper-amount{
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
    }
    .action-tab {
      .link-blue {
        display: none;
      }

      .info-amount {
        .item.advances {
          text-align: left;
          .value{
            justify-content: flex-start;
          }
        }
      }

      .button.yellow {
        font-size: 14px;
      }

      .flex-align-column-filter {
        width: 100%;
        margin-left: 0;
      }

      .item-title {
        padding-bottom: 5px;
        display: inline-block;
        text-align: center;
        width: 100%;
      }

    }
    .btn-check{
      padding: 0 15px;
    }
    .summary-info__item {
      width: 100%;
      margin-right: 0;
      margin-top: 10px;
      &.summary-info__item--secure-btn {
        button {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  app-revenue {
    main {
      background: $white;
    }

    .summary-info__item {
      &.summary-info__item--secure-btn {
        button {
          height: 50px;
        }
      }
    }

    .refund-money {
      display: none;
    }

    .mobile-screen {
      display: block;
    }

    .not-revenue {
      justify-content: flex-end;
    }

    .filter-section.desktop-screen {
      display: none;
    }

    //.wrapper-tooltip {
    //  width: 100%;
    //}
    .rights-tooltip {
      .tooltip {
        right: -5px;
        left: unset;

        &:after {
          right: 5px;
        }
      }
    }

    .table__nested {
      font-size: 12px;

      .nested__td {
        padding: 0;
      }

      .nested__body {
        td {
          padding: 10px 5px;
        }

        tr:last-child td {
          padding: 10px 5px;
        }
      }

      .table-sub-header td {
        padding: 12px 5px 12px 5px;
      }

      .nested__amount {
        font-size: 14px;
      }

      .link-blue {
        font-size: 12px;
      }

      .fa-download {
        padding-left: 8px;
      }
    }

    .non-mobile {
      display: none;
    }

    .table-header {
      border-bottom: 1px dashed #B7BCCB;

    }

    .chev-wrapper {
      padding-left: 0px;
    }

    .button.width-auto-button {
      height: 50px;
    }

    .image-wrapper {
      height: 156px;
    }

    .filter-wrapper {
      justify-content: space-between;
      flex-wrap: wrap;

      .item {
        margin-right: 0;
        width: 48%;

        &:last-child {
          app-datepicker {
            .calendar-wrapper {
              right: 0;
              left: auto;
            }
          }
        }

        &:first-child {
          app-datepicker {
            .calendar-wrapper {
              left: 0;
              right: auto;
            }
          }
        }
      }
    }

    .data-list {
      padding: 0 15px;
      width: 100%;
    }

    .min-screen {
      display: table-cell;
    }

    .mid-screen {
      display: none;
      cursor: default;
    }

    .button-wrapper {
      .button {
        width: 100%;
      }
    }

    .song-name, .artist-name {
      margin-bottom: 5px;
    }

    .revenue {
      text-align: right;
      padding-right: 0;
    }

    .action-tab {
      flex-direction: column;
      width: 100%;

      .info-amount {
        justify-content: space-between;

        .item {
          width: 33.33%;

          &.balance {
            width: 50%;
            margin: 0;
            text-align: left;

            .line {
              display: none;
            }

            .value {
              font-size: 24px;
            }

            .current-value {
              justify-content: flex-start;
            }
          }

          &:nth-child(3) {
            text-align: left;

            .value {
              width: 70%;
              justify-content: flex-start;
              text-align: center;
            }
          }

          &:nth-child(5) {
            text-align: right;

            .value {
              width: 100%;
              justify-content: flex-end;
              text-align: center;
              margin-left: 0;
            }
          }

          .value {
            justify-content: center;
            line-height: 36px;
          }
        }
      }

      .withdraw-mobile-screen {
        margin-top: 30px;

        button {
          width: 100% !important;
        }
      }

      .desktop-screen {
        display: none;
      }

      app-kyc-status {
        width: 50%;
        display: flex;
        justify-content: flex-end;
      }

      .mobile-screen-filter {
        margin-top: 20px;
        width: 100%;
      }

      .item {
        text-align: center;
        margin-top: 20px;
        margin-right: 0;

        .button.yellow, .button.border-blue {
          width: 100%;
          justify-content: center;
        }
      }

      .value {
        font-size: 18px;
      }

      .wrapper {
        width: 100%;

        &:last-child {
          margin-left: 0;
        }

        .item {
          margin-left: 0;
        }

        &:first-child {
          justify-content: space-around;
        }

        &:last-child {
          width: 100%;
          margin: 0 auto;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          .item {
            width: 100%;

            margin-top: 20px;
          }

          .button {
            width: 100%;
          }
        }
      }
    }

    tbody {
      td {
        &:nth-child(3) {
          padding: 20px 15px 20px 0;
        }
      }
    }

    .table th {
      &:nth-child(2) {
        padding: 11px 15px 11px 0;
      }
    }

    mat-form-field {
      + .button {
        width: 100%;
      }

      &:nth-child(4) {
        margin: 0;
      }

      &.year {
        width: 70px;
      }
    }

    .reset-filters {
      margin: 5px auto 0;
    }
  }
}
