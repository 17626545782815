@import '../../../assets/scss/variables';

app-terms-conditions {
  height: 100%;
  .main-wrapper {
    background: $white-color;
    padding-top: 74px;

    .inner {
      max-width: 1180px;
      margin: 0 auto;
    }
  }

  .main-title {
    margin-top: 54px;
    text-align: center;
  }
  section.content {
    margin-bottom:100px;
    font-size: 14px;
    p {
      line-height: 18px;
      margin-bottom: 15px;
    }
    div:first-child {
      margin-top: 10px;
      p:first-child {
        text-align: center;
        margin-bottom: 0;
      }
    }
    h3 {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: normal;
      color: $black-color1;
      margin-bottom: 15px;
    }
    ul, ol {
      line-height: 18px;
      margin: 0 0 20px 20px;
      list-style-type: none;
      li {
        position: relative;
        padding-left: 30px;
        line-height: 22px;
        &:before {
          content: '';
          position: absolute;
          top: 8px;
          left: 0;
          width: 8px;
          height: 8px;
          border: 2px solid #FFA800;
          -webkit-border-radius: 50% 50% 50% 50%;
          -moz-border-radius: 50% 50% 50% 50%;
          -ms-border-radius: 50% 50% 50% 50%;
          -o-border-radius: 50% 50% 50% 50%;
          border-radius: 50% 50% 50% 50%;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  app-terms-conditions {
    .main-wrapper .inner {
      margin: 0 60px;
      width: auto;
    }
  }
}
@media screen and (max-width: 767px) {
  app-terms-conditions {
    section.content {
      margin-bottom: 50px;
    }
    .main-title{
      font-size: 26px;
    }
    .main-wrapper .inner {
      margin: 0 40px;
      width: auto;
    }
  }
}
@media screen and (max-width: 460px) {
  app-terms-conditions {
    .main-wrapper .inner {
      margin: 0 20px;
      width: auto;
    }
  }
}
