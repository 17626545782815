@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixin";

app-sell-bid {
  display: flex;
  min-height: 100vh;
  background: $white-color;
  @include placeholder(18px, 400, center);
  @include screen460 {
    flex-direction: column;
  }
  .flow-info-text {
    font-weight: 900;
    line-height: 1;
    background-size: cover;
    background-image: url('../../../assets/images/nftwizard/collectible_sale.png');
    background-position: 50% 50%;
    @include screen1023 {
      display: none;
    }
  }
  .inner {
    border-right: 1px dashed $gray-color4;
  }
  app-card-collectible {
    .card-outer, .card-image-wrapper {
      border-radius: 20px;
    }
  }
  .circle {
    box-shadow: inset 0 0 8px rgba(144, 170, 190, 0.2);
  }
  .flow-steps {
    color: $gray-color3;
  }

  .step__content {
    border-bottom: 1px dashed $gray-color4;
  }
  .step-one {
    .step__content {
      border-top: 1px dashed $gray-color4;
      padding: 48px 24px 30px;
      @include screen460 {
        padding: 48px 0 30px;
      }
    }
    .mat-radio-group {
      display: flex;
      flex-direction: column;
    }
    .radio-text {
      margin-top: 15px;
    }
    .title {
      width: 340px;
      margin-left: auto;
      margin-right: auto;
    }
    h4 {
      font-weight: 700;
      font-size: 15px;
    }
  }
  .step-four {
    text-align: center;
    .title {
      margin-top: 26px;
    }
    p {
      text-align: center;
      padding-bottom: 30px;
    }
    .button.yellow {
      width: 373px;
    }
  }
  .mat-radio-group {
    .mat-radio-container {
      width: 14px;
      height: 14px;
    }
    .mat-radio-button {
      margin-bottom: 24px;
      padding: 10px 22px 10px 10px;
      &:last-child {
        margin-bottom: 14px;
      }
    }
    .mat-radio-checked {
      border: 2px solid #5AA2E5;
      border-radius: 5px;
    }
    .mat-radio-outer-circle {
      width: 14px;
      height: 14px;
      border-width: 1px;
    }
    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
      border-color: $blue-color1;
    }
    .mat-radio-checked .mat-radio-inner-circle {
      transform: scale(0.6);
    }
    .mat-radio-button.mat-accent .mat-radio-inner-circle {
      background-color: $blue-color1;
      width: 14px;
      height: 14px;
    }
    .mat-radio-label {
      white-space: normal;
      align-items: flex-start;
    }
  }
  .flow-button-wrapper {
    margin: 30px 0;
    .button {
      position: relative;
      @include buttonSubmitSpinner;
    }
  }
  .step-four + .flow-button-wrapper {
    .button {
      margin: 0 auto;
    }
  }
  .price-amount {
    position: relative;
  }
  .tooltip-price {
    position: absolute;
    top: 12px;
    right: 150px;
    .tooltip-card {
      left: -14px;
      right: unset;
    }
    img {
      width: 25px;
      height: 25px;
    }
  }
  .price-selling {
    text-align: center;
    padding-bottom: 30px;
    h5 {
      font-size: 14px;
      line-height: 28px;
    }
  }
  .input-box {
    background: $gray-color2;
    max-width: 329px;
    text-align: center;
    font-weight: 900;
    &.error {
      color: $red-color1;
    }
  }
  .auction-detail {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px dashed $gray-color4;
    .auction-detail__item {
      display: flex;
      line-height: 24px;
      align-items: center;
      padding: 5px 0;
      justify-content: space-between;
      p{
        position: relative;
      }
    }
  }
  .auction-detail-edition {
    display: flex;
  }
  .grs-fee{
    .tooltip-price{
      top: 0;
      right: 0;
      position: relative;
    }
    .tooltip-text{
      display: flex;
      align-items: center;
    }
    img{
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
    .hover-block{
      display: flex;
      align-items: center;
    }
  }
  .bid-amount {
    font-weight: 900;
    font-size: 36px;
    color: $black-color1;
  }
  app-input-date-picker {
    display: none;
    @include screen460 {
      display: block;
    }
  }
  .auction-date {
    position: relative;
    display: flex;
    justify-content: space-between;
    @include screen460 {
      flex-direction: column;
    }
    .button-date {
      position: relative;
      background-color: $blue-color1;
      border-radius: 5px;
      padding: 0 17px;
      height: 42px;
      display: flex;
      justify-content: center;
      text-align: left;
      flex-direction: column;
      width: 196px;
      @include screen460 {
        width: 100%;
        margin-top: 10px;
      }
    }
    .button-date__period {
      font-size: 10px;
      display: inline-block;
    }
    .button-date__select-text {
      text-transform: uppercase;
      font-weight: 700;
      display: inline-block;
    }
    .button-date__image {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
  .default-cross {
    position: absolute;
    top: 10px;
    right: 10px;
    color: $gray-color3 !important;
  }
  .error-message {
    //display: none;
    text-align: left;
    width: 100%;
    margin: 0;
    font-size: 11px;
    @include screen460 {
      display: inline-block;
    }
  }
  .success-image {
    width: 75px;
    height: 75px;
  }
  .terms {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px dashed #C4C4C4;
    padding-top: 30px;
    margin-top: 20px;
  }
  .popup-picker {
    position: absolute;
    bottom: -114px;
    left: -77px;
    display: flex;
    width: 600px;
    justify-content: space-between;
    height: 423px;
    box-shadow: 0 2px 4px rgba(144, 170, 190, 0.5);
    background: #FFFFFF;
    border: 0.5px solid #F2F7FB;
    padding: 32px;
    z-index: 3;
    @include screen460 {
      width: 100%;
      left: 0;
      bottom: -7px;
      height: auto;
    }
  }
  .button.yellow.button-lower-case {
    text-transform: unset;
    width: 370px;
  }
  .flow-steps-inner {
    .step-two, .step-three {
      .step__content {
        width: 416px;
        @include screen460 {
          width: 100%;
        }
      }
    }
    .step-four {
      max-width: 540px;
      .step__content {
        max-width: 416px;
        margin: 0 auto;
        @include screen460 {
          max-width: 100%;
        }
      }
    }
    @include screen460 {
      width: 100%;
    }
  }
  .calendar {
    @include screen460 {
      display: none;
    }
  }
  .calendar-action {
    .button {
      @include buttonSubmitYellow;
      width: 196px;
      margin-top: 21px;
      @include screen460 {
        width: 100%;
      }
    }
    h3 {
      font-size: 14px;
      text-align: left;

      &.picker-block__title {
        margin-top: 20px;
      }
    }
    @include screen460 {
      width: 100%;
    }
  }
  .button.blue:hover:not(:disabled) {
    background: #4a95db;
  }

  app-tooltip {
    .tooltip-card {
      top: 35px;
      z-index: 1;
      width: 124px;
      font-size: 10px;
      font-weight: 500;
      &:after {
        bottom: 0;
        top: -7px;
        right: 90px;
      }
    }
    @include screen460 {
      display: none !important;
    }
  }
  .tooltip-component {
    display: none;
  }
  .picker-block {
    width: 196px;
    height: 42px;
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    position: relative;
    font-size: 13px;
    font-weight: 900;
    &.errorTime {
      span {
        color: $red-color1;
      }
      &:hover .tooltip-component {
        display: block;
      }
    }
    &.picker-block--blue {
      background-color: $blue-color1;
      color: $white-color;
    }
    &.picker-block--blue-border {
      border: 2px solid $blue-color1;
      color: $blue-color1;
    }
    .input {
      @include inputBorderNone;
      width: 90px;
      text-align: left;
      font-weight: 900;
      color: $blue-color1;
      &.input-hours {
        text-align: right;
        padding-right: 5px;
      }
      &.input-minutes {
        padding-left: 5px;
      }
    }
    @include screen460 {
      width: 100%;
    }
  }
  .picker-block__text {
    @include screen460 {
      display: none;
    }
  }
  .flow-progress-line {
    @include screen1023 {
      display: none;
    }
  }
}
