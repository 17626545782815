@import '../../../assets/scss/variables';


app-filter-mobile {
  .m-filters {
    position: fixed;
    background: $white;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 120;
    .wrapper {
      padding: 0 10px;
    }
    .m-filters__page {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .m-filters__footer {
      //flex: 0 0 auto;
      height: 80px;
      width: 100%;
      box-shadow: 0 0 8px rgb(144 170 190 / 40%);

      .wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
      .button {
        font-size: 16px;
        min-width: 210px;
      }
    }
    .m-filters__header {
      height: 62px;
      width: 100%;
      background: #F2F7FB;
      box-shadow: inset 0 0 8px rgba(144, 170, 190, 0.2);
      .wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .m-filters__title {
      font-size: 18px;
    }
    .m-filters__body {
      height: 85%;
      overflow: scroll;
      //flex: 1 0 auto;
    }
  }
  .cancel {
    color: $link-color;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 500;
  }
  .body__item {
    border-bottom: 1px dashed #A8C1D3;
    &:last-child {
      border: none;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .body__text {
      font-size: 14px;
      color: #8089A0;
    }
  }
}
