//Colors=====================================================
$black-color1: #000;
$black-color2: #051B22;
$black-color3: #171719;
$gray-color1: #B7BCCB;
$gray-color2: #F2F7FB;
$gray-color3: #8089A0;
$gray-color4: #A8C1D3;
$gray-color4: #C4C4C4;
$gray-color5: #ECF1F5;
$gray-color6: #DFEBF4;
$gray-color7: #353535;
$gray-color8: #E5E5E5;
$gray-color9: #595A5A;
$gray-color10: #D9D9D9;
$gray-color11: #29292D;
$gray-color12: #1E1E1E;

$white-color: #fff;
$white-color2: #dbe4ee;
$blue-color1: #5AA2E5;
$blue-color2: #a7cee3;
$blue-color3: #1b467f;
$blue-color4: #084C61;
$blue-color5: #0F8F97;
$blue-color6: #14B6C0;
$blue-color7: #273C73;
$blue-color8: #00d1ff;
$blue-color9: #2c53b7;
$blue-color10: #0274B3;
$blue-color11: #00c7f8;
$blue-color12: #6182A8;
$blue-color13: #7B9BC0;
$blue-color14: #D9E8F2;
$blue-green: #006e8d;

$green-color1: #5fa83f;
$green-color2: #8bc24a;
$green-color3: #71a534;
$green-color4: #c1e19a;
$green-color5: #f7fae8;
$green-color6: #8cc052;
$green-color7: #c6e0a9;
$green-color8: #F2FFED;
$green-color9: #82C76A;
$green-color10: #27AE60;
$green-color11: #1ed760;
$green-color12: #25d366;
$green-color13: #24AE60;
$green-color14: #8FCB0E;
$greeen-color15: #3AFCAC;
$red-color1: #F95738;
$red-color2: #eb3b32;
$red-color3: #EF3E42;
$red-color4: #C02424;
$red-color12: #FF0000;

$red-color5: #fff3f0;
$red-color6: #FFE9E4;
$red-color7: #e0518d;
$red-color9: #9a463d;
$red-color10: #E81B22;
$red-color11: #C02424;
$yellow-color1: #faf6e3;
$yellow-color2: #FFA800;
$gold: #ECCE63;
$gold2: #DAB65F;
$gold3: #EFD47F;
$gold4: #C0901E;
$gold-dark: #D2B652;
$yellow-color3: #fffef0;
$yellow-color4: #FF7A00;
$yellow-color5: #F3AC3C;
$white: #fff;

// Headliners line-height
$small-headliner: 26px;
$small-text-headliner: 18px;

//Font
$font-size-14: 14px;
$fontRegular: 'Roboto', sans-serif;
$fontInter: 'Inter', sans-serif;
$default-font: $fontRegular;
$default-font-size: 14px;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-700: 700;
$font-weight-900: 900;
$menu-font-size: 15px;
$default-font-color: $black-color1;
$default-line-height-computed: 1.2;

// Button
$btn-padding: 0 15px;
$popupBtnfontSize: 14px;

// Border styling
$default-border-dash: 1px dashed $gray-color4;
$nav-shadow: 0 4px 4px rgba($black-color1, 0.25);
$default-input-border-color: $gray-color1;
$default-border-color: #949494;
$default-border: 1px solid $default-border-color;
$default-shadow: 0 0 3px $gray-color3;
$block-shadow: 0 1px 2px #B4BCC6;

// Animations
$transition: linear .2s;
$transition1: linear .2s;

// Links
$link-color: $blue-color1;
$link-color-hover: darken($blue-color1, 20%);
$link-color-active: darken($blue-color1, 20%);
$menu-link-color: #fff;
$menu-link-bg-active-color: $blue-color2;
$menu-link-icon-active-color: $green-color1;
$menu-link-border-color: $green-color1;

// Layout
$header-height: 45px;
$header-height-mobile: 72px;
$footer-height: 68px;
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
);

$header-height-mobile: 72px;
