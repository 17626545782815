@import "assets/scss/variables";

.wizard {
  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: 0 20px;
  }

  &__button {
    width: 100%;
    max-width: 212px;
    position: relative;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;

    &--center {
      margin: 0 auto;
    }

    mat-spinner {
      position: absolute;
      left: 10px;
      top: 10px;
    }

    .fa-chevron-left {
      margin-right: 10px;
      font-size: 14px;
    }

    &.cancel {
      color: $blue-color1;
      cursor: pointer;

      @include maxW(768px) {
        width: fit-content;
      }
    }
  }

  &__text {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: $gray-color3;

    &--underline {
      @extend .wizard__text;
      text-decoration: underline;
    }
  }
}

.kyc-label {
  width: 126px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: $white-color;
  border-radius: 7px;

  i {
    margin: auto 2px auto 10px;
  }

  span {
    width: 100%;
    text-align: center;
  }

  &.verified {
    background-color: $green-color14;
  }

  &.pending {
    background-color: $yellow-color2;
  }

  &.failed {
    background-color: $red-color1;
  }
}
