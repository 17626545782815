@import '../../../../assets/scss/variables';

app-tooltip {
  z-index: 2;
  .hover-block {
    height: 28px;
  }
  .tooltip-card {
    position: absolute;
    text-align: left;
    display: block;
    right: 0;
    z-index: 1;
    line-height: 16px;
    border-radius: 5px;
    color: $white-color;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    font-size: 12px;
    padding: 14px;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -6px;
      right: 5px;
      width: 0;
      height: 0;
      border-top: none;
      border-bottom: 7px solid rgba(0, 0, 0, 0.7);
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
    &.tooltip-wrap {
      &:after {
        right: 160px;
      }
    }
    //&.showTooltip {
    //  display: block;
    //}
  }
  .tooltip-box {
    padding: 20px;
    position: absolute;
    top: 0px;
    left: -10px;
  }
  .wrapper-tooltip {
    display: block;
    position: relative;
    width: 0;
    height: 100%;
  }
}
