@import '../../../../assets/scss/variables';

app-submit-song-alert-popup {
  .inner {
    width: 460px;
    max-width: 90vw;
    position: relative;
    padding: 40px 20px;
    text-align: center;
  }


  .lnr-cross {
    position: absolute;
    right: 20px;
    font-size: 16px;
    font-weight: 600;
    top: 20px;
    cursor: pointer;
    color: $blue-color1;
  }
  .button{
    margin: 0 auto;
  }
  .block-title {
    font-weight: 900;
    font-size: 21px;
    text-transform: uppercase;
    text-align: center;
    margin: 30px 0 10px;
  }

  .text, .sub-text {
    font-size: 15px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .text {
    font-weight: bold;
  }

  .sub-text {
    color: $gray-color3;
  }

  .image {
    display: block;
    margin: 0 auto;
    height: 130px;
  }
}

@media screen and (max-width: 767px) {
  app-verify-email-popup {
    .text br {
      display: none;
    }
  }
}

