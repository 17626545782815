@import "../variables";

tbody {
  tr {
    &.primary,
    &.primary:hover {
      td {
        background-color: $white-color;
        box-shadow: 0 1px 2px #c7cfd8;
      }
    }

    &:hover {
      td {
        background: $white-color;
      }
    }
  }

  td {
    padding: 11px 18px;
    background-color: transparent;
    color: $black-color1;
    vertical-align: middle;
    border-bottom: 1px dashed $gray-color4;

    &.warning {
      font-family: $font-weight-900;
      color: $red-color1;
    }

    &.no-list-data {
      padding: 15px;
      text-align: center;
      font-family: $font-weight-900;
    }
  }
}

th.checkbox {
  width: 56px;
}

.table {
  width: 100%;

  th {
    border-bottom: 1px dashed $gray-color4;
    background: transparent;
    text-align: left;
    font-weight: 400;
    font-family: $font-weight-900;
    cursor: pointer;
    vertical-align: middle;
    color: $gray-color3;
    padding: 11px 18px;
  }

  .actions {
    white-space: nowrap;
    width: 1%;
    text-align: center;
  }

  .centered {
    text-align: center;
  }

  .checkmark {
    top: -3px;
  }

  &.w-100 {
    table-layout: fixed;
    width: 100%;
  }
}
