@import "../variables";

.text{
  color: #8089A0;
}

.text-center {
  text-align: center;
}

.text-overflow-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
