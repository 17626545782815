@import "../variables";
@import "../mixin";

.flow-info-text {
  width: 45vw;
  background-size: cover;
  display: flex;
  flex-direction: column;
  text-align: right;
  color: #fff;
  justify-content: center;
  @include screen1023 {
    width: 100%;
    font-size: 45px;
    text-align: center;
  }
  @include screen767 {
    display: none;
  }
  .inner {
    display: flex;
    text-align: right;
    font-weight: 900;
    line-height: 1;
    color: #fff;
    align-items: center;
    justify-content: center;
    height: 100%;
    @include screen1023 {
      padding: 30px 0;
    }
    .sub-text {
      text-transform: none;
      font-size: 56px;
      line-height: 1.1;
    }
    .sub-text2 {
      display: none;
    }
  }
}

.flow-steps {
  width: 55vw;
  display: flex;
  padding: 0 20px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @include screen1023 {
    height: 100%;
    width: 100%;
  }
  @include screen767 {
    margin-top: 75px;
  }
  @include screen460 {
    margin-top: 50px;
  }
}

.flow-progress-line {
  position: absolute;
  height: 100%;
  left: 45vw;
  margin-left: -40px;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .item-line {
    height: 33.33%;
    display: flex;
    align-items: center;
    .circle {
      width: 69px;
      height: 69px;
      display: flex;
      background: $gray-color2;
      align-items: center;
      font-weight: $font-weight-900;
      font-size: 24px;
      justify-content: center;
      color: $gray-color3;
      border-radius: 50%;
      &.isActiveCircle {
        color: $white;
        background: $yellow-color2;
      }

      &__total {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
    }
    .circle-finished {
      background: url("/assets/images/check.svg") center;
      background-size: 20px 15px;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
    }
  }
}

.flow-progress-line--mobile {
  display: flex;
  width: 100%;
  z-index: 19;
  background-color: $blue-color14;
  box-shadow: 0px 4px 8px rgba(144, 170, 190, 0.4);
  
  @include maxW(768px) {
    position: fixed;
    top: 0;
    left: 0;
  }

  .step-counter {
    background-color: $yellow-color2;
    color: $white-color;
    padding: 14px;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;

    @include maxW(768px) {
      position: absolute;
      left: 0;
      bottom: 0;
      width: fit-content;
      height: 100%;
    }

    &__total {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }

    &__title {
      margin: 0 auto 0 36px;
      padding: 17px 0;
      font-weight: 900;
      font-size: 18px;
      line-height: 21px;
      text-align: left;
      color: $blue-color7;
    }
  }
}
.flow-button-wrapper {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  
  @include screen767 {
    padding: 0;
    width: 100%;
  }
  .button.yellow {
    @include buttonSubmitYellow;
    width: 210px;
    height: 50px;

  }

  .button.transparent {
    min-width: 100px;
    width: 100px;
    font-size: 16px;
    font-weight: $font-weight-700;
  }
  .cancel {
    color: $link-color;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 500;

    .fas {
      margin-right: 10px;
    }
  }
}

.flow-steps-inner {
  padding: 20px 0;
  @include screen767 {
    padding: 40px 0;
  }

  .step {
    max-width: 416px;
    width: 100%;
  }
}

.flow-page {
  &.mobile-steps {
    display: none;
    margin-top: $header-height-mobile;
    background: #253a6f;
    position: relative;
    margin-bottom: -50px;
    z-index: 2;
    padding: 0 20px 16px;
    color: #fff;
    align-items: center;
    justify-content: space-between;

    .progress {
      font-size: 24px;
      font-weight: 900;
    }

    .total {
      font-size: 14px;
    }

    .step-title {
      font-weight: 900;
      font-size: 18px;

      text-align: center;
      width: calc(100% - 40px);
    }

    .line {
      position: absolute;
      bottom: -3px;
      left: 0;
      border-top: 3px solid #FFA800;
      max-width: 100%;
      transition: width ease-in-out .5s;
    }
    @include screen460 {
      display: flex;
    }
  }
  .title {
    font-weight: 900;
    font-size: 24px;
    color: $black-color1;
    margin-bottom: 25px;
    text-align: center;
    text-transform: uppercase;
    @include screen460 {
      display: none;
    }
  }
}
