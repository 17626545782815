@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

app-reminder-kyc-popup {
  .inner {
    width: 560px;
    max-width: 90vw;
    position: relative;
    padding: 40px 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include screen767{
      padding: 40px 20px;
    }
  }
  .popup-content{
    position: relative;
  }
  .popup-title {
    font-size: 21px;
    line-height: 60px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
  }
  .popup-sub-title {
    font-weight: 400;
    font-size: 15px;
    text-align: center;
  }
  .popup-image{
    position: absolute;
    left: 5px;
    top: 0;
  }
  .popup-content {
    .popup-button {
      margin-top: 15px;
      width: 100%;
      min-width: auto;
      padding: 0 15px;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

