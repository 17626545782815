@import '../../../assets/scss/variables';

app-collectible {
  .main-wrapper {
    width: 100%;
    position: relative;
    background: $white-color;
  }

  .image-wrapper {
    height: 474px;
    width: 100%;
    position: relative;
  }
  .call-to-action__body {
    visibility: hidden;
    background: #F2F7FB;
    display: flex;
    height: 0;
    .call-to-action__image {
      width: 197px;
      height: 197px;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));
      border-radius: 14px;
    }
    &.show {
      padding: 25px 90px;
      visibility: visible;
      width: 100%;
      height: 100%;
    }
  }
  .play-button {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid #fff;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  app-project-song-detail {
    display: flex;
    flex: 1;
  }
  .project__song-info {
    position: relative;
    width: 100%;
    margin-top: -118px;
    display: flex;
    z-index: 10;
    padding: 0 40px;
    justify-content: center;
  }
  app-card-project {
    .card-outer {
      margin: 0;
    }
    .card-image-wrapper {
      margin: 0;
    }
    .back {
      margin: 0
    }
  }
  .artist-profile-image {
    img {
      width: 100px;
      min-width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .project__box-header {
    display: flex;
    position: relative;
    width: 100%;
    background: $gray-color2;
    padding: 26px 60px;
    box-shadow: 0 2px 4px rgba(144, 170, 190, 0.5);
    border-radius: 7px;

    .project__detail {
      flex-grow: 1;
    }
  }

  .clearfix {
    clear: both;
  }

  .profile-title {
    padding: 30px 0;
  }

  .project__get-information {
    width: 300px;
    min-width: 300px;
    .get-title {
      margin: 25px 0 20px 0;
      text-align: center;
    }
  }
  .project__title-sub {
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
  }
  .item-share-document-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item-share-document {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-top: 20px;
    span {
      padding-left: 10px;
    }
  }
  .item-share-mobile {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    visibility: hidden;
    .item-share-document-mobile {
      display: flex;
      align-items: center;
      text-decoration: none;

      span {
        padding-left: 16px;
      }
    }
    .share-img {
      width: 18px;
    }
    .slider-btn {
      width: 334px;
      background: $yellow-color2;
      font-size: 21px;
      height: 56px;

      &.disabled {
        background: $gray-color3;
        color: #fff;
        opacity: .5;
      }
    }

    h3 {
      margin-top: 20px;
      cursor: pointer;
    }
  }
  .project__mobile-view-p {
    display: none;
  }
  .project__subscribe {
    padding: 25px 90px;
  }
  .project-blue-box {
    background: #DFEBF4;
    box-shadow: 0px 2px 4px rgba(144, 170, 190, 0.5);
    border-radius: 7px;
    margin-top: 20px;
  }
  .album-logo {
    display: block;
    position: relative;
    background-size: cover;
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 17px;
      top: 8px;
      width: 21px;
      height: 21px;
      background-size: 21px;
      background-image: url('../../../assets/images/music.png');
    }
  }

  .profile-artist-info {
    display: flex;
  }

  .project-highlighted {
    position: relative;
    padding: 4px 15px;
    width: auto;
    height: 45px;
    display: flex;
    justify-content: space-between;
    color: $white-color;
    background: $yellow-color2;
    border-radius: 5px 5px 0 0;
    text-align: center;
    span {
      color: $white;
      font-weight: bold;
      line-height: 20px;
      display: inline-block;
      padding: 0 10px;
      font-size: $menu-font-size;
    }
  }
  .project-highlighted__logo {
    width: 15px;
    position: relative;
    margin-top: -18px;
  }
  .project__sign-highlight {
    position: absolute;
    top: -27px;
    left: 40px;
    display: flex;
  }
  .item-highlighted-album {
    position: relative;
    width: auto;
    z-index: 2;
  }
  .album-bcg {
    width: 100%;
    display: block;
    position: relative;
    transform: translateX(-10%) skewX(-20deg);
    background: $red-color11;
  }
  .album-text {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    padding: 5px 20px 5px 30px;
    text-transform: uppercase;
    transform: translateX(0%) skew(20deg);
  }
  .item-song {
    margin-bottom: 10px;
    position: relative;
    height: 50px;
    //margin-bottom: 10px;
    //height: 60px;
    .song-name {
      color: $gray-color3;
    }
  }
  .project__detail {
    position: relative;
    display: flex;

    .item-content-title {
      line-height: 23px;
      font-weight: $font-weight-900;
      font-size: 27px;

    }
  }

  .project-logo {
    margin-right: 35px;
    position: relative;

    .project-logo-img {
      width: 160px;
      position: relative;
      height: 160px;
      background-size: cover;
      background-position: center center;
      border-radius: 80px;
    }
  }

  .profile-info {
    position: relative;
    padding: 0 90px 40px 90px;
  }
  .get-text {
    color: #8089a0;
    list-style-type: disc;
  }
  .get-text .item {
    margin-bottom: 15px;
    padding-left: 20px;
    position: relative;
  }
  .get-text li {
    color: #5aa2e5;
  }

  .profile-album-content {
    .text-description {
      margin-top: 20px;
    }
  }
  .project__title {
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .project-first-section {
    margin-top: -80px;
  }
  .profile-album {
    display: flex;
    .audio-list {
      max-height: 300px;
      width: 50%;
      min-width: 450px;
      overflow-y: auto;
      margin-right: 20px;
    }
    .audio-arrow {
      font-size: 18px;
      color: $blue-color1;
      width: 350px;
      font-weight: bold;
      margin-left: 0;
      text-align: center;
      display: inline-block;
    }
  }
  .wrapper-scrollbar {
    min-height: 250px;
  }
  audio {
    max-width: 350px;
    width: 100%;
  }


  .img-block {
    width: 100px;
    margin-right: 25px;
    margin-bottom: 12px;
  }

  .image-desc-float {
    float: left;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-image: url('../../../assets/images/fallback.png');
    overflow: hidden;

    .inner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
    }
  }
  .profile-title {
    padding: 25px 0;
  }
  .followers-icon {
    margin-top: 4px;
  }
  .followers-count {
    font-weight: 900;
    color: black;
    font-size: 14px;
    font-style: normal;
    padding-left: 30px;
    position: absolute;
    line-height: 16px;
  }
  .img-copy {
    padding-left: 5px;
    cursor: pointer;
  }
  app-footer-about-company {
    .about-company {
      margin-top: 26px;
    }
  }
  .followers-count-twitter {
    font-weight: 900;
    color: black;
    font-size: 14px;
    font-style: normal;
    padding-left: 30px;
    position: absolute;
    line-height: 16px;
  }
  .followers-text {
    color: $gray-color1;
    font-style: normal;
    padding-left: 4px;
  }

  .follow-caption {
    color: $gray-color1;
  }
  .follow-name {
    font-weight: 200;
  }
  .project__followers-social-row {
    position: relative;
  }
  .followers-social-row-mobile {
    height: fit-content;
    visibility: hidden;
    display: none;
    border-bottom: 1px dashed $gray-color4;
  }
  .followers-flex-container {
    display: grid;
    grid-template-columns: 100px 100px 100px;
    grid-template-rows: 50px auto;
    justify-content: left;
    padding-top: 11px;
    pointer-events: none;
  }
  .slider-item {
    background-position: center;
    background-size: cover;
    overflow: hidden;
    background-image: url('../../../assets/images/fallback.png');

    .inner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
    }
  }

  .profile-info-left {
    padding-right: 50px;
    flex-grow: 1;
  }

  .text-description {
    order: 2;
  }

  .info-action-img-mobile {
    display: none;
  }

  .item-detail-mobile {
    display: none;
    position: relative;
  }
  .section__calculation {
    display: none;
  }
  app-loader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .global-page-loader {
      margin: 0;
    }
  }
}

app-song-preview {
  position: fixed;
  bottom: 0;
}

@media screen and (max-width: 1490px) {
  app-collectible {

    .project__box-header {
      padding: 27px 54px;
    }
  }
}

@media screen and (max-width: 1380px) {
  app-collectible {
    .project__calculation {
      display: none;
    }
    .profile-info-left {
      padding-right: 0;
    }

    .profile-artist-info .project__get-information {
      display: none;
    }
    .project__box-header {
      padding: 27px 27px 27px 54px;
    }

    .item-share-document {
      justify-content: center;
    }
    .section__calculation {
      display: flex;
      min-height: 400px;
      margin: 20px auto 0;
      width: 95%;
      justify-content: space-between;
      app-calculation {
        width: 50%;
        .wrapper {
          justify-content: unset;
          align-items: flex-start;
        }
      }
      .project__get-information {
        width: 50%;
        .get-title {
          margin: 15px 0 20px 0;
        }
      }
    }

    .profile-info {
      margin-top: 0;
      padding: 0 28px 20px 28px;
    }
  }
}

@media screen and (max-width: 1100px) {
  app-collectible {
    .project__box-header {
      padding: 27px 24px;
    }

    app-calculation {
      .wrapper {
        padding: 0 24px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  app-collectible {
    .mobile-view-p__body {
      .detail-song-info__song-details {
        border: none;
        display: none;
      }
    }
    .call-to-action__body {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &.show {
        padding: 25px 18px;

      }
    }
    .sign-highlight__wrapper {
      width: 310px;
      margin: 0 auto;
    }
    .get-text {
      margin-left: 18px;
      .item {
        padding-left: 0;
      }
    }
    .project-highlighted {
      width: 160px;
      span {
        line-height: 25px;
      }
      &.superRare {
        width: 150px;
      }
    }
    .project-highlighted__logo {
      margin-top: -15px;
    }
    .project__detail {
      .grs-details {
        display: none;
      }
      .detail-song-info__song-details {
        border: none;
      }
    }
    .project__box-header {
      padding: 0;
      top: 0;
      background: transparent;
      box-shadow: none;
      width: 100%;
    }
    .project__mobile-view-p {
      display: block;
      margin-bottom: 15px;
      padding: 0 18px;
    }
    .image-wrapper {
      display: none;
    }

    .profile-info {
      display: none;
    }

    .project__mobile-view-p {
      margin-top: 15px;
    }
    .mobile-view-p__title {
      display: flex;
      align-items: center;
      padding: 16px 0;
      border-top: 1px dashed #A8C1D3;
      &.mobile-view-p__title--last {
        border-bottom: 1px dashed #A8C1D3;
      }
      &.show {
        border-bottom: 1px dashed #A8C1D3;
      }
    }
    .project__title-sub {
      padding-left: 18px;
      line-height: 14px;
    }

    .mobile-view-p__body {
      overflow: hidden;
      height: 0;
      &.show {
        padding: 15px 0;
        height: auto;
      }
    }
    .section__calculation {
      width: 100%;
      background: #F2F7FB;
      margin: 0;
      padding: 0 18px 10px;
      border-radius: 0 7px 7px 7px;
      app-calculation {
        width: 100%;
        .wrapper {
          padding: 0;
          margin: 0;
          justify-content: center;
          .inner {
            max-width: 100%;
          }
        }
        .amount {
          max-width: 100%;
        }
        .item {
          min-width: 60px;
        }

        .item-dropdown, .button {
          width: 100%;
          max-width: 100%;
        }
      }
      .project__get-information {
        display: none;
      }
    }

    .project__sign-highlight {
      top: 90px;
      left: 0;
      width: 100%;
    }

    app-card-project {
      margin: 0 auto;
    }

    .project__song-info {
      margin-top: 0;
      padding: 120px 0 0 0;
    }
    .project__detail {
      flex-direction: column;
      padding: 0 18px;
    }
    .project__subscribe {
      padding: 15px 18px;
    }

    app-subscribe-newsletter {
      .subscribe__button {
        margin: 0;
      }
      .subscribe__button-wrapper {
        flex-direction: column;
      }
      .subscribe__input {
        width: 100%;
        height: 50px;
        margin-bottom: 15px;
      }
      .subscribe-title {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  app-collectible {
    .limited-edition {
      font-size: 28px;
    }
    .profile-album .audio-list {
      padding-right: 0;
    }
  }

}
