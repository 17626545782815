@import '../../../../assets/scss/variables';

app-card-nft {
  color: #595A5A;
  position: relative;
  display: block;
  z-index: 2;
  .inner-card {
    perspective: 1000px;
  }
  .card {
    -webkit-transition: transform 1.5s;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
  }
  .flipped {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  .fa-play{
    font-size: 12px;
  }
  .card-image-wrapper {
    width: 310px;
    height: 473px;
    overflow: hidden;
    border-radius: 20px 20px 0px 0px;
    z-index: 2;
    margin: 0 12px 0px;
    position: absolute;

    .card__background {
      width: 100%;
    }
  }
  .front {
    -webkit-perspective: 0;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
  .back {
    top: 0px;
    -webkit-perspective: 0;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: rotateY(180deg);
    position: absolute;
    margin: 0 12px 0px;
    border-radius: 20px 20px 0px 0px;
    width: 310px;
    //overflow: auto;
    height: 473px;
    display: flex !important;
    flex-direction: column;
    background-color: $white;
    box-shadow: 0 4px 3px 0 rgb(128 137 160 / 40%);
  }

  .headliner__icons {
    .tooltip-card {
    left: -5px;
    right: unset;
    }
    .tooltip-card:after {
    right: unset;
    left: 5px;
     }
   }

  .tooltip-component {
    display: block;
  }

  app-tooltip {
    .hover-block {
      display: contents;
    }
  }

  .video, .image-gif {
    top:0;
    position: absolute;
    width: 310px;
    height: 473px;
    border-radius: 20px;
    object-fit: cover;
    border-radius: 20px 20px 0px 0px;
  }

  .nft-footer {
    display: flex;
    border-radius: 0px 0px 20px 20px;
    height: 100px;
    width: 310px;
    margin: 0 12px 24px;
    box-shadow: 0 4px 3px 0 rgb(128 137 160 / 40%);
    justify-content: center;
    align-items: center;
  }

  .sell {
    background-color: #5AA2E5 !important;
    color: #FFFFFF !important;
    font-style: normal;
    font-weight: 700;
    width: 220px;
    height: 50px;
  }
  .card-outer {
    position: relative;
    margin: 0 12px 0px;
    border-radius: 20px 20px 0px 0px;
    width: 310px;
    overflow: unset;
    height: 473px;
    display: flex !important;
    flex-direction: column;
    background-color: $white;
    box-shadow: 0 -1px 2px 0 rgb(128 137 160 / 40%);
    .card__img {
      border-radius: 20px;
      min-height: 196px;
      .image {
        width: 196px;
        height: 196px;
        object-fit: cover;
      }
    }
    .lnr-calendar-full {
      cursor: auto;
    }
    .fa-question-circle {
      font-size: 16px;
      color: $blue-color1;
      cursor: pointer;
    }
    .fa-question-circle:hover + .timer-hint {
      .tooltip {
        display: block;
        right: -20px;
        top: -200px;
      }
    }

    .card__logo-title {
      width: 140px;
    }

    .card__logo {
      height: 100%;
      align-items: flex-start;
      .tooltip-card {
        width: 190px;
        top: 40px;
        right: -10px;
        &:after {
          top: -6px;
          right: 25px;
        }
      }
    }

    .card__title {
      font-weight: 700;
      font-size: 14px;
    }
    .informative__item {
      width: 134px;
      font-size: 12px;
    }

    .card__status-box {
      display: none;
    }

    .card__genres {
      font-size: 12px;
      width: 100%;
      line-height: 14px;
      height: 14px;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
      max-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .card__background {
      width: 310px;
    }
    .social-wrapper {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      align-items: center;
    }
    .status-footer {
      padding: 0 20px;
      height: 77px;
      justify-content: center;
      flex-direction: column;
      display: flex;
      border-top: 1px dashed #A8C1D3;
      font-weight: 900;
      font-size: 24px;
      .button {
        width: 240px;
        margin: 0 auto;
        font-size: 16px;
      }
      .status-declined {
        font-weight: bold;
      }
    }
  }
  .chvr-left {
    position: absolute;
    top: 46.5%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    background: url('/assets/images/chvr-left.png') no-repeat center;
  }

  .chvr-right {
    position: absolute;
    right: calc(15% - 23px);
    top: 46.5%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background: url('/assets/images/chvr-right.png') no-repeat center;
    display: flex;
  }
  .play-button {
    position: absolute;
    top: 45%;
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid #fff;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-detail {
    position: relative;
    display: flex;
    align-items: flex-end;

    .auction-edition {
      color: $white-color;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .card__footer {
    height: 80px;
    z-index: 4;
    padding: 6px 0;
    margin: 0 12px;
    border-top: 1px solid #FFFFFF
  }
  .auction__title {
    font-weight: 700;
    font-size: 12px;
    color: #FFFFFF;
    margin-bottom: 10px;
  }
  .card__content {
    height: 45%;
    flex: 1 0 auto;
    padding: 10px 13px 0;
    display: flex;
    z-index: 2;
    flex-direction: column;
  }

  .card__header {
    height: 49px;
    z-index: 2;
    padding: 12px 12px 0;
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: center;
    &:hover {
      z-index: 11 !important;
    }
    .card__header-col {
      width: 33.33%;
    }
  }
  .headliner-project {
    position: relative;
    z-index: 0;
  }
  .card-collectible__auction-sh {
    display: flex;
    justify-content: space-between;
    .timer__timing {
      justify-content: flex-start;
    }
    .timer__title {
      display: none;
    }
    .tooltip-card {
      right: -20px;
      top: 30px;
      width: 190px;
      &:after {
        top: -6px;
        right: 20px;
      }
    }
    .icon-key, .icon-gift {
      position: relative;
    }
    .icon-gift {
      margin-left: 10px;
    }
    .footer-detail {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: column;
    }
    .footer-detail__icons {
      height: 20px;
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .card-collectible__auction-run {
    display: flex;
    justify-content: space-between;
    .timer__timing {
      justify-content: flex-end;
    }
    .timer__title {
      display: none;
    }
    .auction__title {
      text-align: right;
    }
    .tooltip-card {
      left: -20px;
      top: 30px;
      width: 190px;
      &:after {
        top: -7px;
        left: 20px;
      }
    }
    .icon-key, .icon-gift {
      position: relative;
    }
    .icon-key {
      margin-right: 10px;
    }
    .footer-detail {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: column;
    }
    .footer-detail__icons {
      height: 20px;
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
  }
  .tooltip-link {
    color: $white-color;
  }
  .rights-text {
    margin-left: 10px;
    font-size: 12px;
    line-height: 18px;
    color: $white-color;
  }

  .track-numbers {
    font-size: 12px;
    align-self: flex-end;
    padding-right: 12px;
  }

  .artist-collection-text {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    display: inline-block;
    color: #FFFFFF;
    text-align: left;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .headliner__icons {
    position: relative;
    display: flex;
    z-index: 2;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 6px;
    .icon-melody {
      margin-left: 10px;
    }
    .icon-disk, .icon-melody {
      position: relative;
      height: 18px;
    }
    .tooltip-card {
      width: 190px;
      top: 30px;
      right: -20px;
      &:after {
        top: -7px;
        right: 22px;
      }
    }
  }

  .year {
    color: #ffffff;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 22px;
  }

  .card__title {
    font-weight: bold;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    display: flex;
    text-transform: uppercase;
    color: #ffffff;
  }

  .card__informative {
    display: flex;
    justify-content: space-between;
  }
  .informative__item {
    font-size: 14px;
    padding: 2px 0;
    box-sizing: border-box;
    line-height: 16px;
    border-radius: 5px;
    visibility: hidden;
    text-align: center;
    &.active {
      visibility: visible;
    }
  }
  .card__name {
    margin-bottom: 10px;
    z-index: 2;
    color: $white-color;
    display: flex;
    justify-content: space-between;
  }
  .daysleft {
    color: $red-color1;
  }
  .card__title-h3{
    font-size: 16px;
    text-align: left;
  }
  .card__title-h2 {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    line-height: 24px;
    text-transform: uppercase;
  }

  .card__name--padding {
    padding: 0 12px;
  }
  .card__song-name {
    font-size: 15px;
    text-align: left;
    font-weight: 500;
    line-height: 16px;
  }

  .card__status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .card__progress-bar {
    background: #FFFFFF;
    border-radius: 50px;
    margin: 10px 0 8px 0;
    overflow: hidden;
    height: 12px;
    width: 100%;
    .result {
      height: 12px;
      border-radius: 50px;
    }
  }

  .price__per-share {
    font-size: 12px;
    color: $white-color;
    font-weight: 700;
  }
  .card__share {
    font-size: 12px;
    display: flex;
    color: $white-color;
    justify-content: space-between;
    font-weight: 700;
  }
  .card__genres {
    text-align: center;
    width: calc(100% - 140px);
    font-size: 16px;
    font-weight: 800;
    color: #595A5A;
  }
  .card__status-box {
    width: 65px;
    font-size: 11px;
    z-index: 1;
    line-height: 25px;
    border-radius: 5px;
    color: #FFFFFF;
    text-align: center;
    &.new {
      background: $green-color10;
    }
    &.invest {
      background: $yellow-color2;
    }
    &.failed {
      background: $red-color1;
    }
  }
  .image-pin {
    position: absolute;
    top: 10px;
  }

  .card__end {
    width: 100%;
    font-size: 14px;
    height: 40px;
    display: flex;
    background: black;
    font-weight: 700;
    color: $white;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    text-transform: uppercase;
  }

  .card__description {
    font-weight: 400;
    z-index: 2;
    padding: 0 12px;
    font-size: 12px;
    color: $white-color;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 20px;
  }

  .card__logo {
    display: flex;
    z-index: 5;
    justify-content: flex-end;
    img {
      width: 18px;
      display: flex;
      align-items: flex-start;
    }
  }
}

@media screen and (max-width: 767px) {
  app-card-nft {
    .card {
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 320px) {
  app-card-nft {
    .card-outer {
      width: 290px;
    }
    .card__background {
      width: 290px;
    }
  }
}
