@import '../../../assets/scss/variables';

app-project-create {
  display: flex;
  min-height: 100vh;
  background-color: white;

  .info-text {
    width: 45vw;
    background: url('../../../assets/images/wizard.jpg') center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    text-align: right;
    font-weight: 900;
    font-size: 69px;
    line-height: 1;
    text-transform: uppercase;
    color: #fff;

    .inner {
      background: linear-gradient(to left, #134D66 0%, rgba(19, 77, 102, 0.01) 89.43%);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .sub-text {
      text-transform: none;
      font-size: 56px;
      line-height: 1.1;
    }
  }
  button:disabled {
    cursor: initial;
  }
  .opportunity__wrapper {
    padding: 0 25px;
  }
  .item-song, .item-song-wrapper {
    width: 100%;
    .audio {
      width: 100%;
    }
  }
  .wrapper {
    width: 55vw;
    background: $white-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .inner {
      max-width: 70%;
      padding: 100px 0 50px;
      width: 416px;
    }

    .agreement {
      border-bottom: 1px dashed $gray-color4;
      padding: 20px 0 20px 25px;

      .agreement-text {
        width: 270px;
        padding-left: 10px;
      }
    }

    .button-wrapper {
      margin-top: 30px;
      padding: 0 25px;
      justify-content: space-between;
      align-items: center;

      .button.yellow {
        width: 210px;
        height: 50px;
        font-size: 16px;
        font-weight: $font-weight-700;
      }

      .button.transparent {
        min-width: 100px;
        width: 100px;
        font-size: 16px;
        font-weight: $font-weight-700;
      }

      .cancel {
        color: $link-color;
        font-size: 14px;
        text-transform: uppercase;
        cursor: pointer;
        text-align: center;
        width: 50%;
        font-weight: 500;
        .fas {
          margin-right: 10px;
        }
      }
    }

    .title {
      font-weight: 900;
      font-size: 24px;
      margin-bottom: 25px;
      text-align: center;
    }

    .item-document {
      padding: 0 25px;
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
    .upload-section {
      width: 100%;

      &:last-child {
        margin-left: 0;
      }
      label {
        flex-direction: row;
      }
      .fa-music {
        font-size: 20px;
        padding-right: 18px;
      }
    }
    .text {
      font-weight: 500;
      margin-bottom: 5px;
      text-align: center;
      font-size: 16px;
    }
    .production {
      color: $gray-color3;
      text-align: center;
      margin-top: 30px;
      font-size: 16px;

      &.error-message {
        margin-top: -30px;
      }
    }

    mat-spinner {
      margin-right: -40px;
      margin-left: 10px;
      margin-top: 0;
    }
    .upload-section-audio {
      height: 50px;
    }
    .img-close {
      position: relative;
      font-size: 15px;
      padding-right: 10px;
      cursor: pointer;
      color: $red-color1;
    }
    .picture-load__name {
      font-weight: bold;
      display: flex;
      align-items: center;
    }
    .picture-load {
      padding-top: 20px;
    }
    .picture-load__info {
      margin: 10px 0;
    }

    .provide-song {
      border-bottom: 1px dashed #A8C1D3;
      .user-raw {
        padding: 15px 25px 15px;
      }
    }
    .mat-form-field {
      width: 100%;
    }
    .item {
      padding: 0 25px;
      .mat-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .far, .fas {
          color: $link-color;
        }
      }
    }
    .item-text {
      color: $gray-color3;
      padding: 0 25px;
      &.item-text__margin-top {
        margin-top: 10px;
      }
      &.item-text__margin-top20 {
        margin-top: 20px;
      }
    }
    .list-item {
      display: flex;
      position: relative;
      justify-content: space-between;
      .list-controls {
        position: relative;
      }
      .add {
        padding-right: 25px;
        padding-top: 20px;
        cursor: pointer;
        color: $blue-color1;
      }
      .fa-times-circle {
        color: $red-color1;
      }
      .remove-control {
        cursor: pointer;
        position: absolute;
        right: -20px;
        top: 20px;
      }
      .mat-item {
        width: 293px;
        padding-left: 25px;
      }
    }
    .cancel-message.cancel {
      text-align: center;
      width: 100%;
    }
    .input-text-item {
      &.mat-item {
        margin-top: 10px;
      }
    }
    .item-align-left {
      align-items: flex-start;
    }
    .span-label {
      color: $gray-color3;
    }

    .user {
      color: $black-color1;
      font-size: 18px;
      line-height: 32px;
      font-weight: bold;
    }
    .button {
      width: 100%;
      text-transform: uppercase;
    }
  }

  .opportunity__image {
    width: 100%;
  }
  .sum-message {
    .title-status {
      font-weight: 900;
      font-size: 24px;
      margin-bottom: 25px;
      text-align: center;
    }
    .text {
      font-weight: 500;
      text-align: center;
      font-size: 16px;
      color: $gray-color3;
    }
  }

  .icon-cancel {
    cursor: pointer;
  }

  .file-name {
    font-weight: 600;
    margin-top: 10px;
    display: inline-block;
    .icon-cancel {
      padding-right: 10px;
    }
    .fa-times-circle {
      color: $red-color1;
    }
  }
  .success, .error {
    width: 75px;
    height: 75px;
    margin: 0 auto 20px;
    border-radius: 50%;
    color: #fff;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .success {
    background: $green-color2;
  }

  .error {
    background: $red-color1;
  }
}

@media screen and (max-width: 1023px) {
  app-project-create {
    flex-direction: column;
    overflow: scroll;
    .info-text {
      width: 100%;
      height: 200px;
      font-size: 45px;
      text-align: center;

      br {
        display: none;
      }

      .inner {
        align-items: flex-end;
      }

      .sub-text {
        font-size: 30px;
        margin: 8px 0 25px;
      }
    }
    .wrapper {
      height: 80vh;
      width: 100%;
      //margin-top: 50px;
      .inner {
        padding-top: 0;
        height: calc(100vh - 250px);

        display: flex;
        flex-direction: column;
      }
      #step-three {
        flex: 1;
      }
      .item {
        width: 368px;
      }
    }
    .button-wrapper {
      margin: 30px 0;
    }
    .progress-line {
      display: none;
    }
    .title {
      padding-top: 30px;
    }
  }
}

@media screen and (max-width: 460px) {
  app-project-create {
    .info-text {
      display: none;
    }
    .sum-message {
      .title-status {
        font-weight: normal;
        font-size: 18px;
      }
      .text {
        font-weight: 400;
        font-size: 14px;
      }
    }
    .wrapper {
      margin-top: 20px;
      .list-item {
        flex-direction: column;
        .add {
          padding-top: 0;
          padding-right: 0;
          font-weight: bold;
          font-size: 16px;
          text-align: center;
        }
        .mat-item {
          width: 100%;
          padding: 0;
        }
        .remove-control {
          right: 0;
        }
      }
      .item, .item-text {
        padding: 0;
        &.item-text__margin {
          margin-top: 30px;
        }
      }
      .button-wrapper {
        padding: 0;
      }
      .mat-item {
        padding: 0;
      }
      .provide-song {
        border: none;
        .user-raw {
          padding: 15px 0 10px 0;
        }
      }
      .item-document {
        padding: 0;
      }

      .inner {
        height: 100%;
        max-width: calc(100% - 20px);
        width: 100%;
        padding: 70px 14px 20px;
      }
      .stickFooter {
        height: calc(100vh - 84px);
      }
      .item {
        width: 100%;
      }
      .input-text-item {
        &.mat-item {
          margin-top: 0;
        }
        &.input-text-item {
          margin-top: 20px;
        }
      }
      .agreement {
        padding: 20px 0;
      }
    }

    .select-share .item {
      width: 60px;
    }
    .select-wrapper {
      width: calc(100% - 201px);
    }
    .progress-line {
      display: none;
    }
    .title {
      display: none;
    }
    .mobile-steps {
      display: flex;
    }

    .payment-item {
      max-width: calc(50% - 20px);
    }
  }
}

@media screen and (max-width: 320px) {
  app-project-create {
    .wrapper {
      margin-top: 20px;
      .button-wrapper {
        font-size: 13px;
        .cancel {
          font-size: 13px;
        }
        .button.yellow {
          font-size: 13px;
          width: 180px;
        }
      }
    }
  }
}
