@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

app-base-select-amount {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include placeholder(22px, 900, center);
  .lnr {
    cursor: inherit;
  }
  .inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button {
    &.disable {
      opacity: 0.6;
      cursor: pointer;
    }
  }

  .button.button-explore {
    margin-top: 15px !important;
    margin-bottom: 0 !important;
  }

  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: $white-color;
  }
  mat-spinner {
    position: absolute !important;
    left: 10px;
  }

  .nft-explain {
    display: inline-block;
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    width: 100%;
    color: $gray-color3;
  }

  .amount {
    background: #FFFFFF;
    border-radius: 5px;
    height: 54px;
    line-height: 54px;
    text-align: center;
    font-weight: 900;
    width: 100%;
    font-size: 32px;
    margin: 10px auto;
    max-width: 350px;
    .error-message{
      font-size: 28px;
      margin-top: -10px;
    }
    .origin-amount{
      font-size: 12px;
      line-height: 12px;
      color: #8089A0;
      font-weight: normal;
      margin-top: -10px;
      .origin-amount-underline{
        text-decoration: line-through;

      }
      .sale-amount{
        color: #EF3E42;
        display: inline-block;
        padding: 0 5px;
        font-weight: bold;
      }
    }
  }

  .get-title {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }

  .select-share {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;

    .item {
      border: 2px solid $blue-color1;
      border-radius: 5px;
      width: 67px;
      height: 40px;
      line-height: 36px;
      color: $blue-color1;
      font-weight: 900;
      font-size: 15px;
      text-align: center;
      cursor: pointer;
      margin-right: 7px;

      &.active {
        background: $blue-color1;
        color: #fff;
      }

      &.disabled {
        opacity: .4;
        cursor: default;
      }

    }
    .item-dropdown {
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .fa-chevron-down {
      padding-left: 5px;
    }
  }

  .select-wrapper {
    border: 2px solid $blue-color1;
    border-radius: 5px;
    height: 40px;
    width: 90px;
  }
  .menu {
    background: #fff;
    border-radius: 5px;
    position: absolute;
    top: 50px;
    right: 0;
    width: 216px;
    overflow: hidden;
    box-shadow: $default-shadow;
    height: 0;
    transition: all .25s ease-out 0s;
    &.active {
      height: 200px;
      overflow-y: scroll;
      z-index: 1;
    }
  }
  .menu-item {
    font-size: 15px;
    font-weight: 900;
    padding: 10px;
    color: $blue-color1;
    cursor: pointer;
    &:hover {
      background: #e0f1ff;
    }
  }
  mat-select {
    padding: 0;
    border: none;
  }

  .amount-not-allowed {
    color: $gray-color3;
    text-align: center;
    margin: -6px 0 14px;
  }
}

@media screen and (max-width: 1380px) {
  app-calculation {
    .input-box {
      background: #F2F7FB;
      border-radius: 5px;
    }
    .wrapper {
      padding: 0 54px;
    }
    .bid-closed {
      top: 40px;
    }
  }
}

@media screen and (max-width: 1100px) {
  app-calculation {
    .wrapper {
      padding: 0 54px 0 24px;
    }
  }
}

@media screen and (max-width: 1023px) {
  app-calculation {
    flex-direction: column;

    .info-text {
      width: 100%;
      height: 200px;
      font-size: 45px;
      text-align: center;
      br {
        display: none;
      }
      .inner {
        align-items: flex-end;
      }

      .sub-text {
        font-size: 30px;
        margin: 8px 0 25px;
      }
    }
    .followers-social-row {
      visibility: hidden;
      display: none;
    }
    .wrapper {
      height: auto;
      width: 100%;
      margin-top: 18px;
    }
  }
}

@media screen and (max-width: 767px) {
  app-calculation {
    .followers-social-row {
      visibility: hidden;
      display: none;
    }
    .input-box {
      background: $white-color;
    }
    .bid-closed {
      margin: auto;
    }
  }
}

@media screen and (max-width: 460px) {
  app-calculation {
    .info-text {
      display: none;
    }
    .followers-social-row {
      visibility: hidden;
      display: none;
    }
    .wrapper {
      margin-top: 100px;

      .inner {
        max-width: calc(100% - 20px);
      }
    }
    .select-share .item {
      width: 60px;
    }

    .select-wrapper {
      width: calc(100% - 201px);
    }
  }
}
