@import '../../../../assets/scss/variables';

app-card-project {
  color: #595A5A;
  position: relative;

  .card {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;

    &__video-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1 !important;
      transition: opacity 1s;


      &.opaque {
        opacity: 0 !important;
        transition: opacity 0.5s 0.5s;
      }
    }

  }

  mat-spinner {
    z-index: 2;
    position: absolute !important;
    top: 45%;
    left: 45%;

  }

  .sale_corner-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 110px;
  }
  .icon-musical {
    img {
      max-width: 20px;
    }
  }
  .icon-disk, .icon-melody {
    position: relative;
  }

  .tooltip-card {
    width: 190px;
    top: 30px;
    right: -20px;

    &:after {
      top: -6px;
      right: 22px;
    }
  }
  .icon-melody, .icon-disk {
    margin-left: 10px;

    img {
      aspect-ratio: auto 1 / 1;
    }
  }

  .flipped {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .card-image-wrapper {
    width: 310px;
    height: 473px;
    overflow: hidden;
    border-radius: 20px;
    z-index: 2;
    margin: 0 12px 48px;
    position: absolute;

    .card__background {
      width: 100%;
    }
  }

  .front {
    -webkit-backface-visibility: hidden;
  }

  .back {
    top: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: rotateY(180deg);
    position: absolute;
    margin: 0 12px 48px;
    border-radius: 20px;
    width: 310px;
    overflow: hidden;
    height: 473px;
    display: flex !important;
    flex-direction: column;
    background-color: red;
    box-shadow: 0 4px 3px 0 rgb(128 137 160 / 40%);
  }

  .video, .upl__image {
    top: 0px;
    position: absolute;
    width: 310px;
    height: 473px;
    border-radius: 20px;
    object-fit: cover;
  }

  .upl__image {
    z-index: 2;
  }

  .card-outer {
    position: relative;
    margin: 0 12px 48px;
    border-radius: 20px;
    width: 309px;
    overflow: unset;
    height: 473px;
    display: flex !important;
    flex-direction: column;
    box-shadow: 0 4px 3px 0 rgb(128 137 160 / 40%);

    .card__img {
      border-radius: 20px;
      min-height: 196px;

      .image {
        width: 196px;
        height: 196px;
        object-fit: cover;
      }
    }

    .lnr-calendar-full {
      cursor: auto;
    }

    .fa-question-circle {
      font-size: 16px;
      color: $blue-color1;
      cursor: pointer;
    }

    .fa-question-circle:hover + .timer-hint {
      .tooltip {
        display: block;
        right: -20px;
        top: -200px;
      }
    }

    .card__logo {
      height: 100%;
      align-items: flex-start;

      .tooltip-card {
        width: 190px;
        top: 40px;
        right: -10px;

        &:after {
          top: -6px;
          right: 25px;
        }
      }
    }

    .informative__item {
      width: 134px;
      font-size: 12px;
    }

    .card__status-box {
      display: none;
    }

    .card__genres {
      font-size: 12px;
      width: 100%;
      line-height: 14px;
      height: 14px;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
      max-height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .card__background {
      width: 310px;
    }

    .social-wrapper {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      align-items: center;
    }

    .status-footer {
      padding: 0 20px;
      height: 77px;
      justify-content: center;
      flex-direction: column;
      display: flex;
      border-top: 1px dashed #A8C1D3;
      font-weight: 900;
      font-size: 24px;

      .button {
        width: 240px;
        margin: 0 auto;
        font-size: 16px;
      }

      .status-declined {
        font-weight: bold;
      }
    }
  }

  .play-button {
    position: absolute;
    top: 45%;
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid $white-color;
    border-radius: 50%;
    color: $white-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    &.mobile {
      display: none;
    }
  }

  .footer-detail {
    position: relative;
    display: flex;
    align-items: flex-end;

    .auction-edition {
      color: $white-color;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      width: max-content;
    }
  }

  .card__footer {
    height: 80px;
    z-index: 4;
    padding: 10px 0 12px 0;
    margin: 0 12px;
    border-top: 1px solid #FFFFFF
  }

  .auction__title {
    font-weight: 700;
    font-size: 12px;
    color: #FFFFFF;
    margin-bottom: 10px;
  }

  .card__content {
    height: 45%;
    flex: 1 0 auto;
    padding: 10px 13px 0;
    display: flex;
    z-index: 9;
    flex-direction: column;
  }

  .headliner-project {
    position: relative;
  }

  .card-collectible__auction-sh {
    display: flex;
    justify-content: space-between;

    .timer__timing {
      justify-content: flex-start;
    }

    .timer__title {
      display: none;
    }

    .auction__title {
      text-align: left;
    }

    .tooltip-card {
      right: -20px;
      top: 30px;
      width: 190px;

      &:after {
        top: -6px;
        right: 20px;
      }
    }

    .icon-key, .icon-gift {
      position: relative;
    }

    .icon-gift {
      margin-left: 10px;
    }

    .footer-detail {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: column;
    }

    .footer-detail__icons {
      height: 20px;
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .card-collectible__auction-run {
    display: flex;
    justify-content: space-between;

    .timer__timing {
      justify-content: flex-end;
    }

    .timer__title {
      display: none;
    }

    .auction__title {
      text-align: right;
    }

    .tooltip-card {
      left: -20px;
      top: 30px;
      width: 190px;

      &:after {
        top: -6px;
        left: 20px;
      }
    }

    .icon-key, .icon-gift {
      position: relative;
    }

    .icon-key {
      margin-right: 10px;
    }

    .footer-detail {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: column;
    }

    .footer-detail__icons {
      height: 20px;
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
  }



}

.card__informative {
  display: flex;
  justify-content: space-between;
}

.informative__item {
  font-size: 14px;
  padding: 2px 0;
  box-sizing: border-box;
  line-height: 16px;
  border-radius: 5px;
  visibility: hidden;
  text-align: center;

  &.active {
    visibility: visible;
  }
}

.info__holder {
  display: flex;
  justify-content: space-between;
}

.card__name {
  margin-bottom: 10px;
  z-index: 2;
  text-align: left;
  color: $white-color;
}

.daysleft {
  color: $red-color1;
}

.card__title-h2 {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  line-height: 24px;
  text-transform: uppercase;
}

.card__title-h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.card__name--padding {
  padding: 0 12px;
}

.card__song-name {
  font-size: 15px;
  text-align: left;
  font-weight: 500;
  line-height: 16px;
}

.card__status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.card__progress-bar {
  background: #FFFFFF;
  border-radius: 50px;
  margin: 10px 0 8px 0;
  overflow: hidden;
  height: 12px;
  width: 100%;

  .result {
    position: absolute;
    height: 12px;
    border-radius: 50px;
  }
}

.price__per-share {
  font-size: 12px;
  color: $white-color;
  text-align: left;
  font-weight: 700;

  .price__per-share-underline {
    text-decoration: line-through;
    padding-left: 5px;
  }
}

.card__share {
  font-size: 12px;
  display: flex;
  color: $white-color;
  justify-content: space-between;
  font-weight: 700;
}

.card__genres {
  text-align: center;
  width: calc(100% - 140px);
  font-size: 16px;
  font-weight: 800;
  color: #595A5A;
}

.card__status-box {
  width: 65px;
  font-size: 11px;
  z-index: 1;
  line-height: 25px;
  border-radius: 5px;
  color: #FFFFFF;
  text-align: center;

  &.new {
    background: $green-color10;
  }

  &.invest {
    background: $yellow-color2;
  }

  &.failed {
    background: $red-color1;
  }
}

.image-pin {
  position: absolute;
  top: 10px;
}

.card__end {
  width: 100%;
  font-size: 14px;
  margin: 6px auto;
  height: 40px;
  display: flex;
  background: black;
  font-weight: 700;
  color: $white;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  text-transform: uppercase;
}

//.card__description {
//  font-weight: 400;
//  z-index: 2;
//  padding: 0 12px;
//  font-size: 12px;
// // color: $white-color;
//  text-align: left;
//  overflow: hidden;
//  text-overflow: ellipsis;
//  white-space: nowrap;
//  line-height: 20px;
//}

.track__numbers {
  margin-bottom: 10px;
  align-self: flex-end;
  font-weight: 400;
  z-index: 2;
  padding: 0 12px;
  font-size: 12px;
  color: $white-color;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
}

.card__logo {
  display: flex;
  z-index: 5;
  justify-content: flex-end;

  a {
    color: #ffffff;
  }

  img {
    width: 18px;
    display: flex;
    align-items: flex-start;
    aspect-ratio: auto 9 / 11;
  }
}


@media screen and (max-width: 767px) {
  app-card-project {
    .card {
      margin: 0 auto;
    }

    .play-button {
      position: absolute;
      top: 45%;

      &.desktop {
        display: none;
      }

      &.mobile {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  app-card-project {
    .card-outer {
      width: 290px;
    }

    .card__background {
      width: 290px;
    }
  }
}
