@import '../../../../../assets/scss/variables';

app-kyc-upload-document {
  text-align: right;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .form {
    width: 100%;
    padding: 0;
  }
  .document-type {
    width: 100%;
  }
  .file-doc__title {
    text-align: left;
    width: 100%;
    color: #8089A0;
    font-size: 14px;
    &.file-doc__title_padding {
      padding: 10px 0;
    }
  }
  .wrapper-upload{
    width: 100%;
  }
  .item-document,
  .file-doc__input {
    width: 100%;
    height: 110px;
    background: #eefcff;
    position: relative;
    border: 2px dashed $blue-color1;
    overflow: hidden;
    &:last-child {
      margin-left: 15px;
    }
    label {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
    }
    span,
    .fa-camera {
      color: $blue-color1;
    }
    img {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    span {
      font-size: 14px;
    }
    .fa-camera {
      font-size: 20px;
      line-height: 23px;
    }
  }
  .upload__file-doc {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  input[type="file"] {
    display: none;
  }
  .file-doc__pdf {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: 500;
    span {
      padding-left: 10px;
    }
    .fa-times-circle {
      cursor: pointer;
      color: $red-color1;
    }
  }
  .other-photo{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .id__passport {
    display: block;
    margin: auto;
    height: 200px;
    width: auto;
  }
  .form-wrapper {
    padding: 20px 60px 10px 60px;
  }
  .upload-wrapper {
    padding: 0 20px;
  }
  .loader-picture__image {
    width: 100%;
  }
  .idCard__text {
    text-align: center;
    text-transform: uppercase;
    color: #8089A0;
    padding: 10px 0;
    font-weight: bold;
    width: 100%;
    display: inline-block;
  }
  .image__passport {
    width: -webkit-fill-available;
  }
  .kyc-button-wrapper {
    justify-content: center;
    display: flex;
    gap: 8px;
    .button{
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  .crop__upload {
    text-transform: uppercase;
  }
  .image__passport {
    height: 250px;
    margin-top: 15px;
  }
  .title {
    color: $gray-color3;
  }
  .wrapper-kyc {
    cursor: pointer;
  }
  .wrapper-kyc:hover + .wrapper-tooltip-kyc {
    .tooltip {
      display: block;
      width: 180px;
    }
  }
  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: $white !important;
  }
  .warning {
    margin-top: 5px;
    display: inline-block;
  }
  .kyc-status-block {
    margin-top: 5px;
    border-radius: 7px;
    width: 126px;
    height: 24px;
    line-height: 26px;
    text-align: left;
    span {
      font-size: 13px;
      font-weight: bold;
      color: $white-color;
    }
    .fas {
      padding: 0 10px;
    }
    &.inactive {
      background: $red-color1;
    }
    &.active {
      background: $green-color10;
    }
  }
  .tooltip {
    top: 60px;
    text-align: left;
    left: -50px;
    padding: 14px;
    &:after {
      top: -7px;
      right: 45px;
      width: 0;
      height: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  app-kyc-upload-document {
    .kyc-status-block {
      margin-top: 5px;
    }
    .form-wrapper {
      max-width: 486px;
      margin: auto;
      padding: 20px 0 0 0;
    }
    .button {
      font-size: 16px;
      max-width: 100%;
      width: 100%;

    }
    .upload-wrapper{
      padding: 0;
    }

    .img-block img{
      width: 100%;
      object-fit: contain;
    }
  }
}
