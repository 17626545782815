@import "src/assets/scss/variables";
.lnr.default-cross {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 24px;
  color: $white;
  opacity: 1;
  border: 10px solid transparent;
}
