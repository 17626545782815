@import "assets/scss/variables";
@import "assets/scss/mixin";

.home-accordion {
  display: block;
  border-radius: 20px;
  padding: 5px 0;
  background-color: $gray-color6;
  overflow: hidden;

  .mat-expansion-panel {
    background: transparent !important;
    &:not([class*=mat-elevation-z]) {
      box-shadow: none;
    }

    &:not(.last)::after {
      content: "";
      display: block;
      width: calc(100% - 30px);
      height: 1px;
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 1px dashed $gray-color4;

      @include minW(768px) {
        width: calc(100% - 80px);
      }
    }

    &.mat-expanded {
      .mat-expansion-indicator {
        transform: rotate(0deg) !important;
      }
    }
  }

  .mat-expansion-panel-header {
    padding-left: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: $blue-color7;
    background: transparent !important;
  }

  .mat-expansion-panel-header-title {
    color: $blue-color7;
  }

  .mat-expansion-indicator {
    transform: rotate(270deg) !important;

    &::after {
      margin-bottom: 4px;
      color: $blue-color7;
      
    }
    
  }

  .mat-expansion-panel-body {
    padding: 0 15px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $gray-color3;
    text-align: left;

    @include minW(768px) {
      padding: 0 60px 20px 40px;
    }

    a {
      font-weight: 600;
      display: inline-block;
      text-transform: uppercase;
      text-decoration: none;
      margin-top: 24px;
      color: #4DA3E1;
    }

    ol {
      list-style: decimal;
      padding-left: 20px;
    }

    p + ol {
      margin-top: 24px;
    }
  }
}
