@import "assets/scss/variables";
@import "assets/scss/mixin";

app-balance {
  height: 100%;

  .main-wrapper {
    min-height: 100vh;
    background: $white;
  }

  main {
    flex: 1;
    width: 100%;
    margin: 0 auto;
  }

  .section-image {
    margin: -80px auto 0 auto;
    width: 1300px;
    padding-left: 60px;

    .name,
    .text {
      z-index: 10;
    }

    .name {
      color: $white;
    }
  }

  .table-head {
    tr {
      border-bottom: 2px solid $gray-color3;
    }
  }

  .link-blue {
    font-weight: normal;
  }

  .icon-blue {
    color: $blue-color1;
  }

  .filter-title {
    white-space: nowrap;
    line-height: 48px;
    padding-right: 20px;
  }

  .filter-wrapper {
    display: flex;
    width: 100%;
    .item {
      margin-right: 14px;
    }
    &.filter-wrapper_space_between {
      justify-content: space-between;
    }
  }

  .investment-table {
    margin-top: 27px;
  }

  .wrapper-img {
    .fas {
      padding-right: 30px;
    }
  }

  .mobile-screen {
    display: none;
  }

  .action-tab {
    padding-top: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    .info-wrapper {
      justify-content: space-between;
      width: 100%;
      text-align: right;
    }
    .value {
      font-size: 27px;
    }
    .text {
      color: $gray-color3;
    }

    .item {
      width: auto;
      margin-right: 30px;

      &:first-child {
        margin-left: 0;
      }
    }
    .balance {
      margin-right: 60px;
      position: relative;
      span {
        height: 100%;
        display: block;
        border-right: 1px solid #8089a0;
        position: absolute;
        right: -30px;
        top: 0;
        opacity: 0.2;
      }
    }
  }

  .filter-section.desktop-screen {
    justify-content: flex-start;
    display: flex;
    border-top: 1px dashed $gray-color1;
    width: 1300px;
    margin: 0 auto;
    padding-top: 18px;
  }

  .user-name {
    width: 100%;
    padding: 15px 0 25px 37px;
    justify-content: space-between;
  }

  .document {
    cursor: pointer;
  }

  .image-wrapper {
    height: 196px;
    width: 100%;
    position: relative;
  }

  .info-amount {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .item .text {
      margin-bottom: 0;
    }
    .text {
      white-space: nowrap;
    }
  }
  .user-info__button {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    mat-spinner {
      margin-right: 5px;
    }
  }
  .profile-img {
    width: 160px;
    min-width: 160px;
    position: relative;
    background: url("../../../assets/images/user/fallback_new.png");
    height: 160px;
    background-size: cover;
    background-position: center center;
    border-radius: 80px;
    box-shadow: $default-shadow;
  }

  .table-sub-header.download {
    td {
      cursor: pointer;
    }
  }

  .lnr-chevron-right {
    display: inline-block;
    font-weight: $font-weight-900;
    cursor: pointer;

    &.opened {
      transition: all 0.25s ease-out 0s;
      transform: rotate(90deg);
    }
  }

  .investment-table.table {
    td, th {
      @include maxW(376px){
        padding: 10px 0;
      }
    }
  }

  tbody {
    td {
      padding: 20px 18px;
    }

    tr {
      &:hover {
        td {
          background: transparent;
        }
      }
    }
  }

  .download-links {
    display: flex;
    color: $blue-color1;
    justify-content: flex-start;

    > .link-blue {
      width: 100px;
    }
  }

  .no-list-data {
    border-bottom: none;
    font-style: italic;
  }

  .kyc-status-block {
    border-radius: 7px;
    width: 126px;
    height: 24px;
    line-height: 26px;
    text-align: left;
    span {
      font-size: 13px;
      font-weight: 700;
      color: $white-color;
    }
    .fas {
      padding: 0 10px;
    }
    &.inactive {
      background: $red-color1;
    }
    &.active {
      background: $green-color10;
    }
  }

  .button-wrapper {
    justify-content: center;

    .button {
      width: auto;
      padding: 0 30px;
      margin-bottom: 35px;
      height: 50px;
      font-size: 16px;
      font-weight: $font-weight-700;
    }
  }

  .min-screen {
    display: none;
  }

  .warning__list {
    display: flex;
    justify-content: flex-end;
    .warning__title {
      white-space: nowrap;
    }
  }
  .data-list {
    width: 1300px;
    margin-left: auto;
    margin-right: auto;
    &.data-list--margin {
      margin: 0 auto 32px;
    }
  }
  .td-amount__value {
    white-space: nowrap;
  }
  mat-form-field {
    &.month {
      width: 60px;
      margin-right: 5px;
    }

    &.year {
      width: 80px;
      margin-right: 20px;
    }

    + .button {
      height: 40px;
      min-width: 120px;
      // line-height: 36px;
    }
  }
  .td-amount {
    font-size: 18px;
    font-weight: bold;
  }
  .reset-filters {
    height: 40px;
    /* line-height: 40px; */
    cursor: pointer;
    margin-left: 15px;
    color: #5aa2e5;
    white-space: nowrap;
    display: flex;
    /* width: 155px; */
    align-items: center;

    .lnr-cross {
      margin-left: 8px;
      color: $link-color;
    }
  }

  .download-loaded {
    margin-left: 30px;

    div.global-page-loader {
      margin: 0;
      height: 16px;

      .lnr {
        font-size: 20px;
        left: 0;
        top: -2px;
      }
    }
  }

  app-loader {
    height: 200px;
    display: flex;
  }
}

@media screen and (max-width: 1370px) {
  app-balance {
    .section-image {
      width: 90%;
      padding-left: 20px;
    }
    .filter-section.desktop-screen {
      display: block;
      width: 90%;
    }
    .data-list {
      width: 90%;
    }
  }
}

@media screen and (max-width: 1023px) {
  app-balance {
    main {
      width: 100%;
    }

    .action-tab {
      .wrapper {
        align-items: flex-end;

        &:last-child {
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
          margin-left: 0;

          .button {
            padding: 0 20px;
          }

          .item {
            margin-top: 10px;
            margin-left: 0;
          }
        }
      }
    }
    .td-amount {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 767px) {
  app-balance {
    main {
      padding: 0 15px !important;
      background: $white;
    }

    .newline {
      word-break: break-word;
    }

    .mobile-screen {
      display: block;
    }

    td {
      &.mobile-screen {
        display: table-cell;
      }
    }

    tr {
      &.mobile-screen {
        display: table-row;
      }
    }

    .filter-section-upload {
      position: relative;
      width: 100%;
      margin: 10px 0 0 0;
    }
    .data-list {
      &.data-list--margin {
        margin: 0 auto 32px;
      }
    }
    .filter-section.desktop-screen {
      width: 100%;
    }
    .image-wrapper {
      height: 156px;
    }

    .wrapper-img .fas {
      padding-right: 10px;
    }
    .filter-wrapper {
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .button {
        width: 100%;
      }
      .item {
        margin-right: 0;
        width: 48%;
      }
    }

    .data-list {
      width: 100%;
      margin: 0;
    }

    .min-screen {
      display: table-cell;
    }

    .desktop-screen-visible {
      display: none;
    }

    .section-image {
      width: 100%;
      padding: 0 !important;
    }

    .profile-img {
      display: none;
    }

    .button-wrapper {
      .button {
        width: 100%;
      }
    }

    .user-name {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0 20px 0;

      .name {
        font-size: 18px !important;
      }
    }

    .song-name,
    .artist-name {
      margin-bottom: 5px;
    }
    .user-info__button {
      width: 100%;
      height: 50px;
    }
    .action-tab {
      flex-direction: column;
      padding-top: 55px;
      width: 100%;
      .desktop-screen {
        display: none;
      }
      .info-amount {
        justify-content: space-between;
        padding: 0;
        .item {
          width: 33.33%;
          text-align: center;
          margin-right: 0;
          margin-top: 20px;
          &.balance {
            width: 50%;
            margin: 0;
            text-align: left;
            span {
              display: none;
            }
            .value {
              font-size: 24px;
            }
          }
          &.button-load-csv {
            width: 100%;
          }
          &:nth-child(3) {
            text-align: left;
            .value {
              width: 70%;
              text-align: center;
            }
          }
          &:nth-child(5) {
            text-align: right;
            .value {
              width: 100%;
              text-align: center;
              margin-left: 15px;
            }
          }
          .value {
            line-height: 36px;
            font-size: 18px;
          }
        }
      }
    }
    .value {
      font-size: 18px;
    }
    tbody {
      td {
        &:nth-child(3) {
          padding: 20px 18px 20px 0;
        }
      }
    }

    .table th {
      &:nth-child(2) {
        padding: 11px 18px 11px 0;
      }
    }

    .backgroundImage {
      background-image: none !important;
      background-color: #253a6f;
    }

    .image-desc-float {
      margin-right: 16px;
    }

    mat-form-field {
      + .button {
        width: 100%;
        height: 50px;
      }

      &:nth-child(4) {
        margin: 0;
      }

      &.year {
        width: 70px;
      }
    }
    .reset-filters {
      margin: 5px auto 0;
    }
  }
}

.mobile-accordion {
  padding: 0 !important;
}

.mat-expansion-panel {
  width: 100%;
  box-shadow: none;

  .mat-expansion-panel-body {
    padding: 20px 18px;
  }
}
