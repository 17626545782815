@import "../variables";
@import "../mixin";

.popup-inner{
  max-width: 90vw;
  position: relative;
  padding: 40px 90px;
  text-align: center;
  @include screen767{
    padding: 20px;
  }
}
.popup-title {
  font-size: 21px;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;

}
