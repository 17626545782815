@import '../../../../assets/scss/variables';

app-fees-popup {
  .inner {
    width: 660px;
    max-width: 90vw;
    position: relative;
    padding: 40px 20px;
    text-align: center;
  }
  table {
    th {
      font-weight: bold !important;
      color: $black-color1 !important;
      text-align: center !important;
      span {
        white-space: nowrap;
      }
    }
  }
  td {
    span {
      white-space: nowrap;
    }
  }
  .lnr-cross {
    position: absolute;
    right: 20px;
    font-size: 16px;
    font-weight: 600;
    top: 20px;
    cursor: pointer;
    color: $blue-color1;
  }

  .block-title {
    font-weight: 900;
    font-size: 21px;
    text-transform: uppercase;
    text-align: center;
    margin: 30px 0 10px;
  }

  .text, .sub-text {
    font-size: 15px;
    text-align: center;
    margin-bottom: 10px;
  }

  .text {
    margin-top: 20px;
    font-weight: bold;
  }

  .sub-text {
    color: $gray-color3;
  }

  .image {
    display: block;
    margin: 0 auto;
    height: 130px;
  }
  .button-wrapper {
    display: flex;
    width: 80%;
    font-size: 14px;
    margin: 30px auto 0;
    justify-content: space-around;
    align-items: center;
    .cancel {
      color: $link-color;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 767px) {
  app-fees-popup {
    .text br {
      display: none;
    }

    table {
      th {
        padding: 11px 5px!important;
        font-size: 12px!important;
        font-weight: bold !important;
        color: $black-color1 !important;
        text-align: center !important;
        span {
          white-space: normal;
        }
      }
    }
    td {
      padding: 11px 5px;
      font-size: 12px;
      span {
        white-space: normal;
      }
    }
  }
}

