@import '../../../../assets/scss/variables';

app-reminder-nft-popup {
  position: relative;
  .inner {
    width: 560px;
    max-width: 90vw;
    position: relative;
    padding: 40px 90px;
    text-align: center;
  }
  .popup-content{
    position: relative;
  }
  .popup-title {
    font-size: 21px;
    line-height: 60px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;

  }
  .subscribe__input {
    border-bottom: 1px solid #B7BCCB;
    width: 100%;
    outline: 0;
    border-width: 0 0 1px 0;
    padding: 0 0 10px 40px;
    font-size: 14px;
  }
  .popup-input{
    margin: 30px 0;
    position: relative;
  }
  .popup-sub-title {
    font-weight: 400;
    font-size: 15px;
    text-align: center;
  }
  .popup-image{
    position: absolute;
    left: 5px;
    top: 0;
  }
  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    padding: 0 ;
    font-size: 14px;
  }

  .popup-button {
    margin: 0 auto 15px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
  }
  .terms {
    font-weight: 400;
    font-size: 12px;
    color: $gray-color3;
    .link-blue {
      font-weight: 400;
      font-size: 12px;
    }
  }
}

