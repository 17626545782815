@import "src/assets/scss/variables";

app-pdf-popup {
  .lnr.default-cross {
    position: absolute;
    right: -50px;
    top: 0;
    cursor: pointer;
    font-size: 24px;
    color: $white;
    opacity: 1;
    border: 10px solid transparent;
  }


}

@media screen and (max-width: 767px) {
  app-pdf-popup {
    embed {
      width: 100%;
    }
    .lnr.default-cross {
      right: 0;
      top: -50px;
    }
    .popup-wrapper {
      overflow: unset;
    }
    .embed-image {
      width: 100%;
    }
  }
}
