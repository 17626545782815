@import '../../../../assets/scss/variables';

app-tfa-popup {
  .inner {
    width: 460px;
    max-width: 90vw;
    position: relative;
    padding: 40px 20px;
    text-align: center;
  }

  .text-block {
    text-align: center;
    margin: 25px auto;
    width: 85%;
    .text-center {
      text-align: center;
      width: 100%;
      display: inline-block;
      &.name {
        line-height: 28px;
        font-size: 16px;
      }
    }
    .text{
      font-size: 14px;
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .image {
    display: block;
    margin: 0 auto;
    height: 130px;
    border-radius: 50%;
  }
  .button {
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  app-tfa-popup {
    .text br {
      display: none;
    }
  }
}

