@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';

app-profile {
  height: 100%;

  .main-wrapper {
    min-height: 100vh;
    background: $white;
  }

  main {
    flex: 1;
    width: 1200px;
    margin: 0 auto;
  }

  .section-image {
    margin-top: -80px;

    .name, .text {
      z-index: 10;
    }

    .name {
      text-transform: capitalize;
      color: $white;
    }
  }

  .section__kyc-status {

    .title__item {
      display: inline-flex;
      padding-left: 10px;
      gap: 16px;
    }

    app-kyc-status {
      .kyc-title {
        display: none;
      }

      .kyc-status-block {
        margin-top: 0;
      }
    }
  }

  .wrapper-tooltip {
    display: block;
    position: relative;
    width: 0;
    height: 100%;
  }

  .tooltip-ubo {
    text-align: left;
    right: -178px;
    top: -235px;
    padding: 14px;
    width: 280px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -6px;
      right: 5px;
      width: 0;
      height: 0;
      border-top: 7px solid rgba(0, 0, 0, 0.7);
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: none;
    }

    &.tooltip-wrap {
      &:after {
        right: 160px;
      }
    }
  }

  .nickname {
    position: relative;
    text-align: left;

    mat-error {
      position: absolute;
      bottom: 0;
      font-size: 11px;
    }
  }

  .button__check-name {
    height: 30px;
    min-width: 100px;
    margin-bottom: 20px;
    margin-left: 10px;
  }

  .nickname__input {
    width: 100%;
  }

  .user-name {
    padding: 15px 0 20px 37px;
    justify-content: space-between;

    .name {
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  .profile__section-padding {
    padding-bottom: 20px;
  }

  .section__wallet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include screen1199 {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .shareholders__data {
    width: 100%;
  }

  .shareholders__ubos {
    margin: 20px 0;
  }

  .shareholders__wrapper {
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-bottom: 32px;

    .hint_and_button {
      display: inline-flex;

      .ubo_hint {
        margin-left: 15px;
        align-self: center;

        .ubo_hint_icon {
          font-size: 20px;
        }
      }
    }
  }

  .fa-question-circle:hover + .wrapper-tooltip {
    .tooltip-ubo {
      display: flex;
    }
  }

  .wrapper {
    justify-content: space-between;
  }

  .shareholders__status {
    border-radius: 7px;
    width: auto;
    height: 24px;
    line-height: 26px;
    text-align: left;

    &.pending {
      background-color: $yellow-color2;
    }

    &.refused {
      background-color: $red-color1;
    }

    &.validated {
      background-color: $green-color10;
    }

    span {
      color: $white;
      padding: 0 10px;
      font-weight: bold;
    }
  }

  .ubos__button-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 140px;
    margin: 10px 0;
  }

  .shareholders__delete {
    padding-left: 10px;

    .fa-times-circle {
      cursor: pointer;
    }
  }

  .user__company {
    margin-top: 40px;

    .item {
      width: 100%;
    }
  }

  .shareholders__button {
    height: 40px;
  }

  .ubos__button {
    height: 25px;
    min-width: 60px;
  }

  .image-wrapper {
    height: 196px;
    width: 100%;
    position: relative;
  }

  .warn-icon-image {
    height: 15px;
    width: 15px;
    position: relative;
  }

  .profile-image {
    position: absolute;
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 100%;
  }
  .profile-img-wrapper {
    width: 160px;
    min-width: 160px;
    position: relative;
    background-image: url('../../../assets/images/fallback.png');
    height: 160px;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    border-radius: 80px;
    box-shadow: $default-shadow;
  }

  .input-block {
    border-bottom: 1px dashed $gray-color1;
  }

  .project-detail {
    display: flex;
    padding: 0 80px 45px;

    .item-detail {
      width: 70%;
      padding-right: 50px;
    }

    .item-share {
      width: 30%;

      .item-share-document {
        display: flex;
        margin-top: 25px;
        align-items: center;
        text-decoration: none;

        span {
          padding-left: 16px;
        }
      }
    }
  }

  .section-info {
    margin-top: 50px;
  }

  .profile-title {
    padding: 30px 0;
  }

  .item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item {
      width: 48%;
    }
  }

  .investor-warning {
    padding: 15px;
    background-color: #f44336;
    color: white;
    left: 0px;
    right: 0px;
    position: fixed;
    text-align: center;
    height: 40px;
    animation-name: floatingWarning;
    animation-duration: 0.8s;
    animation-delay: 0.1s;
    animation-iteration-count: initial;
    animation-fill-mode: forwards;
  }

  @keyframes floatingWarning {
    from {
      bottom: -40px;
    }
    to {
      bottom: 0px;
    }
  }

  .section-image-wrapper {
    padding: 0 25px;
  }

  .profile__actions {
    .button {
      margin-left: 60px;
    }
  }

  .button-wrapper {
    align-items: center;
    justify-content: center;
    margin: 0 0 32px 0;

    .cancel {
      color: $link-color;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
      font-weight: 500;
    }

    .button {
      width: 213px;
      height: 50px;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .lnr-warning {
    font-size: 14px;
  }

  .genres-list {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .item-genre {
      border: 2px solid $blue-color1;
      border-radius: 5px;
      padding: 0 12px;
      height: 40px;
      line-height: 36px;
      color: $blue-color1;
      text-align: center;
      cursor: pointer;
      margin-right: 7px;

      .fa-times {
        font-size: 14px;
        padding-right: 10px;
        display: inline-block;
      }
    }
  }

  .profile__section {
    padding-top: 30px;
    padding-bottom: 30px;
    @include screen767 {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    //@include screen1199{
    //    width: 50%;
    //    margin: 0 auto;
    //}
    &.profile__section--col {
      padding-right: 25px;
      padding-left: 25px;
    }

    .title {
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      // margin: 30px 0;
      .title-m {
        padding: 0;
      }
    }

    .item-setting {
      width: 100%;
      margin: 0 -25px;
      display: flex;

      .form-item-setting {
        width: 33.33%;
        padding: 0 25px;
        justify-content: space-between;

        &:first-child {
          padding-top: 0;
        }

        .toggle {
          display: flex;
          justify-content: space-between;
        }

        .toggle-desc {
          color: $gray-color3;
          margin-top: 26px;
        }

        .toggle-desc-note {
          color: $gray-color3;
          margin-top: 11px;
        }

        .toggle-desc-note-red {
          color: red;
          margin-top: 11px;
        }

        .label-red {
          font-size: 14px;
          width: 70%;

          span {
            display: inline-block;
            max-width: 200px;
            color: red;
          }
        }

        .label-gray {
          font-size: 14px;
          width: 70%;

          span {
            display: inline-block;
            max-width: 200px;
            color: $gray-color3;
          }
        }

        .label {
          font-size: 14px;
          width: 70%;

          span {
            display: inline-block;
            max-width: 200px;
            color: $black-color1;
          }
        }

        .toggle-item {
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  .toggle-item {
    .status {
      color: $gray-color3;
    }
  }

  .activity-link {
    text-align: center;
    margin: 20px 0;

    .link-blue {
      cursor: pointer;
    }
  }

  .profile-song-content {
    border-bottom: 1px dashed $gray-color4;
    padding-bottom: 30px;
  }

  .gray-text {
    color: $gray-color3;
    font-size: 14px;
    line-height: 18px;
  }

  .section__user-data {
    display: flex;
    padding-bottom: 0;

    .section__wrapper {
      padding: 0 25px;
      width: 33.33%;
    }
  }

  .section__user-social {
    padding-bottom: 0;
    margin-bottom: 18px;

    .section__wrapper {
      width: 33.33%;
    }
  }

  .section__wrapper {
    padding: 0 25px;

    &.section__wrapper--align {
      display: flex;
      flex-direction: column;
    }
  }

  .birth-data {
    margin: 40px 0;

    .item {
      width: 100%;
    }
  }

  .change-picture {
    position: relative;
    overflow: hidden;
    padding: 10px 0;

    input {
      position: absolute;
      padding: 15px 10px;
      height: 0;
      left: 0;
      top: 5px;
      width: 120px;
      opacity: 0;
      cursor: pointer;
    }
  }

  .section-toggle-mob {
    height: 100%;
    margin-top: 20px;
    @include screen767 {
      height: 0;
      overflow: hidden;
      margin-top: 0;
      &.openSetting {
        height: auto;
        margin-top: 10px;
      }
    }
  }

  .lnr-warning {
    fill: red;
  }

  .notification, .nft, .language-s, .bank-account, .section__kyc-status {
    .lnr-chevron-right {
      display: none;
    }
  }

  .nft {
    .title {
      white-space: nowrap;
    }

    .section-toggle-mob {
      margin: 0 0 0 20px;
    }
  }

  .bank-account__forms {
    margin-bottom: 18px;
  }

  input[type="file"] {
    cursor: pointer;
    padding: 20px;
  }

  .size-hint {
    color: $gray-color3;
    margin-top: -7px;
  }

  .birth-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    mat-form-field {
      width: 32%;
    }
  }

  .lnr-chevron-right {
    display: inline-block;
    font-weight: $font-weight-900;

    &.opened {
      transition: all .25s ease-out 0s;
      transform: rotate(90deg);
    }
  }

  .section-toggle-delete {
    height: 0;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.2s ease-out;

    .delete-account__text {
      width: 50%;
      min-width: 260px;
    }

    &.openSetting {
      height: auto;
      margin-top: 20px;
      transition: all 0.2s ease-out;
    }

    .button {
      max-width: max-content;
      margin-right: 20px;
    }

    .button.disabled {
      pointer-events: none;
    }
  }

  .delete-account {
    border-bottom: 1px dashed $gray-color1;

    .title {
      cursor: pointer;

      .title-m {
        padding-left: 10px;
      }
    }
  }

  .reinvest-btn {
    text-align: center;
    width: 100%;

    .button {
      width: 100%;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 1199px) {
  app-profile {
    main {
      width: 100%;
      background: $white;
    }

    .section__user-data {
      flex-direction: column;

      .section__wrapper {
        padding: 20px 25px;
        width: 100%;
      }
    }

    .inner {
      padding: 0 20px;
      width: 100%;
    }

    .section__user-social {
      .section__wrapper {
        width: 100%;
      }
    }

    .shareholders__data {
      width: 50%;
      margin: 0 auto;
    }

    .nft {
      .section-toggle-mob {
        margin: 0;
      }

      .button {
        margin-top: 20px;
      }
    }

    .profile__section {
      .wrapper {
        .item-setting {
          width: 100%;
          flex-direction: column;

          .form-item-setting {
            width: 100%;
            padding: 20px 25px;
          }

          &:nth-child(2) {
            padding-top: 12px;
          }
        }
      }

    }
  }
}

@media screen and (max-width: 1023px) {
  app-profile {
    .section-info {
      display: flex;
      margin-top: 20px;
      align-items: center;
      justify-content: center;
    }

    .profile-img-wrapper {
      width: 120px;
      min-width: 120px;
      height: 120px;
    }

    .section-image {
      display: flex;
      justify-content: center;
      margin: -60px auto 0;

      .name {
        font-size: 18px;
      }

    }

    .profile__section .item-setting .form-item-setting {
      .toggle-item {
        max-width: 100px;
      }
    }

    .size-hint {
      width: 100%;
      word-break: break-all;
    }

    .section-image-wrapper {
      width: 90%;
      margin: 0 auto;
    }

    .link-blue {
      font-size: 16px !important;
    }

    .user-name {
      padding: 5px 0 20px 20px;

      .name {
        font-size: 16px;
        width: 100%;
      }
    }

    .profile-img-btn {
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  app-profile {
    .shareholders__data {
      width: 100%;
      margin-bottom: 10px;
    }

    .profile__wrapper {
      padding: 0;
    }

    .section__user-data {
      .section__wrapper {
        padding: 15px 0;
      }
    }

    .section__user-social {
      .section__wrapper {
        padding: 0;
      }
    }

    ::ng-deep .mat-select-panel {
      min-width: fit-content !important;
    }

    ::ng-deep .mat-select-panel::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: $gray-color6;
    }

    ::ng-deep .mat-select-panel::-webkit-scrollbar {
      width: 7px;
      background-color: #F5F5F5;
    }

    ::ng-deep .mat-select-panel::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: $gray-color3;
      box-shadow: 0 0 0 2px white;
    }

    .notification {
      .lnr-chevron-right {
        display: inline-block;
      }
    }

    .section-image-wrapper {
      width: 100%;
      padding: 0 20px;
    }

    .lnr.lnr-chevron-right {
      display: inline-block;
      font-weight: 900;
    }

    .profile__section {
      width: 100%;

      .title {
        padding: 0;
        font-size: 18px;

        .title-m {
          padding-left: 10px;
          display: inline-block;
        }
      }

      .wrapper {
        .item-setting {
          padding: 0;
          margin: 0;

          .form-item-setting {
            padding: 20px 0;
          }
        }
      }
    }

    .image-wrapper {
      height: 156px;
    }

    .backgroundImage {
      background-image: none !important;
      background-color: #253a6f;
    }

    .profile__section {
      &.profile__section--col {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}
