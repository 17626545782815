@import '../../../../assets/scss/variables';

app-upload-file-popup {
  .inner {
    width: 556px;
    max-width: 90vw;
    position: relative;
    padding: 20px 36px 36px 36px;
    max-height: 95% !important;
  }

  .form {
    padding: 0;
    width: 100%;
    border-top: 1px dashed $gray-color4;
  }
  .form-wrapper {
    padding: 20px 60px 10px 60px;
  }
  .lnr-cross {
    position: absolute;
    right: 20px;
    font-size: 16px;
    font-weight: 600;
    top: 20px;
    cursor: pointer;
    color: $blue-color1;
  }

  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: $white;
  }
  .spinner__button {
    position: relative;
    mat-spinner {
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }
  .block-title {
    font-weight: 900;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    margin: 10px 0 25px;
  }

  .text {
    font-size: 15px;
    text-align: center;
    margin-bottom: 10px;
  }

  .item-document, .file-doc__input {
    width: 177px;
    height: 110px;
    background: #EEFCFF;
    position: relative;
    border: 2px dashed $blue-color1;
    overflow: hidden;
    &:last-child {
      margin-left: 15px;

    }
    label {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
    }
    span, .fa-camera {
      color: $blue-color1
    }
    img {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      //top: 55px;
      // margin-top: -50%;
    }
    span {
      font-size: 14px;
    }
    .fa-camera {
      font-size: 20px;
      line-height: 23px;
    }
  }
  .file-doc__input {
    width: auto;
    &:last-child {
      margin-left: 0;
    }
  }
  .loader-picture__image {
    width: 100%;
  }
  .upload__file-doc {
    margin-bottom: 20px;
  }
  #button-upload{
    width:200px;
  }
  .file-doc__title {
    font-size: 14px;
    text-align: left;
    color: #8089A0;
    &.file-doc__title_padding {
      padding: 10px 0;
    }
  }
  .wrapper-upload {
    // padding-bottom: 32px;
    &.disabled {
      opacity: 0.5;
    }

  }

  .passport, .idCard {
    width: 100%;
    text-align: center;
  }
  .wrapper-spinner {
    display: flex;
    justify-content: center;
  }
  .idCard__text {
    font-size: 14px;
    padding-bottom: 10px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
  }
  .idCard {
    &.backside {
      margin-top: 10px;
    }
    .loader-picture__wrapper {
      height: 150px;
      text-align: center;
      img {
        height: 100%;
        width: auto;
      }
    }
  }
  input[type="file"] {
    display: none;
  }
  .image {
    display: block;
    margin: 0 auto;
    height: 130px;
  }

  mat-form-field {
    width: 100%;
  }

  .fa-envelope {
    color: $link-color;
    margin: 0 20px 0 12px;
  }
  .button-wrapper {
    border-top: 1px dashed $gray-color4;
    padding-top: 27px;
    flex-direction: column;

    .reload-photo {
      margin-top: 20px;
    }

  }
  .button {
    width: 100%;
    max-width: 230px;
    margin: 0 auto;
    font-size: 16px;
  }
  .mobile-upload {
    display: none;
  }
  .desktop-upload {
    display: block;
  }

  app-popup .popup-wrapper {
    overflow-y: scroll;
  }
}

@media screen and (max-width: 767px) {
  app-upload-file-popup {
    .inner {
      padding: 30px 20px !important;
      max-width: 100vw !important;
      height: 100%;
    }

    .kyc-upload-popup {
      .button-readable {
        width: 100% !important;
        font-size: 16px !important;
      }
      .button-upload{
        font-size: 16px !important;
        width: 100% !important;
      }
    }

    .mobile-button {
      justify-content: space-between;

      .button {
        width: auto !important;
        min-width: auto !important;
        height: 40px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 !important;
        &.take-photo-btn {
          width: 115px !important;
        }
        &:first-child {
          padding: 0 10px;
        }
        &.isActive {
          background: $blue-color1;
          color: $white-color;
        }
        &.disabled {
          opacity: .3;
          border: 2px solid #949494;
          cursor: default;
        }
      }

      label {
        justify-content: center;
        padding: 0 10px;
        white-space: nowrap;
        margin-left: 14px;
      }
    }
    .item-document {
      width: 100%;
      margin-top: 16px;
      &:last-child {
        margin-left: 0;
      }
    }
    .popup-wrapper {
      width: 100% !important;
      max-height: 100%;
      height: 100%;
      top: 0;
    }
    .button-wrapper {
      border: none;
      padding-top: 0;
      button {
        max-width: 100%;
        width: 100%;
      }
    }
    .mobile-preview {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;
      top: 0;
      left: 0;
      //  padding: 0 16px;
      background: white;
      label {
        min-width: 100%;

        justify-content: center;
        align-items: center;
        display: flex;
      }
      img {
        width: 100%;
      }
      .img-block {
        max-width: 80%;
        margin: 0 auto;
        flex: 1;
        width: 100%;
        padding: 20px 0;
        text-align: center;
      }
      .button-wrapper {
        margin-bottom: 40px;
      }
    }
    .other-photo {
      line-height: 48px;
      margin-top: 16px
    }
    .form {
      padding: 0;
    }
    .block-title {
      font-size: 18px;
    }
    .form-wrapper {
      padding: 20px 0 0 0;
    }
    .wrapper-upload {
      flex-direction: column;
      padding-bottom: 20px;
    }
    .mobile-upload {
      display: block;
    }
    .desktop-upload {
      display: none;
    }
  }
}
