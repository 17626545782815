@import "../../../../assets/scss/variables";

app-subscribe-newsletter {
  display: block;
  position: relative;

  .subscribe-title {
    text-align: center;
    font-weight: 900;
    font-size: 27px;
    margin-bottom: 25px;
  }
  .subscribe__button-wrapper {
    display: flex;
    margin: 0 auto;
    justify-content: center;
  }
  .subscribe__button {
    margin-left: 20px;
  }
  .subscribe__input {
    border-radius: 50px;
    border: none;
    width: 325px;
    padding: 0 20px;
    font-size: 16px;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    font-size: 16px;
    text-align: center;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 16px;
    text-align: center;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 16px;
    text-align: center;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    font-size: 16px;
    text-align: center;
  }
  .subscribe__text {
    text-align: center;
    margin-top: 15px;
    font-weight: 400;
    font-size: 12px;
    color: $gray-color3;

    .link-blue {
      font-size: 12px;
    }
  }

  .subscribe__success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;

    img {
      width: 50px;
      object-fit: contain;
    }

    p {
      font-weight: 900;
      font-size: 27px;
      margin-top: 16px;
      text-align: center;
    }
  }

  .subscribe--new {
    .subscribe-title {
      font-size: 20px;
      margin-bottom: 18px;
      text-align: center;
    }
    .subscribe__input {
      border-radius: 27px;
      border: 1px solid $gray-color3;
      width: 100%;
      padding: 16px 20px;
      font-size: 18px;
      text-transform: none;
    }
    .subscribe__button {
      width: 100%;
      margin: 15px auto;
    }
  }
}

@media screen and (max-width: 767px) {
  app-subscribe-newsletter {
    .subscribe-title {
      padding: 0 20px;
      margin-bottom: 15px;
    }
    .subscribe__text {
      span {
        display: inline-block;
        width: 100%;
      }
    }
  }
}
