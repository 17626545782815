@import '../../../assets/scss/variables';

app-music-item-list {

  .music-cards {
    display: flex;
    flex-wrap: wrap;
    max-width: 1350px;
    justify-content: center;
    margin: 0 auto;
  }
  .music-cards__item:hover {
    z-index: 10;
  }
  .empty-item {
    width: 310px;
    margin: 0 12px;
  }
  .fa-music {
    font-size: 12px;
    background: white;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .lnr-calendar-full {
    cursor: auto;
  }
  .fa-question-circle {
    font-size: 16px;
    color: $blue-color1;
    cursor: pointer;
  }
  .fa-question-circle:hover + .timer-hint {
    .tooltip {
      display: block;
      right: -20px;
      top: -200px;
    }
  }
  .image {
    display: block;
    height: 240px;
    width: auto;
  }
  .tooltip {
    text-align: left;
    right: -20px;
    top: -120px;
    padding: 14px;
    width: 250px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -6px;
      right: 5px;
      width: 0;
      height: 0;
      border-top: 7px solid rgba(0, 0, 0, 0.7);
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: none;
    }
    &.tooltip-wrap {
      &:after {
        right: 160px;
      }
    }
  }
  .wrapper-tooltip {
    display: block;
    position: relative;
    width: 0;
    height: 100%;
  }
  .action-footer-news {
    justify-content: flex-end;
    padding: 0;
    cursor: auto;
    align-items: center;
    flex-direction: row;
  }
  .social-wrapper {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
  }
  .followers-container {
    width: 30px;
    margin: 0 4px;
    height: 28px;
    justify-content: flex-end;
    word-wrap: normal;
    &:last-child {
      margin-right: 0;
    }
    a {
      display: block;
    }
  }

  .status-footer {
    padding: 0 20px;
    height: 77px;
    justify-content: center;
    flex-direction: column;
    display: flex;
    border-top: 1px dashed #A8C1D3;
    font-weight: 900;
    font-size: 24px;
    .button {
      width: 240px;
      margin: 0 auto;
      font-size: 16px;
    }
    .status-declined {
      font-weight: bold;
    }
  }

  .play-button {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid #fff;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: rgba(0, 0, 0, 0.7);
    }
    .fa-play {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 1400px) {
  app-music-item-list {
    .empty-item {
      margin: 0 10px 0;
    }
    .music-cards {
      max-width: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  app-music-item-list {
    .music-cards__item {
      margin: 0 auto;
    }
  }
}
@media screen and (max-width: 767px) {
    .card {
      margin: 0 auto;
    }
    .play-button {
      position: absolute;
      top: 45%;
      &.desktop{
        display: none;
      }
      &.mobile{
        display: flex;
      }
    }
}

@media screen and (max-width: 320px) {
    .card-outer {
      width: 290px;
    }
    .card__background {
      width: 290px;
    }
}
