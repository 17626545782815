@import '../../../../assets/scss/variables';

app-timer {
  .timer {
    width: 217px;
    height: 133px;
    background: #DFEBF4;
    box-shadow: 0 2px 4px rgba(144, 170, 190, 0.5);
    border-radius: 7px;
    &.cardBlack {
      background: transparent;
      margin: 0 auto;
      box-shadow: none;
      height: auto;
      .timer__title {
        font-size: 11px;
        padding: 0 0 8px 0;
      }
      .timer__timing-item {
        height: 32px;
        width: 30px;
        margin: 0 4px;
        background: $black-color1;
        font-size: 20px;
        line-height: 32px;
        &:first-child{
          margin-left: 0;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }

  }
  .timer__title {
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    color: #000000;
    padding: 15px 0;
  }
  .timer__timing {
    display: flex;
    justify-content: center;

  }

  .timer__timing-item {
    width: fit-content;
    height: 38px;
    min-width: 34px;
    padding: 0 5px;
    text-align: center;
    line-height: 38px;
    font-size: 22px;
    color: #FFFFFF;
    font-weight: 700;
    margin: 0 4px;
    background: #8089A0;
    border-radius: 5px;
    outline: 1px solid rgba(255, 255, 255, 0.6);
  }
}

@media screen and (max-width: 767px) {
}
