@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';
app-footer-about-company {
  .about-company {
    background: #F2F7FB;
    border-radius: 0 7px 7px 7px;
    padding: 26px 36px;
    margin: 26px auto 0;
    @include screen1380 {
      padding: 28px;
    }
    @include screen767 {
      background: #F2F7FB;
      margin: 15px 0;
      padding: 15px 18px;
      border-radius: 0 7px 7px 7px;
    }
    .about-company-title {
      margin-bottom: 15px;
      font-size: 15px;
      font-weight: 700;
      @include screen767 {
        font-size: 15px;
        font-weight: 700;
      }
    }

    .gray-text {
      margin-top: 4px;
    }
    p {
      margin-top: 4px;
    }
  }

}
