@import "assets/scss/variables";
@import "assets/scss/mixin";

app-payment-methods {
  google-pay-button{
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
  }


  .payment-item {
    border: 2px solid $blue-color1;
    position: relative;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    margin: 10px;
    cursor: pointer;
  }

  mat-radio-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .mat-radio-button {
    border: 2px solid $blue-color1;
    border-radius: 5px;
    height: 45px;
    margin-top: 15px;

    &.mat-radio-disabled {
      border: 2px solid $gray-color10;

      img {
        filter: contrast(0);
        opacity: 0.3;
      }

      .payment_name {
        color: $gray-color10;
      }

      .mat-radio-outer-circle {
        border-color: $gray-color10;
      }
    }

  }

  .mat-radio-label-content {
    width: 100%;
  }

  .mat-radio-label {
    padding: 0 20px;
  }

  .radio-content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 42px;
    align-items: center;
  }

  .klarna-wrapper {
    display: flex;
    align-items: center;
  }

  .tooltip-klarna {
    margin-right: 15px;

    &__title {
      font-weight: 700;
    }

    .m-b-10 {
      margin-bottom: 10px;
    }

    .tooltip-card {
      top: 40px;
      width: 200px;
      right: -40px;
      white-space: pre-wrap;
      &:after {
        top: -6px;
        right: 138px;
      }
    }
    .hover-block{
      height: 22px;
    }
    img {
      width: 22px;
      height: 22px;
    }
  }

  .card-payment {
    display: flex;
    align-items: center;

    .visa {
      margin-right: 10px;
    }
  }

  .mat-radio-outer-circle {
    border-color: $blue-color1;
  }

  .mat-radio-button.active {
    background-color: $blue-color14;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $blue-color1;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $blue-color1;
  }

  .payment_name {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-left: 12px;
    color: $black-color1;
  }

  .klarna {
    height: 18px;
  }

  .maestro {
    height: 35px;
    margin-right: -5px;
  }

  .payments {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include maxW(576px) {
      padding: 0 22px;
    }
  }

  .direct-payment {
    margin: 15px 0;
    width: 100%;

    .google-pay {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &.disablePayment {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}

@media screen and (max-width: 767px) {

}
