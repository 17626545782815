@import '../../../../assets/scss/variables';

app-dropdown {
  position: relative;
  z-index: 2;
  cursor: pointer;
  .item-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fa-chevron-down {
    padding-left: 5px;
  }
  .menu {
    background: #fff;
    border-radius: 5px;
    position: absolute;
    top: 20px;
    right: 0;
    width: 216px;
    overflow: hidden;
    box-shadow: $default-shadow;
    height: 0;
    transition: all .25s ease-out 0s;
    &.active {
      height:auto;
      overflow-y: scroll;
    }
  }
  .menu-item {
    font-size: 15px;
    font-weight: 900;
    padding: 10px;
    color: $blue-color1;
    cursor: pointer;
    &:hover {
      background: #e0f1ff;
    }
  }
  .fa-chevron-down {
    padding-left: 5px;
  }
}
