@import '../../../assets/scss/variables';

app-artist-details {
  .wrapper-content {
    width: 95%;
    margin-bottom: 3rem;
  }
  section.about-artist {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    max-width: 1380px;
    height: 475px;
    background: #F2F7FB;
    box-shadow: 0 2px 4px rgba(144, 170, 190, 0.5);
    border-radius: 7px;
    .artist-info-block {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .artist-details-list {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      list-style-type: none;
    }
    .artist-img {
      display: flex;
      width: 324px;
      height: 273px;
      border-radius: 5px;
      object-fit: cover;
    }
    .artist-text-block {
      display: flex;
      flex-direction: column;
      gap: 15px;
      max-width: 551px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
    }
    .artist-text-title {
      line-height: 32px;
      font-style: normal;
      font-weight: 500;
      font-size: 27px;
      text-transform: uppercase;
    }
    .artist-text-subtitle {
      line-height: 23px;
      color: #000000;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
    }
    .artist-text-info {
      white-space: pre-wrap;
      text-align: justify;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #8089A0
    }
    ul.static li {
      margin: 1rem;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
    }
    ul.artist-object li {
      margin: 1.05rem;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #8089A0;
    }
  }

  #video {
    margin: 20px;
  }

  section.investment-opportunity {
    .investment-image {
      background-size: cover;
      justify-content: flex-end;
      display: flex;
      margin: auto;
      align-items: center;
      max-width: 1380px;
      height: 235px;
      border-radius: 7px;
    }
    .investment-text {
      cursor: pointer;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 27px;
      line-height: 20px;
      color: #5AA2E5;
    }
  }

  .latest-releases {
    text-align: center;
    padding: 80px 0;

    h3, h2 {
      margin: 10px 0 30px 0;
    }
  }
  .fas {
    color: white;
  }

  .artist-new-release {
    margin: auto;
    max-width: 1380px;
    height: 475px;
    background: #F2F7FB;
    box-shadow: 0px 2px 4px rgba(144, 170, 190, 0.5);
    border-radius: 7px;
  }
  .new-release-info {
    position: relative;
    top: 50px;
    h2 {
      text-align: center !important;
    }
  }

  section.videos {
    .artist-videos {
      align-items: center;
      display: flex;
      margin: auto;
      flex-wrap: wrap;
      max-width: 1380px;
      min-height: 300px;
      justify-content: space-evenly;
    }
    .latest-videos {
      display: flex;
      width: 400px;
      height: 230px;
      background-image: url('/assets/images/testArtist/newvideo/image2.svg');
    }
    .artist-videos-mobile {
      display: none;
    }
  }

  section.event {
    max-width: 1380px;
    margin: auto;
    .event-buttons {
      width: 103px;
      height: 30px;
      margin: 0.5rem;
      background: #FFFFFF;
      border: 2px solid #5AA2E5;
      box-sizing: border-box;
      border-radius: 5px;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      text-transform: capitalize;
      color: #5AA2E5;
    }
    .event-date-text {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #000000;
    }
    .row {
      display: flex;
      align-items: center;
      border-top: 1px dashed #b7bccb;
      min-height: 69px;
      max-width: 1380px;
      margin: auto;
    }
    .col {
      flex: 1;
      text-align: center;
    }
  }

  .play-button {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid #FFFFFF;
    border-radius: 27px;
  }

  .titles {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 32px;
    line-height: 37px;
    text-transform: uppercase;
    color: #000000;
  }

  .social-icons {
    display: flex;
    gap: 3px;
  }

  .facebook {
    margin: 0.3rem;
    width: 30px;
    height: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-transform: capitalize;
    background-image: url('/assets/images/social/facebook.png')
  }

  .spotify {
    margin: 0.3rem;
    width: 30px;
    height: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-transform: capitalize;
    background-image: url('/assets/images/social/spotify.png')
  }

  .twitter {
    margin: 0.3rem;
    width: 30px;
    height: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-transform: capitalize;
    background-image: url('/assets/images/social/twitter.png')
  }

  .instagram {
    margin: 0.3rem;
    width: 30px;
    height: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-transform: capitalize;
    background-image: url('/assets/images/social/instagram.png')
  }

  .youtube {
    margin: 0.3rem;
    width: 30px;
    height: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-transform: capitalize;
    background-image: url('/assets/images/social/youtube.png')
  }

  .background-tone {
    background-color: white;
    position: relative;
    top: 1px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .share-button {
    padding-right: 108px;
  }

  .play-button-web {
    padding-right: 230px;
  }

  .mobile {
    display: none !important;
  }

  .page-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #8089A0;
  }
}

@media screen and (min-width: 100px) and (max-width: 960px) {
  app-artist-details {
    app-new-music-slider {
      .next-slide {
        position: absolute;
        left: -25px;
      }
      .prev-slide {
        position: absolute;
        right: -25px;
      }
    }
    app-video-slider {
      .next-slide {
        position: absolute;
        left: -25px;

      }
      .slick-initialized {
        .slick-slide {
          display: flex;
          justify-content: center;
        }
      }
      #video {
        margin: 0;
      }
      .nav-btn {
        color: #5AA2E5;
        width: 20px;
        height: 20px;
        z-index: 10;
        top: 50%;
      }
      .prev-slide {
        position: absolute;
        right: -25px;
      }
    }
    section.about-artist {
      height: auto;
      background-color: white;
      .artist-img {
        width: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
      }
      .artist-info-block {
        gap: 10px;
        flex-direction: column;
        align-items: center;
      }
      .artist-details-list {
        gap: 10px;
      }
      .social-icons {
        justify-content: center;
      }
      .artist-text-title {
        text-align: center;
      }
      .artist-text-info {
        padding: 0px 20px;
      }
      .artist-text-subtitle {
        text-align: center;
      }
    }
    section.investment-opportunity {
      margin: 2rem;
    }
    section.latest-releases {
      background: white;
      .inner2 {
        flex-shrink: 0;
      }
      .artist-slider {
        justify-content: center;
        gap: 16px;
        height: auto;
        background-color: white;
      }
      .artist-slider-images {
        gap: 16px;
        justify-content: center;
        width: fit-content;
        margin: 3rem;
      }
      .slider-mobile-image {
        width: 240px !important;
        height: 240px !important;
        background-position: 50% !important;
        background-repeat: no-repeat !important;
        background-size: contain;
      }
    }
    section.artist-new-release {
      margin: 60px 20px 60px 20px;
      gap: 20px;
      height: auto;
      flex-direction: column;
      background-color: white;
      .release-title {
        text-align: center;
        width: auto;
      }
      .release-text {
        text-align: justify;
      }
      .release-img {
        width: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        width: 265px;
        height: 164px;
      }
      .release-text {
        margin: 1rem;
      }
      .new-release-info {
        position: unset;
      }
    }
    section.videos {
      .artist-videos {
        display: none;
      }
      .artist-videos-mobile {
        display: flex !important;
        justify-content: center;
        align-items: center;
      }
    }
    .background-tone {
      background-color: #F2F7FB;;
    }
    .share-button {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      padding: 0px;
    }
    .play-button-web {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      padding: 0px;
      position: relative;
    }
    .web {
      display: none !important;
    }
    .mobile {
      display: flex !important;
    }
    .titles {
      text-align: center;
    }
  }
}
